.main-navigation-vertical-brand__logo-container {
    img {
        min-width: 150px;
        height: auto;
    }

    svg {
        font-size: 45px;
        cursor: pointer;
    }
}

.main-navigation-vertical-brand__icon-wrapper {
    width: 150px;

    .main-navigation-vertical-brand__cart-link,
    .main-navigation-vertical-brand__user-link {
        svg {
            transform: scale(1.2);
        }
    }

    .country-switch__select-country {
        svg {
            transform: scale(1.6);
        }
    }
}

.main-navigation-vertical-brand__cart-item-amount {
    top: -5px;
    right: 0;
}

.main-navigation-vertical-brand__icon {
    fill: var(--theme-primary);
}

.main-navigation-vertical-brand__burger-menu {
    svg {
        transform: scale(1) !important; /* stylelint-disable-line declaration-no-important */
    }
}

/**
* Desktop styles
*/
.main-navigation-vertical-menu__desktop-wrapper {
    max-width: 600px;
    margin-top: 4px;
}

.main-navigation-vertical-menu__desktop-wrapper:hover .main-navigation-vertical-menu__main-navigation-link-hovered {
    @include mq($from: desktop) {
        svg {
            transform: scale(2.3) rotate(180deg) !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}

/**
* Mobile styles
*/
.main-navigation-vertical-menu__child-navigation-link-active {
    svg {
        @include mq($until: desktop) {
            transform: scale(2.3) rotate(0deg) !important; /* stylelint-disable-line declaration-no-important */
        }

        @include mq($from: desktop) {
            transform: scale(2.3) rotate(180deg) !important; /* stylelint-disable-line declaration-no-important */
        }
    }
}

.main-navigation-vertical-menu__arrow-icon-rotation-state {
    @include mq($until: desktop) {
        svg {
            @include mq($until: desktop) {
                transform: scale(2.3) rotate(180deg) !important; /* stylelint-disable-line declaration-no-important */
            }

            @include mq($from: desktop) {
                transform: scale(2.3) rotate(0deg) !important; /* stylelint-disable-line declaration-no-important */
            }
        }
    }
}

/**
* Common styles
*/
.main-navigation-vertical-menu__main-navigation-link {
    @include mq($until: desktop) {
        overflow: hidden;

        svg {
            font-size: 25px;
        }

        a,
        span {
            font-weight: var(--fw-bold);
        }
    }

    @include mq($from: desktop) {
        a,
        span {
            font-weight: var(--fw-normal);
        }
    }

    svg {
        padding-top: 1px !important; /* stylelint-disable-line declaration-no-important */
        transform: scale(2.3) rotate(0deg);
    }

    &:hover {
        svg {
            @include mq($from: desktop) {
                transform: scale(2.3) rotate(180deg) !important; /* stylelint-disable-line declaration-no-important */
            }
        }
    }
}

.main-navigation-vertical-menu__child-navigation-wrapper {
    @include mq($from: desktop) {
        inset: 65px 0 0 !important; /* stylelint-disable-line declaration-no-important */
    }
}

.main-navigation-vertical-menu__main-navigation-link-active {
    height: 35px;
}

.main-navigation-vertical-menu__navigation-list-item {
    padding-left: 0;

    &--headline {
        font-weight: var(--fw-semibold);
    }

    @include mq($until: desktop) {
        &--headline {
            font-weight: var(--fw-semibold);
            margin-bottom: 0;
        }
    }
}

@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed/SofiaSansCondensed-VariableFont_wght.woff2') format('woff2');
    font-style: normal;
    font-weight: 200 1000;
    font-display: swap;
}
@font-face {
    font-family: 'Sofia Sans Condensed';
    src: url('../assets/fonts/SofiaSansCondensed/SofiaSansCondensed-Italic-VariableFont_wght.woff2') format('woff2');
    font-display: swap;
    font-style: italic;
    font-weight: 200 1000;
}

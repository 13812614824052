.main-navigation-vertical-brand__wrapper {
    border-bottom: 1px solid var(--border-color-main-navigation);
    position: relative;
    z-index: 5;
}

.main-navigation-vertical-brand__content {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.main-navigation-vertical-brand__logo-container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.main-navigation-vertical-brand__burger-menu {
    @include mq($from: desktop) {
        display: none;
    }

    svg {
        font-size: 45px;
        transform: scale(0.7);
        cursor: pointer;
    }
}

.main-navigation-vertical-brand__icon-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-column: 3;
    grid-area: actions;
    margin-right: 8px;
    position: relative;
    place-self: center flex-end;

    > div,
    > a {
        margin: 0 4px;
        padding: 4px;
    }

    @include mq($from: desktop) {
        margin-right: 0;
        align-items: center;
    }

    .icon {
        color: var(--color-icons);
        fill: var(--color-icons);
        transform: scale(1);
        font-size: 25px;
        display: block;
    }

    .country-switch__title {
        display: none;
    }

    .country-switch {
        position: unset;
        padding: 0;
        @include mq($from: tablet) {
            position: relative;
        }
    }

    .country-switch__select-container {
        @include mq($until: tablet) {
            position: absolute;
            background-color: #fff;
            z-index: var(--zi-index-dropdown);
            padding: 30px;
            left: calc(-99vw + 100%);
            width: 101vw;
            border-right: none;
            border-left: none;
        }
    }

    .dropdown__header {
        font-size: 16px;

        /* stylelint-disable-next-line selector-class-pattern */
        .icon--ChevronDown {
            display: none;

            @include mq($from: tablet) {
                display: inline-block;
            }
        }
    }
}

.main-navigation-vertical-brand__cart-item-amount {
    position: absolute;
    top: 0;
    left: 15px;
    color: #fff;
    background-color: var(--theme-primary);
    border-radius: 50%;
    font-size: 0.8em;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    font-weight: var(--fw-normal);
}

.main-navigation-vertical-brand__login-wrapper {
    position: relative;
    cursor: pointer;
}

.main-navigation-vertical-brand__login-wrapper:hover .login-dropdown {
    display: block;
}

.main-navigation-vertical-brand__icon {
    transform: scale(1.2);
}

.main-navigation-vertical-brand__icon:hover {
    cursor: pointer;
}

.main-navigation-vertical-brand__cart-link {
    position: relative;
}

.main-navigation-vertical-brand__shipping-wrapper {
    display: none;
    grid-area: shipping;
    color: var(--free-delivery-highlighted);
    font-family: var(--ff-menu);
    flex-direction: column;
    justify-content: center;

    @include mq($from: desktop) {
        display: flex;
    }

    p:first-of-type {
        font-family: var(--ff-menu);
        font-size: 0.7rem;
        font-weight: 800;

        @include mq($from: tablet) {
            font-size: 1.6rem;
        }
    }

    p {
        margin: 0;
        line-height: 1.1;
        font-size: 0.5rem;

        @include mq($from: tablet) {
            font-size: 1rem;
        }
    }
}

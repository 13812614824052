/**
* Desktop styles
*/
.main-navigation-vertical-menu__desktop-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;

    a {
        color: var(--menu-color-primary);
    }
}

.main-navigation-vertical-menu__desktop-wrapper--link-text {
    @include mq($from: desktop) {
        padding: 14px 1px;
        position: relative;
    }
}

.main-navigation-vertical-menu__desktop-wrapper:hover .main-navigation-vertical-menu__main-navigation-link-hovered {
    @include mq($from: desktop) {
        svg {
            transform: scale(0.9) rotate(180deg);
            margin-top: 17px;
        }
    }
}

.main-navigation-vertical-menu__arrow-down {
    @include mq($from: desktop) {
        margin: 14px 0;
    }
}

.main-navigation-vertical-menu__desktop-wrapper--child-menu-container {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    &--with-child-heading {
        display: flex;
        gap: 25px;
    }
}

/**
* Mobile styles
*/
.main-navigation-vertical-menu__arrow-icon-rotation-state {
    @include mq($until: desktop) {
        svg {
            @include mq($until: desktop) {
                transform: scale(0.9) rotate(180deg);
            }

            @include mq($from: desktop) {
                transform: scale(0.9) rotate(0deg);
            }
        }
    }
}

.main-navigation-vertical-menu__mobile-wrapper {
    position: absolute;
    top: 81px;
    right: 0;
    left: 0;
    height: 100vh;
    z-index: 10;
    background-color: var(--modal-overlay-bg-color);
}

.main-navigation-vertical-menu__mobile-content-wrapper {
    background-color: var(--color-white-true);
    max-height: calc(100% - var(--mobile-menu-top));
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 10px;
}

@supports (height: 100dvh) {
    .main-navigation-vertical-menu__mobile-wrapper {
        height: 100dvh;
    }

    .main-navigation-vertical-menu__mobile-content-wrapper {
        max-height: calc(100dvh - var(--mobile-menu-top));
    }
}

/**
* Common styles
*/
.main-navigation-vertical-menu__main-navigation-link {
    cursor: pointer;
    color: var(--menu-color-primary);
    border-bottom: 3px solid transparent;
    height: 42px;
    font-size: var(--fs-main-navigation-desktop);
    width: fit-content;
    display: flex;
    align-items: center;

    svg {
        margin-left: 5px;
        padding-top: 4px;
        transition: transform 0.3s ease;
        fill: var(--menu-color-primary);
    }

    &:hover {
        svg {
            @include mq($until: desktop) {
                margin-top: 0;
            }

            @include mq($from: desktop) {
                margin-top: 17px;
                transform: scale(0.9) rotate(180deg);
            }
        }
    }

    @include mq($until: desktop) {
        font-size: var(--fs-main-navigation-mobile);
        height: auto;
        padding: 3px;
        width: calc(100% - 23px);
        justify-content: space-between;
        margin-left: 10px;
    }

    a {
        display: inline-flex;
        color: var(--menu-color-primary);
        font-size: var(--fs-main-navigation-desktop);

        @include mq($until: desktop) {
            font-size: var(--fs-main-navigation-mobile);
        }
    }
}

.main-navigation-vertical-menu__main-navigation-link-active,
.main-navigation-vertical-menu__main-navigation-link-hovered {
    color: var(--color-main-navigation-vb-hover);
    border-bottom: solid 3px var(--color-main-navigation-vb-hover);

    a,
    span {
        color: var(--color-main-navigation-vb-hover);
    }

    svg {
        fill: var(--color-main-navigation-vb-hover);
    }
}

.main-navigation-vertical-menu__child-navigation-link-active {
    color: var(--color-main-navigation-vb-hover);

    a,
    span {
        color: var(--color-main-navigation-vb-hover) !important; /* stylelint-disable-line declaration-no-important */
    }

    svg {
        fill: var(--color-main-navigation-vb-hover);

        @include mq($until: desktop) {
            transform: scale(0.9) rotate(0deg);
        }

        @include mq($from: desktop) {
            transform: scale(0.9) rotate(180deg);
        }
    }
}

.main-navigation-vertical-menu__child-navigation-wrapper {
    @include mq($from: desktop) {
        position: absolute;
        z-index: 10;
        inset: 61px 0 0;
        pointer-events: none;
    }
}

.main-navigation-vertical-menu__child-navigation {
    @include mq($from: desktop) {
        pointer-events: all;
        position: absolute;
        background-color: var(--theme-beige);
        z-index: 15;
        left: 0;
        right: 0;
        top: 0;
    }

    @include mq($until: desktop) {
        background-color: var(--theme-beige);
        padding: 10px 0;
        margin-bottom: 10px;
    }
}

.main-navigation-vertical-menu__navigation-list {
    display: flex;
    flex-flow: column wrap;
    gap: 10px 15px;
    list-style-type: none;

    &--with-child-heading {
        gap: 5px 0;
    }

    @include mq($from: desktop) {
        margin: 20px 0 20px 5px;

        &--with-child-heading {
            max-height: fit-content;
        }
    }

    @include mq($until: desktop) {
        margin: 10px 0 20px 25px;

        &--with-child-heading {
            margin: 10px 0 10px 20px;
        }
    }
}

.main-navigation-vertical-menu__navigation-list-item {
    margin: 0;
    font-size: var(--fs-main-navigation-desktop);
    width: fit-content;

    &--headline {
        margin-bottom: 3px;
    }

    @include mq($until: desktop) {
        font-size: var(--fs-main-navigation-mobile);
        width: auto;
    }

    a,
    span {
        color: var(--menu-color-primary);

        @include mq($until: desktop) {
            font-size: var(--fs-main-navigation-mobile);
        }
    }

    a:hover {
        color: var(--color-main-navigation-vb-hover);
    }

    span {
        padding: 6px 0;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.main-navigation-vertical-menu__backdrop {
    position: relative;

    @include mq($from: desktop) {
        &::after {
            opacity: 1;
            pointer-events: unset;
        }
    }
}

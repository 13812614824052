/* stylelint-disable declaration-no-important */

body,
ul,
li,
span,
p,
button,
a {
    font-family: var(--ff-base) !important;
}

p {
    margin: 12px 0 !important;
}

.product__title > a.link-open-in-tab {
    font-family: var(--ff-headline) !important;
}

/* Headlines */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-family: var(--ff-headline) !important;
    margin: 0;
    color: var(--color-gray-800) !important;
}

.title-h1 {
    color: var(--theme-primary) !important;
}

.title-h2 {
    font-family: var(--ff-headline) !important;
}

.title-h3 {
    font-family: var(--ff-headline) !important;
}

.title-h4 {
    font-family: var(--ff-headline) !important;
}

/* Sub-Headlines */
.h1-sub {
    font-family: var(--ff-headline) !important;
    color: var(--theme-gray) !important;
    font-weight: var(--fw-normal) !important;
}

.title-h1-sub {
    font-family: var(--ff-base) !important;
    color: var(--theme-gray) !important;
    font-weight: var(--fw-normal) !important;
}

.title-h2-sub {
    font-family: var(--ff-base) !important;
    color: var(--theme-gray) !important;
    font-weight: var(--fw-normal) !important;
}

.title-h3-sub {
    font-family: var(--ff-headline) !important;
    color: var(--theme-gray) !important;
    font-weight: var(--fw-normal) !important;
}

.title-h4-sub {
    font-family: var(--ff-headline) !important;
    color: var(--theme-gray) !important;
    font-weight: var(--fw-normal) !important;
}

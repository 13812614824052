@charset "UTF-8";
@import '~normalize.css';
@import '~normalize.css';
@import '~swiper/swiper-bundle.css';
:root {
  --breakpoint-mobile: 375px;
  --breakpoint-tablet: 768px;
  --breakpoint-desktop: 1200px;
  --breakpoint-wide: 1300px;
}

@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/nunito/Nunito-VariableFont_wght.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 200 1000;
}
@font-face {
  font-family: "Nunito";
  src: url("../../assets/fonts/nunito/Nunito-Italic-VariableFont_wght.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
  font-weight: 200 1000;
}
@font-face {
  font-family: "Oswald";
  src: url("../../assets/fonts/oswald/Oswald-VariableFont_wght.woff2") format("woff2");
  font-display: swap;
  font-weight: 200 700;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../../assets/fonts/robotoCondensed/RobotoCondensed-VariableFont_wght.woff2") format("woff2");
  font-display: swap;
  font-style: normal;
  font-weight: 200 1000;
}
@font-face {
  font-family: "Roboto Condensed";
  src: url("../../assets/fonts/robotoCondensed/RobotoCondensed-Italic-VariableFont_wght.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
  font-weight: 200 1000;
}
/** Any global variables go here */
:root {
  --border-radius: 10px;
  --border-radius-sm: 2px;
  --sp-base: 1rem;
  --sp-xs: 0.5rem;
  --sp-sm: 0.8rem;
  --sp-md: var(--sp-base);
  --sp-lg: 1.5rem;
  --sp-xl: 2rem;
  --sp-xxl: 3rem;
}

:root {
  --ff-headline: sans-serif;
  --ff-base: sans-serif;
  --ff-title: sans-serif;
  --ff-product-price: "Oswald", sans-serif;
  --fw-light: 300;
  --fw-thin: var(--fw-light);
  --fw-normal: 400;
  --fw-regular: var(--fw-normal);
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 600;
  --fw-black: 800;
  --fw-title: var(--fw-black);
  --fw-headline: var(--fw-semibold);
  --fw-base: var(--fw-regular);
  --lh-default: 1;
  --lh-base: var(--lh-default) * 1.45;
  --lh-small: var(--lh-default);
  --lh-big: var(--lh-default) * 2;
  --fs-base-px: 15;
  --fs-xxs: calc(0.4rem / 16) * var(--fs-base-px);
  --fs-xs: calc(0.8rem / 16) * var(--fs-base-px);
  --fs-s: calc(0.9rem / 16) * var(--fs-base-px);
  --fs-base: calc(1rem / 16) * var(--fs-base-px);
  --fs-l: calc(1.33rem / 16) * var(--fs-base-px);
  --fs-xl: calc(1.66rem / 16) * var(--fs-base-px);
  --fs-xxl: calc(2.66rem / 16) * var(--fs-base-px);
  --fs-xxxl: calc(3.6rem / 16) * var(--fs-base-px);
  --fs-xxxxl: calc(3.8rem / 16) * var(--fs-base-px);
  --fs-h1-mobile: var(--fs-xl);
  --fs-h1: var(--fs-xxl);
  --fs-h1-sub-mobile: var(--fs-l);
  --fs-h1-sub: var(--fs-xl);
  --fs-h2-mobile: var(--fs-l);
  --fs-h2: var(--fs-xl);
  --fs-h2-sub-mobile: var(--fs-base);
  --fs-h2-sub: var(--fs-l);
  --fs-h3-mobile: var(--fs-base);
  --fs-h3: var(--fs-l);
  --fs-h3-sub-mobile: var(--fs-s);
  --fs-h3-sub: var(--fs-base);
  --fs-h4-mobile: var(--fs-base);
  --fs-h4: var(--fs-l);
  --fs-h4-sub-mobile: var(--fs-s);
  --fs-h4-sub: var(--fs-base);
  --fs-h5-mobile: var(--fs-s);
  --fs-h5: var(--fs-base);
  --fs-h6-mobile: var(--fs-s);
  --fs-h6: var(--fs-base);
}

/* Please file all your z-index values in here */
:root {
  --zi-cart-product: 0;
  --zi-btn: 1;
  --zi-login-form-loading: 2;
  --z-index-slider-arrows: 100;
  --zi-table-cell-first-child: 100;
  --zi-search-backdrop: 101;
  --zi-main-navigation: 102;
  --zi-search-bar-autosuggest: 103;
  --zi-search-bar: 104;
  --zi-login-dropdown: 105;
  --zi-flyout: 120;
  --zi-badge: 130;
  --zi-sticky-button: 140;
  --zi-index-dropdown: 200;
  --zi-loading: 200;
  --zi-emarsys: 997;
  --zi-sticky-bar: 998;
  --zi-main-menu-open: 999;
  --zi-modal: 1000;
  --zi-precheckout: 2000;
  --zi-mobile-menu: 2001;
  --zi-country-switch-modal-overlay: 2002;
}

:root {
  --font-base: sans-serif;
  --font-title: sans-serif;
  --font-headlines: sans-serif;
  --font-menu: sans-serif;
  --font-product-price: sans-serif;
  --fs-base-px: 15;
  --fs-xxs: calc(0.4rem / 16 * var(--fs-base-px));
  --fs-xs: calc(0.8rem / 16 * var(--fs-base-px));
  --fs-s: calc(0.9rem / 16 * var(--fs-base-px));
  --fs-sm: calc(0.9375rem / 16 * var(--fs-base-px));
  --fs-base: calc(1rem / 16 * var(--fs-base-px));
  --fs-l: calc(1.33rem / 16 * var(--fs-base-px));
  --fs-xl: calc(1.66rem / 16 * var(--fs-base-px));
  --fs-xxl: calc(2.66rem / 16 * var(--fs-base-px));
  --fs-xxxl: calc(3.6rem / 16 * var(--fs-base-px));
  --fs-xxxxl: calc(3.8rem / 16 * var(--fs-base-px));
  --fs-h1-mobile: var(--fs-xl);
  --fs-h1: var(--fs-xxl);
  --fs-h1-sub-mobile: var(--fs-l);
  --fs-h1-sub: var(--fs-xl);
  --fs-h2-mobile: var(--fs-l);
  --fs-h2: var(--fs-xl);
  --fs-h2-sub-mobile: var(--fs-base);
  --fs-h2-sub: var(--fs-l);
  --fs-h3-mobile: var(--fs-base);
  --fs-h3: var(--fs-l);
  --fs-h3-sub-mobile: var(--fs-s);
  --fs-h3-sub: var(--fs-base);
  --fs-h4-mobile: var(--fs-base);
  --fs-h4: var(--fs-l);
  --fs-h4-sub-mobile: var(--fs-s);
  --fs-h4-sub: var(--fs-base);
  --fs-h5-mobile: var(--fs-s);
  --fs-h5: var(--fs-base);
  --fs-h6-mobile: var(--fs-s);
  --fs-h6: var(--fs-base);
  --lh-default: 1.15;
  --lh-base: var(--lh-default) * 1.45;
  --ls-small: var(--lh-default);
  --lh-big: var(--lh-default) * 2;
  --color-gray-80: #ccc;
  --color-gray-200: #f0f0f0;
  --color-gray-250: #f7f7f7;
  --color-gray-300: #efefef;
  --color-gray-400: #ddd;
  --color-gray-550: #666;
  --color-gray-600: #707070;
  --color-gray-800: #333;
  --color-gray-900: #222;
  --color-gray-900-20: #2223;
  --color-green-300: #e0ffd8;
  --color-green-500: #238e07;
  --color-yellow-500: #ffed00;
  --color-turqoise: #009bac;
  --color-white-true: #fff;
  --color-white: var(--color-gray-200);
  --color-black-true: #000;
  --color-black: var(--color-gray-900);
  --theme-primary: #333;
  --theme-secondary: #ddd;
  --theme-tertiary: #eee;
  --theme-default: var(--color-white-true);
  --theme-primary-200: #888;
  --theme-primary-transparent: #3330;
  --theme-accent: #ffee09;
  --theme-attention: #ff9000;
  --theme-cta: #ff8c00;
  --theme-error: #e30613;
  --theme-success: #009000;
  --theme-promotion: #e0fdd8;
  --theme-highlight: var(--theme-secondary);
  --theme-info: var(--theme-accent);
  --theme-warning: var(--theme-attention);
  --theme-on-primary: var(--color-white);
  --theme-on-primary-highlight: var(--color-white-true);
  --theme-on-primary-headline: var(--color-white-true);
  --theme-on-primary-subheadline: var(--color-white-true);
  --theme-on-primary-border: var(--color-white-true);
  --theme-on-secondary: var(--color-black);
  --theme-on-secondary-highlight: var(--color-black-true);
  --theme-on-secondary-headline: var(--color-black-true);
  --theme-on-secondary-subheadline: var(--color-black-true);
  --theme-on-secondary-border: var(--color-white-true);
  --theme-on-tertiary: var(--color-white);
  --theme-on-tertiary-highlight: var(--color-white-true);
  --theme-on-tertiary-headline: var(--color-white-true);
  --theme-on-tertiary-subheadline: var(--color-white-true);
  --theme-on-tertiary-border: var(--color-white-true);
  --theme-on-default: var(--color-black);
  --theme-on-default-highlight: var(--color-black-true);
  --theme-on-default-headline: var(--color-black-true);
  --theme-on-default-subheadline: var(--color-black-true);
  --theme-on-default-border: var(--theme-primary);
  --bg: var(--color-white);
  --color-text: var(--color-black);
  --color-headline: var(--color-black);
  --color-subheadline: var(--color-black);
  --color-highlight: var(--theme-primary);
  --fw-light: 300;
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-black: 800;
  --border-color-accordion-default: var(--theme-primary);
  --border-color-accordion-primary: var(--color-white-true);
  --border-color-accordion-secondary: var(--color-white-true);
  --border-color-accordion-tertiary: var(--color-white-true);
  --bg-background-image-with-text: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(0, 0, 0, 0.25) 75%,
      rgba(0, 0, 0, 0) 100%
  );
  --color-background-image-with-text: var(--color-white-true);
  --bg-background-image-with-text-cta: var(--theme-tertiary);
  --color-background-image-with-text-cta: var(--color-white-true);
  --bg-button: var(--theme-primary);
  --color-button: var(--color-white-true);
  --bg-button-primary: var(--theme-primary);
  --color-button-primary: var(--color-white-true);
  --bg-button-secondary: var(--theme-secondary);
  --color-button-tertiary: var(--color-white-true);
  --bg-button-tertiary: var(--theme-tertiary);
  --color-button-secondary: var(--color-white-true);
  --bg-button-success: var(--theme-success);
  --color-button-success: var(--color-white-true);
  --bg-button-warning: var(--theme-attention);
  --color-button-warning: var(--color-white-true);
  --bg-button-error: var(--theme-error);
  --color-button-error: var(--color-white-true);
  --fw-links: var(--fw-regular);
  --td-links: none;
  --bg-li-marker: var(--color-text);
  --bg-select: var(--color-white-true);
  --color-select: var(--color-black-true);
  --color-select-arrow: var(--theme-primary);
  --fw-select: var(--fw-bold);
  --bg-checkbox: var(--color-white-true);
  --color-checkbox: var(--theme-primary);
  --color-border-checkbox: var(--theme-primary);
  --color-checkbox-label: var(--color-black-true);
  --fw-navigation-links: var(--fw-bold);
  --bd-radius-navigation-links: 0;
  --bg-header-bar: var(--theme-primary);
  --color-header-bar: var(--color-white-true);
  --fs-footer-header-desktop: 24px;
  --color-component-description-box: var(--color-white-true);
  --color-border-polaroid: var(--color-white-true);
  --bg-slider-item: var(--theme-primary);
  --color-slider-item: var(--color-white);
  --color-highlight-slider-item: var(--color-white-true);
  --bg-slider-button: var(--theme-attention);
  --color-slider-button: var(--color-white-true);
  --bg-badge: var(--theme-accent);
  --color-badge: var(--color-black);
  --bg-badge-primary: var(--theme-primary);
  --color-badge-primary: var(--theme-on-primary);
  --bg-badge-secondary: var(--theme-secondary);
  --color-badge-secondary: var(--theme-on-secondary);
  --bg-badge-tertiary: var(--theme-tertiary);
  --color-badge-tertiary: var(--theme-on-tertiary);
  --bg-badge-info: var(--theme-accent);
  --color-badge-info: var(--color-black);
  --bg-badge-highlight: var(--theme-error);
  --color-badge-highlight: var(--color-white-true);
  --color-badge-raten: var(--color-turqoise);
  --bg-badge-promotion: var(--theme-promotion);
  --color-badge-promotion: var(--color-white-true);
  --fw-text-badge: var(--fw-bold);
  --bg-badge-warning: var(--theme-attention);
  --color-badge-warning: var(--color-white-true);
  --bg-badge-success: var(--bg-button-success);
  --color-badge-success: var(--color-button-success);
  --bg-badge-error: var(--bg-button-error);
  --color-badge-error: var(--color-button-error);
  --color-star-full: #ffbe52;
  --color-star-empty: var(--color-gray-400);
  --color-star-text: var(--color-gray-800);
  --bg-play-button: var(--theme-tertiary);
  --color-play-icon: var(--color-white-true);
  --bg-info-banner-warning: var(--theme-accent);
  --bg-info-banner-error: var(--theme-error);
  --bg-info-banner-succes: var(--theme-success);
  --bg-info-banner-info: var(--color-gray-600);
  --bg-info-banner-tertiary: var(--theme-tertiary);
  --bg-asymetric-image-title: var(--theme-accent);
  --color-asymetric-image-title: var(--color-black);
  --color-price-box-price: var(--theme-primary);
  --color-price-box-tax: var(--color-gray-600);
  --bg-product-stage-logos: var(--color-white-true);
  --bg-product-stage-description-show-more-button: var(--color-white-true);
  --color-product-stage-description-show-more-button: var(--theme-primary);
  --bg-product-stage-description-gradient-start: var(--theme-primary-transparent);
  --bg-product-stage-description-gradient-end: var(--theme-primary);
  --bg-cart-product: var(--theme-secondary);
  --border-cart-product: var(--color-white-true);
  --border-cart-product-select: var(--theme-primary);
  --color-cart-product-shipping-time: var(--theme-success);
  --bg-cart-addon: var(--theme-secondary);
  --bg-cart-addon-product: var(--theme-primary);
  --color-cart-addon-product: var(--color-white-true);
  --bg-cart-summary: var(--theme-secondary);
  --color-cart-summary-total: var(--theme-primary);
  --color-cart-summary-savings: var(--theme-success);
  --bg-cart-sticky-footer: var(--color-white-true);
  --bg-radio-button: var(--theme-primary);
  --bg-input: var(--color-white-true);
  --border-input: var(--theme-primary);
  --input-error: var(--theme-error);
  --input-success: var(--theme-success);
  --input-text: var(--color-gray-600);
  --color-border-checkout-cart: var(--theme-primary);
  --color-border-alt-checkout-cart: var(--theme-secondary);
  --color-checkout-cart-price: var(--theme-primary);
  --color-checkout-cart-tax: var(--color-black);
  --color-checkout-cart-arrow: var(--theme-primary);
  --color-borders-checkout: var(--color-gray-80);
  --color-checkout-delivery-cost: var(--theme-primary);
  --color-checkout-total-value: var(--theme-primary);
  --color-checkout-total-savings: var(--theme-success);
  --color-checkout-delivery-info: var(--theme-success);
  --bg-default-checkout-cart: var(--color-gray-200);
  --bg-checkout-summary: inherit;
  --op-checkout-hint: var(--color-gray-600);
  --color-social-icon: var(--theme-secondary);
  --color-social-text: var(--theme-secondary);
  --bg-product-box: var(--theme-primary);
  --bg-product-card: var(--color-white-true);
  --color-product-card-price: var(--theme-primary);
  --bg-table-cell-default: var(--theme-secondary);
  --bg-table-cell-primary: var(--theme-primary);
  --bg-table-cell-secondary: var(--theme-secondary);
  --bg-table-cell-tertiary: var(--theme-tertiary);
  --border-table-cell-default: var(--theme-primary);
  --border-table-cell-primary: var(--theme-secondary);
  --border-table-cell-secondary: var(--theme-primary);
  --border-table-cell-tertiary: var(--theme-secondary);
  --color-table-cell-default: var(--color-black);
  --color-table-cell-primary: var(--color-white-true);
  --color-table-cell-secondary: var(--color-black);
  --color-table-cell-tertiary: var(--color-black);
  --color-headline-table-cell-default: var(--color-black-true);
  --color-headline-table-cell-primary: var(--color-black-true);
  --color-headline-table-cell-secondary: var(--color-black-true);
  --color-headline-table-cell-tertiary: var(--color-black-true);
  --color-slider-dot-default: var(--color-gray-600);
  --color-slider-dot-primary: var(--color-white-true);
  --color-slider-dot-secondary: var(--theme-primary-200);
  --color-slider-dot-tertiary: var(--color-white-true);
  --color-slider-active-dot-default: var(--color-black);
  --color-slider-active-dot-primary: var(--theme-attention);
  --color-slider-active-dot-secondary: var(--theme-attention);
  --color-slider-active-dot-tertiary: var(--theme-attention);
  --bg-flyout-button: var(--theme-attention);
  --fw-shop-switch-headline: 800;
  --fw-shop-switch-sub: 300;
  --fw-shop-switch-link: 400;
  --fs-shop-switch-flag: 25px;
  --color-shop-switch-link: #3789de;
  --color-shop-switch-button-bg-focus: var(--color-gray-400);
  --color-shop-switch-button-text-focus: var(--color-black);
  --color-shop-switch-button-bg-normal: var(--color-white-true);
  --color-shop-switch-button-text-normal: var(--color-gray-600);
  --color-voucher-overlay-bg: var(--color-white-true);
  --fs-club-price-text-small: var(--fs-xs);
  --fs-club-price-text-medium: var(--fs-base);
  --fs-club-price-icon-small: var(--fs-s);
  --fs-club-price-icon-medium: var(--fs-l);
  --bg-select-box-selected: var(--theme-primary);
  --bg-select-box-hover: #deebff;
  --variant-selector-border-color: #e4e4e4;
  --modal-overlay-bg-color: rgba(0, 0, 0, 0.6);
  --color-background-image-with-text-headline: var(--color-white-true);
  --color-background-image-with-text-subheadline: var(--color-white-true);
  --color-select-button: var(--color-white-true);
}

[data-wps-ribbon][data-wps-ribbon-placement=TOP] {
  /* stylelint-disable-next-line declaration-no-important */
  z-index: var(--zi-emarsys) !important;
}

* {
  box-sizing: border-box;
}

.sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
}

h1,
.h1 {
  font-size: var(--fs-h1-mobile);
  font-weight: var(--fw-title);
  font-family: var(--ff-title);
  color: currentcolor;
  padding: 0;
  margin: 0;
  line-height: var(--lh-small);
}
@media (min-width: 48em) {
  h1,
  .h1 {
    font-size: var(--fs-h1);
  }
}

.h1-sub {
  font-size: var(--fs-h1-sub-mobile);
  font-weight: var(--fw-headline);
  font-family: var(--ff-headline);
}
@media (min-width: 48em) {
  .h1-sub {
    font-size: var(--fs-h1-sub);
  }
}

h2,
.title-h1 {
  font-family: var(--ff-headline);
  font-size: var(--fs-h1-mobile);
  font-weight: var(--fw-headline);
  margin: 0;
  color: currentcolor;
}
@media (min-width: 48em) {
  h2,
  .title-h1 {
    font-size: var(--fs-h1);
  }
}

.title-h1-sub {
  font-size: var(--fs-h1-sub-mobile);
  font-weight: var(--fw-headline);
}
@media (min-width: 48em) {
  .title-h1-sub {
    font-size: var(--fs-h1-sub);
  }
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--ff-headline);
  margin: 0;
}

h3,
.title-h2 {
  font-family: var(--ff-headline);
  font-size: var(--fs-h2-mobile);
  font-weight: var(--fw-headline);
}
@media (min-width: 48em) {
  h3,
  .title-h2 {
    font-size: var(--fs-h2);
  }
}

.title-h2-sub {
  font-size: var(--fs-h2-sub-mobile);
  font-weight: var(--fw-headline);
}
@media (min-width: 48em) {
  .title-h2-sub {
    font-size: var(--fs-h2-sub);
  }
}

h4,
.title-h3 {
  font-family: var(--ff-headline);
  font-size: var(--fs-h3-mobile);
  font-weight: var(--fw-headline);
}
@media (min-width: 48em) {
  h4,
  .title-h3 {
    font-size: var(--fs-h3);
  }
}

.title-h3-sub {
  font-size: var(--fs-h3-sub-mobile);
  font-weight: var(--fw-headline);
}
@media (min-width: 48em) {
  .title-h3-sub {
    font-size: var(--fs-h3-sub);
  }
}

h5,
.title-h4 {
  font-family: var(--ff-headline);
  font-size: var(--fs-h4-mobile);
  font-weight: var(--fw-headline);
}
@media (min-width: 48em) {
  h5,
  .title-h4 {
    font-size: var(--fs-h4);
  }
}

.title-h4-sub {
  font-size: var(--fs-h4-sub-mobile);
  font-weight: var(--fw-headline);
}
@media (min-width: 48em) {
  .title-h4-sub {
    font-size: var(--fs-h4-sub);
  }
}

img {
  max-width: 100%;
}

ul {
  padding: 0;
  margin: 0 0 0 20px;
}
ul li {
  position: relative;
  padding-left: 5px;
  margin: 0 0 5px 10px;
}

ol {
  padding-left: 20px;
}
ol li {
  margin-bottom: 10px;
  padding-left: 10px;
}

html {
  font-family: var(--font-base);
  font-weight: var(--fw-light);
  font-size: var(--fs-base);
  color: var(--color-black);
  line-height: var(--lh-base);
}
@media (min-width: 48em) {
  html {
    font-size: var(--fs-base);
  }
}
@media (min-width: 75em) {
  html {
    font-size: var(--fs-base);
  }
}

em {
  font-style: italic;
}

b,
strong {
  font-weight: var(--fw-bold);
}

p {
  margin-bottom: var(--sp-base);
}

a {
  text-decoration: var(--td-links);
}
a:focus {
  outline: none;
}

.async-component {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background--primary {
  --bg: var(--theme-primary);
  --color-text: var(--theme-on-primary);
  --color-highlight: var(--theme-on-primary-highlight);
  --color-headline: var(--theme-on-primary-headline);
  --color-subheadline: var(--theme-on-primary-headline);
  --color-border: var(--theme-on-primary-border);
}

.background--secondary {
  --bg: var(--theme-secondary);
  --color-text: var(--theme-on-secondary);
  --color-highlight: var(--theme-on-secondary-highlight);
  --color-headline: var(--theme-on-secondary-headline);
  --color-subheadline: var(--theme-on-secondary-headline);
  --color-banner: var(--bg-info-banner-secondary);
  --color-border: var(--theme-on-secondary-border);
}

.background--tertiary {
  --bg: var(--theme-tertiary);
  --color-text: var(--theme-on-tertiary);
  --color-highlight: var(--theme-on-tertiary-highlight);
  --color-headline: var(--theme-on-tertiary-headline);
  --color-subheadline: var(--theme-on-tertiary-headline);
  --color-banner: var(--bg-info-banner-tertiary);
  --color-banner-close: var(--color-white-true);
  --color-border: var(--theme-on-tertiary-border);
}

.background--default {
  --bg: var(--theme-default);
  --color-text: var(--theme-on-default);
  --color-highlight: var(--theme-on-default-highlight);
  --color-headline: var(--theme-on-default-headline);
  --color-subheadline: var(--theme-on-default-subheadline);
  --color-banner: var(--bg-info-banner);
  --color-border: var(--theme-on-default-border);
}

.background--white-true {
  --bg: var(--color-white-true);
  --color-text: var(--theme-on-default);
  --color-highlight: var(--theme-on-default-highlight);
  --color-headline: var(--theme-on-default-headline);
  --color-subheadline: var(--theme-on-default-headline);
  --color-banner: var(--bg-info-banner);
}

.background--black-true {
  --bg: var(--color-black-true);
  --color-text: var(--theme-on-default);
  --color-highlight: var(--theme-on-default-highlight);
  --color-headline: var(--theme-on-default-headline);
  --color-subheadline: var(--theme-on-default-headline);
  --color-banner: var(--bg-info-banner);
}

.background--primary,
.background--secondary,
.background--tertiary,
.background--default,
.background--white-true,
.background--black-true {
  background: var(--bg);
  color: var(--color-text);
  --bg-li-marker: var(--color-text);
}

.background--warning {
  --color-banner: var(--theme-warning);
  --color-banner-close: var(--color-white-true);
  --color-headline: var(---color-white-true);
  --color-subheadline: var(---color-white-true);
  --color-text: var(--color-white-true);
}

.background--error {
  --color-banner: var(--theme-error);
  --color-banner-close: var(--color-white-true);
  --color-headline: var(---color-white-true);
  --color-subheadline: var(---color-white-true);
  --color-text: var(--color-white-true);
}

.background--info {
  --color-banner: var(--theme-info);
}

.background--success {
  --color-banner: var(--theme-success);
  --color-banner-close: var(--color-white-true);
  --color-headline: var(---color-white-true);
  --color-subheadline: var(---color-white-true);
  --color-text: var(--color-white-true);
}

.badge {
  display: inline-block;
  position: relative;
  font-weight: var(--fw-text-badge);
  padding: 0 18px;
  letter-spacing: 0.25px;
  font-size: 18px;
  z-index: var(--zi-badge);
}
.badge::after {
  content: "";
  width: 20px;
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  background: var(--color-bg);
  position: absolute;
  right: -19px;
  top: 0;
  bottom: 0;
}
@media (min-width: 48em) {
  .badge {
    font-size: 20px;
  }
}

.badge--primary {
  background: var(--bg-badge-primary);
  color: var(--color-badge-primary);
}

.badge--secondary {
  background: var(--bg-badge-primary);
  color: var(--color-badge-primary);
}

.badge--tertiary {
  background: var(--bg-badge-primary);
  color: var(--color-badge-primary);
}

.badge--info {
  --color-bg: var(--bg-badge-info);
  background-color: var(--bg-badge-info);
  color: var(--color-badge-info);
}

.product__marketing .badge--type-gratis {
  background: var(--bg-badge-highlight);
  color: var(--color-badge-highlight);
}
.product__marketing .badge--type-gespart {
  background: var(--color-badge-highlight);
  color: var(--bg-badge-highlight);
  border: 1px solid var(--bg-badge-highlight);
}
.product__marketing .badge--type-raten {
  background: var(--color-badge-raten);
  color: var(--color-badge-highlight);
}

.badge--highlight {
  --color-bg: var(--bg-badge-highlight);
  background-color: var(--bg-badge-highlight);
  color: var(--color-badge-highlight);
  border: 1px solid transparent;
}

.badge--promotion {
  --color-bg: var(--bg-badge-promotion);
  background-color: var(--bg-badge-promotion);
  color: var(--color-badge-promotion);
}

.badge--warning {
  --color-bg: var(--bg-badge-warning);
  background-color: var(--bg-badge-warning);
  color: var(--color-badge-warning);
}

.badge--success {
  --color-bg: var(--bg-badge-success);
  background-color: var(--bg-badge-success);
  color: var(--color-badge-success);
}

.badge--error {
  --color-bg: var(--bg-badge-error);
  background-color: var(--bg-badge-error);
  color: var(--color-badge-error);
}

.badge--transform-uppercase {
  text-transform: uppercase;
}

.badge--large {
  padding: 0 21px;
  letter-spacing: 0.25px;
  font-size: var(--fs-h1-mobile);
  font-weight: var(--fw-bold);
}
@media (min-width: 48em) {
  .badge--large {
    letter-spacing: 0.28px;
    padding: 0 34px;
    font-size: var(--fs-h1);
  }
}

.badge--medium {
  font-size: 16px;
}
@media (min-width: 48em) {
  .badge--medium {
    padding: 0 var(--fs-xs);
    font-size: 18px;
  }
}

.badge--small {
  padding: 0 21px;
  font-size: var(--fs-base);
}

.badge--spacing-small {
  padding: 4px 6px;
}

.badge--spacing-medium {
  padding: 8px 21px;
}

.badge--spacing-large {
  padding: 12px 15px;
}

.badge--align-center {
  text-align: center;
}

.badge--align-left {
  text-align: left;
}

.badge--align-right {
  text-align: right;
}

/* Correct positioning of edit-button */
.br-component--is-preview {
  position: relative;
}

/* Correct position in CSS grid */
.br-component__account-navigation {
  grid-area: navigation;
}

.br-component__account-content {
  grid-area: content;
}

.responsive-image__img {
  display: block;
  height: auto;
}

.bloomreach-video {
  position: relative;
}
.bloomreach-video video {
  object-fit: cover;
  vertical-align: bottom;
}

.button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  padding: 10px 4px;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  letter-spacing: 1.25px;
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  border-radius: var(--border-radius-button);
  background: var(--bg-button);
  color: var(--color-button);
}

.button--full-width {
  width: 100%;
}

.button--primary {
  background: var(--bg-button-primary);
  color: var(--color-button-primary);
}

.button--secondary {
  background: var(--bg-button-secondary);
  color: var(--color-button-secondary);
}

.button--tertiary {
  background: var(--bg-button-tertiary);
  color: var(--color-button-tertiary);
}

.button--success {
  background: var(--bg-button-success);
  color: var(--color-button-success);
}

.button--warning {
  background: var(--bg-button-warning);
  color: var(--color-button-warning);
}

.button--error {
  background: var(--bg-button-error);
  color: var(--color-button-error);
}

.button--gray {
  background: var(--color-gray-500);
  color: var(--color-white-true);
}

.button--large {
  font-size: 20px;
  font-weight: var(--fw-bold);
  padding: 18px 6px;
}

.button--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.button--disabled:active {
  pointer-events: none;
}

.button--loading {
  opacity: 0.5;
  cursor: progress;
}
.button--loading:active {
  pointer-events: none;
}
.button--loading.button--medium {
  padding-top: 4px;
  padding-bottom: 4px;
}
.button--loading.button--large {
  padding-top: 15px;
  padding-bottom: 15px;
}

.button--with-icon-left {
  padding-left: 35px;
}

.button--with-icon-right {
  padding-right: 35px;
}

.button__icon--medium {
  font-size: 0.9rem;
}

.button__icon--small {
  font-size: 0.6rem;
}

.button .button__icon-right,
.button .button__icon-left {
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.button__icon-right {
  right: 8px;
}

.button__icon-left {
  left: 8px;
}

.button--animate {
  animation: wiggle 0.5s ease-in-out 1 normal forwards;
  animation-delay: 3s;
  -webkit-transform-origin: 50% 50%;
}

@keyframes wiggle {
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-2px, 0);
  }
  50% {
    transform: translate(2px, 0);
  }
  75% {
    transform: translate(-1px, 0);
  }
}
.checkbox {
  display: grid;
  grid-template-areas: "box label" ". error";
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;
  position: relative;
  color: var(--color-checkbox);
}

.checkbox__input {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
}

.checkbox__box--small {
  width: 15px;
  height: 15px;
  border: 1px solid var(--color-border-checkbox);
  padding: 3px;
}

.checkbox__box--medium {
  width: 25px;
  height: 25px;
  border: 1px solid var(--color-border-checkbox);
  padding: 3px;
}

.checkbox__box--large {
  width: 40px;
  height: 40px;
  border: 2px solid var(--color-border-checkbox);
  padding: 5px;
}

.checkbox__box--error {
  border-color: var(--input-error);
}

.checkbox__box {
  grid-area: box;
  margin-right: 20px;
  background: var(--bg-checkbox);
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox__checked {
  width: 100%;
  height: 100%;
  color: var(--color-checkbox);
}

.checkbox__label {
  grid-area: label;
  color: var(--color-text);
}
.checkbox__label p:first-of-type {
  margin: 4px 0;
}
.checkbox__label a {
  text-decoration: underline;
  color: var(--color-checkbox-label);
  font-weight: var(--fw-bold);
}

.checkbox__error-message {
  grid-area: error;
  color: var(--input-error);
  font-size: var(--fs-sm);
}

.checkmark-list li {
  align-items: center;
}
.checkmark-list li::before {
  height: 1em;
  width: 1em;
  mask: url("../../../assets/icons/check.svg") no-repeat center center;
  background: var(--theme-primary);
}

@media (max-width: 47.99em) {
  .content-width-wrapper--mobile {
    width: 100%;
    margin: 0 auto;
  }
}
@media (min-width: 48em) {
  .content-width-wrapper--tablet {
    width: 100%;
    max-width: var(--breakpoint-tablet);
    margin: 0 auto;
  }
}
@media (min-width: 75em) {
  .content-width-wrapper--desktop {
    width: 100%;
    max-width: var(--breakpoint-desktop);
    margin: 0 auto;
  }
}
@media (min-width: 81.25em) {
  .content-width-wrapper--wide {
    width: 100%;
    max-width: var(--breakpoint-wide);
    margin: 0 auto;
  }
}

.date-of-birth__container {
  display: flex;
  flex-direction: column;
}

.date-of-birth__input-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1.5fr;
  grid-gap: 10px;
}

.date-of-birth__error-wrapper {
  width: 100%;
  min-height: 20px;
}

.date-of-birth__error-wrapper .input__error {
  display: block;
}

.date-of-birth-input__inputs {
  display: flex;
}

.date-of-birth-input__masked-input {
  flex-grow: 1;
}

.date-of-birth-input__masked-input:not(:last-child) {
  margin-right: 10px;
}

.date-of-birth-input__error {
  padding-top: 2px;
  display: block;
  color: var(--input-error);
  font-size: 15px;
}

.date-of-birth-input__hidden-input {
  opacity: 0;
  width: 0;
  padding: 0;
  margin: 0;
  border: 0;
  filter: alpha(opacity=0);
  overflow: hidden;
}

.date-of-birth-input__label {
  margin-bottom: 10px;
  margin-left: 4px;
  font-size: var(--fs-sm);
  font-weight: var(--fw-semibold);
}

.dynamic-textarea {
  margin-bottom: 24px;
  width: 100%;
  height: 10em;
}

.dynamic-textarea__textarea {
  width: 100%;
  height: 100%;
  resize: none;
  padding: 8px;
}

.full-screen-loading-indicator {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: var(--zi-loading);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.full-screen-loading-indicator__loading-indicator {
  border: 16px solid var(--theme-tertiary);
  border-top: 16px solid var(--theme-primary);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.headlines {
  text-transform: none;
}

.headlines--padding {
  padding: 20px;
}

.headlines--normalcase {
  text-transform: none;
}

.headlines--uppercase {
  text-transform: uppercase;
}

.headlines--lowercase {
  text-transform: lowercase;
}

.headlines--text-align-left {
  text-align: left;
}

.headlines--text-align-center {
  text-align: center;
}

.headlines--text-align-right {
  text-align: right;
}

@media (max-width: 47.99em) {
  .headlines--mobile-start {
    padding: 20px 15px;
  }
}

@media (min-width: 48em) and (max-width: 74.99em) {
  .headlines--tablet-start {
    padding: 20px 15px;
  }
}

@media (min-width: 75em) {
  .headlines--desktop-start {
    padding: 20px 15px;
  }
}

.headlines__headline {
  color: var(--color-headline);
}

@media (max-width: 47.99em) {
  .headlines__headline--mobile-start,
  .headlines__subheadline--mobile-start {
    text-align: start;
  }
}

@media (min-width: 48em) and (max-width: 74.99em) {
  .headlines__headline--tablet-start,
  .headlines__subheadline--tablet-start {
    text-align: start;
  }
}

@media (min-width: 75em) {
  .headlines__headline--desktop-start,
  .headlines__subheadline--desktop-start {
    text-align: start;
  }
}

.icon {
  color: currentcolor;
  display: inline-block;
}

.input__label {
  position: relative;
  display: block;
}

.input__input {
  width: 100%;
  padding: 18px 15px 8px;
  background-color: var(--bg-input);
  border: 1px solid var(--border-input);
}
.input__input::placeholder {
  color: transparent;
}
.input__input:disabled {
  border-color: var(--color-gray-400);
  color: var(--color-gray-600);
}

.input__input--error {
  border: 1px solid var(--theme-error);
}

.input__title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 13px 15px 11px;
  color: var(--input-text);
  font-size: var(--fs-sm);
  font-weight: var(--fw-normal);
  pointer-events: none;
  transition: 0.2s ease transform;
}

.input__input:focus ~ .input__title,
.input__input:not(:placeholder-shown) ~ .input__title {
  pointer-events: none;
  transform: translate(-4px, -11px) scale(0.85);
}

.input__error {
  padding-top: 2px;
  color: var(--input-error);
  font-size: var(--fs-sm);
}

.input__input:not(:placeholder-shown):invalid ~ .input__error {
  display: block;
}

.input__check-icon {
  color: var(--input-success);
}

.input__check {
  display: none;
}

.input__input:not(:placeholder-shown):valid ~ .input__check {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
}

.link-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 170px;
  padding: 10px 4px;
  text-align: center;
  text-decoration: none;
  border: none;
  cursor: pointer;
  letter-spacing: 1.25px;
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  border-radius: var(--border-radius-button);
  background: var(--bg-button);
  color: var(--color-button);
}

.link-button--full-width {
  width: 100%;
}

.link-button--primary {
  background: var(--bg-button-primary);
  color: var(--color-button-primary);
}

.link-button--secondary {
  background: var(--bg-button-secondary);
  color: var(--color-button-secondary);
}

.link-button--tertiary {
  background: var(--bg-button-tertiary);
  color: var(--color-button-tertiary);
}

.link-button--success {
  background: var(--bg-button-success);
  color: var(--color-button-success);
}

.link-button--warning {
  background: var(--bg-button-warning);
  color: var(--color-button-warning);
}

.link-button--error {
  background: var(--bg-button-error);
  color: var(--color-button-error);
}

.link-button--gray {
  background: var(--color-gray-500);
  color: var(--color-white-true);
}

.link-button--large {
  font-size: 20px;
  font-weight: var(--fw-bold);
  padding: 18px 6px;
}

.link-button--loading {
  opacity: 0.5;
  cursor: progress;
}
.link-button--loading:active {
  pointer-events: none;
}
.link-button--loading.link-button--medium {
  padding-top: 4px;
  padding-bottom: 4px;
}
.link-button--loading.link-button--large {
  padding-top: 15px;
  padding-bottom: 15px;
}

.link-button--with-icon-left {
  padding-left: 35px;
}

.link-button--with-icon-right {
  padding-right: 35px;
}

.link-button__icon--medium {
  font-size: 0.9rem;
}

.link-button__icon--small {
  font-size: 0.6rem;
}

.link-button .link-button__icon-right,
.link-button .link-button__icon-left {
  position: absolute;
  top: 0;
  width: 20px;
  height: 100%;
  display: flex;
  align-items: center;
}

.link-button__icon-right {
  right: 8px;
}

.link-button__icon-left {
  left: 8px;
}

.link-button--animate {
  animation: wiggle 0.5s ease-in-out 1 normal forwards;
  animation-delay: 3s;
  -webkit-transform-origin: 50% 50%;
}

@keyframes wiggle {
  0%, 100% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-2px, 0);
  }
  50% {
    transform: translate(2px, 0);
  }
  75% {
    transform: translate(-1px, 0);
  }
}
.loading-indicator::after, .async-component__inner::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  margin: auto;
  border-radius: 50%;
  border: 3px solid;
  border-color: transparent currentcolor;
  animation: loading-indicator 1.5s linear infinite;
}

@keyframes loading-indicator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loqate-input {
  position: relative;
}
.loqate-input--with-values input {
  border-radius: var(--border-radius-product-image-container);
  border-bottom: 0;
}

.loqate-input__label {
  position: relative;
  display: block;
}

.loqate-input:has(.loqate-input__suggestion-list) input {
  border-color: #000;
}

.loqate-input__input {
  width: 100%;
  padding: 18px 15px 8px;
  background-color: var(--bg-input);
  border: 1px solid var(--border-input);
}
.loqate-input__input::placeholder {
  color: transparent;
}
.loqate-input__input:disabled {
  border-color: var(--color-gray-400);
  color: var(--color-gray-600);
}

.loqate-input--error {
  border: 1px solid var(--theme-error);
}

.loqate-input__title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 13px 15px 11px;
  color: var(--input-text);
  font-size: var(--fs-sm);
  font-weight: var(--fw-normal);
  pointer-events: none;
  transition: 0.2s ease transform;
}

.loqate-input__search-icon {
  font-size: 25px;
  position: absolute;
  left: 9px;
  top: 10px;
}

.loqate-input__title-text {
  padding-left: 18px;
}

.loqate-input__input:focus ~ .loqate-input__title,
.loqate-input__input:not(:placeholder-shown) ~ .loqate-input__title {
  pointer-events: none;
  transform: translate(-4px, -11px) scale(0.85);
}

.loqate-input__error-message {
  padding-top: 2px;
  color: var(--input-error);
  font-size: var(--fs-sm);
}

.loqate-input__suggestion-list {
  position: absolute;
  top: 46px;
  background: var(--color-white-true);
  z-index: 10;
  width: 100%;
  border: 1px solid var(--border-input);
  border-top: 0;
  max-height: 180px;
  overflow-y: scroll;
}
@media (min-width: 48em) {
  .loqate-input__suggestion-list {
    max-height: 300px;
  }
}

.loqate-input__suggestion-list-item {
  cursor: pointer;
  padding: 8px 12px;
}
.loqate-input__suggestion-list-item:hover {
  background: var(--color-white);
}

.loqate-input__highlight {
  font-weight: var(--fw-semibold);
}

.masked-input__label {
  position: relative;
  display: block;
}

.masked-input__input {
  width: 100%;
  padding: 18px 15px 8px;
  background-color: var(--bg-input);
  border: 1px solid var(--border-input);
  letter-spacing: 1px;
}
.masked-input__input::placeholder {
  color: transparent;
}
.masked-input__input:disabled {
  border-color: var(--color-gray-400);
  color: var(--color-gray-600);
}

.masked-input--error {
  border: 1px solid var(--theme-error);
}

.masked-input__title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 13px 15px 11px;
  color: var(--input-text);
  font-size: var(--fs-sm);
  font-weight: var(--fw-normal);
  pointer-events: none;
  transition: 0.2s ease transform;
}

.masked-input__input:focus ~ .masked-input__title,
.masked-input__input:not(:placeholder-shown) ~ .masked-input__title {
  pointer-events: none;
  transform: translate(-4px, -11px) scale(0.85);
}

.masked-input__error {
  padding-top: 2px;
  color: var(--input-error);
  font-size: var(--fs-sm);
}

.masked-input__input:not(:placeholder-shown):invalid ~ .masked-input__error {
  display: block;
}

.masked-input__check-icon {
  color: var(--input-success);
}

.masked-input__check {
  display: none;
}

.masked-input__input:not(:placeholder-shown):valid ~ .masked-input__check {
  display: block;
  position: absolute;
  top: 50%;
  right: 10px;
  width: 15px;
  height: 15px;
  transform: translateY(-50%);
}

.modal-discount__validity {
  font-size: 16px;
}

.modal-discount__details {
  width: 100%;
  padding: 12px;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--color-gray-500);
  cursor: pointer;
}

.modal-discount__details--active {
  border: 2px solid var(--theme-primary);
  background: var(--theme-primary-opaque);
}

.modal-discount__info {
  font-size: 16px;
  display: flex;
  gap: 4px;
  flex-flow: column;
}

.modal-discount__info-details {
  display: flex;
  flex-flow: column;
}

.modal-discount__info-code {
  font-size: 18px;
  font-weight: 600;
  border-radius: var(--border-radius-default);
  color: var(--theme-primary);
}

.modal-discount__info-details-savings {
  font-weight: var(--fw-bold);
  font-size: 16px;
}

.modal-discount__show-more-container {
  display: flex;
}

.modal-discount__show-more {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 6px;
  text-decoration: underline;
}

.modal-discount__legal {
  font-size: 14px;
  margin-top: 6px;
  color: #999;
}

.password-input__label {
  position: relative;
  display: block;
}

.password-input__input {
  width: 100%;
  padding: 18px 15px 8px;
  background-color: var(--bg-input);
  border: 1px solid var(--border-input);
}
.password-input__input::placeholder {
  color: transparent;
}
.password-input__input::-webkit-contacts-auto-fill-button, .password-input__input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  position: absolute;
  right: 0;
  display: none;
  pointer-events: none;
  width: 0;
}
.password-input__input::-ms-reveal {
  display: none;
}

.password-input__input--error {
  border: 1px solid var(--theme-error);
}

.password-input__title {
  position: absolute;
  top: 0;
  left: 0;
  padding: 13px 15px 11px;
  color: var(--input-text);
  font-size: var(--fs-sm);
  pointer-events: none;
  transition: 0.2s ease transform;
}

.password-input__input:focus ~ .password-input__title,
.password-input__input:not(:placeholder-shown) ~ .password-input__title {
  pointer-events: none;
  transform: translate(-4px, -11px) scale(0.85);
}

.password-input__error {
  padding-top: 2px;
  color: var(--input-error);
  font-size: var(--fs-sm);
}

.password-input__input:not(:placeholder-shown):invalid ~ .password-input__error {
  display: block;
}

.password-input__show-hide {
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
}

.password-input__show-hide-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
}

.phone-number-input__phone-input-holder {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 8px;
}

.phone-number-input__country-code-select > select {
  border: 1px solid var(--border-input);
  font-size: var(--fs-sm);
}

.radio-button {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 85px;
  margin-right: 15px;
}

.radio-button__label {
  width: 28px;
  height: 28px;
  border: 1px solid var(--bg-radio-button);
  border-radius: 50%;
  position: relative;
}

.radio-button__input {
  opacity: 0;
  pointer-events: none;
  width: 0;
  height: 0;
  position: absolute;
}

.radio-button__button {
  display: inline-block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
}

.radio-button__input:checked + .radio-button__button {
  background-color: var(--bg-radio-button);
  border: 4px solid var(--color-white-true);
}

.radio-button__text {
  margin-left: 5px;
  font-size: var(--fs-base);
}

.radio-button__error-text {
  padding-top: 2px;
  color: var(--input-error);
  font-size: var(--fs-sm);
}

.radio-button--error {
  border-color: var(--input-error);
}

.radio-button--error > .radio-button__input:checked + .radio-button__button {
  background-color: var(--input-error);
}

.radio-button-group__error {
  color: var(--input-error);
}

.radio-button-group__radios {
  display: flex;
}

@media (max-width: 47.99em) {
  .responsive-full-page-width-wrapper--mobile {
    position: relative;
    display: grid;
    grid-template-areas: ". wrapperContainer .";
    width: var(--max-width);
    grid-template-columns: auto var(--max-width) auto;
    color: var(--color-text);
  }
}
@media (max-width: 47.99em) and (min-width: 75em) {
  .responsive-full-page-width-wrapper--mobile {
    left: calc((var(--max-width) - var(--breakpoint-desktop)) / -2);
    grid-template-columns: auto var(--breakpoint-desktop) auto;
  }
}
@media (max-width: 47.99em) {
  .responsive-full-page-width-wrapper--mobile.responsive-full-page-width-wrapper--overflow-both {
    grid-template-areas: "wrapperContainer";
    grid-template-columns: var(--max-width);
  }
}
@media (min-width: 48em) and (max-width: 74.99em) {
  .responsive-full-page-width-wrapper--tablet {
    position: relative;
    display: grid;
    grid-template-areas: ". wrapperContainer .";
    width: var(--max-width);
    grid-template-columns: auto var(--max-width) auto;
    color: var(--color-text);
  }
}
@media (min-width: 48em) and (max-width: 74.99em) and (min-width: 75em) {
  .responsive-full-page-width-wrapper--tablet {
    left: calc((var(--max-width) - var(--breakpoint-desktop)) / -2);
    grid-template-columns: auto var(--breakpoint-desktop) auto;
  }
}
@media (min-width: 48em) and (max-width: 74.99em) {
  .responsive-full-page-width-wrapper--tablet.responsive-full-page-width-wrapper--overflow-both {
    grid-template-areas: "wrapperContainer";
    grid-template-columns: var(--max-width);
  }
}
@media (min-width: 75em) {
  .responsive-full-page-width-wrapper--desktop {
    position: relative;
    display: grid;
    grid-template-areas: ". wrapperContainer .";
    width: var(--max-width);
    grid-template-columns: auto var(--max-width) auto;
    color: var(--color-text);
  }
}
@media (min-width: 75em) and (min-width: 75em) {
  .responsive-full-page-width-wrapper--desktop {
    left: calc((var(--max-width) - var(--breakpoint-desktop)) / -2);
    grid-template-columns: auto var(--breakpoint-desktop) auto;
  }
}
@media (min-width: 75em) {
  .responsive-full-page-width-wrapper--desktop.responsive-full-page-width-wrapper--overflow-both {
    grid-template-areas: "wrapperContainer";
    grid-template-columns: var(--max-width);
  }
}

.responsive-full-page-width-wrapper__container {
  grid-area: wrapperContainer;
}

/**
 * Use the height-property on .select to give the whole thing a height to your liking.
 * Padding should be set in the .select__select
 */
.select {
  position: relative;
  font-size: 16px;
  height: 45px;
  background: var(--bg-select);
  color: var(--color-select);
  font-weight: var(--fw-select);
}

.select--small {
  font-weight: var(--fw-normal);
}

.select--medium {
  height: 50px;
  width: 105px;
  font-size: 18px;
}

.select--large {
  font-size: 18px;
  height: 54px;
}

.select--blue {
  border: solid 1px var(--theme-primary);
}

.select > select:required:invalid {
  color: var(--input-text);
}

.select > select > option[value=""][disabled] {
  color: var(--input-text);
}

.select > select > option {
  color: var(--color-black);
}

.select__select {
  appearance: none;
  border: 1px solid var(--color-black);
  border-radius: var(--border-radius-default);
  background: none;
  display: block;
  width: 100%;
  height: 100%;
  font-weight: inherit;
  padding: 0 52px 0 15px;
}

.select__select--error {
  border: 1px solid var(--theme-error);
}

.select__arrow {
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: var(--color-select-arrow);
  right: 15px;
  font-size: 25px;
}
.select--disabled .select__arrow {
  opacity: 0.5;
}

.select__arrow--error {
  color: var(--theme-error);
}

/* stylelint-disable declaration-no-important */
.select-box {
  position: relative;
  width: 100%;
}
@media (min-width: 48em) {
  .select-box {
    width: 250px;
  }
}

.select-box__selected-value-wrap {
  position: relative;
  display: flex;
  cursor: pointer;
  border: 1px solid #b3b3b3;
  justify-content: space-between;
  align-items: center;
}
.select-box__selected-value-wrap--disabled {
  cursor: auto;
}
.select-box__selected-value-wrap--disabled .select-box__selected-value-icon {
  display: none;
}

.select-box__selected-value {
  padding: 8px 0 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.select-box__selected-value-icon {
  border-left: 1px solid #b3b3b3;
  width: 36px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-box__selected-value-icon .icon {
  color: #b3b3b3;
  font-size: 12px !important;
}

.select-box__options {
  position: absolute;
  max-height: 288px;
  width: 100%;
  background: var(--color-white-true);
  z-index: 10;
  overflow-y: auto;
  border: 1px solid #b3b3b3;
  border-top: none;
}

.select-box__options-item {
  cursor: pointer;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.select-box__options-item:hover {
  background: var(--bg-select-box-hover);
}
.select-box__options-item--selected {
  background: var(--bg-select-box-selected) !important;
  color: var(--color-white-true);
}

.separator__space-above {
  height: 20px;
  background: #fff;
}

.separator__line {
  height: 1px;
  background: #ebe4d9;
}

.separator__space-below {
  height: 20px;
  background: #fff;
}

.star-rating {
  display: flex;
  justify-content: center;
  align-items: center;
}

.star-rating__star {
  margin-right: 0.1em;
}
.star-rating__star--empty {
  color: var(--color-star-empty);
}
.star-rating__star--half {
  color: var(--color-star-full);
  fill: var(--color-star-full);
}
.star-rating__star--full {
  color: var(--color-star-full);
  fill: var(--color-star-full);
}
.star-rating__star:last-child {
  margin-right: 0;
}

.star-rating__text {
  color: var(--color-black);
  margin-left: 8px;
  font-size: 14px;
  margin-top: 2px;
}

.text {
  padding: 20px;
}
.text a {
  text-decoration: none;
  border-bottom: 1px solid;
  overflow-wrap: break-word;
  color: var(--color-text);
}
.text a:hover {
  cursor: pointer;
}
.text .headlines--padding {
  padding: 0;
}
.text p:last-child {
  margin-bottom: 0;
}

.text--align-left {
  text-align: left;
}

.text--align-right {
  text-align: right;
}

.text--align-center {
  text-align: center;
}

.text--align-justify-left,
.text--align-justify-right,
.text--align-justify-center,
.text--align-justify {
  text-align: justify;
}

.text--no-bottom-padding {
  padding-bottom: 0;
}

.toggle-play-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  color: #000;
  opacity: 0.4;
  z-index: 2;
  cursor: pointer;
  border-radius: 100%;
}
.toggle-play-button svg {
  width: 30px;
  height: 30px;
}

.toggle-play-button__play-button-color {
  color: var(--theme-primary);
  opacity: 1;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip__text {
  visibility: hidden;
  background-color: var(--color-black);
  color: var(--color-white);
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -40px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip__text {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltip__text::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--color-black) transparent transparent transparent;
}

.voucher-validity-indicator__icon-container {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: var(--fs-sm);
}

.voucher-validity-indicator__code-container {
  display: flex;
}

.voucher-validity-indicator__label {
  display: flex;
  gap: 5px;
  font-size: var(--fs-sm);
  min-width: 0;
}

.voucher-validity-indicator__code {
  cursor: pointer;
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow: hidden;
  min-width: 0;
}

.voucher-validity-indicator__voucher-valid {
  color: var(--theme-success);
}

.voucher-validity-indicator__voucher-invalid {
  color: var(--theme-error);
}

.voucher-tool-tip__voucher-overlay {
  position: absolute;
  display: flex;
  gap: 8px;
  background-color: var(--color-voucher-overlay-bg);
  left: -60px;
  bottom: 24px;
  width: 212px;
  min-height: 35px;
  box-shadow: 1px 1px 5px var(--color-gray-400);
  border-left: 3px solid var(--theme-error);
}
@media (min-width: 75em) {
  .voucher-tool-tip__voucher-overlay {
    left: 16px;
    bottom: 16px;
  }
}

.voucher-tool-tip__content-container {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 15px 5px;
  font-size: 14px;
  color: var(--color-text);
}
.voucher-tool-tip__content-container svg {
  color: var(--theme-error);
}

.voucher-tool-tip__icon {
  min-width: 15px;
}

.voucher-tool-tip__error-message {
  line-height: var(--lh-base);
}

.accordion-item {
  border-top: 1px solid;
  border-bottom: 0;
}
.accordion-item:last-child {
  border-bottom: 1px solid;
}

.accordion-item__image {
  margin-bottom: 20px;
  width: auto;
}

.accordion-item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  cursor: pointer;
}

.accordion-item__header-text {
  display: flex;
  place-items: center center;
}

.accordion-item__icon-container {
  flex-shrink: 0;
  position: relative;
  height: 50px;
  width: 50px;
  margin-right: 20px;
}
.accordion-item__icon-container .accordion-item__icon {
  position: relative;
  color: currentcolor;
}

.accordion-item__arrow {
  color: var(--color-text);
  margin-left: 20px;
}

.accordion-item__content {
  padding: 0 20px 20px;
}

.accordion-item__content--has-icon {
  padding: 20px 100px;
}
@media (max-width: 47.99em) {
  .accordion-item__content--has-icon {
    padding: 20px 15px;
  }
}

.accordion-item__content--closed {
  display: none;
}

.accordion-item__headlines {
  text-align: start;
}

.accordion-item__html p {
  margin-top: 0;
}
.accordion-item__html a {
  text-decoration: underline;
  text-underline-offset: 2px;
  color: inherit;
}
.accordion-item__html li {
  margin-left: 0;
  margin-bottom: 4px;
}

.accordion {
  padding: 20px 0 30px;
}
.accordion:has(.headlines) {
  padding-top: 0;
}

.accordion-item__headline-container {
  padding: 10px 0;
}

.accordion__background--primary {
  --color-border: var(--border-color-accordion-primary);
}

.accordion__background--secondary {
  --color-border: var(--border-color-accordion-secondary);
}

.accordion__background--tertiary {
  --color-border: var(--border-color-accordion-tertiary);
}

.accordion__background--default {
  --color-border: var(--border-color-accordion-default);
}

.arrow-button {
  appearance: none;
  background: var(--bg-slider-button);
  color: var(--color-slider-button);
  border: none;
  cursor: pointer;
  padding-top: 2px;
  height: 45px;
  width: 55px;
  line-height: 45px;
  font-size: 18px;
}
@media (max-width: 23.4375em) {
  .arrow-button {
    margin-bottom: 20px;
  }
}
.arrow-button:focus {
  outline: none;
}

.arrow-button--left {
  position: absolute;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.arrow-button--right {
  position: absolute;
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.asymmetric-image {
  padding: 20px 0 50px;
}

.asymmetric-image--title-position-top .asymmetric-image__content {
  top: 32px;
}

.asymmetric-image--title-position-bottom .asymmetric-image__content {
  bottom: 32px;
}

.asymmetric-image__content-container {
  position: relative;
}

.asymmetric-image__image-container {
  height: 350px;
  margin-left: 10%;
}
.asymmetric-image__image-container img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
@media (min-width: 48em) {
  .asymmetric-image__image-container {
    height: 350px;
  }
}
@media (min-width: 75em) {
  .asymmetric-image__image-container {
    height: 450px;
  }
}

.asymmetric-image__content {
  position: absolute;
  display: flex;
  left: 0;
  flex-direction: column;
  align-items: flex-start;
}
.asymmetric-image__content .asymmetric-image__image-title,
.asymmetric-image__content .asymmetric-image__image-subtitle {
  display: block;
  margin-bottom: 4px;
  margin-left: 8px;
  padding: 4px 10px;
}
@media (min-width: 48em) {
  .asymmetric-image__content .asymmetric-image__image-title,
  .asymmetric-image__content .asymmetric-image__image-subtitle {
    margin-bottom: 8px;
    margin-left: 16px;
  }
}

.background-image {
  height: 100%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.background-image--parallax {
  background-attachment: fixed;
}
@supports (-webkit-touch-callout: none) {
  .background-image--parallax {
    background-attachment: initial !important; /* stylelint-disable-line declaration-no-important */
  }
}

.background-image-with-text {
  position: relative;
  --color-headline: var(--color-background-image-with-text-headline);
  --color-subheadline: var(--color-background-image-with-text-subheadline);
}
.background-image-with-text__container {
  display: grid;
}

.background-image-with-text__text-container,
.background-image-with-text__text-container-mobile {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  color: var(--color-background-image-with-text);
}

.background-image-with-text__text-container-mobile {
  padding: 50px 0 20px;
}

.background-image-with-text__cta-button {
  padding: 12px;
}

.background-image-with-text__image {
  width: 100%;
  height: auto;
}

.before-after-image {
  position: relative;
  overflow: hidden;
  user-select: none;
  touch-action: pan-y;
}
.before-after-image__image-before {
  position: absolute;
  overflow: hidden;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  border-right: 6px solid var(--color-white-true);
}
.before-after-image__image-before .responsive-image__img {
  height: 100%;
  width: auto;
  object-fit: cover;
  object-position: left;
}
.before-after-image__image-after {
  z-index: 1;
}
.before-after-image__image-after .responsive-image__img {
  width: 100%;
}
.before-after-image__slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  outline: none;
  z-index: 3;
}
.before-after-image__slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
  height: 800px;
  background: transparent;
  cursor: grab;
}
.before-after-image__slider::-moz-range-thumb {
  width: 6px;
  height: 800px;
  background: transparent;
  cursor: grab;
}
.before-after-image__slider-button {
  pointer-events: none;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--color-white-true);
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  z-index: 3;
}
.before-after-image__slider-button::after, .before-after-image__slider-button::before {
  content: "";
  padding: 3px;
  display: inline-block;
  border: solid var(--theme-dark);
  border-width: 0 2px 2px 0;
  transform: rotate(-45deg);
}
.before-after-image__slider-button::before {
  transform: rotate(135deg);
}

.checkout-success-section .dynamic-fieldset__fields {
  margin-bottom: 0;
}

.checkout-success-section__headline {
  border-bottom: 1px solid var(--theme-primary);
  padding-bottom: 4px;
  margin-bottom: 12px;
  font-weight: var(--fw-semibold);
}

.checkout-success-section__feedback {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.checkout-success-section__icon {
  font-size: 24px;
  line-height: 16px;
  padding-right: 20px;
  color: var(--theme-primary);
}

.checkout-success-message {
  font-size: 16px;
  color: var(--color-black-true);
  text-align: left;
  margin-bottom: 50px;
  padding: 20px;
}
@media (max-width: 47.99em) {
  .checkout-success-message {
    margin: 30px 0 0;
  }
}
.checkout-success-message p {
  margin: 0 0 12px;
}
.checkout-success-message p:has(+ ul) {
  margin-bottom: 0;
}
.checkout-success-message ul {
  margin-bottom: 12px;
}
.checkout-success-message ul li {
  margin-bottom: 0;
  padding-left: 0;
}
.checkout-success-message .input__helper-text {
  margin-top: 12px;
}

.checkout-success-message__header {
  text-align: center;
  padding: 0 0 20px;
}

.checkout-success-message__icon {
  font-size: 86px;
  color: var(--theme-primary);
}
.checkout-success-message--error .checkout-success-message__icon {
  color: var(--theme-error);
}

.checkout-success-message__headline {
  margin-bottom: 10px;
}
.checkout-success-message__headline .headlines {
  padding: 0;
}

.checkout-success-message__text {
  margin: 0 0 20px;
}
.checkout-success-message__text:last-child {
  margin-bottom: 0;
}

.checkout-success-message__link {
  color: inherit;
  text-decoration: underline;
  font-weight: var(--fw-bold);
}

.checkout-success-message__form {
  text-align: left;
}

.checkout-success-message__subscriptions {
  width: fit-content;
}
.checkout-success-message__subscriptions--center {
  margin: 0 auto;
}
.checkout-success-message__subscriptions--left {
  margin-left: auto;
}
.checkout-success-message__subscriptions--right {
  margin-right: auto;
}

.checkout-success-message__submit {
  margin: 20px 0;
}

.checkout-success-message__register-advantages {
  list-style-type: none;
  margin: 0;
}

.checkout-success-message__checkmark {
  margin-right: 12px;
  font-size: 12px;
}

.club-modal__banner {
  width: 100%;
  padding: 16px 24px;
  background: var(--theme-secondary);
  color: var(--theme-primary);
  border-radius: var(--border-radius-product-image-container);
}

.club-modal__banner-headline {
  font-size: var(--fs-xl);
  font-weight: 500;
}

.club-modal__banner-sub-headline {
  font-size: var(--fs-s);
}

.club-modal__banner-line {
  background: var(--theme-primary);
  height: 16px;
}

.club-modal__headline {
  font-size: var(--fs-l);
  margin: 16px 0;
}

.club-modal__text {
  font-size: var(--fs-base);
  margin-bottom: 10px;
}

.club-modal__button {
  color: var(--theme-primary);
  background: var(--white);
  border: 2px solid var(--theme-primary);
  border-radius: 0;
  padding-left: 14px;
  padding-right: 14px;
}

.club-price {
  font-family: var(--font-base);
  display: flex;
  gap: 12px;
  margin: 4px 0;
}

.club-price__left {
  justify-content: left;
}

.club-price__right {
  justify-content: right;
}

.club-price__icon-container {
  display: flex;
}

.club-price__help-icon {
  cursor: pointer;
}

.club-price__content-container {
  display: flex;
  gap: 5px;
}

.club-price__card-icon-container {
  color: var(--theme-primary);
}

.club-price__icon--small {
  font-size: var(--fs-club-price-icon-small);
}

.club-price__icon--medium {
  font-size: var(--fs-club-price-icon-medium);
}

.club-price__text-container--small {
  font-size: var(--fs-club-price-text-small);
  hyphens: auto;
  word-break: break-word;
}

.club-price__text-container--medium {
  font-size: var(--fs-club-price-text-medium);
}

.club-price__price-container {
  display: inline-block;
}

.contact-block {
  display: flex;
  flex: 1 1 50%;
  padding-bottom: 20px;
}
@media (min-width: 48em) {
  .contact-block {
    padding-bottom: 0;
  }
}

.contact-block__icon {
  flex-shrink: 0;
  filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.35));
  width: 40px;
  height: 40px;
}

.contact-block__items {
  margin-left: 20px;
}

.contact-block__title {
  font-weight: var(--fw-bold);
  font-size: var(--fs-h4-mobile);
  margin-bottom: 10px;
}
@media (min-width: 48em) {
  .contact-block__title {
    font-size: var(--fs-h4);
  }
}

.contact-block-item {
  margin-bottom: 8px;
  --td-links: underline;
}

.contact-block-item__value {
  font-weight: var(--fw-links);
  text-decoration: var(--td-links);
  white-space: nowrap;
  color: inherit;
}

.contact-block-item__prefix {
  font-weight: var(--fw-light);
}
.contact-block-item__prefix::after {
  content: " ";
}

.contact-block-item__suffix {
  font-weight: var(--fw-light);
}
.contact-block-item__suffix::before {
  content: " ";
}

.contact {
  padding: 20px;
}
.contact .headlines {
  text-align: left;
}
@media (min-width: 48em) {
  .contact .headlines {
    text-align: center;
  }
}

.background--default .headlines__headline {
  color: var(--theme-on-default);
}

.background--tertiary .headlines__headline {
  color: var(--theme-on-tertiary-border);
}

.contact__quote-container {
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-areas: "imageContainer quoteContainer";
  grid-gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}
@media (min-width: 48em) {
  .contact__quote-container {
    grid-template-areas: ". imageContainer quoteContainer .";
    grid-template-columns: auto 150px 300px auto;
  }
}
@media (min-width: 75em) {
  .contact__quote-container {
    grid-template-areas: ". imageContainer quoteContainer .";
    grid-template-columns: auto 200px 300px auto;
  }
}

.contact__image-container {
  grid-area: imageContainer;
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 48em) {
  .contact__image-container {
    width: 150px;
    height: 150px;
  }
}
@media (min-width: 75em) {
  .contact__image-container {
    width: 200px;
    height: 200px;
  }
}
.contact__image-container img {
  display: inline;
  height: 100%;
  width: auto;
  max-width: unset;
}

.contact__quote-content {
  grid-area: quoteContainer;
}

.contact__quote {
  font-style: italic;
}

.contact__quote-name {
  font-weight: var(--fw-bold);
}

.contact__contact-headline {
  text-align: left;
  font-weight: var(--fw-medium);
  font-size: var(--fs-h3-mobile);
  margin-bottom: 20px;
}
@media (min-width: 48em) {
  .contact__contact-headline {
    font-size: var(--fs-h3);
    text-align: center;
  }
}

.contact__contact-container {
  display: flex;
  justify-content: center;
}
@media (min-width: 48em) {
  .contact__contact-container {
    width: 100%;
  }
}

.contact__contact-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media (min-width: 48em) {
  .contact__contact-wrapper {
    display: grid;
    width: 100%;
    grid-template-areas: "even odd";
    grid-template-columns: 1fr 1fr;
  }
}

.contact__odd-grid,
.contact__even-grid {
  grid-area: odd;
  display: grid;
  grid-gap: 20px;
  grid-auto-flow: row;
}
@media (min-width: 48em) {
  .contact__odd-grid,
  .contact__even-grid {
    justify-content: center;
  }
}

.contact__even-grid {
  grid-area: even;
}

.content-list {
  margin: 32px 0;
}

.content-list__headlines {
  padding: 0 20px 10px 80px;
}

.content-list__container {
  max-width: max-content;
  margin-left: auto;
  margin-right: auto;
}

.content-list__number {
  background-color: var(--theme-primary);
  font-family: var(--ff-headline);
  font-size: var(--fs-xl);
  font-weight: var(--fw-semibold);
  color: var(--color-white-true);
  margin: 0 15px 0 0;
  height: 46px;
  line-height: 46px;
  text-align: center;
  min-width: 46px;
}

.content-list__text {
  font-family: var(--ff-headline);
  font-size: var(--fs-base);
  line-height: 1.5;
}
.content-list__text p {
  margin-block-start: 4px;
}

.content-list__article {
  display: flex;
  margin: 10px 20px;
}

.content-list__icon-container {
  flex-shrink: 0;
  flex-grow: 0;
  position: relative;
  height: 50px;
  width: 50px;
  margin-right: 30px;
}

.content-list__section {
  line-height: 1;
  flex-grow: 1;
}

.content-list__title {
  text-align: start;
  padding: 0;
}

.content-slider-basic-slide__content {
  padding: 15px 20px;
}

.content-slider-basic-slide__image {
  width: 100%;
}

.content-slider-basic-slide--rounded {
  padding-top: 30px;
}
.content-slider-basic-slide--rounded .content-slider-basic-slide__image-container {
  margin: 0 20px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.content-slider-basic-slide--rounded .content-slider-basic-slide__image-container::before {
  content: "";
  padding-top: 100%;
  display: block;
}
.content-slider-basic-slide--rounded .responsive-image__img.content-slider-basic-slide__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-slider {
  padding: 20px 0;
}
@media (min-width: 48em) {
  .content-slider {
    padding: 40px 0;
  }
}

.content-slider__slide {
  overflow: hidden;
}

.country-select-container {
  display: none;
  position: absolute;
  background-color: #fff;
  z-index: var(--zi-index-dropdown);
  padding: 30px;
  left: 0;
  right: 0;
  border: 1px solid #989292;
  top: 100%;
  cursor: default;
  flex-direction: column;
  gap: 12px;
}
@media (min-width: 48em) {
  .country-select-container {
    top: calc(100% + 10px);
    left: unset;
    right: -94px;
  }
}
@media (min-width: 75em) {
  .country-select-container {
    right: -86px;
  }
}
.country-select-container--open {
  display: flex;
}

.country-select-container__select-label {
  font-weight: var(--fw-bold);
  font-size: var(--fs-18);
  display: inline-block;
  margin-bottom: 5px;
}

.country-select-container__title {
  font-weight: var(--fw-bold);
  font-size: var(--fs-18);
}

.country-select-container__select-disable {
  display: none;
}
.country-select-container__select-disable .select__control {
  pointer-events: none;
}
.country-select-container__select-disable .select__indicators {
  display: none;
}

.country-select-container__button-container .button__icon-right .icon {
  color: var(--color-button-primary);
}

.burger-menu-country__wrapper .country-select-container {
  top: 0;
  right: 0;
  left: 0;
}
.burger-menu-country__wrapper .select-box {
  width: 100%;
}

body:has(.country-select-container--open) [id^=userlike-frame-] {
  visibility: hidden;
}

.country-switch {
  padding: 0 3px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
@media (min-width: 48em) {
  .country-switch {
    padding: 3px;
    width: fit-content;
  }
}

.dropdown__header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropdown__header--clickable {
  cursor: pointer;
}

.dropdown__item-flag {
  margin: 0 5px;
  font-size: 25px;
}
.dropdown__item-flag--select-disabled {
  cursor: default;
}

.dropdown__country-switch-icon-filled {
  transform: scale(1.6);
  fill: var(--theme-primary);
}

/* stylelint-disable declaration-no-important */
.country-switch-modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.country-switch-modal__attention-wrapper {
  background-color: var(--theme-primary);
  color: var(--theme-accent);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  margin-bottom: 8px;
}

.country-switch-modal__attention-icon {
  font-size: 5rem;
  padding: 10px 15px 15px;
}

.country-switch-modal__text {
  text-align: center;
}

.country-switch-modal__actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 75em) {
  .country-switch-modal__actions {
    flex-direction: row;
    width: auto;
  }
}

.country-switch-modal__cta {
  margin: 4px 0;
}
@media (min-width: 75em) {
  .country-switch-modal__cta {
    margin: 0 4px;
  }
}

.country-switch-modal__overlay-bg {
  z-index: var(--zi-country-switch-modal-overlay) !important;
}

.cross-selling-banner {
  padding: 30px 27px 20px;
  background: var(--theme-secondary);
  color: var(--theme-on-secondary-headline);
}

.cross-selling-banner__container {
  max-width: 600px;
  margin: 0 auto;
}

.cross-selling-banner__headline {
  margin-bottom: 35px;
  font-size: 24px;
  font-weight: var(--fw-bold);
  text-align: center;
}

.cross-selling-banner__image {
  margin: 0 auto 20px;
}

.cta__container {
  padding: 15px 10px;
}
@media (max-width: 47.99em) {
  .cta__container {
    padding: 10px;
  }
}

.cta__headlines {
  padding-top: 0;
}

.cta__headlines .headlines__headline {
  font-size: 2rem;
}
@media (max-width: 47.99em) {
  .cta__headlines .headlines__headline {
    font-size: var(--fs-h1-mobile);
  }
}

.cta__content-container {
  display: grid;
  justify-content: center;
}

.cta__text-container {
  text-align: center;
}
.cta__text-container p {
  margin-top: 0;
}

.cta__button-primary-container {
  text-align: center;
}

.cta__button-primary {
  padding: 8px 16px;
}

.cta__button-secondary-container {
  margin-top: 10px;
  text-align: center;
  --td-links: underline;
}

.cta__button-secondary {
  font-weight: var(--fw-links);
  text-decoration: var(--td-links);
  white-space: nowrap;
  color: var(--color-highlight);
}

.delivery-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: var(--fw-bold);
  margin-top: 20px;
  color: var(--color-checkout-delivery-info);
}

.delivery-info__icon {
  margin-right: 5px;
  font-size: var(--fs-xl);
}

.download-with-image-and-text-item__content {
  padding: 15px 20px;
  text-align: center;
}

.download-with-image-and-text-item__button-container {
  text-align: center;
}

.download-with-image-and-text-item--rounded {
  padding-top: 30px;
}

.download-with-image-and-text-item__image-container {
  margin: 0 20px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
}
.download-with-image-and-text-item__image-container::before {
  content: "";
  padding-top: 100%;
  display: block;
}

.download-with-image-and-text-item__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.download-with-image-and-text {
  padding: 20px 0;
}
@media (min-width: 48em) {
  .download-with-image-and-text {
    padding: 40px 0;
  }
}

.download-with-image-and-text__flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.download-with-image-and-text__slide {
  height: 100%;
  min-width: 200px;
}

.dynamic-select > select {
  border: 1px solid var(--border-input);
  font-size: var(--fs-sm);
}

.dynamic-select--error > select {
  border: 1px solid var(--input-error);
}

.dynamic-select__error-message {
  padding-top: 2px;
  color: var(--input-error);
  font-size: var(--fs-sm);
}

.checkbox-input-error,
.input-error input {
  border: 1px solid var(--theme-error);
}

.input-error-message {
  color: var(--theme-error);
}

.dynamic-input {
  display: block;
}

.dynamic-input__separator {
  position: absolute;
  top: 10px;
  right: -10px;
}

.dynamic-input--relative {
  position: relative;
}

.dynamic-fieldset {
  display: block;
}

.dynamic-fieldset__title {
  margin-bottom: 10px;
  margin-left: 4px;
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
}

.dynamic-fieldset__fields {
  display: grid;
  align-items: flex-start;
  justify-content: stretch;
  gap: 15px;
  grid-template-columns: auto;
  grid-auto-flow: row;
  margin-bottom: 15px;
}

.dynamic-fieldset__fields--cols-2 {
  grid-template-columns: 1fr 1fr;
}

.dynamic-fieldset__fields--cols-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-mobile-1 {
  grid-template-columns: 1fr;
}

.dynamic-fieldset__fields--cols-mobile-2 {
  grid-template-columns: 1fr 1fr;
}

.dynamic-fieldset__fields--cols-mobile-3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-mobile-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.dynamic-fieldset__fields--cols-mobile-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

@media (min-width: 48em) {
  .dynamic-fieldset__fields--cols-tablet-1 {
    grid-template-columns: 1fr;
  }
  .dynamic-fieldset__fields--cols-tablet-2 {
    grid-template-columns: 1fr 1fr;
  }
  .dynamic-fieldset__fields--cols-tablet-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .dynamic-fieldset__fields--cols-tablet-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .dynamic-fieldset__fields--cols-tablet-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 75em) {
  .dynamic-fieldset__fields--cols-desktop-1 {
    grid-template-columns: 1fr;
  }
  .dynamic-fieldset__fields--cols-desktop-2 {
    grid-template-columns: 1fr 1fr;
  }
  .dynamic-fieldset__fields--cols-desktop-3 {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .dynamic-fieldset__fields--cols-desktop-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .dynamic-fieldset__fields--cols-desktop-5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
}
.loqate-wrapper__action {
  margin-bottom: 15px;
}

.loqate-wrapper__action-link {
  cursor: pointer;
  text-decoration: underline;
}

.flyout {
  position: fixed;
  right: 0;
  transform: rotate(270deg);
  transform-origin: right bottom;
  filter: drop-shadow(-4px -2px 4px rgba(0, 0, 0, 0.19));
  z-index: var(--zi-flyout);
}

.flyout__button {
  transform: rotate(180deg);
  justify-content: flex-start;
  min-height: 55px;
  padding-left: 15px;
  padding-right: 15px;
  width: auto;
  height: auto;
  clip-path: polygon(0% 0%, 100% 0%, 70% 100%, 0% 100%);
}

.flyout__button-with-icon {
  min-width: 60px;
  padding-left: 20px;
  padding-right: 46px;
}

.flyout__button-icon {
  margin: 0 10px;
  width: 36px;
  height: 36px;
  transform: rotate(-90deg);
}

.form {
  display: grid;
  align-items: center;
  justify-content: stretch;
  gap: 15px;
  grid-template-columns: auto;
}

.form-row {
  display: grid;
  justify-content: stretch;
  gap: 15px;
}
@media (min-width: 48em) {
  .form-row {
    grid-auto-flow: column;
  }
}

.form-row--split {
  display: grid;
  gap: 15px;
  grid-template-columns: auto;
}
@media (min-width: 48em) {
  .form-row--split {
    grid-template-columns: 1fr 1fr;
  }
}

.form-row--single {
  justify-content: stretch;
  max-width: 410px;
}

.form-row--split-group,
.form-row--split-group__input {
  display: grid;
  justify-content: stretch;
  gap: 15px;
  grid-auto-flow: column;
}

.form-row-spacer {
  display: none;
}
@media (min-width: 48em) {
  .form-row-spacer {
    display: block;
  }
}

.form__title, .radio-button-group__title {
  margin-bottom: 10px;
  margin-left: 4px;
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
}

.form__title--empty {
  /* Fixed height is necessary in case two inputs in a row have to be aligned */
  height: 20px;
  display: none;
}
@media (min-width: 48em) {
  .form__title--empty {
    display: block;
  }
}

.header-bar {
  display: grid;
  grid-template-columns: 50px auto 50px;
  grid-template-areas: "start center end";
  height: 64px;
  line-height: 64px;
}

.header-bar__start {
  grid-area: start;
  margin-left: 30px;
}

.header-bar__center {
  grid-area: center;
  line-height: 64px;
  text-align: center;
}

.header-bar__end {
  grid-area: end;
  margin-right: 30px;
}

.header-bar__backlink-icon {
  font-weight: var(--fw-semibold);
  cursor: pointer;
  color: var(--color-header-bar);
}
.header-bar__backlink-icon:focus {
  outline: none;
}

.header-bar__title {
  font-family: var(--ff-headline);
  font-size: var(--fs-xl);
  font-weight: var(--fw-semibold);
}

.image-banner-style-wrapper {
  position: relative;
}

.image-banner-style-wrapper__inset-shadow {
  position: absolute;
  inset: 0;
}

.image-banner img {
  width: 100%;
  height: auto;
}

.image-banner__teaser-box {
  padding: 20px;
}
.image-banner__teaser-box--with-box-shadow {
  box-shadow: var(--box-shadow-default);
}

.image-banner__teaser-box--mobile {
  width: 100%;
}

.image-banner__teaser-box--desktop {
  position: absolute;
  overflow-y: auto;
}

.image-banner__teaser-box--mobile-container {
  display: flex;
  flex-direction: column;
}

.image-banner__teaser-box--mobile-container-reverse {
  display: flex;
  flex-direction: column-reverse;
}

.image-with-text__icon {
  height: 1em;
  width: 1em;
  margin-right: 8px;
}

.info-banner__headlines {
  padding: 0;
  text-align: left;
  margin: auto 0;
}

.info-banner__centered-headlines {
  padding: 0;
  text-align: center;
  margin: auto 0;
}

.info-banner-common {
  display: grid;
  grid-template-columns: [content] auto;
  padding: 10px 0;
  background-color: var(--color-banner);
}
.info-banner-common.info-banner-common--no-close-button {
  justify-content: center;
}

.info-banner-common__closeable {
  grid-template-columns: [spacer] 50px [content] auto [close] 50px;
}

.info-banner-common__has-icon {
  grid-template-columns: [spacer] 50px [content] auto [close] 50px;
}

.info-banner-common__icon {
  width: 50px;
  height: 50px;
  padding: 10px;
  margin-right: 20px;
}

.info-banner-common__content-container {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: content;
}

.info-banner-common__headlines {
  padding: 0;
  text-align: left;
  margin: auto 0;
}

.info-banner-common__centered-headlines {
  padding: 0;
  text-align: center;
  margin: auto 0;
}

.info-banner-common__close-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.info-banner-common__close-icon-container {
  outline: 0;
  padding: 0;
  min-width: 35px;
  height: 35px;
  border: 0;
  background-color: transparent;
  cursor: pointer;
}

.info-banner-common__close-icon {
  width: 19px;
  height: 19px;
  color: var(--color-banner-close);
}

.info-banner-custom__html-content {
  padding: 0 20px;
}
.info-banner-custom__html-content a {
  color: inherit;
}
.info-banner-custom__html-content p {
  margin: 0;
}

@media (min-width: 48em) {
  .info-banner-custom__html-content-mobile {
    display: none;
  }
}

@media (max-width: 47.99em) {
  .info-banner-custom__html-content-desktop {
    display: none;
  }
}

.logo-slider-basic-slide__image-container {
  height: 80px;
  padding: 10px;
}
.logo-slider-basic-slide__image-container picture {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.logo-slider-basic-slide__image {
  max-height: 70%;
  aspect-ratio: auto 1/1;
}
@media (min-width: 48em) {
  .logo-slider-basic-slide__image {
    min-width: 128px;
  }
}

.logo-slider {
  padding-bottom: 20px;
}

.logo-slider__slider-wrapper {
  display: flex;
  --swiper-theme-color: var(--theme-primary);
}
@media (min-width: 48em) {
  .logo-slider__slider-wrapper {
    position: relative;
    align-items: center;
  }
}
.logo-slider__slider-wrapper .swiper-container {
  width: 100%;
}
.logo-slider__slider-wrapper .swiper-slide-next,
.logo-slider__slider-wrapper .swiper-slide-prev {
  opacity: 1;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: calc(100% - 26px);
  max-width: 700px;
  max-height: 80vh;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  z-index: var(--zi-modal);
}
@media (min-width: 48em) {
  .modal {
    width: 80%;
  }
}
.modal:focus {
  outline: none;
}

.modal__inner {
  position: relative;
  padding: 0;
  border-radius: var(--border-radius-default);
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.34);
}
@media (min-width: 75em) {
  .modal__inner {
    border-radius: 10px/15px;
    box-shadow: 0 3px 13px 0 rgba(0, 0, 0, 0.16);
  }
}

.modal__body {
  padding: 45px;
  max-height: 80vh;
  overflow: auto;
  overscroll-behavior: contain;
}
.modal__body h2 {
  margin-right: 40px;
}

.modal__footer {
  position: sticky;
  padding: 15px 30px 30px;
  bottom: 0;
}

.modal__headline-icon {
  display: flex;
  align-items: center;
  margin-right: 1ch;
}

.modal__close {
  position: fixed;
  right: 24px;
  top: 12px;
  font-size: 22px;
  border: 0;
  color: var(--color-gray-900);
  background: transparent;
  padding: 0;
  cursor: pointer;
  z-index: 1;
}

.modal--bg-white .modal__inner {
  background: var(--color-white);
}

.modal--bg-white-true .modal__inner {
  background: var(--color-white-true);
}

.page--overlay-open {
  height: 100%;
  width: 100%;
}

.modal__overlay-bg {
  position: fixed;
  inset: 0;
  z-index: var(--zi-modal);
  background: var(--modal-overlay-bg-color);
}

.newsletter-unsubscribe {
  text-align: center;
  padding: 30px 20px;
}
@media (min-width: 75em) {
  .newsletter-unsubscribe {
    padding: 30px 0;
  }
}
.newsletter-unsubscribe:has(.headlines) {
  padding-top: 10px;
}

.newsletter-unsubscribe__message {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.newsletter-unsubscribe__message--success {
  color: var(--theme-success);
}
.newsletter-unsubscribe__message--error {
  color: var(--theme-error);
}

.newsletter-unsubscribe__message-icon {
  font-size: 24px;
}

.voucher-modal {
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: center;
}

.voucher-modal__headline {
  font-size: 18px;
  font-weight: 500;
}

.voucher-modal__button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.polaroid-collection__headlines {
  padding: 20px;
  text-align: left;
}
@media (min-width: 48em) {
  .polaroid-collection__headlines {
    text-align: center;
  }
}

@media (min-width: 48em) {
  .polaroid-collection__content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: 60px;
    padding-top: 60px;
  }
}

.polaroid {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 40px;
}

.polaroid:nth-child(odd) {
  align-items: flex-start;
}
@media (min-width: 48em) {
  .polaroid:nth-child(odd) {
    flex-direction: column-reverse;
  }
}
.polaroid:nth-child(odd) .polaroid__image-container {
  border-left-width: 0;
}
@media (min-width: 48em) {
  .polaroid:nth-child(odd) .polaroid__image-container {
    border-left-width: 20px;
  }
}

.polaroid:nth-child(even) {
  align-items: flex-end;
}
.polaroid:nth-child(even) .polaroid__image-container {
  border-right-width: 0;
}
@media (min-width: 48em) {
  .polaroid:nth-child(even) .polaroid__image-container {
    border-right-width: 20px;
  }
}

.polaroid__image-container {
  border: var(--color-border-polaroid) 20px solid;
  width: 90%;
}
.polaroid__image-container img {
  width: 100%;
}
@media (min-width: 48em) {
  .polaroid__image-container {
    width: 100%;
  }
}

.polaroid__content {
  padding: 20px;
  width: 90%;
}
@media (min-width: 48em) {
  .polaroid__content {
    width: 100%;
  }
}

.polaroid__title {
  font-weight: var(--fw-bold);
  font-size: var(--fs-h3);
}

.price-box {
  display: flex;
  flex-direction: column;
  font-weight: var(--fw-semibold);
  gap: 8px;
}

.price-box--align-right {
  align-items: flex-end;
}

.price-box--align-left {
  align-items: flex-start;
}

.price-box__price {
  font-size: var(--fs-xxl);
  color: var(--theme-primary);
  font-family: var(--font-product-price);
  white-space: nowrap;
}

.price-box--small {
  font-size: var(--fs-s);
}
.price-box--small .price-box__price {
  font-size: var(--fs-xl);
}

.product-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  min-width: 375px;
  padding: 0 15px 20px;
}
@media (max-width: 47.99em) {
  .product-box {
    max-height: 572px;
  }
}

.product-card {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background: var(--bg-product-card);
}

.product-card--large {
  max-width: 688px;
  max-height: 355px;
}

.product-card--small {
  max-height: 204px;
  max-width: 380px;
  margin: 0 auto;
}

.product-card--large-portrait {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-height: none;
  max-width: 345px;
}

.product-card__image-wrapper {
  position: relative;
}

.product-card__image-wrapper--small {
  padding: 15px 0;
}

.product-card__image {
  height: 100%;
  max-height: 280px;
  object-fit: contain;
}

.product-card__image--small {
  max-height: 160px;
}

.product-card__image--large-portrait {
  max-height: 200px;
}

.badge.product-card__image-sticker {
  position: absolute;
  top: 10px;
}

.product-card__detail-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--color-black-true);
}

.product-card__detail-wrapper--large {
  padding: 50px 15px;
}

.product-card__detail-wrapper--small {
  padding: 15px;
}

.product-card__detail-wrapper--large-portrait {
  padding: 15px;
}

.product-card__product-name {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
}

.product-card__rating-container {
  margin-top: 7px;
  display: flex;
  align-content: center;
  align-items: center;
}

.product-card__rating-container--large-portrait {
  margin: 7px 0 10px;
}

.product-card__rating {
  margin-right: 6px;
}

.product-card__rating-count {
  font-size: var(--fs-xs);
  line-height: 15px;
}

.product-card__price-container {
  display: flex;
  justify-content: space-between;
  margin: 4px 0 10px;
}

.product-card__price-container--small {
  margin: 8px 0 5px;
}

.product-card__price-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: normal;
  margin-top: 4px;
}

.product-card__price-left--small {
  margin: 0;
}

.product-card__price-left--large-portrait {
  margin: 4px 0 10px;
}

.product-card__savings {
  color: var(--theme-primary);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
}

.product-card__savings--small {
  margin-top: -5px;
}

.product-card__price-right {
  margin-left: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.product-card__original-price--large {
  width: 100%;
  font-size: var(--fs-xs);
  text-align: end;
}

.product-card__original-price--small {
  width: 100%;
  font-size: var(--fs-xs);
  text-align: end;
}

.product-card__price-per-unit {
  font-size: var(--fs-xs);
  width: 100%;
  text-align: end;
}

.product-card__price {
  margin: -4px 0 -6px;
  font-size: var(--fs-xl);
  font-weight: var(--fw-bold);
  color: var(--color-price-box-price);
}

.select-button {
  display: flex;
  background: var(--theme-attention);
}

.select-button--disabled {
  opacity: 0.5;
}

.select-button--loading {
  opacity: 0.7;
}

.select-button__select {
  flex: 0 0 auto;
  min-width: 94px;
}

.select-button__button {
  appearance: none;
  background: none;
  border: none;
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 4px;
  cursor: pointer;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--color-select-button);
}
.select-button--disabled .select-button__button, .select-button--loading .select-button__button {
  cursor: default;
}

.select-button__icon {
  margin-left: 15px;
  font-size: 17px;
}

.shop-switch-modal__body {
  overflow: initial;
}
@media (max-width: 47.99em) {
  .shop-switch-modal__body {
    margin: 0 20px;
    padding: 10px;
  }
}

.shop-switch-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.shop-switch-modal__headline {
  font-size: var(--fs-h1-mobile);
  font-weight: var(--fw-shop-switch-headline);
  line-height: 40px;
  margin-bottom: 20px;
}

.shop-switch-modal__sub-headline {
  font-size: var(--fs-h3-mobile);
  font-weight: var(--fw-shop-switch-sub);
  line-height: 24px;
  margin-bottom: 24px;
  text-align: center;
}

.shop-switch-modal__content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
}
@media (min-width: 48em) {
  .shop-switch-modal__content-container {
    width: 70%;
  }
}
.shop-switch-modal__content-container .select-box .icon {
  font-size: 25px;
}
.shop-switch-modal__content-container .select-box__selected-value-wrap {
  background: var(--color-gray-300);
  border-color: var(--color-gray-500);
}

.shop-switch-modal__content-container--button {
  margin: 20px;
  background: var(--color-black-true);
  color: var(--color-white-true);
  border-radius: 4px;
  width: 100%;
}

.shop-switch-modal__disable-scroll {
  overflow: hidden;
}

.shop-switch-modal__flag {
  margin: 0 5px;
  position: relative;
  top: 2px;
  font-size: var(--fs-shop-switch-flag);
}

.shop-switch-modal__width {
  max-width: 450px;
}

.shop-switch-modal__close-modal-text {
  font-weight: var(--fw-shop-switch-link);
  cursor: pointer;
}

.shop-switch-modal__select-disable {
  cursor: default;
}
.shop-switch-modal__select-disable .select__control {
  pointer-events: none;
}
.shop-switch-modal__select-disable .select__indicators {
  display: none;
}

.show-more-button {
  padding: 0;
  border: 0;
  background-color: unset;
  color: var(--color-text);
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 15px;
}

/* stylelint-disable declaration-no-important */
:root {
  --slider-gutter-mobile: 10px;
  --slider-arrow-height: 40px;
}

.slider {
  width: 100%;
}

.slider__inner-wrapper {
  position: relative;
  display: flex;
  align-items: center;
}
.slider__inner-wrapper--with-arrows .slider__inner-wrapper--arrows-wrapper {
  display: contents;
}
.slider__inner-wrapper--with-arrows .arrow-button {
  display: none;
}
@media (min-width: 75em) {
  .slider__inner-wrapper--with-arrows .arrow-button {
    display: flex;
  }
}
.slider__inner-wrapper--with-arrows .arrow-button--left {
  left: -54px;
}
.slider__inner-wrapper--with-arrows .arrow-button--right {
  right: -54px;
}

.swiper {
  width: 100%;
}

.product-slider .swiper {
  padding-bottom: 5px;
}
.product-slider .swiper-slide {
  height: auto;
}

.swiper-wrapper {
  user-select: none;
  padding-bottom: 8px;
  width: 100vw;
}

.arrow-button--navigation-disabled {
  display: none !important;
}

.slider__pagination {
  display: flex;
  justify-content: center;
  margin-top: 12px;
  padding-bottom: 10px;
}

.slider__pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.2);
  margin: 0 4px;
}

.slider__pagination-disabled {
  pointer-events: none;
  cursor: default;
}

.slider__pagination-bullet-active {
  background: var(--theme-primary);
  opacity: 1;
}

.slider__pagination-bullet-active-animated {
  opacity: 1;
  width: 35px;
  border-radius: 5px;
  background-size: 200% 100%;
  background-image: linear-gradient(to right, #e5e5e5 50%, var(--theme-primary) 50%);
  animation: slideBackground var(--swiper-pagination-bullet-animation-duration) ease-out forwards;
}

.slider__pagination-bullet-active-animation-completed .slider__pagination-bullet-active-animated {
  background-position: -100% 0 !important;
}

.slider__pagination-bullet-active-animation-paused-on-hover:hover .slider__pagination-bullet-active-animated {
  animation-play-state: paused;
}
.slider__pagination-bullet-active-animation-paused-on-hover:hover .slider__pagination:hover .slider__pagination-bullet-active-animated {
  animation-play-state: running;
}

@keyframes slideBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -100% 0;
  }
}
.background--primary .slider__pagination-bullet-active {
  background: var(--color-white-true);
}

.swiper-pagination-clickable .slider__pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-lock {
  display: none;
}

.swiper-pagination-horizontal {
  top: var(--swiper-pagination-top, 100%) !important;
}

/* CUSTOM OPACITY ANIMATION */
.slider__custom-transition-animation .swiper-slide {
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.slider__custom-transition-animation .swiper-slide.swiper-slide-active {
  opacity: 1;
}

.slider-navigation-item {
  appearance: none;
  border: none;
  margin: 6px;
  padding: 0;
  cursor: pointer;
}
.slider-navigation-item:focus {
  outline: none;
}

.social-share {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
}

.social-share__button-container {
  display: flex;
  justify-content: space-around;
}

.social-share__button {
  width: 80px;
  height: 80px;
  min-width: 80px;
}
@media (min-width: 48em) {
  .social-share__button {
    flex-basis: auto;
    min-width: 200px;
    min-height: 30px;
    height: 30px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.social-share__button-icon {
  height: 20px;
  width: 20px;
  color: var(--color-social-icon);
}
@media (min-width: 48em) {
  .social-share__button-icon {
    height: 50px;
    width: 50px;
    margin-right: 15px;
  }
}

.social-share__button-text {
  display: none;
  text-transform: none;
  font-size: var(--fs-base);
  color: var(--color-social-text);
}
@media (min-width: 48em) {
  .social-share__button-text {
    display: inline;
  }
}

@media (min-width: 48em) {
  .social-share__button-mobile {
    display: none;
  }
}

.stage-slider .slider {
  margin: 0;
}

.stage-slider__content-container {
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  grid-template-areas: "imageContainer" "textContainer";
}
@media (min-width: 48em) {
  .stage-slider__content-container {
    overflow: hidden;
  }
}

@media (min-width: 75em) {
  .stage-slider--image-position-left .stage-slider__content-container {
    grid-template-columns: 75% 25%;
    grid-template-areas: "imageContainer textContainer";
  }
}

.stage-slider__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 0 15px;
}

.stage-slider__content {
  grid-area: textContainer;
  padding: var(--sp-base) var(--sp-lg);
}
@media (min-width: 75em) {
  .stage-slider__content {
    padding: var(--sp-base) var(--sp-xl) var(--sp-base) var(--sp-lg);
  }
}

.stage-slider__image-container {
  position: relative;
  clip-path: polygon(0 0, 100% 0%, 100% 84%, 0% 100%);
  height: 300px;
  margin-top: 20px;
}
@media (min-width: 75em) {
  .stage-slider__image-container {
    position: relative;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
    height: auto;
  }
}

.stage-slider__image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.star-rating-bar {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.star-rating-bar__star-rating {
  margin-right: 5px;
  font-size: 16px;
}

.sticky-button {
  position: fixed;
  z-index: var(--zi-sticky-button);
  bottom: 0;
  height: 70px;
  left: 0;
  right: 0;
}

.sticky-button--top {
  top: 0;
}

.sticky-button--bottom {
  bottom: 0;
}

.sticky-button__dummy {
  height: 70px;
}

.sticky-button__content {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.sticky-button__column-left {
  display: none;
}
@media (min-width: 48em) {
  .sticky-button__column-left {
    display: flex;
  }
}

.sticky-button__image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.sticky-button__headline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sticky-button__headline {
  text-align: start;
  padding: 0;
  line-height: 1.3;
}

.sticky-button__column-right {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 0 10px;
}
@media (min-width: 48em) {
  .sticky-button__column-right {
    width: auto;
    justify-content: flex-start;
  }
}

.sticky-button__price-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 15px;
}

.sticky-button__original-price {
  font-size: var(--fs-xs);
}

.sticky-button__price {
  font-size: var(--fs-xl);
  font-weight: var(--fw-bold);
  margin-top: -8px;
  margin-bottom: -4px;
}

.sticky-button__savings {
  color: var(--theme-tertiary);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
}

.sticky-button__button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.data-cell__text {
  padding: 0 20px;
}

.data-cell__image {
  margin: auto;
  width: auto;
}

.table {
  padding: 50px 0;
  width: 100%;
  margin: 0 auto;
}

.table__wrapper {
  position: relative;
}

.table__table {
  white-space: nowrap;
  border-spacing: 0;
}

.table__scroller {
  overflow: auto;
  width: var(--max-width);
  margin: 0 auto;
}
@media (min-width: 48em) {
  .table__scroller {
    width: auto;
  }
}

.table__background--default {
  --cell-bg: var(--bg-table-cell-default);
  --cell-border: var(--border-table-cell-default);
  --color-text: var(--color-table-cell-default);
  --color-headline: var(--color-headline-table-cell-default);
}

.table__background--primary {
  --cell-bg: var(--bg-table-cell-primary);
  --cell-border: var(--border-table-cell-primary);
  --color-text: var(--color-table-cell-primary);
  --color-headline: var(--color-headline-table-cell-primary);
}

.table__background--secondary {
  --cell-bg: var(--bg-table-cell-secondary);
  --cell-border: var(--border-table-cell-secondary);
  --color-text: var(--color-table-cell-secondary);
  --color-headline: var(--color-headline-table-cell-secondary);
}

.table__background--tertiary {
  --cell-bg: var(--bg-table-cell-tertiary);
  --cell-border: var(--border-table-cell-tertiary);
  --color-text: var(--color-table-cell-tertiary);
  --color-headline: var(--color-headline-table-cell-tertiary);
}

.table__table-body-row:nth-child(even) .table__table-cell {
  background-color: var(--cell-bg);
}

.table__table-body-row:nth-child(odd) .table__table-cell {
  background-color: var(--color-white-true);
}

.table__table-cell {
  border-right: 1px solid var(--cell-border);
  min-width: 120px;
  text-align: center;
}
@media (min-width: 48em) {
  .table__table-cell {
    min-width: 170px;
  }
}

.table__table-head-cell {
  background-color: var(--cell-bg);
  color: var(--color-headline);
  position: sticky;
  top: 0;
}

.table__table-cell:last-child {
  border-right: 1px solid transparent;
}

.table__table-cell:first-child {
  color: var(--color-headline);
  position: sticky;
  left: 0;
  z-index: var(--zi-table-cell-first-child);
  font-weight: var(--fw-semibold);
}

.table__table-cell-shadow::after,
.table__table-head-cell-shadow::after {
  box-shadow: inset 11px 0 12px -15px #111;
  content: " ";
  height: 100%;
  position: absolute;
  top: 0;
  right: -16px;
  width: 15px;
}

.testimonial-slider {
  padding: 20px 0 40px;
}
@media (max-width: 47.99em) {
  .testimonial-slider .swiper-slide-next,
  .testimonial-slider .swiper-slide-prev {
    opacity: 0.5;
  }
}

.testimonial-slider__slider-wrapper {
  display: flex;
  --swiper-theme-color: var(--theme-primary);
  position: relative;
  align-items: center;
}
.testimonial-slider__slider-wrapper .swiper-container {
  padding-left: 20px;
  width: 100%;
}

@media (min-width: 75em) {
  .testimonial-slider__slider-wrapper .slider__inner-wrapper--with-arrows {
    margin-left: 60px;
    margin-right: 60px;
  }
}

.background--primary .testimonial-slider__pagination-bullet-active,
.background--tertiary .testimonial-slider__pagination-bullet-active {
  background: var(--color-white-true);
}

.testimonial-slide {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 2em;
  text-align: center;
}
@media (min-width: 75em) {
  .testimonial-slide {
    padding: 3em;
  }
}
.testimonial-slide .testimonial-slide__image {
  padding: 0 2em;
  width: 60vw;
  height: auto;
  align-self: flex-start;
  margin: 0 auto;
}

.testimonial-slide__name-container {
  margin-top: 1em;
}

.testimonial-slide__name {
  font-weight: var(--fw-semibold);
  font-size: var(--fs-h3);
}

.testimonial-slide__position {
  font-weight: var(--fw-regular);
}
.testimonial-slide__position::before {
  content: " ";
}
@media (max-width: 74.99em) {
  .testimonial-slide__position::before {
    content: "\a";
    white-space: pre-wrap;
  }
}

.testimonial-slide__quote {
  font-style: italic;
  min-height: 80px;
}

.testimonial-slide__stars {
  margin-top: 15px;
  font-size: 24px;
}

.text-box__content {
  position: relative;
  border: 8px solid #000;
  height: 30vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  margin: 0 5vw;
}
.text-box__content span {
  letter-spacing: 0.3em;
  font-family: var(--fw-bold);
}
.text-box__content h4 {
  font-weight: var(--fw-bold);
}

.text-box__close-button {
  position: absolute;
  top: 15px;
  right: 10px;
  background: none;
  border: none;
  margin: 0;
  cursor: pointer;
  color: var(--color-gray-800);
}

.text-with-image {
  clip-path: inset(0);
}

.text-with-image__content-container {
  display: grid;
  align-items: center;
  grid-template-columns: auto;
  grid-template-areas: "imageContainer" "textContainer";
}
@media (min-width: 48em) {
  .text-with-image__content-container {
    overflow: hidden;
  }
}

.text-with-image__image-container {
  grid-area: imageContainer;
  position: relative;
}
.text-with-image__image-container img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
@media (min-width: 48em) {
  .text-with-image__image-container {
    position: relative;
    overflow: hidden;
    height: 300px;
  }
  .text-with-image__image-container img {
    object-fit: cover;
    min-width: 100%;
    min-height: 100%;
  }
}
@media (min-width: 75em) {
  .text-with-image__image-container {
    height: 500px;
  }
}

.text-with-image__image-badge {
  position: absolute;
  display: inline-block;
  top: 10px;
  padding: 10px 40px;
  background: var(--bg-badge-info);
  color: var(--color-badge-info);
  font-weight: var(--fw-text-badge);
}

.text-with-image__content {
  grid-area: textContainer;
  padding: var(--sp-base) var(--sp-lg);
}
@media (min-width: 48em) {
  .text-with-image__content {
    padding: var(--sp-base) var(--sp-xl) var(--sp-base) var(--sp-lg);
  }
}

.text-with-image__headlines {
  padding: 0;
  text-align: start;
}

.text-with-image__icon-background {
  color: var(--theme-primary);
  position: absolute;
  width: 80%;
  height: 50%;
  opacity: 0.2;
  transform: translate(0%, -50%);
  z-index: -1;
}

.text-with-image--image-position-left .text-with-image__image-badge {
  position: absolute;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  left: 0;
}
@media (min-width: 48em) {
  .text-with-image--image-position-left {
    grid-template-columns: 60% 40%;
    grid-template-areas: "imageContainer textContainer";
  }
  .text-with-image--image-position-left .text-with-image__image-container {
    position: relative;
    clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  }
}
@media (min-width: 75em) {
  .text-with-image--image-position-left {
    grid-template-columns: 60% 40%;
    grid-template-areas: "imageContainer textContainer";
  }
}

.text-with-image--image-position-right .text-with-image__image-badge {
  position: absolute;
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  right: 0;
}
@media (min-width: 48em) {
  .text-with-image--image-position-right {
    grid-template-columns: 40% 60%;
    grid-template-areas: "textContainer imageContainer";
  }
  .text-with-image--image-position-right .text-with-image__content {
    padding-left: 3.7rem;
  }
  .text-with-image--image-position-right .text-with-image__image-container {
    position: relative;
    clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
@media (min-width: 75em) {
  .text-with-image--image-position-right {
    grid-template-columns: 40% 60%;
    grid-template-areas: "textContainer imageContainer";
  }
}

.usps {
  padding: 10px;
  min-height: 85px;
  display: flex;
}

.usps__container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.usps__item {
  display: flex;
  align-items: center;
}
@media (max-width: 47.99em) {
  .usps__item {
    gap: 6px;
    flex-direction: column;
  }
}

.usps__icon-wrapper picture {
  width: 30px;
  height: 30px;
  display: flex;
  place-content: center;
}
@media (min-width: 48em) {
  .usps__icon-wrapper picture {
    width: 40px;
    height: 40px;
  }
}
.usps__icon-wrapper picture img {
  aspect-ratio: auto 1/1;
}

.usps__icon-wrapper--clickable:hover {
  cursor: pointer;
}

.usps__text-container {
  font-weight: var(--fw-normal);
  display: flex;
  align-content: center;
  align-items: center;
}
@media (min-width: 48em) {
  .usps__text-container {
    margin-left: 5px;
  }
}
@media (min-width: 75em) {
  .usps__text-container {
    margin-left: 20px;
  }
}

.usps__text {
  word-break: break-word;
  line-height: 1.15;
  font-size: 13px;
}
@media (min-width: 48em) {
  .usps__text {
    word-break: unset;
    font-size: 15px;
  }
}
@media (max-width: 47.99em) {
  .usps__text {
    text-align: center;
  }
}

.background--primary .usps__text {
  color: var(--color-white-true);
}

.usps__modal-background {
  position: fixed;
  inset: 0;
  z-index: var(--zi-modal);
  background: var(--modal-overlay-bg-color);
}

.video-with-text {
  padding: 30px 0 10px;
}
.video-with-text:has(.video-with-text__top-headline) {
  padding: 10px 0;
}
.video-with-text > div {
  padding-bottom: 20px;
}
.video-with-text:has(.video-with-text__video-headline) .video-with-text__video-wrapper {
  padding-bottom: 0;
}

.video-with-text__text {
  padding: 0 20px;
}
@media (min-width: 48em) {
  .video-with-text__text {
    padding: 0;
  }
}
.video-with-text__text p {
  margin: 0 0 1rem;
}
.video-with-text__text p:last-child {
  margin: 0;
}

.voucher-redemption__head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 20px;
}
@media (min-width: 48em) {
  .voucher-redemption__head {
    padding: 10px 20px;
  }
}
.voucher-redemption__head:hover {
  cursor: pointer;
}

.voucher-redemption__body {
  display: flex;
  margin-bottom: 20px;
}
@media (min-width: 48em) {
  .voucher-redemption__body {
    padding: 0;
  }
}

.voucher-redemption__body-code {
  border: 1px solid var(--color-gray-80);
  border-right: none;
  border-radius: var(--border-radius-default) 0 0 var(--border-radius-default);
  text-overflow: ellipsis;
  width: 100%;
  font-size: var(--fs-sm);
  color: var(--color-gray-600);
}
.voucher-redemption__body-code .input__input:focus ~ .input__title,
.voucher-redemption__body-code .input__input:not(:placeholder-shown) ~ .input__title {
  transform: translate(-12px, -11px) scale(0.85);
  width: 115%;
}
.voucher-redemption__body-code .input__label {
  overflow: hidden;
}
.voucher-redemption__body-code .input__input {
  border: none;
  outline: none;
}
.voucher-redemption__body-code .input__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 0;
}
.voucher-redemption__body-code::placeholder {
  color: var(--color-gray-500);
}

.voucher-redemption__body-btn {
  color: var(--color-gray-600);
  font-weight: var(--fw-semibold);
  padding: 12px;
  border: 1px solid var(--color-gray-80);
  background: var(--color-gray-200);
  border-radius: 0 var(--border-radius-default) var(--border-radius-default) 0;
  text-transform: none;
  min-width: auto;
  font-size: var(--fs-sm);
  cursor: pointer;
}
.voucher-redemption__body-btn:disabled {
  cursor: not-allowed;
  opacity: 1;
}

.voucher-redemption__msg-success {
  color: var(--theme-success);
  padding: 0 20px;
  margin-top: 20px;
}

.voucher-redemption__msg-error {
  display: flex;
  color: var(--theme-error);
  margin: 12px;
}

.voucher-redemption__wrapper {
  display: grid;
  grid-template-columns: 80% 20%;
  padding: 0 20px;
}

.voucher-redemption__voucher {
  line-height: 23px;
  max-width: 250px;
}

.voucher-row {
  display: flex;
  gap: 5px;
}
.voucher-row .op-checkout-summary__mini-cart-summary-line {
  width: 100%;
}

.voucher-row__remove-icon {
  margin-top: 2px;
  font-size: var(--fs-sm);
}

.voucher-row__remove-button {
  cursor: pointer;
}

.voucher-row__voucher-valid {
  color: var(--theme-success);
}

.voucher-row__voucher-invalid {
  color: var(--theme-error);
}

.voucher-row__label {
  display: flex;
}

.voucher-row__value {
  font-family: var(--font-product-price);
  text-align: right;
  min-width: 64px;
  font-size: var(--fs-sm);
}

.voucher-row__voucher-overlay--flip-left {
  border-left: none;
  border-right: 3px solid var(--theme-error);
  left: -224px;
}
@media (min-width: 48em) {
  .voucher-row__voucher-overlay--flip-left {
    border-left: none;
    border-right: 3px solid var(--theme-error);
    left: -180px;
    bottom: 36px;
  }
}
@media (min-width: 75em) {
  .voucher-row__voucher-overlay--flip-left {
    border-left: none;
    border-right: 3px solid var(--theme-error);
    left: -224px;
    bottom: 16px;
  }
}

.youtube-video {
  position: relative;
  overflow: hidden;
  padding-bottom: 56.25%;
  height: 0;
}
.youtube-video iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
@media (max-width: 47.99em) {
  .youtube-video .uc-embedding-container {
    min-height: 200px;
  }
}
@media (max-width: 47.99em) {
  .youtube-video .uc-embedding-wrapper {
    top: 42%;
  }
}

.youtube-video__video-headline {
  width: 80%;
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: var(--fs-l);
  font-weight: var(--fw-semibold);
}
@media (min-width: 48em) {
  .youtube-video__video-headline {
    font-size: var(--fs-ml);
    font-weight: var(--fw-semibold);
  }
}

.youtube-video__overlay-button {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  margin: 0;
}

.youtube-video-poster {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  inset: 0;
  cursor: pointer;
  z-index: 1;
}

.youtube-video-poster__play-button {
  background: var(--bg-play-button);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: "";
  position: relative;
  clip-path: polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%);
  padding: 12px 12px 12px 15px;
  width: 75px;
  height: 65px;
}

.youtube-video-poster__arrow {
  width: 100%;
  height: 100%;
  color: var(--color-play-icon);
}

.checkout-payment {
  padding-bottom: 8px;
}

.checkout-payment__hint {
  padding: 10px 0 22px;
  white-space: pre-wrap;
}

.checkout-payment__submit {
  margin-bottom: 20px;
}

.checkout-payment__required-hint {
  margin: 5px 0 20px;
  font-size: var(--fs-s);
  color: var(--color-gray-500);
}

.payment-method-error {
  color: var(--color-red);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-s);
  margin-bottom: 20px;
}

.payment-method-required-fields__invoice-dob-error {
  color: var(--color-red);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-s);
}

.payment-method-item {
  margin-bottom: 16px;
}
.payment-method-item:not(:last-child) {
  border-bottom: 1px solid var(--color-gray-80);
}

.payment-method-item__inner-wrapper {
  padding: 0 8px;
  margin-bottom: 12px;
}

.payment-method-item__payment-info {
  display: flex;
  justify-content: space-between;
}
.payment-method-item__payment-info:not(.payment-method-item__payment-description) {
  margin-bottom: 4px;
}

.payment-method-item__radio .radio-button__label {
  margin-right: 20px;
}

.payment-method-item__radio-button-text {
  margin: 0 0 8px;
}

.payment-method-item__radio-label {
  font-weight: var(--fw-semibold);
  font-size: 17px;
}
@media (min-width: 48em) {
  .payment-method-item__radio-label {
    font-size: 18px;
  }
}

.payment-method-item__radio-fees {
  font-size: 15px;
  font-weight: 300;
}

.payment-method-item__payment-description {
  margin-left: 48px;
  color: var(--color-gray-500);
  font-size: var(--fs-s);
  margin-bottom: 12px;
}

.payment-method-item__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  max-height: 40px;
  color: var(--theme-primary);
}

.payment-network-unavailable__container {
  display: flex;
  align-items: center;
  margin-top: 24px;
}

.payment-network-unavailable__attention-icon-container {
  background-color: var(--theme-primary);
  color: var(--theme-accent);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  margin-right: 16px;
}

.payment-network-unavailable__attention-icon {
  font-size: 4rem;
  padding: 10px 15px 15px;
}

.payment-network-unavailable__text strong {
  font-size: var(--fs-l);
}

.payment-network-unavailable__submit {
  margin-top: 24px;
}

.paypal-banner__container {
  margin-top: 10px;
}

.product-details-content__wrapper .paypal-banner__container {
  margin-top: 20px;
}

.checkout-summary-usp {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: var(--fs-base);
}

.checkout-summary-usp__usp {
  display: flex;
  align-items: center;
  padding: 0;
}

.footer-text-item__column {
  width: 100%;
  padding: 19px;
  border-bottom: 1px solid;
}
@media (min-width: 75em) {
  .footer-text-item__column {
    width: 25%;
    padding: 0;
    border: none;
  }
}

.footer-text-item__item {
  padding-top: 10px;
}
@media (min-width: 75em) {
  .footer-text-item__item {
    background-color: transparent;
    padding: 0;
  }
}
.footer-text-item__item p {
  margin: 10px 0;
}

.footer-text-item__column a {
  color: currentcolor;
}

.footer-text-item__heading {
  font-size: 19px;
  font-weight: var(--fw-semibold);
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 75em) {
  .footer-text-item__heading {
    font-size: var(--fs-footer-header-desktop);
    line-height: 30px;
    cursor: auto;
  }
}

.footer-text-item__column p {
  line-height: 26px;
  font-weight: var(--fw-normal);
}

.footer-text-item__plus {
  float: right;
}
@media (min-width: 75em) {
  .footer-text-item__plus {
    display: none;
  }
}

.footer-text-item__icon {
  margin-left: auto;
}

.footer-text__main {
  background: var(--footer-background-color);
}

.footer-text__wrapper {
  color: var(--footer-font-color);
  padding: 30px 20px 20px;
}
@media (min-width: 75em) {
  .footer-text__wrapper {
    padding: 50px 20px 20px;
    display: flex;
    gap: 19px;
    flex-direction: row;
  }
}

.op-checkout-addresses {
  position: relative;
}

.op-checkout-addresses__hint {
  margin-bottom: 25px;
  padding-left: 25px;
  font-size: var(--fs-xs);
  color: var(--op-checkout-hint);
}

.op-checkout-addresses__loading {
  position: absolute;
  inset: 0;
  display: flex;
  background: rgba(255, 255, 255, 0.7);
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.op-checkout-addresses__loading-text {
  margin-top: 10px;
  font-size: 30px;
}

.op-checkout-cart__header {
  display: grid;
  grid-template-columns: 1fr auto 40px;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 2px solid var(--color-border-checkout-cart);
  cursor: pointer;
}
@media (min-width: 75em) {
  .op-checkout-cart__header {
    border-top: 1px solid var(--color-border-alt-checkout-cart);
    border-left: 1px solid var(--color-border-alt-checkout-cart);
    border-right: 1px solid var(--color-border-alt-checkout-cart);
  }
}

.op-checkout-cart__content {
  border-bottom: 1px solid var(--color-border-checkout-cart);
  padding: 10px 0 30px;
}
@media (min-width: 75em) {
  .op-checkout-cart__content {
    padding: 10px 20px;
    border-left: 1px solid var(--color-border-alt-checkout-cart);
    border-right: 1px solid var(--color-border-alt-checkout-cart);
  }
}

.op-checkout-cart__headline {
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
  display: flex;
  flex-direction: row;
}

.op-checkout-cart__total-price {
  font-family: var(--font-product-price);
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
  line-height: 1;
  text-align: right;
  color: var(--color-checkout-cart-price);
}

.op-checkout-cart__tax-info {
  font-size: var(--fs-base);
  font-weight: var(--fw-regular);
  display: block;
  color: var(--color-checkout-cart-tax);
}

.op-checkout-cart__detail-arrow {
  font-size: var(--fs-h2);
  text-align: right;
  color: var(--color-checkout-cart-arrow);
}

.op-checkout-cart__arrow-icon {
  vertical-align: middle;
}

.op-checkout-cart__back-to-cart {
  margin-left: 19px;
  font-size: 12px;
  margin-top: -2px;
  background: var(--color-checkout-cart-arrow);
  color: #fff;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: var(--border-radius-default);
}

.op-checkout-cart-line-item {
  min-height: 100px;
  display: flex;
  flex-direction: row;
  border: 1px solid #bfbfbf;
  margin-bottom: 15px;
  border-radius: 5px;
}

.op-checkout-cart-line-item__image-container {
  margin-right: 10px;
  width: 36%;
  border-right: 1px solid #bfbfbf;
}
@media (min-width: 75em) {
  .op-checkout-cart-line-item__image-container {
    width: 18%;
  }
}

.op-checkout-cart-line-item__name {
  font-size: var(--fs-h3);
  font-weight: var(--fw-bold);
  margin-top: 8px;
  color: #b2b2b2;
}

.op-checkout-cart-line-item__detail,
.op-checkout-cart-line-item__quantity {
  font-size: var(--fs-base);
  font-weight: var(--fw-regular);
}

.op-checkout-cart-line-item__image-container img {
  padding: 5px;
}

.op-checkout-cart-line-item__detail {
  margin-top: 20px;
}

.op-checkout-cart-line-item .price-box {
  align-items: flex-start;
  margin-top: 14px;
}

.op-checkout-cart-line-item__quantity {
  margin-top: 10px;
}

.op-checkout-cart-line-item__price-free {
  margin-top: 30px;
  margin-bottom: 7px;
  background: var(--color-badge-gratis-text);
  color: var(--color-white);
  padding: 2px 10px;
  font-size: 28px;
  font-family: var(--font-product-price);
  border-radius: 6px;
  text-transform: uppercase;
  width: 43%;
}

.op-checkout {
  display: grid;
  grid-template: "divider" "address" "payment" "summary";
  gap: 20px;
  padding: 0 0 50px;
  align-items: flex-start;
}
@media (min-width: 75em) {
  .op-checkout {
    grid-template: "address summary" "payment summary"/1fr 333px;
    padding: 50px 0;
  }
}

.op-checkout__panel {
  padding: 25px 15px 30px;
  color: var(--color-text);
}
@media (min-width: 48em) {
  .op-checkout__panel {
    background: var(--bg);
  }
}

.op-checkout__headline {
  font-size: var(--fs-18);
  font-weight: var(--fw-bold);
  color: var(--color-gray-800);
}
@media (min-width: 48em) {
  .op-checkout__headline {
    font-size: var(--fs-l);
  }
}

.op-checkout__header {
  color: var(--color-headline);
}

.op-checkout__shipping-headline {
  padding: 50px 0 20px;
}

.op-checkout__cart {
  grid-area: cart;
}

.op-checkout__divider {
  grid-area: divider;
  display: none;
  align-items: center;
  font-size: var(--fs-h2);
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  color: var(--theme-primary);
}
.op-checkout__divider::before, .op-checkout__divider::after {
  content: "";
  flex: 1 0 auto;
  display: block;
  height: 2px;
  background: var(--theme-primary);
}
.op-checkout__divider::before {
  margin-right: 8px;
}
.op-checkout__divider::after {
  margin-left: 8px;
}

.op-checkout__address {
  grid-area: address;
}

.op-checkout__payment {
  grid-area: payment;
  border-top: 3px solid var(--color-borders-checkout);
}
@media (min-width: 48em) {
  .op-checkout__payment {
    border-top: 0;
  }
}

.op-checkout__summary {
  grid-area: summary;
  border-top: 3px solid var(--color-borders-checkout);
}
@media (min-width: 48em) {
  .op-checkout__summary {
    border-top: 0;
  }
}

.op-checkout__loading-indicator {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.op-checkout__loading-indicator-text {
  font-size: 30px;
  margin-top: 20px;
}

.op-checkout__background--primary {
  --bg: var(--theme-primary);
  --color-text: var(--theme-on-primary);
  --color-highlight: var(--theme-on-primary-highlight);
  --color-headline: var(--theme-on-primary-headline);
  --color-subheadline: var(--theme-on-primary-headline);
  --color-border: var(--theme-on-primary-border);
}

.op-checkout__background--secondary {
  --bg: var(--theme-secondary);
  --color-text: var(--theme-on-secondary);
  --color-highlight: var(--theme-on-secondary-highlight);
  --color-headline: var(--theme-on-secondary-headline);
  --color-subheadline: var(--theme-on-secondary-headline);
  --color-banner: var(--bg-info-banner-secondary);
  --color-border: var(--theme-on-secondary-border);
}

.op-checkout__background--tertiary {
  --bg: var(--theme-tertiary);
  --color-text: var(--theme-on-tertiary);
  --color-highlight: var(--theme-on-tertiary-highlight);
  --color-headline: var(--theme-on-tertiary-headline);
  --color-subheadline: var(--theme-on-tertiary-headline);
  --color-banner: var(--bg-info-banner-tertiary);
  --color-banner-close: var(--color-white-true);
  --color-border: var(--theme-on-tertiary-border);
}

.op-checkout__background--default {
  --bg: var(--bg-default-checkout-cart);
  --color-text: var(--theme-on-default);
  --color-highlight: var(--theme-on-default-highlight);
  --color-headline: var(--theme-on-default-headline);
  --color-subheadline: var(--theme-on-default-subheadline);
  --color-banner: var(--bg-info-banner);
  --color-border: var(--theme-on-default-border);
}

.op-checkout-summary {
  padding: 25px 15px;
}
@media (min-width: 75em) {
  .op-checkout-summary {
    overflow: auto;
    position: sticky;
    top: 20px;
    background: var(--bg-checkout-summary);
  }
}
.op-checkout-summary .voucher-redemption__head {
  padding: 10px;
  text-decoration: underline;
}

.op-checkout-summary__header {
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
  color: var(--color-headline);
}

.op-checkout-summary__usps {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: var(--fs-l);
}

.op-checkout-summary__usp {
  display: flex;
  align-items: center;
  padding: 0;
}

.op-checkout-summary__usp::before {
  content: none;
}

.op-checkout-summary__line-item {
  display: grid;
  grid-template-areas: "quantity productName price";
  grid-template-columns: 30px auto 120px;
  align-items: center;
  margin-bottom: 15px;
}

.op-checkout-summary__quantity {
  grid-area: quantity;
  font-size: var(--fs-h4);
  font-weight: var(--fw-bold);
}

.op-checkout-summary__product-name {
  grid-area: productName;
  font-size: var(--fs-h4);
  font-weight: var(--fw-bold);
  line-height: 1;
}

.op-checkout-summary__price {
  grid-area: price;
}

.op-checkout-summary__mini-cart .price-box__unit-price {
  font-size: var(--fs-xs);
}
@media (min-width: 75em) {
  .op-checkout-summary__mini-cart {
    margin-top: 15px;
  }
}

.op-checkout-summary__mini-cart-summary {
  border-top: 2px solid var(--color-borders-checkout);
  padding-top: 20px;
}

.op-checkout-summary__mini-cart-summary-line {
  display: grid;
  grid-template-columns: auto 140px;
  margin: 5px 0;
}

.op-checkout-summary__label {
  text-align: left;
  hyphens: auto;
}

.op-checkout-summary__voucher-label {
  display: flex;
}

.op-checkout-summary__value {
  font-family: var(--font-product-price);
  text-align: right;
}

.op-checkout-summary__subtotal {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
}

.op-checkout-summary__delivery-cost {
  font-size: var(--fs-l);
  color: var(--color-checkout-delivery-cost);
}

.op-checkout-summary__total {
  font-size: var(--fs-xl);
  font-weight: var(--fw-bold);
  margin-top: 10px;
  word-break: break-word;
  line-height: 1;
}
.op-checkout-summary__total .op-checkout-summary__total-label {
  display: inline-block;
  margin-right: 1ch;
}
.op-checkout-summary__total .op-checkout-summary__tax-label {
  font-size: var(--fs-s);
  font-weight: var(--fw-regular);
  color: var(--color-checkout-cart-tax);
  white-space: nowrap;
}

.op-checkout-summary__total-price {
  color: var(--color-checkout-total-value);
  font-family: var(--font-product-price);
}

.op-checkout-summary__total-savings {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  color: var(--color-checkout-total-savings);
}

.op-checkout-summary__footer {
  margin-top: 15px;
}

.op-checkout-summary__footer-legal-text {
  padding: 15px 0 30px;
}

.op-checkout-summary__footer-legal-text a {
  text-decoration: underline;
  color: var(--color-checkbox-label);
  font-weight: 600;
}

.op-checkout-summary__buynow-button {
  padding: 20px;
  margin-top: 15px;
  font-size: var(--fs-xl);
  height: auto;
}

.op-checkout-summary .delivery-info {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  margin-top: 10px;
}

@media (min-width: 48em) {
  .op-checkout-summary__voucher-overlay {
    left: 25px;
    bottom: 15px;
  }
}

.bundle__title {
  font-size: 16px;
  color: var(--color-gray-800);
  font-weight: var(--fw-semibold);
  padding: 8px 0 13px;
}

.bundle__item-wrapper {
  border: 1px solid var(--color-gray-80);
  border-radius: var(--border-radius-default);
  overflow: hidden;
}

.bundle-item {
  cursor: pointer;
  padding: 0 12px;
}
.bundle-item:not(:last-child) .bundle-item__inner-wrapper {
  border-bottom: 1px solid #ebebeb;
}
.bundle-item:hover {
  background: var(--color-gray-250);
}
.bundle-item:hover .bundle-item__icon {
  color: var(--theme-primary);
}

.bundle-item__inner-wrapper {
  display: flex;
  flex-direction: row;
  gap: 12px;
  padding: 12px 0;
}

.bundle-item__image-container img {
  vertical-align: bottom;
}

.bundle-item__info-container {
  flex: 1;
}

.bundle-item__title {
  font-family: var(--font-base);
  font-weight: var(--fw-semibold);
  color: var(--color-product-title);
  font-size: var(--fs-product-title-desktop);
  margin-bottom: 4px;
}
@media (max-width: 26.25em) {
  .bundle-item__title {
    word-break: break-word;
    hyphens: auto;
  }
}

.bundle-item__claim {
  font-size: var(--fs-product-description-desktop);
  margin-bottom: 6px;
}

.bundle-item__price {
  font-weight: var(--fw-semibold);
  margin-right: 8px;
}
.bundle-item__price--saving {
  color: var(--color-red);
}

.bundle-item__icon {
  display: none;
  font-size: 18px;
  color: var(--color-gray-500);
  align-self: center;
}
@media (min-width: 48em) {
  .bundle-item__icon {
    display: block;
  }
}

.product-stage-description__preview {
  position: relative;
  font-size: 14px;
}

.product-stage-description__preview--oversized {
  overflow: hidden;
}
.product-stage-description__preview--oversized::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 3em;
  width: 100%;
  background: linear-gradient(var(--bg-product-stage-description-gradient-start) 0%, var(--bg-product-stage-description-gradient-end) 100%);
}

.product-stage-description__show-more-button {
  padding: 4px 10px;
  background: var(--bg-product-stage-description-show-more-button);
  color: var(--color-product-stage-description-show-more-button);
  font-size: var(--fs-xs);
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}

.product-stage {
  position: relative;
}
@media (min-width: 48em) {
  .product-stage {
    display: flex;
  }
}

.product-stage__badge {
  position: absolute;
  left: 0;
  top: 25px;
}
@media (min-width: 48em) {
  .product-stage__badge {
    top: 70px;
  }
}

.product-stage__product-logo {
  max-width: 150px;
  margin-bottom: 6px;
}

.product-stage__media {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 400px;
}

.product-stage__image {
  max-height: 700px;
}

.product-stage__detail {
  padding: 25px 15px;
}
@media (min-width: 48em) {
  .product-stage__detail {
    flex: 0 1 500px;
    padding: 50px 15px;
  }
}

/* Display a slant in the background of the image */
@media (min-width: 48em) {
  .product-stage__container::before {
    content: "";
    display: block;
    background: var(--color-white-true);
  }
}

.product-stage__title {
  font-size: 30px;
  font-weight: var(--fw-semibold);
  line-height: 1.2;
  margin-bottom: 6px;
}

.product-stage__rating {
  display: flex;
  font-size: 12px;
  margin-bottom: 7px;
}

.product-stage__claim {
  font-family: var(--ff-headline);
  font-weight: var(--fw-medium);
  font-size: 20px;
  margin-bottom: 11px;
}

.product-stage__description {
  margin-bottom: 10px;
  font-size: 14px;
}

.product-stage__variant-switcher {
  margin-bottom: 10px;
}

.product-stage__infos {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.product-stage__loading-indicator {
  margin: 0 auto;
}

.product-stage__saved-money {
  font-size: 16px;
  font-weight: var(--fw-semibold);
  color: var(--theme-accent);
}

.product-stage__price-container {
  flex: 1 0 auto;
  text-align: right;
  line-height: 1.3;
}

.product-stage__old-price {
  font-size: 16px;
  display: block;
  margin-bottom: -0.7em;
}

.product-stage__price {
  font-family: var(--ff-headline);
  font-weight: var(--fw-semibold);
  font-size: 45px;
}

.product-stage__unit-price {
  font-size: 12px;
}

.product-stage__price-hint {
  margin: 5px 0 10px;
  font-size: 12px;
  text-align: center;
}

.product-stage__price-hint-link {
  cursor: pointer;
  text-decoration: underline;
}

.product-stage__delivery-hint {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: var(--fw-semibold);
}

.product-stage__delivery-icon {
  font-size: 28px;
  margin-right: 10px;
}

.product-data__old-price {
  color: var(--theme-dark);
  font-size: var(--fs-xs);
  font-weight: var(--fw-semibold);
  margin-bottom: 6px;
  line-height: 1;
}

.product-stage-logos {
  display: flex;
}

.product-stage-logos__logo {
  background: var(--bg-product-stage-logos);
  object-fit: scale-down;
  margin-right: 10px;
  flex: 1 1 0;
  height: 40px;
  width: 0;
  padding: 3px;
}
.product-stage-logos__logo:last-child {
  margin-right: 0;
}

.product-stage-variant__variant-error {
  color: var(--theme-error);
  padding-top: 5px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.product-stage-variant__attention-icon {
  margin-right: 5px;
}

.product-stage-variant__select > select {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 20px 0;
}

.variant-selector {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.variant-selector__title {
  font-size: 16px;
  color: var(--color-gray-800);
  font-weight: var(--fw-semibold);
  padding-bottom: 13px;
}
@media (min-width: 48em) {
  .variant-selector__title {
    padding: 8px 0 13px;
  }
}

.variant-selector__subline {
  font-weight: var(--fw-light);
}

.variant-selector__options {
  display: flex;
  gap: 10px;
  flex-flow: row wrap;
  margin: 0 -16px;
  padding: 0 16px;
}
@media (min-width: 48em) {
  .variant-selector__options {
    margin: 0;
    padding: 0;
  }
}

.variant-selector__variant-error {
  color: var(--theme-error);
  padding-top: 5px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.variant-selector__attention-icon {
  margin-right: 5px;
}

.variant-selector__dimension {
  border-top: 1px solid var(--variant-selector-border-color);
  padding: 23px 16px 0;
  margin: 0 -16px;
}
.variant-selector__dimension:last-child {
  border-bottom: 1px solid var(--variant-selector-border-color);
}
@media (min-width: 48em) {
  .variant-selector__dimension {
    border: none;
    margin: 0;
    padding: 0;
  }
  .variant-selector__dimension:last-child {
    border: none;
  }
}

@media (max-width: 47.99em) {
  .variant-selector__dimension--badge .variant-selector__options {
    flex-flow: row nowrap;
    overflow: auto;
    padding: 0 16px 23px;
  }
  .variant-selector__dimension--badge .variant-selector__options::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 5px;
  }
  .variant-selector__dimension--badge .variant-selector__options::-webkit-scrollbar-thumb {
    background-color: var(--variant-selector-border-color);
  }
}

.variant-option {
  border: 1px solid var(--color-gray-400);
  border-radius: var(--border-radius-default);
  cursor: pointer;
  background: var(--color-gray-250);
  user-select: none;
}

.variant-option__title {
  min-width: 95px;
  max-width: 190px;
  font-size: 16px;
  color: var(--color-gray-550);
  padding: 6px 12px;
  background: var(--color-white-true);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--color-gray-500);
  margin: -1px;
}
@media (min-width: 48em) {
  .variant-option__title {
    word-break: break-word;
  }
}

.variant-option__price {
  font-size: 16px;
  padding: 6px 12px;
}

.variant-option--disabled {
  border-style: dashed;
  border-color: var(--color-gray-500);
}
.variant-option--disabled .variant-option__title {
  border: none;
  background: transparent;
}

.variant-option:not(.variant-option--disabled):hover .variant-option__title,
.variant-option--active .variant-option__title {
  color: var(--theme-primary);
  border-color: var(--theme-primary);
}

.variant-option--active .variant-option__title {
  font-weight: var(--fw-bold);
}

.variant-option--promotion .variant-option__price {
  color: var(--color-red);
  font-weight: var(--fw-semibold);
}

.cart-addon-product {
  --color-text: var(--color-cart-addon-product);
  position: relative;
  display: grid;
  grid-template-areas: "productImage productDetail" "orderInfo orderInfo";
  grid-gap: 15px;
  padding: 10px;
  color: var(--color-cart-addon-product);
  background: var(--bg-cart-addon-product);
}
@media (min-width: 48em) {
  .cart-addon-product {
    grid-template-columns: 80px auto auto;
    grid-template-areas: "productImage productDetail orderInfo";
    padding: 20px;
    background: var(--bg-cart-addon-product);
  }
}

.cart-addon-product__image-container {
  grid-area: productImage;
}

.cart-addon-product__detail {
  grid-area: productDetail;
}

.cart-addon-product__order-info {
  grid-area: orderInfo;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "addButton price";
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 48em) {
  .cart-addon-product__order-info {
    grid-template-columns: auto;
    grid-template-areas: "price" "addButton";
    justify-content: end;
    align-items: flex-start;
  }
}

.cart-addon-product__price {
  --color-price-box-price: var(--color-text);
  line-height: 1;
  grid-area: price;
}

.cart-addon-product__add-button {
  grid-area: addButton;
  margin-top: 10px;
}
@media (max-width: 47.99em) {
  .cart-addon-product__add-button {
    margin-top: 0;
  }
}

.cart-addon-product__description {
  grid-area: description;
  --bg-li-marker: var(--color-text);
}
.cart-addon-product__description li {
  margin-bottom: 0;
}

.cart-addon-product__price-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  line-height: 1;
}

.cart-product {
  z-index: var(--zi-cart-product);
  position: relative;
  display: grid;
  grid-template-areas: "productImage productDetail" "orderInfo orderInfo";
  grid-gap: 15px;
  padding: 0 15px;
  margin-bottom: 10px;
  background: var(--bg-cart-product);
}
@media (min-width: 48em) {
  .cart-product {
    grid-template-columns: 25% auto;
    grid-template-areas: "productImage productDetail" "productImage orderInfo";
    padding: 20px;
  }
}

.cart-product__image-container {
  grid-area: productImage;
}

.cart-product__detail {
  grid-area: productDetail;
}

.cart-product__order-info {
  grid-area: orderInfo;
}

.cart-product__detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-product__remove-button {
  grid-area: removeButton;
  cursor: pointer;
  font-size: var(--fs-h3);
  padding-left: 10px;
}

.cart-product__description {
  grid-area: description;
}
.cart-product__description li {
  margin-bottom: 0;
}

.cart-product-description__preview--oversized {
  overflow-y: hidden;
}

.cart-product__price-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  line-height: 1;
}

.cart-product__quantity-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.cart-product__quantity {
  margin-right: 10px;
}

.cart-summary {
  padding: 15px;
  border-top: 2px solid var(--bg-cart-summary);
}
@media (min-width: 48em) {
  .cart-summary {
    border-top: 0;
    background: var(--bg-cart-summary);
  }
}

.cart-summary__content {
  padding: 15px;
}
@media (min-width: 48em) {
  .cart-summary__content {
    padding: 0;
  }
}

.cart-summary__subtotal {
  margin-bottom: 4px;
}

.cart-summary__subtotal-label {
  font-size: var(--fs-l);
  margin-right: 1ch;
}

.cart-summary__subtotal-price {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  color: var(--color-cart-summary-total);
}

.cart-summary__delivery {
  margin-bottom: 10px;
}

.cart-summary__delivery-label {
  font-size: var(--fs-l);
  font-weight: var(--fw-regular);
  color: var(--color-checkout-delivery-cost);
  margin-right: 1ch;
}

.cart-summary__delivery-price {
  font-size: var(--fs-l);
  font-weight: var(--fw-regular);
  color: var(--color-cart-summary-total);
}

.cart-summary__total, .cart-summary__subtotal, .cart-summary__delivery {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}

.cart-summary__savings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-cart-summary-savings);
  font-size: var(--fs-h3);
  font-weight: var(--fw-bold);
  margin-bottom: 10px;
}

.cart-summary__savings-price {
  color: var(--color-cart-summary-savings);
  font-size: var(--fs-h3);
  font-weight: var(--fw-bold);
}

.cart-summary__label {
  font-size: var(--fs-h3);
  font-weight: var(--fw-bold);
  line-height: 1;
  word-break: break-word;
  hyphens: auto;
}

.cart-summary__total-label {
  margin-right: 1ch;
}

.cart-summary__tax-info {
  display: inline-block;
  font-size: var(--fs-s);
  font-weight: var(--fw-regular);
  white-space: nowrap;
  hyphens: auto;
}

.cart-summary__price {
  white-space: nowrap;
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
  color: var(--color-cart-summary-total);
  font-family: var(--font-product-price);
}

.cart-summary__shipping-cost {
  width: calc(100% - 19px);
  margin-bottom: 10px;
}

.cart-summary__checkout-button {
  width: 100%;
}

.cart-summary--sticky {
  padding: 0;
  background: var(--bg-cart-product);
}
.cart-summary--sticky .cart-summary__content {
  padding: 4px 15px;
}
.cart-summary--sticky .cart-summary__total .cart-summary__price, .cart-summary--sticky .cart-summary__subtotal .cart-summary__price, .cart-summary--sticky .cart-summary__delivery .cart-summary__price {
  font-size: var(--fs-l);
}
.cart-summary--sticky .cart-summary__shipping-cost {
  width: 100%;
  padding: 4px 15px;
}

.splp-cart__container {
  display: grid;
  grid-gap: 20px;
  grid-template-areas: "products" "addon" "summary";
  padding: 50px 0;
}
@media (min-width: 48em) {
  .splp-cart__container {
    grid-template-columns: 70% auto;
    grid-template-areas: "products summary" "addon summary";
  }
}

.splp-cart__products {
  grid-area: products;
}

.splp-cart__addon {
  grid-area: addon;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding: 0 15px;
}
@media (min-width: 48em) {
  .splp-cart__addon {
    padding: 15px;
    background: var(--bg-cart-addon);
  }
}

.splp-cart__addon-headline {
  font-size: var(--fs-h2);
}

.splp-cart__summary {
  grid-area: summary;
}

.splp-cart--has-sticky-summary .splp-cart__summary {
  display: none;
}
.splp-cart--has-sticky-summary .splp-cart__sticky-summary {
  display: block;
  position: sticky;
  bottom: 0;
  background: var(--bg-cart-sticky-footer);
}

.splp-navigation {
  padding: 20px;
}
@media (min-width: 48em) {
  .splp-navigation {
    text-align: center;
  }
}

.splp-navigation__wrapper {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow: scroll;
}
.splp-navigation__wrapper::-webkit-scrollbar {
  display: none; /* Webkit */
}

.splp-navigation__logo {
  display: block;
  padding: 10px;
}
.splp-navigation__logo:focus {
  outline: none;
}

.splp-navigation__logo-image {
  max-height: 75px;
  width: auto;
  margin: 0 auto;
}

.splp-navigation__navigation-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: block;
  list-style: none;
  margin: 0 auto;
  padding: 0;
  overflow: scroll;
  width: max-content;
}
.splp-navigation__navigation-bar::-webkit-scrollbar {
  display: none; /* Webkit */
}
@media (min-width: 75em) {
  .splp-navigation__navigation-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: initial;
  }
}

.splp-navigation-item {
  display: inline-block;
  margin-bottom: 0;
  padding: 0;
  width: auto;
}
.splp-navigation-item::before {
  display: none;
}

.splp-navigation-item__link {
  display: block;
  margin: 10px 5px;
  padding: 6px 14px;
  white-space: nowrap;
  border: 1px solid var(--color-text);
  color: var(--color-text);
  background: var(--bg);
  font-weight: var(--fw-navigation-links);
  border-radius: var(--bd-radius-navigation-links);
}

.splp-navigation-item__anchor-link {
  cursor: pointer;
}

.container-inline {
  display: grid;
  align-items: center;
  gap: 50px;
  grid-auto-columns: 1fr;
}
@media (min-width: 48em) {
  .container-inline {
    grid-auto-flow: column;
    padding: 30px 0;
  }
}

.container-inline__item .container-wrapper {
  width: auto;
}

.container-undefined {
  display: grid;
  place-items: center;
  text-align: center;
  padding: 30px;
  font-size: 2em;
  font-weight: 800;
}

:root {
  --ff-menu: "Nunito", sans-serif;
  --ff-headline: "Nunito", sans-serif;
  --ff-base: "Nunito", sans-serif;
  --ff-title: "Nunito", sans-serif;
  --ff-product-price: "Oswald", sans-serif;
  --fw-title: 600;
  --fw-headline: 500;
  --fw-base: 400;
  --fw-menu: 600;
  --fw-product-price: 500;
}

:root {
  --font-base: "Nunito", sans-serif;
  --font-title: "Nunito", sans-serif;
  --font-headlines: "Nunito", sans-serif;
  --font-menu: "Nunito", sans-serif;
  --font-product-price: "Oswald", sans-serif;
  --theme-headlines: var(--font-headlines);
  --theme-subheadlines: var(--font-headlines);
  --zi-video-badge: 100;
  --zi-search-overlay-portal: 99999;
  --color-gray-200: #f0f0f0;
  --color-gray-300: #f5f5f5;
  --color-gray-400: #dfdfdf;
  --color-gray-500: #aaa;
  --color-gray-600: #777;
  --color-gray-800: #222;
  --color-red: #e30613;
  --color-red-opacity-80: #e30613cc; /* 80% opacity */
  --theme-primary: #008bd2;
  --theme-secondary: #dff2fd;
  --theme-tertiary: #f9d100;
  --theme-primary-transparent: #008bd200;
  --theme-primary-opaque: rgba(0, 139, 210, 0.05);
  --theme-success: #26bb31;
  --theme-attention: #008bd2;
  --theme-promotion: #008bd2;
  --theme-dark: #544750;
  --theme-beige: #faf5ee;
  --theme-cta: #faf5ee;
  --theme-cta-font-color: var(--theme-dark);
  --fs-base-px: 16;
  --fs-xs: 0.75rem;
  --fs-s: 0.875rem;
  --fs-sm: 0.9375rem;
  --fs-base: 1rem;
  --fs-17: 1.0625rem;
  --fs-18: 1.125rem;
  --fs-l: 1.25rem;
  --fs-ml: 1.5rem;
  --fs-xl: 1.75rem;
  --fs-2xl: 2rem;
  --fs-3xl: 2.5rem;
  --fs-main-navigation-desktop: 18px;
  --fs-main-navigation-mobile: 25px;
  --fs-sub-navigation-desktop: 20px;
  --fs-badge-small: 13px;
  --fs-badge-medium: 16px;
  --fs-badge-large: 19px;
  --fs-h1: 28px;
  --fs-h1-mobile: 23px;
  --fs-h1-sub: calc(var(--fs-h1) * 0.65);
  --fs-h1-sub-mobile: calc(var(--fs-h1-mobile) * 0.65);
  --fs-h2: 24px;
  --fs-h2-mobile: 21px;
  --fs-h2-sub: calc(var(--fs-h2) * 0.65);
  --fs-h2-sub-mobile: calc(var(--fs-h2-mobile) * 0.65);
  --fs-h3: 21px;
  --fs-h3-mobile: 18px;
  --fs-h3-sub: calc(var(--fs-h3) * 0.65);
  --fs-h3-sub-mobile: calc(var(--fs-h3-mobile) * 0.65);
  --fs-h4: 18px;
  --fs-h4-mobile: 16px;
  --fs-h4-sub: calc(var(--fs-h4) * 0.65);
  --fs-h4-sub-mobile: calc(var(--fs-h4-mobile) * 0.65);
  --fs-h5: 16px;
  --fs-h5-mobile: 15px;
  --fs-h5-sub: calc(var(--fs-h5) * 0.65);
  --fs-h5-sub-mobile: calc(var(--fs-h5-mobile) * 0.65);
  --fs-h6: 14px;
  --fs-h6-mobile: 13px;
  --headline-line-height-desktop: 50px;
  --headline-line-height-mobile: 36px;
  --headline-margin-bottom: 8px;
  --subheadline-line-height-desktop: 26px;
  --subheadline-line-height-mobile: 26px;
  --lh-default: 1;
  --lh-small: var(--lh-default) * 1.15;
  --lh-base: var(--lh-default) * 1.3;
  --lh-large: var(--lh-default) * 2;
  --theme-on-secondary-highlight: var(--theme-cta);
  --theme-on-secondary-headline: var(--theme-attention);
  --theme-on-secondary-subheadline: var(--theme-cta-font-color);
  --theme-on-tertiary-highlight: var(--theme-cta);
  --theme-on-tertiary-headline: var(--theme-attention);
  --theme-on-tertiary-subheadline: var(--theme-cta-font-color);
  --theme-on-default-highlight: var(--theme-cta);
  --theme-on-default-headline: var(--theme-primary);
  --theme-on-default-subheadline: var(--theme-cta-font-color);
  --bg-button: var(--theme-primary);
  --color-button: var(--color-white-true);
  --bg-button-primary: var(--theme-cta);
  --color-button-primary: var(--color-white-true);
  --bg-button-secondary: var(--theme-secondary);
  --color-button-tertiary: var(--color-white-true);
  --bg-button-tertiary: var(--theme-primary);
  --color-button-secondary: var(--color-white-true);
  --bg-button-success: var(--theme-success);
  --color-button-success: var(--color-white-true);
  --bg-button-warning: var(--theme-attention);
  --color-button-warning: var(--color-white-true);
  --bg-button-error: var(--theme-error);
  --color-button-error: var(--color-white-true);
  --border-radius-button: 10px;
  --fw-light: 300;
  --fw-normal: 400;
  --fw-medium: 500;
  --fw-semibold: 600;
  --fw-bold: 700;
  --fw-black: 800;
  --top-header-background: var(--theme-attention);
  --top-header-text-color: var(--color-white-true);
  --top-header-icon-color: var(--color-white-true);
  --top-header-phone-number-color: var(--color-white-true);
  --top-header-cta-color: var(--color-white-true);
  --color-search-bar-input: var(--theme-dark);
  --fs-search-bar-input: 18px;
  --border-color-main-navigation: var(--theme-attention);
  --quicklink-button-color: var(--theme-cta);
  --quicklink-text-color: var(--theme-cta-font-color);
  --bg-cart-mobile: var(--color-gray-250);
  --bg-cart: var(--color-gray-250);
  --border-cart-product-select: var(--theme-dark);
  --cart-product-price: var(--color-gray-800);
  --cart-product-old-price: var(--theme-primary);
  --color-checkout-delivery-cost: var(--color-gray-800);
  --color-cart-summary-total: var(--color-gray-800);
  --color-cart-summary-tax: var(--color-gray-600);
  --bg-cart-summary: var(--color-white-true);
  --color-cart-summary-installment: var(--color-turqoise);
  --bg-shipping-cost-bar: #ebf8fe;
  --color-dots: var(--color-gray-400);
  --color-dots--active: var(--theme-primary);
  --menu-color-primary: var(--theme-dark);
  --menu-color-primary-hover: var(--theme-primary);
  --loader-background: rgba(255, 255, 255, 0.7);
  --color-teaser-right-active-box: var(--theme-beige);
  --color-teaser-right-box-border: #ece5d9;
  --color-teaser-cta-box: var(--theme-tertiary);
  --box-shadow-default: 0 2px 5px rgba(0, 0, 0, 0.2);
  --color-badge-topseller-background: var(--color-gray-600);
  --color-badge-topseller-text: var(--color-white-true);
  --color-badge-bware-background: #ffee09;
  --color-badge-bware-text: var(--theme-dark);
  --color-badge-sale-background: var(--color-red);
  --color-badge-sale-text: var(--color-white-true);
  --color-badge-aktion-background: var(--color-red);
  --color-badge-aktion-text: var(--color-white-true);
  --color-badge-sparset-background: var(--color-red);
  --color-badge-sparset-text: var(--color-white-true);
  --color-badge-property-background: #90dacd;
  --color-badge-property-text: var(--theme-dark);
  --color-badge-savings-background: var(--color-white-true);
  --color-badge-savings-text: var(--color-red);
  --color-badge-savings-pdp-background: var(--color-red);
  --color-badge-savings-pdp-text: var(--color-white-true);
  --color-badge-savings-special-deal-background: var(--color-red);
  --color-badge-savings-special-deal-text: var(--color-white-true);
  --color-badge-gratis-background: #ffffffe6;
  --color-badge-gratis-text: var(--color-red);
  --color-badge-xplusy-background: var(--color-red);
  --color-badge-xplusy-text: var(--color-white-true);
  --color-badge-raten-background: #009bac;
  --color-badge-raten-text: var(--color-white-true);
  --hero-teaser-slider-headline-color: var(--theme-primary);
  --hero-teaser-slider-subheadline-color: var(--theme-cta-font-color);
  --hero-teaser-slider-cta-color: var(--theme-cta-font-color);
  --color-product-title: var(--theme-attention);
  --background-product-buy-button: var(--theme-primary);
  --color-product-buy-button: var(--theme-cta-font-color);
  --fs-product-title-mobile: 17px;
  --lh-product-title-mobile: 20px;
  --fs-product-title-desktop: 17px;
  --lh-product-title-desktop: 20px;
  --fs-product-description-mobile: var(--fs-s);
  --fs-product-description-desktop: 15px;
  --fs-product-title-special-deal-desktop: 19px;
  --pdp-color-product-title: var(--theme-attention);
  --pdp-background-buy-button: var(--theme-primary);
  --pdp-color-buy-button: var(--color-white-true);
  --pdp-color-caption: var(--theme-attention);
  --pdp-background-caption: var(--theme-secondary);
  --pdp-selected-image-border-color: var(--theme-primary);
  --pdp-slider-arrow-color: var(--theme-primary);
  --pdp-slider-arrow-background: var(--color-white-true);
  --pdp-video-thumbnail-background: var(--theme-secondary);
  --pdp-video-thumbnail-icon: var(--theme-primary);
  --pdp-abo-info-color: var(--theme-primary);
  --fs-accordionpdp-title: 20px;
  --plp-headline-color: var(--theme-cta);
  --hero-teaser-box-headline-font-size-desktop: 34px;
  --hero-teaser-box-headline-line-height-desktop: 40px;
  --hero-teaser-box-headline-font-size-mobile: 28px;
  --hero-teaser-box-headline-line-height-mobile: 36px;
  --hero-teaser-button-text-color: var(--color-white);
  --hero-teaser-button-color: var(--theme-primary);
  --color-slider-active-dot-default: var(--theme-primary);
  --newsletter-background-button: var(--theme-cta);
  --newsletter-color-button: var(--theme-cta-font-color);
  --footer-background-color: var(--theme-beige);
  --footer-font-color: var(--theme-cta);
  --empty-cart-link: var(--color-white);
  --safety-list-line-height: 20px;
  --safety-list-fs: var(--fs-s);
  --free-delivery-highlighted: var(--theme-primary);
  --main-navigation-grid-column-width-mobile: 60px auto auto 125px;
  --main-navigation-grid-column-width-tablet: 60px auto auto 142px;
  --main-navigation-grid-column-width-desktop: 112px 33% auto 142px;
  --main-navigation-grid-column-width-wide: var(--main-navigation-grid-column-width-desktop);
  --main-logo-wrapper-max-width-desktop: 112px;
  --main-logo-min-height-desktop: 78px;
  --main-logo-wrapper-min-height-mobile: 50px;
  --main-logo-height-mobile: auto;
  --main-logo-max-height-mobile: auto;
  --ambiente-tooltip-title-color: var(--theme-primary);
  --ambiente-tooltip-title-font-size: 18px;
  --ambiente-tooltip-price-color: var(--theme-dark);
  --ambiente-tooltip-link-color: var(--theme-primary);
  --ambiente-marker-inactive-background-color: #999;
  --mp-checkout-steps-background-color: var(--color-gray-200);
  --mp-checkout-steps-text-color: var(--color-white-true);
  --border-radius-default: 10px;
  --border-radius-default-inner: 8px;
  --border-radius-wide: 20px;
  --border-radius-product-image-container: 10px 10px 0 0;
  --border-radius-badge: 0 10px 10px 0;
  --border-radius-precheckout: 10px 10px 10px 0;
  --border-radius-bottom-right-badge: 10px 0 0 10px;
  --border-radius-product-card: 10px;
  --border-radius-max: 100%;
  --content-navigation-bg: #fff;
  --content-navigation-line: #f5f5f5;
  --content-navigation-font-color: #666;
  --content-navigation-cta: var(--theme-primary);
  --color-headline: var(--color-gray-800);
  --color-subheadline: var(--color-gray-800);
  --bg-component-description-box: var(--theme-primary);
  --main-navigation-custom-height-desktop: 128px;
  --main-navigation-custom-separator-color: var(--theme-primary);
  --main-navigation-custom-background-color: var(--color-white-true);
  --main-navigation-custom-font-color: var(--menu-color-primary);
  --main-navigation-custom-active-color: var(--theme-primary);
  --main-navigation-custom-dropdown-background-color: var(--theme-beige);
  --main-navigation-custom-search-background-color: var(--color-white-true);
  --main-navigation-custom-search-border-color: var(--color-gray-80);
  --main-navigation-custom-search-font-color: var(--color-gray-550);
  --main-navigation-custom-quick-links-separator-color: var(--color-gray-80);
  --color-icons: var(--theme-primary);
  --header-logo-custom-min-width-desktop-logo: 190px;
  --header-logo-custom-min-width-tablet-logo: 150px;
  --header-logo-custom-min-width-mobile-logo: 150px;
  --desktop-navigation-font-family: var(--theme-headlines);
  --desktop-navigation-font-size: var(--fs-18);
  --desktop-navigation-font-weight: var(--fw-semibold);
  --desktop-navigation-item-font-weight: var(--fw-medium);
  --desktop-navigation-headline-font-weight: var(--fw-bold);
  --burger-menu-font-size: var(--fs-l);
  --burger-menu-icon-color: var(--theme-primary);
  --burger-menu-quick-links-item-font-color: var(--main-navigation-custom-font-color);
  --live-tv-button-font-color: var(--color-white-true);
  --special-button-font-color: var(--color-white-true);
  --special-button-border-color: var(--color-gray-800);
  --special-button-border-color-hover: var(--color-gray-800);
}

@media (max-width: 47.99em) {
  :root {
    --fs-base: 15px;
  }
}
html, p {
  font-size: var(--fs-base);
}

.text-bold {
  font-weight: var(--fw-bold);
}

h1,
.h1,
.title-h1 {
  font-size: var(--fs-h1-mobile);
  font-weight: var(--fw-semibold);
  line-height: var(--lh-small);
}
@media (min-width: 48em) {
  h1,
  .h1,
  .title-h1 {
    font-size: var(--fs-h1);
  }
}

.title-h1-sub {
  font-size: var(--fs-h1-sub-mobile);
  font-weight: var(--fw-semibold);
}
@media (min-width: 48em) {
  .title-h1-sub {
    font-size: var(--fs-h1-sub);
  }
}

h2,
.h2,
.title-h2 {
  font-size: var(--fs-h2-mobile);
  font-weight: var(--fw-semibold);
}
@media (min-width: 48em) {
  h2,
  .h2,
  .title-h2 {
    font-size: var(--fs-h2);
  }
}

.title-h2-sub {
  font-size: var(--fs-h2-sub-mobile);
  font-weight: var(--fw-semibold);
}
@media (min-width: 48em) {
  .title-h2-sub {
    font-size: var(--fs-h2-sub);
  }
}

h3,
.h3,
.title-h3 {
  font-size: var(--fs-h3-mobile);
  font-weight: var(--fw-semibold);
}
@media (min-width: 48em) {
  h3,
  .h3,
  .title-h3 {
    font-size: var(--fs-h3);
  }
}

.title-h3-sub {
  font-size: var(--fs-h3-sub-mobile);
  font-weight: var(--fw-medium);
}
@media (min-width: 48em) {
  .title-h3-sub {
    font-size: var(--fs-h3-sub);
  }
}

h4,
.h4,
.title-h4 {
  font-size: var(--fs-h4-mobile);
  font-weight: var(--fw-medium);
}
@media (min-width: 48em) {
  h4,
  .h4,
  .title-h4 {
    font-size: var(--fs-h4);
  }
}

.title-h4-sub {
  font-size: var(--fs-h4-sub-mobile);
  font-weight: var(--fw-medium);
}
@media (min-width: 48em) {
  .title-h4-sub {
    font-size: var(--fs-h4-sub);
  }
}

h5,
.h5,
.title-h5 {
  font-size: var(--fs-h5-mobile);
  font-weight: var(--fw-normal);
}
@media (min-width: 48em) {
  h5,
  .h5,
  .title-h5 {
    font-size: var(--fs-h5);
  }
}

.title-h5-sub {
  font-size: var(--fs-h5-sub-mobile);
  font-weight: var(--fw-medium);
}
@media (min-width: 48em) {
  .title-h5-sub {
    font-size: var(--fs-h5-sub);
  }
}

h6,
.h6,
.title-h6 {
  font-size: var(--fs-h6-mobile);
  font-weight: var(--fw-light);
}
@media (min-width: 48em) {
  h6,
  .h6,
  .title-h6 {
    font-size: var(--fs-h6);
  }
}

.chevron-button {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 17px;
}
.chevron-button:focus {
  outline: none;
}

.background--primary {
  --color-subheadline: var(--theme-on-primary-subheadline);
}

.background--secondary {
  --color-subheadline: var(--theme-on-secondary-subheadline);
}

.background--tertiary {
  --color-subheadline: var(--theme-on-tertiary-subheadline);
}

.background--default {
  --color-subheadline: var(--theme-on-default-subheadline);
}

.background--white-true {
  --color-subheadline: var(--theme-on-default-subheadline);
}

body.hide-scrollbar {
  overflow-y: hidden;
}

.background--beige {
  background: var(--theme-beige);
}

.catalog-badge {
  border-radius: var(--border-radius-badge);
  font-size: var(--fs-badge-medium);
  font-weight: 600;
  padding: 0 10px;
  text-align: center;
}

.catalog-badge--size-small {
  font-size: var(--fs-badge-small);
}

.catalog-badge--size-medium {
  font-size: var(--fs-badge-medium);
}

.catalog-badge--size-large {
  font-size: var(--fs-badge-medium);
  line-height: 28px;
}

.catalog-badge--size-xlarge {
  font-size: var(--fs-badge-large);
  line-height: 32px;
}

:not(.catalog-badge--size-large).catalog-badge--corner-bottom-left {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  font-size: var(--fs-badge-small);
  line-height: 21px;
}

.catalog-badge--corner-bottom-right {
  border-radius: var(--border-radius-bottom-right-badge);
  text-align: right;
}

.catalog-badge--corner-top-left {
  border-top-left-radius: 0;
}

.catalog-badge--corner-top-right {
  border-top-right-radius: 0;
}

.catalog-badge--topseller {
  background-color: var(--color-badge-topseller-background);
  color: var(--color-badge-topseller-text);
}

.catalog-badge--bware {
  background-color: var(--color-badge-bware-background);
  color: var(--color-badge-bware-text);
}

.catalog-badge--sale {
  background-color: var(--color-badge-sale-background);
  color: var(--color-badge-sale-text);
}

.catalog-badge--aktion {
  background-color: var(--color-badge-aktion-background);
  color: var(--color-badge-aktion-text);
}

.catalog-badge--sparset {
  background-color: var(--color-badge-sparset-background);
  color: var(--color-badge-sparset-text);
}

.catalog-badge--property {
  background-color: var(--color-badge-property-background);
  color: var(--color-badge-property-text);
}

.catalog-badge--savings {
  background-color: var(--color-badge-savings-background);
  color: var(--color-badge-savings-text);
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-badge-savings-text);
}

.catalog-badge--savings-pdp {
  background-color: var(--color-badge-savings-pdp-background);
  color: var(--color-badge-savings-pdp-text);
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-badge-savings-pdp-background);
}

.catalog-badge--savings-special-deal {
  background-color: var(--color-badge-savings-special-deal-background);
  color: var(--color-badge-savings-pdp-text);
  border-style: solid;
  border-width: 1px;
  border-color: var(--color-badge-savings-special-deal-background);
}

.catalog-badge--gratis {
  background-color: var(--color-badge-gratis-background);
  color: var(--color-badge-gratis-text);
  border: 1px solid var(--color-badge-gratis-text);
  border-right: none;
}

.catalog-badge--xplusy {
  background-color: var(--color-badge-xplusy-background);
  color: var(--color-badge-xplusy-text);
}

.catalog-badge--raten {
  background-color: var(--color-badge-raten-background);
  color: var(--color-badge-raten-text);
  display: block;
}

.catalog-badge--ratensavings {
  background-color: var(--color-badge-raten-background);
  color: var(--color-badge-raten-text);
}

.progress-bar__progress-bar {
  width: 100%;
  position: relative;
  display: block;
  margin: 0 auto;
  height: 4px;
  background: var(--color-gray-400);
  border-radius: 2px;
  margin-bottom: 15px;
}
@media (min-width: 48em) {
  .progress-bar__progress-bar {
    width: 50%;
  }
}
.progress-bar__progress-bar .progress-bar__progress {
  display: block;
  background: var(--theme-primary);
  height: 4px;
}

.progress-bar__articles-container {
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
  padding: 16px 0;
  font-size: 16px;
  font-weight: 600;
  color: var(--theme-primary);
}

@media (min-width: 81.25em) and (min-width: 81.25em) {
  .responsive-full-page-width-wrapper--desktop {
    left: calc((var(--max-width) - var(--breakpoint-wide)) / -2);
    grid-template-columns: auto var(--breakpoint-wide) auto;
  }
}
@media (min-width: 81.25em) {
  .responsive-full-page-width-wrapper--desktop.responsive-full-page-width-wrapper--overflow-both {
    grid-template-areas: "wrapperContainer";
    grid-template-columns: var(--max-width);
  }
}
@media (min-width: 81.25em) and (min-width: 81.25em) {
  .responsive-full-page-width-wrapper--wide {
    left: calc((var(--max-width) - var(--breakpoint-wide)) / -2);
    grid-template-columns: auto var(--breakpoint-wide) auto;
  }
}
@media (min-width: 81.25em) {
  .responsive-full-page-width-wrapper--wide.responsive-full-page-width-wrapper--overflow-both {
    grid-template-areas: "wrapperContainer";
    grid-template-columns: var(--max-width);
  }
}

.tabs__list {
  display: flex;
  list-style: none;
}

.tabs__tab {
  width: 50%;
}

.tab-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: var(--color-white);
  padding: 15px 5px;
  border: 1px solid var(--color-gray-400);
  cursor: pointer;
  font-size: 15px;
  border-radius: var(--border-radius-product-image-container);
  height: 100%;
}
@media (min-width: 75em) {
  .tab-title {
    padding: 15px 75px;
  }
}

.tab-title:hover {
  background-color: var(--color-white-true);
}

.tab-title--active {
  color: var(--theme-primary);
  font-weight: var(--fw-semibold);
  background-color: var(--color-white-true);
  border: none;
  border-top: 4px solid var(--theme-primary);
  transition: 0.1s;
}

.tab-title__border-left {
  border-left: 1px solid var(--color-gray-400);
}

.tab-title__border-right {
  border-right: 1px solid var(--color-gray-400);
}

.abo-selector {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.accordion-pdp-item__column {
  width: 100%;
  border-bottom: 1px solid var(--color-gray-80);
  padding: 16px;
}

.accordion-pdp-item__heading {
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--theme-attention);
}
.accordion-pdp-item__heading .accordion-pdp-item__chevron {
  float: right;
  margin-top: 5px;
  color: var(--theme-attention);
}
.accordion-pdp-item__heading--ratings {
  display: flex;
  align-items: center;
  gap: 12px;
}

.delivery-item {
  border-collapse: collapse;
}

.delivery-item__row:nth-child(odd) {
  background-color: var(--color-gray-300);
}

.delivery-item__data-label {
  text-align: left;
  padding: 8px;
  width: 50%;
  overflow: hidden;
}

.delivery-item__data-value {
  text-align: left;
  padding: 8px;
  width: 50%;
  overflow: hidden;
}

@media (min-width: 48em) {
  .accordion-pdp-item__item {
    padding: 14px 83px;
  }
}

.accordion-pdp-item__ratings-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.accordion-pdp-item__rating {
  scroll-padding-top: 20px;
}
.accordion-pdp-item__rating .star-rating__star {
  font-size: 1em;
}

.accordion-pdp-item__column {
  width: 100%;
  border-bottom: 1px solid var(--color-gray-80);
  padding: 16px;
}

.accordion-pdp-item__heading {
  font-size: var(--fs-accordionpdp-title);
  cursor: pointer;
}

.attributes-item {
  border-collapse: collapse;
}
@media (min-width: 48em) {
  .attributes-item {
    display: flex;
    flex-direction: row;
    padding: 16px 72px;
  }
}

.attributes-item__row:nth-child(odd) {
  background-color: var(--color-gray-300);
}

.attributes-item__data-label {
  text-align: left;
  padding: 8px;
  width: 50%;
  overflow: hidden;
  word-wrap: break-word;
}

.attributes-item__data-value {
  text-align: left;
  padding: 8px;
  width: 50%;
  overflow: hidden;
  word-wrap: break-word;
}

.accordion-pdp-item__item {
  padding: 20px;
}

.attributes-item table {
  width: 100%;
  table-layout: fixed;
}
@media (min-width: 48em) {
  .attributes-item table {
    width: 50%;
    table-layout: auto;
  }
}

.attributes-item__textile-care-symbols {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: var(--color-gray-900);
}
.attributes-item__textile-care-symbols div {
  display: flex;
  gap: 8px;
  align-items: center;
}
.attributes-item__textile-care-symbols div img {
  height: 48px;
}

@media (min-width: 48em) {
  .delivery-item {
    padding: 16px 72px;
  }
}
.delivery-item table {
  width: 100%;
}

.download-asset {
  padding: 10px;
}

.download-asset__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 175px;
  color: var(--theme-primary);
}
.download-asset__link:visited {
  color: var(--theme-primary);
}

.download-asset__image-loading {
  background-color: #ededed;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 40%, rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 60%) #ededed;
  background-size: 200% 100%;
  background-position-x: 180%;
  animation: 1s loading ease-in-out infinite;
}

@keyframes loading {
  to {
    background-position-x: -20%;
  }
}
.download-asset__image {
  margin-bottom: 10px;
  aspect-ratio: 2/3;
  object-fit: cover;
  width: 175px;
  border-radius: var(--border-radius-default);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.download-asset__name {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.download-item {
  display: flex;
  flex-wrap: wrap;
}
@media (min-width: 48em) {
  .download-item {
    padding: 15px 75px;
  }
}
@media (max-width: 47.99em) {
  .download-item {
    justify-content: space-around;
  }
}

.download-item__no-data {
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.accordion-pdp__wrapper {
  padding: 10px 20px 25px;
}
@media (min-width: 48em) {
  .accordion-pdp__wrapper {
    padding: 35px 20px;
  }
}

@media (min-width: 48em) {
  .product-details-item__text-container {
    padding: 16px 72px;
  }
}
.product-details-item__text-container > * {
  margin: 0 0 16px;
}
.product-details-item__text-container ul {
  margin-left: 20px;
}

.product-details-item__text-container a {
  color: var(--color-black);
  text-decoration: none;
  border-bottom: 1px solid;
}

.product-hazard-warning {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px;
  margin-top: 15px;
}
@media (min-width: 48em) {
  .product-hazard-warning {
    margin-top: 30px;
  }
}

.product-hazard-warning__list-item {
  word-break: break-word;
  margin-bottom: 0;
}
.product-hazard-warning__list-item::before {
  border-radius: 100%;
  width: 4px;
  height: 4px;
  top: 5px;
}

.product-hazard-warning__image-container {
  display: flex;
  gap: 10px;
  width: 80px;
  margin: 5px 0;
}

.product-hazard-warning__list {
  line-height: var(--safety-list-line-height);
  font-size: var(--safety-list-fs);
}

@media (min-width: 48em) {
  .product-safety {
    padding: 16px 72px;
  }
}

@media (min-width: 75em) {
  .product-safety__table-container {
    display: flex;
    flex-direction: row;
  }
}

.product-safety__table-container--margin-bottom {
  margin-bottom: 15px;
}
@media (min-width: 48em) {
  .product-safety__table-container--margin-bottom {
    margin-bottom: 40px;
  }
}

.product-safety__table {
  width: 100%;
  table-layout: fixed;
}
@media (min-width: 75em) {
  .product-safety__table {
    width: 50%;
    table-layout: auto;
  }
}

.product-safety__row {
  background-color: var(--color-gray-300);
}

.product-safety__data-label,
.product-safety__data-value {
  text-align: left;
  padding: 8px;
  width: 50%;
  overflow: hidden;
  word-wrap: break-word;
}

.product-safety-pictograms {
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  margin-top: 15px;
  gap: 16px;
  padding: 8px;
}
@media (min-width: 48em) {
  .product-safety-pictograms {
    margin-top: 30px;
    grid-template-columns: max-content 1fr max-content 1fr;
  }
}

.product-safety-pictograms__icon {
  height: 30px;
}

.accordion-pdp-item__item {
  padding: 20px 0;
}

.accordion-pdp-item__column {
  padding: 16px 0;
}

@media (min-width: 48em) {
  .ratings-item {
    padding: 16px 72px;
  }
}

.ratings-item__holder {
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--theme-attention);
}
@media (min-width: 48em) {
  .ratings-item__holder {
    display: flex;
    flex-direction: row;
  }
}

.ratings-item__total-no {
  color: var(--theme-attention);
  font-size: 42px;
  font-weight: 600;
  margin-bottom: 0;
}

.ratings-item__first-holder {
  padding: 0;
  width: 100%;
}
@media (min-width: 48em) {
  .ratings-item__first-holder {
    width: 15%;
    flex-shrink: 0;
    padding-left: 0;
  }
}

.ratings-item__second-holder {
  margin-top: 12px;
  width: 84%;
}

.ratings-item__total-reviews {
  color: var(--theme-attention);
  font-weight: 500;
  font-size: 15px;
}

.ratings-item__top-review {
  font-size: 18px;
  font-weight: 500;
  color: var(--theme-attention);
}

.ratings-item__show-more-holder {
  text-align: center;
  margin-top: 21px;
}

.ratings-item__show-more {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid var(--theme-attention);
  padding-bottom: 8px;
  font-size: 16px;
  color: var(--theme-attention);
  margin-bottom: 30px;
}

.ratings-item__show-more:hover {
  cursor: pointer;
}

.ratings-item__pagination {
  margin-top: 25px;
}

.ratings-item__loading {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 30%;
  z-index: 9999;
}

.ratings-item__loader {
  border: 16px solid var(--theme-tertiary);
  border-top: 16px solid var(--theme-primary);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.review__details {
  font-size: 14px;
  display: flex;
  gap: 12px;
}
.review__details .review-stars {
  position: relative;
  top: 1px;
}

.review__text {
  margin: 0.5rem 0 1rem;
}

.review-stars__stars {
  color: var(--color-star-full);
}

.review-stars__stars-normal {
  color: #bfbfbf;
}

.video-item {
  padding: 20px;
}
.video-item .youtube-video {
  margin-bottom: 30px;
}

@media (max-width: 47.99em) {
  .account-header {
    padding: 20px;
  }
}

.account-header__customer-name {
  font-family: var(--ff-menu);
  line-height: var(--lh-small);
  font-size: 26px;
  font-weight: 500;
}

.account-header__skeleton {
  height: 1lh;
  width: 50%;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: var(--color-gray-400);
  }
  100% {
    background-color: var(--color-gray-300);
  }
}
.account-navigation {
  padding: 0 20px;
}
@media (min-width: 48em) {
  .account-navigation {
    padding: 0;
  }
}
.account-navigation .headlines {
  text-align: left;
  padding-left: 0;
}
@media (min-width: 48em) {
  .account-navigation .headlines {
    border-bottom: 1px solid var(--theme-primary);
  }
}

.account-navigation__logout-text {
  text-align: center;
}

.account-navigation__logout {
  margin: 0 auto;
  display: block;
  width: 100%;
  border-radius: var(--border-radius-default);
}

.account-navigation__logout-wrapper {
  text-align: center;
}

.account-navigation__attention-icon {
  font-size: 40px;
  color: var(--theme-tertiary);
}

.account-navigation__attention-wrapper {
  padding: 20px 25px;
  border-radius: var(--border-radius-default);
  display: inline-block;
  background: var(--theme-primary);
}

.account-navigation__logout-header {
  margin-top: 15px;
}

.account-navigation__items {
  list-style: none;
  margin: 0;
}

.account-navigation__menu-item {
  padding-left: 0;
  border-top: 1px solid var(--theme-primary);
}
@media (min-width: 48em) {
  .account-navigation__menu-item {
    border-top: 0 solid var(--theme-primary);
    margin: 0;
  }
}
.account-navigation__menu-item:last-of-type {
  border-bottom: 1px solid var(--theme-primary);
}
@media (min-width: 48em) {
  .account-navigation__menu-item:last-of-type {
    border-bottom: 0 solid var(--theme-primary);
  }
}
.account-navigation__menu-item::before {
  list-style-type: none;
  background: transparent;
  position: absolute;
  right: 0;
  left: auto;
  top: 20px;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.1em solid var(--theme-primary);
  border-top: 0.1em solid var(--theme-primary);
  margin-right: 0.5em;
  font-size: 1.8em;
  color: var(--theme-primary);
  transform: rotate(45deg);
}
@media (min-width: 48em) {
  .account-navigation__menu-item::before {
    margin-right: 0;
    width: unset;
    height: unset;
    border: none;
    position: relative;
  }
}

.account-navigation__menu-item-link {
  color: var(--menu-color-primary);
  font-family: var(--ff-menu);
  font-weight: 700;
  font-size: 20px;
  display: block;
  width: 100%;
  padding-top: 14px;
  margin-bottom: 14px;
}
@media (min-width: 48em) {
  .account-navigation__menu-item-link {
    font-weight: 600;
    padding-top: 0;
    margin-bottom: 0;
  }
}
.account-navigation__menu-item-link:focus {
  outline: none;
}
.account-navigation__menu-item-link.account-navigation__menu-item-link-active {
  color: var(--menu-color-primary-hover);
}
.account-navigation__menu-item-link:hover {
  color: var(--menu-color-primary-hover);
}

.account-settings__customer-number-text {
  margin-left: 10px;
}

.account-settings {
  padding: 0 20px;
}
@media (min-width: 48em) {
  .account-settings {
    padding: 0;
  }
}
.account-settings .headlines {
  padding-left: 0;
  padding-right: 0;
}
.account-settings .headlines__headline {
  text-align: left;
  padding: 20px 0;
  border-bottom: 1px solid var(--theme-primary);
  font-family: var(--ff-menu);
  font-weight: var(--fw-semibold);
}

.account-settings__headlines {
  font-size: 26px;
  padding: 0;
}

.account-settings__newsletter-headlines,
.account-settings__delete-account-headlines {
  padding-bottom: 0;
}

.account-settings__button-holder {
  margin-top: 25px;
}
.account-settings__button-holder button {
  padding: 10px 15px;
}

.account-settings__newsletter-wrapper {
  padding-bottom: 20px;
}

.additional-address-fields {
  display: flex;
}
.additional-address-fields span:not(:last-child)::after {
  content: "/";
  margin-left: 3px;
  margin-right: 3px;
}

.ambiente-shopping {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 24px;
  padding: 20px;
}
@media (max-width: 74.99em) {
  .ambiente-shopping {
    flex-direction: column;
  }
}
.ambiente-shopping > div {
  border-radius: var(--border-radius-default);
}
.ambiente-shopping--with-box-shadow > div {
  box-shadow: var(--box-shadow-default);
}
.ambiente-shopping--with-square-corners > div {
  border-radius: 0;
}
.ambiente-shopping--with-square-corners .ambiente-shopping__image-wrapper img {
  border-radius: 0;
}

.ambiente-shopping__image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  flex: 2 1 0;
}

.ambiente-shopping__marker {
  background-color: var(--theme-primary);
  border-radius: 100%;
  position: absolute;
  cursor: pointer;
  text-align: center;
  color: #fff;
  user-select: none;
  z-index: 1;
  box-shadow: var(--box-shadow-default);
}
.ambiente-shopping__marker--open {
  transform: rotate(45deg);
  z-index: 4;
}

.ambiente-shopping__marker--gray {
  background-color: var(--ambiente-marker-inactive-background-color);
}

.ambiente-shopping__image-wrapper img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: var(--border-radius-default);
}

.ambiente-shopping__image-wrapper .tooltip {
  display: flex;
  background-color: var(--color-white-true);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 162px;
  gap: 10px;
  position: absolute;
  border-radius: var(--border-radius-default);
  text-align: center;
  padding: 12px 8px;
  z-index: var(--zi-btn);
}

.ambiente-shopping__tooltip-title {
  color: var(--ambiente-tooltip-title-color);
  font-size: var(--ambiente-tooltip-title-font-size);
  font-weight: var(--fw-bold);
  line-height: var(--lh-small);
}

.ambiente-shopping__tooltip-link {
  text-decoration: underline;
  text-underline-offset: 5px;
  color: var(--ambiente-tooltip-link-color);
  cursor: pointer;
}

.ambiente-shopping__tooltip-price .price-box__price span {
  color: var(--ambiente-tooltip-price-color);
}

.ambiente-shopping__image-wrapper .tooltip-hide {
  display: none;
}

.ambiente-shopping__card-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  gap: 10px;
  flex: 1 1 0;
  background: #fff;
}
.ambiente-shopping__card-wrapper .headlines {
  padding: 0;
}
.ambiente-shopping__card-wrapper .headlines .headlines__headline {
  margin-bottom: 8px;
}
.ambiente-shopping__card-wrapper p {
  margin-top: 0;
}

.ambiente-shopping__card-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.arrow-button {
  justify-content: center;
  align-items: center;
  color: var(--theme-primary);
  background: var(--color-white-true);
  width: 45px;
  clip-path: unset;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 1px 3px -1px var(--color-black-true);
  user-select: none;
}

.available-content-space {
  width: 100%;
}
@media (min-width: 75em) {
  .available-content-space {
    margin: 0 auto;
  }
  .available-content-space--width-90 {
    width: 90%;
  }
  .available-content-space--width-80 {
    width: 80%;
  }
  .available-content-space--width-70 {
    width: 70%;
  }
  .available-content-space--width-60 {
    width: 60%;
  }
  .available-content-space--width-50 {
    width: 50%;
  }
  .available-content-space--width-40 {
    width: 40%;
  }
}

.breadcrumb {
  display: flex;
  padding: 12px 12px 0;
  margin-bottom: 12px;
  list-style: none;
}
@media (min-width: 75em) {
  .breadcrumb {
    padding: 12px 0 0;
  }
}

.breadcrumb-item {
  display: flex;
  align-items: center;
  font-size: var(--fs-s);
  color: var(--color-black);
  /* stylelint-disable-next-line selector-class-pattern */
}
.breadcrumb-item .icon--Home {
  position: relative;
  top: 2px;
}
.breadcrumb-item__link {
  color: var(--color-black);
}
.breadcrumb-item__icon {
  font-size: 8px;
  margin: 0 8px;
}
.breadcrumb-item span {
  color: var(--theme-primary);
}

.breadcrumb-item:has(.breadcrumb-item__current-product) {
  display: none;
}
@media (min-width: 48em) {
  .breadcrumb-item:has(.breadcrumb-item__current-product) {
    display: flex;
  }
}

.checkout-success-section .dynamic-fieldset__fields {
  margin-bottom: 0;
}

.checkout-success-section__headline {
  border-bottom: 1px solid var(--theme-primary);
  padding-bottom: 4px;
  margin-bottom: 12px;
  font-weight: var(--fw-semibold);
}

.checkout-success-section__feedback {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.checkout-success-section__icon {
  font-size: 24px;
  padding-right: 20px;
  color: var(--theme-primary);
}

.checkout-success-message {
  font-size: 16px;
  color: var(--color-black-true);
  text-align: left;
  margin-bottom: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 47.99em) {
  .checkout-success-message {
    margin: 30px 0 0;
  }
}
.checkout-success-message p {
  margin: 0 0 12px;
}
.checkout-success-message p:has(+ ul) {
  margin-bottom: 0;
}
.checkout-success-message ul {
  margin-bottom: 12px;
}
.checkout-success-message ul li {
  margin-bottom: 0;
  padding-left: 0;
}
.checkout-success-message .input__helper-text {
  margin-top: 12px;
}

.checkout-success-message__header {
  text-align: center;
  padding: 0 0 20px;
}

.checkout-success-message__icon {
  font-size: 86px;
  color: var(--theme-primary);
}
.checkout-success-message--error .checkout-success-message__icon {
  color: var(--theme-error);
}

.checkout-success-message__headline {
  margin-bottom: 10px;
}
.checkout-success-message__headline .headlines {
  padding: 0;
}

.checkout-success-message__text {
  margin: 0 0 20px;
}
.checkout-success-message__text:last-child {
  margin-bottom: 0;
}

.checkout-success-message__link {
  color: inherit;
  text-decoration: underline;
  font-weight: var(--fw-bold);
}

.checkout-success-message__form {
  text-align: left;
}

.checkout-success-message__error {
  color: var(--theme-error);
  margin-bottom: 12px;
}

.checkout-success-message__subscriptions {
  width: fit-content;
}
.checkout-success-message__subscriptions--center {
  margin: 0 auto;
}
.checkout-success-message__subscriptions--left {
  margin-left: auto;
}
.checkout-success-message__subscriptions--right {
  margin-right: auto;
}

.checkout-success-message__submit {
  margin: 20px 0;
}

.checkout-success-message__register-advantages {
  list-style-type: none;
  margin: 0;
}

.checkout-success-message__checkmark {
  margin-right: 12px;
  font-size: 12px;
}

.content-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content-navigation__item-wrap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  display: flex;
  flex-wrap: nowrap;
  font-family: var(--font-base);
  font-size: 15px;
  font-weight: var(--fw-normal);
  position: relative;
  scroll-behavior: smooth;
  overflow-x: scroll;
  touch-action: pan-x;
}
.content-navigation__item-wrap::-webkit-scrollbar {
  display: none; /* Webkit */
}

.content-navigation__background-color-wrapper {
  height: 42px;
  border-bottom: 2px solid var(--content-navigation-line);
  position: relative;
  z-index: 3;
  width: 100%;
  top: 0;
}

.content-navigation__item {
  padding: 0 12px;
  color: var(--content-navigation-font-color);
  cursor: pointer;
}
@media (hover: hover) {
  .content-navigation__item:hover {
    background: var(--content-navigation-line);
  }
}

.content-navigation__item-link {
  display: flex;
  align-items: center;
  gap: 4px;
  height: 42px;
  padding: 10px 0 8px;
  white-space: nowrap;
  border-bottom: 2px solid transparent;
}
.content-navigation__item-link--active {
  color: var(--content-navigation-cta);
  border-bottom-color: var(--content-navigation-cta);
}
.content-navigation__item-link svg {
  font-size: 12px;
}

.content-navigation__arrow {
  position: absolute;
  z-index: 100;
  top: 0;
  height: 42px;
  width: 39px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--content-navigation-bg);
  cursor: pointer;
  user-select: none;
  color: var(--content-navigation-cta);
  border-bottom: 2px solid var(--content-navigation-line);
}
.content-navigation__arrow:hover {
  background: var(--content-navigation-line);
}
.content-navigation__arrow--hidden {
  display: none;
}
.content-navigation__arrow-left {
  left: 0;
}
.content-navigation__arrow-right {
  right: 0;
}

a .content-slider__slide {
  cursor: pointer;
}

.content-slider-basic-slide__content {
  padding: 0 20px;
  text-align: center;
}

.content-slider .headlines__headline {
  color: var(--color-headline);
  line-height: var(--lh-small);
  margin-bottom: var(--headline-margin-bottom);
}
@media (min-width: 48em) {
  .content-slider .headlines__headline {
    line-height: var(--lh-small);
  }
}
.content-slider .headlines__subheadline {
  margin: 0 4px;
  line-height: var(--lh-small);
}
@media (min-width: 48em) {
  .content-slider .headlines__subheadline {
    line-height: var(--lh-small);
  }
}
@media (min-width: 48em) and (max-width: 74.99em) {
  .content-slider .slider__inner-wrapper--with-arrows {
    margin-left: 55px;
    margin-right: 55px;
  }
  .content-slider .slider__inner-wrapper--with-arrows .arrow-button {
    display: flex;
  }
}

.content-slider__mobile {
  color: var(--color-text);
}

.content-slider__swiper-server .content-slider-basic-slide {
  max-width: 250px;
}

.card-extension {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 8px;
}

.card-extension__icon {
  margin-right: 8px;
  position: relative;
  top: 2px;
  color: var(--color-red);
}

.card-extension__submit {
  padding: 12px 18px;
  max-width: 316px;
}

.club-card-link__form-fields {
  display: grid;
  gap: 20px;
  margin-bottom: 20px;
}
@media (min-width: 48em) {
  .club-card-link__form-fields {
    grid-template-columns: 1fr 1fr;
  }
}

.customer-club-membership__info {
  margin-bottom: 30px;
}

.customer-club-user-info {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin: 15px 0;
  position: relative;
}

.customer-club-user-info__image-container {
  margin-top: 8px;
  width: 360px;
  display: flex;
}

.customer-club-user-info__customer-name {
  position: absolute;
  bottom: 48px;
  margin-left: 10px;
}

.customer-club {
  padding: 0 20px;
}
@media (min-width: 48em) {
  .customer-club {
    padding: 0;
  }
}

.customer-club__headlines {
  padding: 0;
}

.customer-club__wrapper {
  font-family: var(--ff-menu);
}
.customer-club__wrapper h1,
.customer-club__wrapper h2,
.customer-club__wrapper h3 {
  font-family: var(--ff-menu);
  font-weight: var(--fw-semibold);
}
.customer-club__wrapper .headlines__headline {
  text-align: left;
  border-bottom: 1px solid var(--theme-primary);
  padding: 20px 0;
}

.customer-club__submit {
  padding: 12px 18px;
  max-width: 316px;
}

.deal-of-the-day-countdown {
  background-color: var(--theme-error);
  color: var(--color-white-true);
  box-shadow: var(--box-shadow-default);
  text-align: center;
  width: 100%;
  padding: 10px;
}
@media (min-width: 48em) {
  .deal-of-the-day-countdown {
    border-radius: var(--border-radius-default);
  }
}

.deal-of-the-day-countdown__time {
  font-size: 20px;
}

@media (max-width: 47.99em) {
  .deal-of-the-day__wrapper .product {
    display: grid;
    flex-wrap: wrap;
    margin-top: 10px;
    grid-template-columns: 30% 70%;
  }
  .deal-of-the-day__wrapper .product .product__product-top {
    aspect-ratio: 1/1;
  }
  .deal-of-the-day__wrapper .product .product__details-container {
    min-height: auto;
  }
}

.deal-of-the-day__has-products {
  margin-bottom: 40px;
}

.deal-of-the-day__header {
  text-align: center;
}

.deal-of-the-day__header-title {
  padding: 20px 0 0;
}
.deal-of-the-day__header-title .headlines__headline {
  color: var(--color-product-title);
}
.deal-of-the-day__header-title .headlines__subheadline {
  margin-bottom: 20px;
}

.deal-of-the-day__product-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}
@media (min-width: 48em) {
  .deal-of-the-day__product-container {
    margin-top: 20px;
    grid-gap: 20px;
    grid-template-columns: auto 30%;
  }
}

.deal-of-the-day__product-container-left {
  position: relative;
  height: 100%;
}
.deal-of-the-day__product-container-left .catalog-badge--filled--red {
  position: absolute;
  bottom: 5px;
  right: 10px;
  background: var(--color-white-true);
  color: var(--theme-error);
  border: 1px solid var(--theme-error);
}

.deal-of-the-day__main-image {
  box-shadow: var(--box-shadow-default);
  height: 100%;
  object-fit: cover;
  border-radius: var(--border-radius-default);
}
@media (max-width: 47.99em) {
  .deal-of-the-day__main-image {
    width: 100%;
  }
}

.product.deal-of-the-day__product {
  flex-direction: row;
  margin: 20px;
  height: 95%;
}
@media (min-width: 48em) {
  .product.deal-of-the-day__product {
    display: grid;
    grid-template-rows: 1fr 1fr;
    margin: 0;
    height: 100%;
  }
}

.deal-of-the-day__product .product__image-container {
  width: 100px;
  padding: 10px;
  margin-bottom: 0;
}
@media (min-width: 48em) {
  .deal-of-the-day__product .product__image-container {
    padding: 0;
    width: 100%;
  }
}
.deal-of-the-day__product .product__image-container .product__image {
  margin: 0;
  height: 100%;
  border-radius: var(--border-radius-default);
  object-fit: cover;
  align-self: flex-start;
}
@media (min-width: 48em) {
  .deal-of-the-day__product .product__image-container .product__image {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.deal-of-the-day__product .product__title {
  margin: 0;
  min-height: unset;
  padding-bottom: unset;
}
.deal-of-the-day__product .star-rating-bar {
  margin: 10px 0;
}
.deal-of-the-day__product .product__product-data {
  min-height: unset;
}
.deal-of-the-day__product .product__product-button {
  color: var(--color-black-true);
}
.deal-of-the-day__product .product__image-container__image {
  border-radius: var(--border-radius-product-image-container);
}

.deal-of-the-day__image-sticker {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.badge.deal-of-the-day__image-sticker {
  border-bottom-right-radius: 0;
  background: var(--color-white-true);
  color: var(--theme-error);
  border: 1px solid var(--theme-error);
  padding: 0 5px;
}

.emarsys-field-writer {
  text-align: center;
  padding: 20px;
}
@media (min-width: 75em) {
  .emarsys-field-writer {
    padding: 20px 0;
  }
}

.emarsys-field-writer__message {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.emarsys-field-writer__message--success {
  color: var(--theme-success);
}
.emarsys-field-writer__message--error {
  color: var(--theme-error);
}

.emarsys-field-writer__message-icon {
  font-size: 24px;
}

.flexbox {
  padding-bottom: 20px;
}
@media (min-width: 48em) {
  .flexbox {
    padding-bottom: 40px;
  }
}

.flexbox-container {
  display: grid;
  grid-gap: 1rem;
  grid-auto-flow: dense;
  margin: 20px;
}
@media (min-width: 48em) {
  .flexbox-container {
    margin: 0;
  }
}

.flexbox-box {
  aspect-ratio: 1/1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
}

.flexbox-box__image {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-default);
}

.flexbox-box__video {
  position: absolute;
  inset: 0;
}
.flexbox-box__video video {
  border-radius: var(--border-radius-default);
}

.flexbox-box__btn {
  background-color: var(--color-teaser-cta-box);
  color: var(--color-black);
  border-radius: var(--border-radius-default);
  margin-bottom: 12px;
  max-width: 90%;
  min-width: unset;
  padding: 10px 40px;
  font-size: 0.8em;
  font-weight: var(--fw-semibold);
  z-index: var(--zi-btn);
}
@media (min-width: 75em) {
  .flexbox-box__btn {
    font-size: 1em;
  }
}

.flexbox-container--grid-1 {
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.flexbox-container--grid-2 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(1, 1fr);
}

.flexbox-container--grid-3 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}
.flexbox-container--grid-3 .flexbox-box--big:nth-child(1) {
  grid-row: 2/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-3 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .flexbox-container--grid-3 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 2/span 2;
  }
}

.flexbox-container--grid-4 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
}
.flexbox-container--grid-4 .flexbox-box--big:nth-child(1) {
  grid-row: 1/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-4 .flexbox-box--big:nth-child(2) {
  grid-row: 4/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-4 {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .flexbox-container--grid-4 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 1/span 2;
  }
  .flexbox-container--grid-4 .flexbox-box--big:nth-child(2) {
    grid-row: 1/span 2;
    grid-column: 4/span 2;
  }
}

.flexbox-container--grid-5 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 1fr);
}
.flexbox-container--grid-5 .flexbox-box--big:nth-child(1) {
  grid-row: 2/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-5 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  .flexbox-container--grid-5 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 2/span 2;
  }
}

.flexbox-container--grid-6 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
}
.flexbox-container--grid-6 .flexbox-box--big:nth-child(1) {
  grid-row: 2/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-6 .flexbox-box--big:nth-child(2) {
  grid-row: 5/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-6 {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .flexbox-container--grid-6 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 2/span 2;
  }
  .flexbox-container--grid-6 .flexbox-box--big:nth-child(2) {
    grid-row: 3/span 2;
    grid-column: 1/span 2;
  }
}

.flexbox-container--grid-7 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(8, 1fr);
}
.flexbox-container--grid-7 .flexbox-box--big:nth-child(1) {
  grid-row: 1/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-7 .flexbox-box--big:nth-child(2) {
  grid-row: 4/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-7 .flexbox-box--big:nth-child(3) {
  grid-row: 7/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-7 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .flexbox-container--grid-7 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 2/span 2;
  }
  .flexbox-container--grid-7 .flexbox-box--big:nth-child(2) {
    grid-row: 3/span 2;
    grid-column: 1/span 2;
  }
  .flexbox-container--grid-7 .flexbox-box--big:nth-child(3) {
    grid-row: 3/span 2;
    grid-column: 3/span 2;
  }
}

.flexbox-container--grid-8 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(7, 1fr);
}
.flexbox-container--grid-8 .flexbox-box--big:nth-child(1) {
  grid-row: 2/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-8 .flexbox-box--big:nth-child(2) {
  grid-row: 5/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-8 {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .flexbox-container--grid-8 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 1/span 2;
  }
  .flexbox-container--grid-8 .flexbox-box--big:nth-child(2) {
    grid-row: 1/span 2;
    grid-column: 4/span 2;
  }
  .flexbox-container--grid-8 .flexbox-box--big:nth-child(3) {
    grid-row: 3/span 2;
    grid-column: 1/span 2;
  }
  .flexbox-container--grid-8 .flexbox-box--big:nth-child(4) {
    grid-row: 3/span 2;
    grid-column: 4/span 2;
  }
}

.flexbox-container--grid-9 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(9, 1fr);
}
.flexbox-container--grid-9 .flexbox-box--big:nth-child(1) {
  grid-row: 2/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-9 .flexbox-box--big:nth-child(2) {
  grid-row: 5/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-9 .flexbox-box--big:nth-child(3) {
  grid-row: 8/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-9 {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  .flexbox-container--grid-9 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 1/span 2;
  }
  .flexbox-container--grid-9 .flexbox-box--big:nth-child(2) {
    grid-row: 2/span 2;
    grid-column: 4/span 2;
  }
  .flexbox-container--grid-9 .flexbox-box--big:nth-child(3) {
    grid-row: auto;
    grid-column: auto;
  }
}

.flexbox-container--grid-10 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(11, 1fr);
}
.flexbox-container--grid-10 .flexbox-box--big:nth-child(1) {
  grid-row: 1/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-10 .flexbox-box--big:nth-child(2) {
  grid-row: 4/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-10 .flexbox-box--big:nth-child(3) {
  grid-row: 7/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-10 .flexbox-box--big:nth-child(4) {
  grid-row: 10/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-10 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .flexbox-container--grid-10 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 2/span 2;
  }
  .flexbox-container--grid-10 .flexbox-box--big:nth-child(2) {
    grid-row: 3/span 2;
    grid-column: 2/span 2;
  }
  .flexbox-container--grid-10 .flexbox-box--big:nth-child(3) {
    grid-row: auto;
    grid-column: auto;
  }
  .flexbox-container--grid-10 .flexbox-box--big:nth-child(4) {
    grid-row: auto;
    grid-column: auto;
  }
}

.flexbox-container--grid-11 {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(10, 1fr);
}
.flexbox-container--grid-11 .flexbox-box--big:nth-child(1) {
  grid-row: 2/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-11 .flexbox-box--big:nth-child(2) {
  grid-row: 5/span 2;
  grid-column: 1/span 2;
}
.flexbox-container--grid-11 .flexbox-box--big:nth-child(3) {
  grid-row: 8/span 2;
  grid-column: 1/span 2;
}
@media only screen and (min-width: 1200px) {
  .flexbox-container--grid-11 {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
  }
  .flexbox-container--grid-11 .flexbox-box--big:nth-child(1) {
    grid-row: 1/span 2;
    grid-column: 2/span 2;
  }
  .flexbox-container--grid-11 .flexbox-box--big:nth-child(2) {
    grid-row: 3/span 2;
    grid-column: 1/span 2;
  }
  .flexbox-container--grid-11 .flexbox-box--big:nth-child(3) {
    grid-row: 3/span 2;
    grid-column: 3/span 2;
  }
}

@media (min-width: 48em) {
  .product-listing {
    padding: 0;
  }
}
.product-listing:has(> .headlines) {
  padding-top: 10px;
}
.product-listing .headlines--padding {
  padding: 20px 0;
}
.product-listing .product-slider {
  padding: 0 0 30px;
}
@media (min-width: 48em) {
  .product-listing .product-slider {
    margin: 0 -4px;
  }
}
.product-listing .content-width-wrapper--mobile,
.product-listing .content-width-wrapper--tablet,
.product-listing .content-width-wrapper--desktop,
.product-listing .content-width-wrapper--wide {
  width: inherit;
}

.product-listing__teaser-wrapper {
  padding: 0 0 30px;
}
@media (min-width: 75em) {
  .product-listing__teaser-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "product hero hero hero";
    grid-column-gap: 24px;
  }
}
.product-listing__teaser-wrapper--tablet {
  grid-template-areas: "hero hero hero hero";
}

.product-listing__teaser-wrapper--no-headline {
  padding: 30px 0;
}

.product-listing__teaser-product {
  grid-area: product;
}
@media (max-width: 74.99em) {
  .product-listing__teaser-product {
    display: none !important;
  }
}

.product-listing__teaser-hero {
  grid-area: hero;
}
@media (min-width: 48em) {
  .product-listing__teaser-hero {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: var(--border-radius-default);
  }
}
.product-listing__teaser-hero .hero-teaser__button {
  border-radius: var(--border-radius-default);
  color: var(--color-white-true);
  padding: 6px;
  line-height: 24px;
  font-weight: var(--fw-normal);
  letter-spacing: normal;
}
@media (max-width: 47.99em) {
  .product-listing__teaser-hero .hero-teaser__button {
    padding: 6px 12px;
    font-size: var(--fs-s);
  }
}
.product-listing__teaser-hero .hero-teaser__box {
  padding: 30px;
}

.product-listing__teaser-wrapper--no-bottom-padding {
  padding-bottom: 0;
}

.hero-teaser {
  overflow: hidden;
  position: relative;
}
@media (min-width: 48em) {
  .hero-teaser {
    height: 100%;
  }
}

@media (min-width: 48em) {
  .hero-teaser__image-container {
    height: 100%;
  }
}

.hero-teaser__image {
  width: 100%;
  height: auto;
}
@media (min-width: 48em) {
  .hero-teaser__image {
    border-top-left-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
    height: 100%;
    object-fit: cover;
  }
}
@media (min-width: 75em) {
  .hero-teaser__image {
    width: auto;
  }
}

.hero-teaser__video {
  position: relative;
  aspect-ratio: 16/9;
}
@media (min-width: 75em) {
  .hero-teaser__video {
    aspect-ratio: auto;
    position: absolute;
    inset: 0;
  }
}

.hero-teaser__box {
  font-family: var(--ff-menu);
  background: var(--color-teaser-cta-box);
  width: 100%;
  padding: 0 15px 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 48em) {
  .hero-teaser__box {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 50px;
    border-top-right-radius: var(--border-radius-wide);
    border-top-left-radius: var(--border-radius-wide);
    max-width: 350px;
  }
}
.hero-teaser__box .headlines {
  padding-top: 0;
}
.hero-teaser__box .headlines__headline {
  color: var(--color-subheadline);
  margin-bottom: var(--headline-margin-bottom);
  line-height: var(--lh-small);
}
@media (min-width: 48em) {
  .hero-teaser__box .headlines__headline {
    line-height: var(--lh-small);
  }
}
.hero-teaser__box .headlines__subheadline {
  color: var(--color-headline);
  font-size: var(--hero-teaser-box-headline-font-size-mobile);
  line-height: var(--lh-small);
}
@media (min-width: 48em) {
  .hero-teaser__box .headlines__subheadline {
    font-size: var(--hero-teaser-box-headline-font-size-desktop);
    line-height: var(--lh-small);
  }
}

@media (min-width: 48em) {
  .hero-teaser__box--right {
    right: 5%;
    border-bottom-right-radius: var(--border-radius-wide);
  }
}

@media (min-width: 48em) {
  .hero-teaser__box--left {
    left: 5%;
    border-bottom-right-radius: var(--border-radius-wide);
  }
}

.hero-teaser__button {
  padding: 16px 40px;
  border-radius: var(--border-radius-wide);
  text-transform: initial;
  display: flex;
  margin: 0 auto;
  background: var(--hero-teaser-button-color);
  color: var(--hero-teaser-button-text-color);
  justify-content: center;
  font-weight: 600;
  letter-spacing: 1.25px;
  min-width: 170px;
}
@media (min-width: 48em) {
  .hero-teaser__button {
    padding: 16px;
    width: 100%;
  }
}

.background--primary .hero-teaser-slider__item-active .headlines__headline,
.background--primary .hero-teaser-slider__item-active .headlines__subheadline {
  color: var(--theme-primary);
}
.background--primary .hero-teaser-slider__link,
.background--primary .headlines__headline {
  color: var(--color-white-true);
}

.background--tertiary .hero-teaser-slider__item-active .headlines__headline,
.background--tertiary .hero-teaser-slider__item-active .headlines__subheadline {
  color: var(--theme-primary);
}

.hero-teaser-slider {
  display: grid;
  grid-template-columns: 100%;
}
@media (max-width: 47.99em) {
  .hero-teaser-slider {
    background: var(--color-teaser-cta-box);
  }
}
@media (min-width: 48em) {
  .hero-teaser-slider {
    padding: 50px 0;
    grid-template-columns: 79% auto;
  }
}
@media (min-width: 48em) {
  .hero-teaser-slider .hero-teaser__video {
    aspect-ratio: auto;
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
  }
}

.hero-teaser-slider__left-slider {
  position: relative;
  margin-bottom: 0;
}
@media (min-width: 48em) {
  .hero-teaser-slider__left-slider {
    border-top-left-radius: var(--border-radius-default);
    border-bottom-left-radius: var(--border-radius-default);
    overflow: hidden;
  }
}
.hero-teaser-slider__left-slider .arrow-button {
  border-radius: 40px;
  margin-left: 15px;
  margin-right: 15px;
  top: 25%;
}
@media (min-width: 48em) {
  .hero-teaser-slider__left-slider .arrow-button {
    display: none;
  }
}

.hero-teaser-slider__left-slider__slider {
  height: 100%;
  margin: 0;
}
.hero-teaser-slider__left-slider__slider .slider__inner-wrapper {
  height: 100%;
}
.hero-teaser-slider__left-slider__slider .slider__inner-wrapper .swiper {
  height: 100%;
}

.hero-teaser-slider__right-items {
  position: relative;
  display: none;
  font-family: var(--font-base);
}
@media (min-width: 48em) {
  .hero-teaser-slider__right-items {
    display: grid;
    width: 100%;
  }
}
.hero-teaser-slider__right-items .title-h3 {
  font-family: var(--font-base);
}
.hero-teaser-slider__right-items a {
  text-decoration: underline;
  font-weight: 600;
}
.hero-teaser-slider__right-items a:hover {
  text-decoration: none;
}

.hero-teaser-slider__text-content-container {
  display: table;
}
@media (min-width: 48em) {
  .hero-teaser-slider__text-content-container {
    background: var(--color-white-true);
    border-top: 1px solid var(--color-teaser-right-box-border);
    border-left: 1px solid var(--color-teaser-right-box-border);
    border-right: 1px solid var(--color-teaser-right-box-border);
  }
}
.hero-teaser-slider__text-content-container:first-of-type {
  border-top-right-radius: var(--border-radius-default);
}
.hero-teaser-slider__text-content-container:last-of-type {
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom: 1px solid var(--color-teaser-right-box-border);
}
.hero-teaser-slider__text-content-container.hero-teaser-slider__item-active {
  background: var(--color-teaser-right-active-box);
}

.hero-teaser-slider__text-wrapper {
  padding: 10px 20px;
  display: table-cell;
  vertical-align: middle;
}
.hero-teaser-slider__text-wrapper:not(.hero-teaser-slider__item-active):hover {
  cursor: pointer;
}
.hero-teaser-slider__text-wrapper .headlines {
  padding: 0 0 16px;
  text-align: left;
}
.hero-teaser-slider__text-wrapper .headlines__headline {
  color: var(--hero-teaser-slider-headline-color);
}
.hero-teaser-slider__text-wrapper .headlines__subheadline {
  color: var(--hero-teaser-slider-subheadline-color);
}
.hero-teaser-slider__text-wrapper .hero-teaser-slider__link {
  color: var(--hero-teaser-slider-cta-color);
}
@media (min-width: 48em) and (max-width: 74.99em) {
  .hero-teaser-slider__text-wrapper .hero-teaser-slider__link {
    font-size: 12px;
  }
}

/* stylelint-disable selector-class-pattern */
.image-banner-slider {
  display: block;
  position: relative;
}
.image-banner-slider .arrow-button {
  color: var(--pdp-slider-arrow-color);
  background: var(--pdp-slider-arrow-background);
  z-index: 1;
}
.image-banner-slider .arrow-button--left {
  left: 8px;
}
.image-banner-slider .arrow-button--right {
  right: 8px;
}

.swiper-wrapper {
  padding-bottom: 0;
}

.image-banner-slider__swiper {
  margin: 0;
}
.image-banner-slider__swiper .swiper-wrapper {
  align-items: center;
}
.image-banner-slider__swiper .slider__inner-wrapper {
  margin: 0;
}

.image-banner-slider__toggle-play-button {
  position: absolute;
  bottom: 42px;
  left: 12px;
}
@media (min-width: 48em) {
  .image-banner-slider__toggle-play-button {
    display: none;
  }
}

@media only screen and (min-width: 1423px) {
  .image-banner-slider__arrow-buttons--content-banner .arrow-button--left {
    left: -54px;
  }
  .image-banner-slider__arrow-buttons--content-banner .arrow-button--right {
    right: -54px;
  }
}

.swiper-wrapper > :not(.swiper-slide-active) > .image-banner--banner,
.swiper-wrapper > :not(.swiper-slide-active) > .image-banner--contentBanner {
  display: none;
}

.image-banner-slider:not(:has(.swiper-initialized)) .swiper-wrapper > :first-child > .image-banner--banner,
.image-banner-slider:not(:has(.swiper-initialized)) .swiper-wrapper > :first-child > .image-banner--contentBanner {
  display: block;
}
.image-banner-slider:not(:has(.swiper-initialized)) .image-banner-slider__toggle-play-button,
.image-banner-slider:not(:has(.swiper-initialized)) .slider__inner-wrapper--arrows-wrapper {
  display: none;
}

.info-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  border: 1px solid var(--color-gray-400);
  padding: 20px;
  margin: 0 15px;
}
@media (min-width: 48em) {
  .info-box {
    margin: 0;
  }
}
.info-box__icon {
  display: flex;
}
.info-box__icon svg {
  width: 36px;
  height: 36px;
  color: var(--theme-primary);
}
.info-box__text {
  font-size: var(--fs-s);
  color: var(--color-gray-500);
}

.authorization-modal__authorize-wrapper {
  text-align: center;
}

.authorization-modal__attention-icon {
  font-size: 40px;
  color: var(--theme-tertiary);
}

.authorization-modal__attention-wrapper {
  padding: 20px 25px;
  border-radius: var(--border-radius-default);
  display: inline-block;
  background: var(--theme-primary);
}

.authorization-modal__authorize-header {
  margin-top: 15px;
}

.authorization-modal__yes,
.authorization-modal__no {
  margin: 8px;
}

.authorization-modal__shop-name {
  color: var(--theme-primary);
  font-size: 1.1em;
}

.existing-account-modal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.existing-account-modal::-webkit-scrollbar {
  display: none; /* Webkit */
}

.existing-account-modal__actions {
  text-align: center;
}
.existing-account-modal__actions > button {
  margin: 8px;
}

.login-and-register {
  display: grid;
  gap: 10px 50px;
  grid-template-rows: 1fr;
  grid-template-areas: "topHeadlines" "loginHeadlines" "loginForm" "loginCta" "loginLinks" "registerHeadlines" "registerContent" "registerCta";
  position: relative;
  margin: 20px;
}
@media (min-width: 48em) {
  .login-and-register {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "topHeadlines topHeadlines" "loginHeadlines registerHeadlines" "loginForm registerContent" "loginCta registerCta" "loginLinks .";
    margin: 20px 50px;
  }
}

.login-and-register__headlines {
  padding: 20px 0 0;
}
@media (max-width: 47.99em) {
  .login-and-register__headlines {
    padding: 10px 0;
  }
}

.login-and-register__headlines .headlines__subheadline {
  font-weight: var(--fw-normal);
}

.login-and-register__login-headlines {
  grid-area: loginHeadlines;
}

.login-and-register__register-headlines {
  grid-area: registerHeadlines;
}
@media (max-width: 47.99em) {
  .login-and-register__register-headlines {
    margin-top: 30px;
  }
}

.login-and-register__register-text {
  grid-area: registerContent;
}

.login-and-register__register-button {
  grid-area: registerCta;
  width: 100%;
  line-height: 1.15;
}

.login-and-register__register {
  border-radius: var(--border-radius-default);
}

.login-and-register__top-headlines {
  grid-area: topHeadlines;
}

.login-form {
  grid-area: loginForm;
  margin-top: 1rem;
}

.login-form__email,
.login-form__password {
  min-height: 70px;
}

.login-form__loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(138, 138, 138, 0.3);
  z-index: var(--zi-login-form-loading);
}

.login-form__loader {
  border: 16px solid var(--theme-tertiary); /* Light grey */
  border-top: 16px solid var(--theme-primary); /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.login-form__login-cta {
  grid-area: loginLinks;
  display: flex;
  flex-direction: column;
}

.login-form__login-forgot a {
  text-decoration: underline;
  color: var(--color-black);
}

.login-form__login-problems {
  margin-top: 10px;
}
.login-form__login-problems a {
  text-decoration: underline;
  color: var(--color-black);
}

.login-form__login-button {
  grid-area: loginCta;
  width: 100%;
}

.login-form__full-name {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.marketing-slider__slide-item {
  cursor: default;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.marketing-slider__slide-item * {
  width: 100%;
}
@media (max-width: 74.99em) {
  .marketing-slider__slide-item * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.marketing-slider__slide-text {
  padding: 0 20px;
}

.marketing-slider__text-wrapper {
  margin: 10px 0;
}

.marketing-slider__cursor-pointer {
  cursor: pointer;
}

.swiper-wrapper {
  width: auto;
}

.mobile-image-gallery {
  position: fixed;
  inset: 0;
  background: var(--color-white-true);
  z-index: var(--zi-modal);
}
.mobile-image-gallery .swiper-slide-prev {
  margin-left: 0;
}
@media (max-width: 47.99em) {
  .mobile-image-gallery .swiper-slide-next,
  .mobile-image-gallery .swiper-slide-prev {
    opacity: 1;
  }
}
.mobile-image-gallery .slider__inner-wrapper {
  height: 100%;
}
.mobile-image-gallery .swiper-wrapper {
  height: 100%;
  align-items: center;
}
.mobile-image-gallery figcaption {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  text-align: center;
  color: var(--pdp-color-caption);
  font-size: var(--fs-l);
  font-weight: var(--fw-semibold);
}

.mobile-image-gallery__slide-inner-wrapper {
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.mobile-image-gallery__slide-inner-wrapper > div {
  display: flex;
  align-items: flex-end;
}
.mobile-image-gallery__slide-inner-wrapper .mobile-image-gallery__video {
  height: calc(90vh - 23vw);
  align-items: center;
}
.mobile-image-gallery__slide-inner-wrapper .mobile-image-gallery__video .youtube-video {
  width: 100%;
}

.mobile-image-gallery__header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  padding: 0 12px;
}

.mobile-image-gallery__icon-wrapper svg {
  display: block;
  font-size: 24px;
  color: var(--color-gray-900);
}

.mobile-image-gallery__slider-wrapper {
  position: relative;
  height: calc(100% - 40px);
}

.mobile-image-gallery__main-slider__slider {
  height: calc(100% - 23vw);
  margin: 0;
}
.mobile-image-gallery__main-slider__slider .arrow-button {
  display: flex;
  z-index: 10;
  margin-bottom: 0;
}
.mobile-image-gallery__main-slider__slider .arrow-button--left {
  left: 4px;
}
.mobile-image-gallery__main-slider__slider .arrow-button--right {
  right: 4px;
}
.mobile-image-gallery__main-slider__slider .swiper {
  height: 100%;
}

.mobile-image-gallery__thumbs-slider__slider {
  margin: 0;
}
.mobile-image-gallery__thumbs-slider__slider .swiper-slide > div {
  opacity: 0.5;
}
.mobile-image-gallery__thumbs-slider__slider .swiper-slide img {
  display: block;
}
.mobile-image-gallery__thumbs-slider__slider .swiper-slide-thumb-active > div {
  border: 2px solid var(--pdp-selected-image-border-color);
  opacity: 1;
}
.mobile-image-gallery__thumbs-slider__slider .swipper-wrapper {
  padding-bottom: 0;
}

.new-passwords {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 420px;
  margin: 16px;
}
@media (min-width: 48em) {
  .new-passwords {
    margin: 80px auto;
  }
}

.new-passwords__password-requirements {
  margin: 20px 0 10px;
}

.new-passwords__password {
  margin-bottom: 15px;
}

.newsletter {
  padding: 30px 20px;
}
.newsletter .headlines {
  padding: 0 0 20px;
}
@media (min-width: 75em) {
  .newsletter .headlines {
    padding: 0;
    margin-bottom: 8px;
  }
}

.newsletter__description,
.newsletter__form {
  margin-bottom: 16px;
}

.newsletter__description p,
.newsletter__legal-text p {
  margin: 0;
}
.newsletter__description a,
.newsletter__legal-text a {
  text-decoration: underline;
  color: currentcolor;
}

@media (min-width: 48em) {
  .newsletter__form-input-wrapper {
    display: flex;
  }
}
.newsletter__form-input-wrapper .newsletter__input input {
  height: 46px;
}

.newsletter__input {
  width: 100%;
}
.newsletter__input input {
  text-overflow: ellipsis;
}
@media (max-width: 47.99em) {
  .newsletter__input input {
    border-bottom: none;
    border-radius: 10px 10px 0 0;
  }
}
@media (min-width: 48em) {
  .newsletter__input input {
    border-right: none;
    border-radius: var(--border-radius-bottom-right-badge);
  }
}
.newsletter__input .input__title {
  right: 0;
  padding: 13px 5px 11px 15px;
}

.newsletter__button {
  height: 46px;
  padding: 15px 25px;
  text-transform: none;
  min-width: auto;
}
@media (max-width: 47.99em) {
  .newsletter__button {
    width: 100%;
    border-radius: 0 0 10px 10px;
  }
}
@media (min-width: 48em) {
  .newsletter__button {
    border-radius: var(--border-radius-badge);
  }
}

.newsletter__legal-text {
  font-size: var(--fs-s);
}

.newsletter__company-transactions {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.newsletter__text-holder p a {
  color: var(--theme-cta);
  font-weight: 600;
}

.newsletter__text-holder p a:hover {
  cursor: pointer;
}

.option-selector {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.option-selector__title {
  font-size: 16px;
  color: var(--color-gray-800);
  font-weight: var(--fw-semibold);
  padding-bottom: 13px;
}
@media (min-width: 48em) {
  .option-selector__title {
    padding: 8px 0 13px;
  }
}

.option-selector__subline {
  font-weight: var(--fw-light);
}

.option-selector__options {
  display: flex;
  gap: 10px;
  flex-flow: row wrap;
  margin: 0 -16px;
  padding: 0 16px;
}
@media (min-width: 48em) {
  .option-selector__options {
    margin: 0;
    padding: 0;
  }
}

.option-selector__variant-error {
  color: var(--theme-error);
  padding-top: 5px;
  line-height: 20px;
  display: flex;
  align-items: center;
}

.option-selector__attention-icon {
  margin-right: 5px;
}

.option-selector__dimension {
  border-top: 1px solid var(--option-selector-border-color);
  padding: 23px 16px 0;
  margin: 0 -16px;
}
.option-selector__dimension:last-child {
  border-bottom: 1px solid var(--option-selector-border-color);
}
@media (min-width: 48em) {
  .option-selector__dimension {
    border: none;
    margin: 0;
    padding: 0;
  }
  .option-selector__dimension:last-child {
    border: none;
  }
}

.option-item {
  border: 1px solid var(--color-gray-400);
  border-radius: var(--border-radius-default);
  cursor: pointer;
  background: var(--color-gray-250);
  user-select: none;
  padding: 0;
  align-self: baseline;
}

.option-item__title {
  min-width: 95px;
  max-width: 190px;
  font-size: 16px;
  color: var(--color-gray-550);
  padding: 6px 12px;
  background: var(--color-white-true);
  border-radius: var(--border-radius-default);
  border: 1px solid var(--color-gray-500);
  margin: -1px;
}
@media (min-width: 48em) {
  .option-item__title {
    word-break: break-word;
  }
}

.option-item__description {
  font-size: 16px;
  padding: 6px 12px;
  display: flex;
}

.option-item__price,
.option-item__subtext {
  font-weight: 300;
}

.option-item--disabled {
  border-style: dashed;
  border-color: var(--color-gray-500);
}
.option-item--disabled .option-item__title {
  border: none;
  background: transparent;
}

.option-item:not(.option-item--disabled):hover .option-item__title,
.option-item--active .option-item__title {
  color: var(--theme-primary);
  border-color: var(--theme-primary);
}

.option-item--active .option-item__title {
  font-weight: var(--fw-bold);
}

.order-details {
  padding: 0 20px;
}
@media (min-width: 48em) {
  .order-details {
    padding: 0;
  }
}

.order-details__wrapper {
  margin-top: 20px;
}

.order-details__item-header {
  margin: 40px 0;
}

.order-details__item-title {
  font-weight: var(--fw-semibold);
  font-size: 24px;
}

.order-details__item-address {
  margin-top: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: column;
  margin-bottom: 35px;
}
@media (min-width: 48em) {
  .order-details__item-address {
    display: flex;
    margin: 0;
    padding: 20px 0;
    border-top: 1px solid var(--theme-primary);
  }
}

.order-details__headlines {
  padding: 20px 0;
  border-bottom: 1px solid var(--theme-primary);
}
.order-details__headlines .headlines__headline {
  text-align: left;
}

.order-details__go-back {
  margin-top: 20px;
}

.order-details__item-payment {
  margin: 20px 0;
}
@media (min-width: 48em) {
  .order-details__item-payment {
    margin: 0 0 0 10%;
  }
}

.order-details__item-shipping {
  display: flex;
  flex-direction: column;
}

.order-details__shipping-title,
.order-details__payment-title {
  font-size: 18px;
  font-weight: var(--fw-semibold);
  border-bottom: 1px solid var(--theme-primary);
  margin-bottom: 5px;
}
@media (min-width: 48em) {
  .order-details__shipping-title,
  .order-details__payment-title {
    border: none;
  }
}

.order-details__customer-number-text {
  margin-left: 10px;
}

.order-details__line-items {
  border-bottom: 1px solid var(--theme-primary);
}
.order-details__line-items .order-details__line-item {
  border-top: 1px solid var(--theme-primary);
}

.order-details__payment-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 52px;
  max-height: 40px;
  color: var(--theme-primary);
}

.order-detail-line-item {
  border-top: 1px solid var(--theme-primary);
  display: grid;
  grid-template-columns: 30% 70%;
  grid-template-areas: "Image Description" "Image Items" "Image Price" "Actions Actions";
  padding: 20px 0;
}
@media (min-width: 48em) {
  .order-detail-line-item {
    grid-template-columns: 20% 50% 20% 10%;
    grid-template-areas: "Image Description Items Price" "Image Description Actions Actions";
  }
}

.order-detail-line-item__image {
  grid-area: Image;
  padding: 10px;
}

.order-detail-line-item__name {
  color: var(--theme-primary);
  padding-bottom: 15px;
  font-family: var(--font-base);
  font-weight: var(--fw-semibold);
  font-size: 21px;
}

.order-detail-line-item__description {
  grid-area: Description;
}

.product-stage-description__preview--oversized::after {
  background: none;
}

.order-detail-line-item__quantity {
  grid-area: Items;
  font-size: 20px;
  font-weight: var(--fw-semibold);
  margin-top: 15px;
}
@media (min-width: 48em) {
  .order-detail-line-item__quantity {
    margin-top: 62px;
  }
}

.order-detail-line-item__price {
  grid-area: Price;
  font-size: 20px;
  font-weight: var(--fw-semibold);
  color: var(--theme-primary);
  margin-top: 15px;
  margin-bottom: 20px;
}
@media (min-width: 48em) {
  .order-detail-line-item__price {
    margin-top: 62px;
    margin-bottom: 0;
    text-align: right;
  }
}

.order-detail-line-item__actions {
  grid-area: Actions;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  border-top: 1px solid var(--theme-primary);
}
@media (min-width: 75em) {
  .order-detail-line-item__actions {
    align-self: self-end;
    text-align: right;
    margin-top: 0;
    border-top: none;
  }
}

.order-detail-line-item__unavailable {
  grid-area: Actions;
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: var(--fw-semibold);
}
.order-detail-line-item__unavailable .order-detail-line-item__unavailable-indicator {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: var(--color-red);
}

.order-detail-line-item__add-to-cart-button {
  font-size: 14px;
  border-radius: var(--border-radius-default);
  padding-left: 35px;
  padding-right: 35px;
}

.order-overview-images {
  display: flex;
  gap: 8px;
}
@media (max-width: 47.99em) {
  .order-overview-images {
    display: none;
  }
}

.order-overview-images__image {
  max-width: 120px;
  border: 2px solid var(--theme-primary);
}

.order-overview-images__line-extras {
  position: relative;
}

.order-overview-images__line-overlay {
  position: absolute;
  background-color: rgba(110, 110, 110, 0.7);
  left: 0;
  top: 0;
  width: 100%;
  height: calc(100% - 7px);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  font-weight: 600;
}

.order-overview {
  padding: 0 20px;
  font-family: var(--font-menu);
}
.order-overview h1,
.order-overview h2,
.order-overview h3 {
  font-family: var(--font-menu);
  font-weight: var(--fw-semibold);
  padding: 0;
}
@media (min-width: 48em) {
  .order-overview {
    padding: 0;
  }
}

.order-overview__headlines {
  border-bottom: 1px solid var(--theme-primary);
  padding: 0;
}

.order-overview__headlines .headlines__headline {
  text-align: left;
  padding: 20px 0;
}

@media (min-width: 48em) {
  .order-overview__item-text {
    width: 30%;
    margin-right: 20px;
  }
}

.order-overview__item {
  padding: 20px 0;
}
@media (min-width: 48em) {
  .order-overview__item {
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.order-overview__item:first-child {
  border-top: none;
}

.order-overview__item:last-child {
  border-bottom: 1px solid var(--theme-primary);
}

.order-overview__checkout-button {
  padding-left: 30px;
  padding-right: 30px;
  border-radius: var(--border-radius-default);
  font-weight: var(--fw-medium);
  margin-bottom: 20px;
  width: 100%;
}
@media (min-width: 48em) {
  .order-overview__checkout-button {
    margin-bottom: 0;
  }
}

.order-overview__item-sum {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  font-weight: var(--fw-semibold);
  margin-bottom: 15px;
}

.order-overview__item-sum-nr {
  margin-left: 16px;
  font-size: 16px;
  color: var(--theme-primary);
}

.order-overview__item-totals {
  font-size: 16px;
  font-weight: var(--fw-semibold);
  color: var(--theme-primary);
}

.order-overview__item-title {
  font-size: 16px;
  font-weight: var(--fw-semibold);
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.order-overview__item-checkout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 65px;
}

.order-overview__share-icon {
  font-size: 24px;
  margin-top: 27px;
  margin-right: 20px;
}
@media (min-width: 48em) {
  .order-overview__share-icon {
    margin-top: 9px;
    margin-right: 0;
  }
}

.order-overview__item-load-more {
  margin: 20px 0;
}

.order-overview__load-more-btn {
  border-radius: var(--border-radius-default);
  margin: 0 auto;
  display: block;
  padding-left: 80px;
  padding-right: 80px;
  text-align: center;
}

.pagination__wrapper {
  display: flex;
  justify-content: center;
}

.pagination {
  display: flex;
  border-radius: var(--border-radius-default);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background: var(--color-white-true);
  width: fit-content;
  list-style: none;
  margin: 0;
}

.pagination__item {
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.pagination__item:nth-last-child(2) .pagination__link:not(.pagination__item-active)::before {
  content: "";
  width: 1px;
  height: 32px;
  background: var(--theme-primary);
  position: absolute;
  right: 0;
  top: 7px;
}

.pagination__item:nth-child(2) .pagination__link:not(.pagination__item-active)::before {
  content: "";
  width: 1px;
  height: 32px;
  background: var(--theme-primary);
  position: absolute;
  left: 0;
  top: 7px;
}

.pagination__link {
  height: 46px;
  border-top: 1px solid var(--theme-primary);
  border-bottom: 1px solid var(--theme-primary);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 100ms ease-in-out;
  color: var(--color-black);
}

@media (min-width: 48em) {
  .pagination__page-link,
  .pagination__break-link {
    width: 46px;
  }
}
@media (max-width: 47.99em) {
  .pagination__page-link,
  .pagination__break-link {
    width: 40px;
  }
}
@media (max-width: 22.5em) {
  .pagination__page-link,
  .pagination__break-link {
    width: 35px;
  }
}

@media (max-width: 47.99em) {
  .pagination__break-link {
    width: 25px;
  }
}

.pagination__link:hover {
  background-color: var(--color-gray-300);
}

.pagination__link:focus {
  background-color: var(--color-gray-300);
  outline: none;
}

.pagination__item::before,
.pagination__next-previous::before {
  display: none;
}

.pagination__next-previous {
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.pagination__next-previous.disabled {
  cursor: not-allowed;
}
.pagination__next-previous.disabled .pagination__link:hover,
.pagination__next-previous.disabled .pagination__link:focus {
  background-color: unset;
}
.pagination__next-previous.disabled .pagination__link {
  color: var(--color-gray-600);
}

.pagination__previous {
  padding: 0 16px;
  border-left: 1px solid var(--theme-primary);
  border-bottom: 1px solid var(--theme-primary);
  border-top-left-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
  font-weight: var(--fw-bold);
}
@media (max-width: 47.99em) {
  .pagination__previous {
    padding: 0 12px;
  }
}
@media (max-width: 22.5em) {
  .pagination__previous {
    padding: 0 10px;
  }
}

@media (min-width: 48em) {
  .pagination__previous-icon {
    padding-right: 4px;
  }
}

.pagination__previous-text {
  padding-left: 4px;
}
@media (max-width: 47.99em) {
  .pagination__previous-text {
    display: none;
  }
}

.pagination__next {
  line-height: 16px;
  padding: 0 16px;
  border-right: 1px solid var(--theme-primary);
  border-bottom: 1px solid var(--theme-primary);
  border-top-right-radius: var(--border-radius-default);
  border-bottom-right-radius: var(--border-radius-default);
  font-weight: var(--fw-bold);
}
@media (max-width: 47.99em) {
  .pagination__next {
    padding: 0 12px;
  }
}
@media (max-width: 22.5em) {
  .pagination__next {
    padding: 0 10px;
  }
}

@media (min-width: 48em) {
  .pagination__next-icon {
    padding-left: 4px;
  }
}

.pagination__next-text {
  padding-right: 4px;
}
@media (max-width: 47.99em) {
  .pagination__next-text {
    display: none;
  }
}

.pagination__item-active {
  border: 1px solid var(--color-black);
  font-weight: var(--fw-bold);
}

.pagination__item-active:focus {
  background-color: unset;
}

.password-criteria__criteria-wrapper {
  margin-top: 0.5rem;
}

.password-criteria__criteria {
  display: block;
  transition: color 100ms ease-in-out;
}

.password-criteria__fulfills-criteria {
  color: var(--theme-success);
}

.password-forgotten {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  margin: auto;
  padding: 0 16px;
}
@media (min-width: 48em) {
  .password-forgotten {
    padding: 0;
  }
}

.password-forgotten__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.password-forgotten__title {
  padding: 20px 0;
}
@media (max-width: 47.99em) {
  .password-forgotten__title {
    padding: 10px 0;
  }
}

.password-forgotten__title .headlines__headline {
  color: var(--theme-primary);
}

.password-forgotten__title .headlines__subheadline {
  font-size: 18px;
}

.password-forgotten__description {
  margin-bottom: 10px;
}

.password-forgotten__input-field {
  padding: 15px 0;
  cursor: pointer;
}

.password-forgotten__login-link {
  text-align: center;
  margin-bottom: 16px;
}
.password-forgotten__login-link a {
  text-decoration: underline;
  font-size: 14px;
  color: var(--color-black-true);
}

.password-forgotten__btn {
  margin-bottom: 12px;
}

.password-forgotten__reset {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 420px;
  margin: 16px;
}
@media (min-width: 48em) {
  .password-forgotten__reset {
    margin: 0 auto;
  }
}

.password-forgotten__reset-title {
  font-size: var(--fs-h2);
  font-weight: var(--fw-semibold);
  color: var(--theme-primary);
  padding: 20px 0 0;
}
@media (max-width: 47.99em) {
  .password-forgotten__reset-title {
    padding: 10px 0;
  }
}

.password-forgotten__reset-tips {
  padding-bottom: 20px;
}

.password-forgotten__reset-tips-title {
  font-size: 14px;
  font-weight: var(--fw-semibold);
  padding-bottom: 10px;
}

.password-forgotten__reset-tips li {
  padding-left: 10px;
}

.password-forgotten__reset-description {
  margin: 10px 0 20px;
}

.password-forgotten__loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(138, 138, 138, 0.3);
  z-index: 2;
}

.password-forgotten__loader {
  width: 120px;
  height: 120px;
  border: 16px solid var(--theme-tertiary);
  border-radius: 50%;
  animation: spin 2s linear infinite;
  position: absolute;
  top: calc(50% - 60px);
  left: calc(50% - 60px);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.password-forgotten__full-name {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.set-new-password {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  margin: auto;
  padding: 0 15px;
}
@media (min-width: 48em) {
  .set-new-password {
    padding: 0;
  }
}

.set-new-password__title {
  padding: 20px 0;
}
@media (max-width: 47.99em) {
  .set-new-password__title {
    padding: 10px 0;
  }
}

.set-new-password__title .headlines__headline {
  color: var(--theme-primary);
}

.set-new-password__description {
  margin-bottom: 20px;
}

.set-new-password__btn {
  margin: 15px 0;
}

.personal-info-email__title {
  margin-bottom: 15px;
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
}

.personal-info {
  padding: 0 20px;
}
@media (min-width: 48em) {
  .personal-info {
    padding: 0;
  }
}

.personal-info__headlines {
  padding: 0;
}

.personal-info__wrapper {
  font-family: var(--ff-menu);
}
.personal-info__wrapper h1,
.personal-info__wrapper h2,
.personal-info__wrapper h3 {
  font-family: var(--ff-menu);
}
.personal-info__wrapper .headlines__headline {
  text-align: left;
  border-bottom: 1px solid var(--theme-primary);
  padding: 20px 0;
}

.personal-info__general-info,
.personal-info__customer-identification {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--theme-primary);
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

.personal-info__section {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

.personal-info__section:not(:last-child) {
  border-bottom: 1px solid var(--theme-primary);
}

.personal-info__customer-credentials {
  border-bottom: 0 solid var(--theme-primary);
}

.personal-info__reset {
  margin-left: 5px;
}

.personal-info__billing-address {
  margin: 15px 0;
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
}

.personal-info__customer-number-text {
  margin-left: 10px;
}

.personal-info__edit-btn {
  position: absolute;
  right: 0;
  cursor: pointer;
  margin: 15px 0;
}

.personal-info__password {
  margin-bottom: 15px;
}

.personal-info__submit {
  border-radius: var(--border-radius-default);
}

.personal-info__billing-address-container {
  display: flex;
  align-items: unset;
}

.personal-info-password__title {
  margin-bottom: 15px;
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
}

.personal-info-password__text {
  margin-bottom: 15px;
}
.personal-info-password__text p {
  margin-top: 0;
}

.personal-info-phone-number__header {
  display: flex;
  align-items: unset;
  justify-content: space-between;
}

.personal-info-phone-number__title {
  margin-bottom: 15px;
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
}

.personal-info-phone-number__edit-icon {
  cursor: pointer;
}

.personal-info-phone-number__input {
  margin-bottom: 15px;
}

.pre-checkout-added-product {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 8px;
}

.pre-checkout-added-product__image {
  width: 75px;
  height: 75px;
  border-radius: var(--border-radius-default);
}
@media (max-width: 47.99em) {
  .pre-checkout-added-product__image {
    width: 50px;
    height: 50px;
  }
}

.pre-checkout-added-product__text {
  margin-left: 10px;
}

.pre-checkout-added-product__title {
  color: var(--color-black-true);
  text-overflow: ellipsis;
  font-weight: var(--fw-bold);
  font-size: 12px;
}
@media (min-width: 48em) {
  .pre-checkout-added-product__title {
    color: var(--theme-primary);
    max-width: 200px;
    font-size: 16px;
  }
}

.pre-checkout-added-product__link {
  font-size: 14px;
  color: var(--color-text);
  text-decoration: underline;
}

.customer-club-suggestion {
  position: relative;
  display: grid;
  grid-template-areas: "productImage productDetail" "productImage orderInfo";
  grid-template-columns: 25% auto;
  grid-gap: 15px;
  padding: 10px;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  background: var(--color-white-true);
}
@media (min-width: 48em) {
  .customer-club-suggestion {
    background: var(--color-white-true);
    padding: 16px;
    margin: 0 0 26px;
  }
}

.customer-club-suggestion__image-container {
  grid-area: productImage;
  border-radius: var(--border-radius-default);
  overflow: hidden;
  aspect-ratio: 1/1;
  display: flex;
  align-items: stretch;
}

.customer-club-suggestion__image {
  object-fit: cover;
}

.customer-club-suggestion__detail {
  grid-area: productDetail;
}

.customer-club-suggestion__order-info {
  grid-area: orderInfo;
  align-self: end;
}

.customer-club-suggestion__detail-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 5px 10px 5px 0;
}

.customer-club-suggestion__title {
  font-size: 16px;
  color: var(--theme-primary);
  font-weight: 700;
}

.customer-club-suggestion__description {
  grid-area: description;
  font-size: 14px;
  text-align: start;
}
.customer-club-suggestion__description li {
  margin-bottom: 0;
}

.customer-club-suggestion-description__preview--oversized {
  overflow-y: hidden;
}

.customer-club-suggestion__price-info-container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 47.99em) {
  .customer-club-suggestion__price-info-container {
    display: block;
  }
}

.customer-club-suggestion__price {
  align-self: flex-end;
}
.customer-club-suggestion__price .price-box__old-price {
  color: var(--re-checkout-product-old-price);
  margin-bottom: 10px;
}
.customer-club-suggestion__price .price-box__price {
  color: var(--pre-checkout-product-price);
  font-size: 25px;
}

.customer-club-suggestion__promotion {
  margin-bottom: 12px;
  padding: 3px 16px;
  border-radius: var(--border-radius-precheckout);
  font-weight: 600;
  font-size: 13px;
  display: none;
}
@media (min-width: 48em) {
  .customer-club-suggestion__promotion {
    display: block;
  }
}

.customer-club-suggestion__cta-btn-cart {
  border-radius: var(--border-radius-default);
  padding: 11px;
  max-width: 100px;
  font-size: 18px;
  text-transform: unset;
  border: 1px solid var(--theme-primary);
  background: var(--color-white-true);
  color: var(--theme-primary);
}

.customer-club-suggestion__product-suggestion-title {
  text-align: center;
  padding: 12px;
  color: var(--theme-primary);
  background-color: var(--color-white-true);
}

.pre-checkout-flyout__container {
  position: fixed;
  top: 0;
  right: 0;
  background: var(--color-gray-300);
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  z-index: 2001;
  /* stylelint-disable declaration-block-no-duplicate-properties */
  max-height: 100%;
  max-height: -webkit-fill-available;
}
@media (min-width: 48em) {
  .pre-checkout-flyout__container {
    width: 380px;
  }
}
@media (min-width: 75em) {
  .pre-checkout-flyout__container {
    width: 500px;
  }
}
@media (max-width: 74.99em) {
  .pre-checkout-flyout__container {
    overflow-y: auto;
  }
}

.pre-checkout-flyout__backdrop {
  position: fixed;
  inset: 0;
  background-color: var(--modal-overlay-bg-color);
  z-index: var(--zi-precheckout);
  cursor: default;
}

.pre-checkout-flyout__header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  background-color: var(--color-white-true);
  box-shadow: var(--box-shadow-default);
  gap: 4px;
}
@media (min-width: 48em) {
  .pre-checkout-flyout__header {
    align-items: center;
    padding: 20px;
    gap: 0;
  }
}

.pre-checkout-flyout__price-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--theme-cta);
  margin-top: 20px;
}

.pre-checkout-flyout__price {
  font-size: 20px;
}

.pre-checkout-flyout__cta-sentence {
  font-size: 12px;
}
@media (min-width: 48em) {
  .pre-checkout-flyout__cta-sentence {
    font-size: 16px;
  }
}

.pre-checkout-flyout__cta-sentence-bold {
  font-weight: var(--fw-bold);
}

.pre-checkout-flyout__product-suggestion-title {
  text-align: center;
  margin: 20px;
  color: var(--theme-primary);
}

.pre-checkout-flyout__buttons {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  height: 75px;
  box-shadow: var(--box-shadow-default);
  background-color: var(--color-white-true);
  position: relative;
  z-index: 1;
}
@media (min-width: 48em) {
  .pre-checkout-flyout__buttons {
    height: 80px;
  }
}
@media (max-width: 22.5em) {
  .pre-checkout-flyout__buttons {
    flex-direction: column;
    align-items: center;
    gap: 12px;
    height: 110px;
  }
}

.pre-checkout-flyout__product-suggestion-wrapper {
  height: 100%;
  padding: 0 10px;
  overflow-y: auto;
  padding-bottom: 400px;
  scrollbar-width: none;
}
.pre-checkout-flyout__product-suggestion-wrapper--club-suggestion .customer-club-suggestion,
.pre-checkout-flyout__product-suggestion-wrapper--club-suggestion .customer-club-suggestion__product-suggestion-title {
  margin-left: -10px;
  margin-right: -10px;
}

.pre-checkout-flyout__product-suggestion-wrapper::-webkit-scrollbar {
  width: 0;
}

.pre-checkout-flyout__btn {
  width: 50%;
  border-radius: var(--border-radius-default);
  text-transform: none;
}

.pre-checkout-flyout--btn-go-back {
  border: 1px solid var(--theme-primary);
  background: var(--color-white-true);
  color: var(--theme-primary);
  margin-left: 20px;
  margin-right: 10px;
}
@media (max-width: 22.5em) {
  .pre-checkout-flyout--btn-go-back {
    margin: 0;
  }
}

.pre-checkout-flyout--btn-go-to-cart {
  color: var(--pdp-color-buy-button);
  background: var(--pdp-background-buy-button);
  margin-left: 10px;
  margin-right: 20px;
}
@media (max-width: 22.5em) {
  .pre-checkout-flyout--btn-go-to-cart {
    margin: 0;
  }
}

.pre-checkout-message {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (min-width: 48em) {
  .pre-checkout-message {
    flex-direction: column;
  }
}

.pre-checkout-message__success {
  color: var(--theme-success);
}

.pre-checkout-message__error {
  color: var(--theme-error);
}

.pre-checkout-message__icon-wrapper {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (min-width: 48em) {
  .pre-checkout-message__icon-wrapper {
    width: 75px;
    height: 75px;
  }
}

.pre-checkout-message__check-icon-wrapper {
  background-color: rgba(56, 170, 3, 0.2);
}

.pre-checkout-message__x-icon-wrapper {
  background-color: rgba(170, 3, 3, 0.2);
}

.pre-checkout-message__icon {
  font-size: 40px;
  padding: 10px 5px;
}
@media (min-width: 48em) {
  .pre-checkout-message__icon {
    padding: 0;
  }
}

.pre-checkout-message__message {
  margin: 0 10px;
  font-weight: var(--fw-bold);
  font-size: 12px;
}
@media (min-width: 48em) {
  .pre-checkout-message__message {
    margin: 20px 0;
    font-size: 15px;
  }
}

.pre-checkout-product {
  position: relative;
  display: grid;
  grid-template-columns: 25% 75%;
  padding: 10px;
  margin: 0 15px 26px;
  border-radius: var(--border-radius-default);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-bottom: 0;
  background: var(--color-white-true);
  gap: 5px 15px;
  grid-template-areas: "productImage claim" "productDetailMobile productDetailMobile" "orderInfo orderInfo";
}
@media (min-width: 48em) {
  .pre-checkout-product {
    background: var(--color-white-true);
    padding: 16px;
    margin: 0 0 26px;
    grid-gap: 15px;
    grid-template-areas: "productImage productDetail" "orderInfo orderInfo";
  }
}
@media (min-width: 75em) {
  .pre-checkout-product {
    grid-template-areas: "productImage productDetail" "productImage orderInfo";
  }
}

.pre-checkout-product__image-container {
  position: relative;
  grid-area: productImage;
  border-radius: var(--border-radius-default);
  overflow: hidden;
  aspect-ratio: 1/1;
  display: flex;
  align-items: stretch;
}

.pre-checkout-product__image {
  width: 100%;
  height: auto;
}

.pre-checkout-product__detail {
  padding-right: 10px;
}
@media (min-width: 48em) {
  .pre-checkout-product__detail {
    grid-area: productDetail;
  }
}

.pre-checkout-product__order-info {
  grid-area: orderInfo;
  align-self: end;
  margin-right: 20px;
}

.pre-checkout-product__detail-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 5px 10px 5px 0;
}

.pre-checkout-product__title {
  font-size: 16px;
  color: var(--theme-primary);
  font-weight: 700;
}

.pre-checkout-product__remove-button {
  grid-area: removeButton;
  cursor: pointer;
  font-size: var(--fs-h2);
  padding-left: 20px;
  line-height: 1;
}
.pre-checkout-product__remove-button:hover {
  color: var(--theme-primary);
}

.pre-checkout-product__description {
  grid-area: productDetailMobile;
  padding-right: 10px;
  font-size: 14px;
  text-align: start;
}
@media (min-width: 48em) {
  .pre-checkout-product__description {
    grid-area: description;
  }
}
.pre-checkout-product__description li {
  margin-bottom: 0;
}

.pre-checkout-product-description__preview--oversized {
  overflow-y: hidden;
}

.pre-checkout-product__price-info-container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 47.99em) {
  .pre-checkout-product__price-info-container {
    display: block;
  }
}
.pre-checkout-product__price-info-container .price-colored-sparset,
.pre-checkout-product__price-info-container .price-colored-sale {
  color: var(--color-red);
}

.pre-checkout-product__quantity-info {
  grid-area: quantityInfo;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.pre-checkout-product__price {
  align-self: flex-end;
}
.pre-checkout-product__price .price-box__old-price {
  color: var(--pre-checkout-product-old-price);
  margin-bottom: 10px;
}
.pre-checkout-product__price .price-box__price {
  color: var(--pre-checkout-product-price);
  font-size: 25px;
}

.pre-checkout-product__promotion {
  margin-bottom: 12px;
  padding: 3px 16px;
  border-radius: var(--border-radius-precheckout);
  font-weight: 600;
  font-size: 13px;
  display: none;
}
@media (min-width: 48em) {
  .pre-checkout-product__promotion {
    display: block;
  }
}

.pre-checkout-product__quantity {
  border: 1px solid var(--border-pre-checkout-product-select);
  margin-right: 0;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius-default);
}
.pre-checkout-product__quantity select {
  font-size: 16px;
  padding: 12px 50px 12px 24px;
}
.pre-checkout-product__quantity select:focus-visible {
  outline: 0;
}
.pre-checkout-product__quantity .select__arrow {
  font-size: 16px;
}

.pre-checkout-product__shipping-time {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: var(--fs-h5);
  font-weight: var(--fw-bold);
  color: var(--color-pre-checkout-product-shipping-time);
  margin: 10px 0 5px;
}

.pre-checkout-product__shipping-icon {
  display: inline-flex;
  margin-right: 12px;
  font-size: 32px;
  color: var(--color-pre-checkout-product-shipping-time);
}

.pre-checkout-product__badge-wrapper {
  display: none;
  width: 100%;
  position: absolute;
}
@media (min-width: 75em) {
  .pre-checkout-product__badge-wrapper {
    display: flex;
  }
}

.pre-checkout-product__badge-container--top {
  display: flex;
  flex-direction: row;
  width: inherit;
  position: absolute;
  align-items: flex-start;
  gap: 4px;
  justify-content: space-between;
}
.pre-checkout-product__badge-container--top :first-child {
  border-radius: var(--border-radius-default) 0 0 0;
}
.pre-checkout-product__badge-container--top :nth-child(2) {
  border-radius: 0 var(--border-radius-default) 0 0;
}

.pre-checkout-product__badge-container--bottom .catalog-badge {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 0;
}

.pre-checkout-product__cta-btn-cart {
  border-radius: var(--border-radius-default);
  padding: 11px;
  max-width: 140px;
  font-size: 18px;
  text-transform: unset;
  border: 1px solid var(--theme-primary);
  background: var(--color-white-true);
  color: var(--theme-primary);
}
@media (max-width: 74.99em) {
  .pre-checkout-product__cta-btn-cart {
    min-width: 0;
  }
}

.pre-checkout-product__product-details__wrapper {
  display: flex;
  flex-direction: column;
}

.pre-checkout-product__product-details__claim {
  margin-bottom: 8px;
}

.pre-checkout-product__product-details__usps {
  margin-bottom: 8px;
}
.pre-checkout-product__product-details__usps ::before {
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin-top: 2px;
}
.pre-checkout-product__product-details__usps a {
  color: inherit;
  text-decoration: underline;
}

.pre-checkout-product__product-details__deliverables {
  margin-bottom: 8px;
}

.pre-checkout-product__product-details__deliverables-text {
  font-weight: var(--fw-semibold);
}

.pre-checkout-product__product-details__cta-btn {
  border-radius: var(--border-radius-default);
  margin: 8px 0 12px;
  padding: 11px;
  width: 100%;
  font-size: 18px;
  align-self: center;
  text-transform: unset;
  border: 1px solid var(--theme-primary);
  background: var(--color-white-true);
  color: var(--theme-primary);
}
@media (max-width: 74.99em) {
  .pre-checkout-product__product-details__cta-btn {
    width: 50%;
    align-self: baseline;
  }
}

.pre-checkout-product__product-details__rating {
  align-self: baseline;
  margin-bottom: 8px;
  font-size: 16px;
}
.pre-checkout-product__product-details__rating .pre-checkout-product__product-details__review-count {
  font-size: 12px;
}

.price-box__price--rate {
  display: flex;
  flex-direction: column;
}
.price-box__price--rate .price-box__total-installment-price {
  font-family: var(--font-base);
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  margin-top: 6px;
  white-space: break-spaces;
  margin-bottom: 8px;
}
.price-box__price--rate .pre-checkout-product__price-box__total-installments-price {
  font-family: var(--font-base);
  font-size: var(--fs-xs);
  font-weight: var(--fw-normal);
  white-space: break-spaces;
}

.price-box__cheaper-set-box {
  display: flex;
  flex-direction: column;
  font-size: var(--fs-s);
  font-weight: 700;
}

.price-box__old-price {
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  color: var(--color-black-true);
}

.price-box__amount-text {
  font-weight: var(--fw-normal);
  font-family: var(--font-base);
}

.price-box__cheaper-set-quantity-advantage-text {
  font-family: var(--font-base);
  color: var(--color-black-true);
}

.price-box__unit-price {
  color: var(--color-black-true);
}

.price-box__price--club-price {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.price-box__price--club-price .catalog-badge {
  border-radius: var(--border-radius-badge);
}

.price-box__per-deliver-info {
  font-family: var(--font-base);
  font-size: var(--fs-base);
  font-weight: var(--fw-light);
  color: var(--color-black);
  margin-left: 5px;
}

/* stylelint-disable declaration-no-important */
/* stylelint-disable max-nesting-depth */
.product {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-default);
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
}
.product .loading-indicator::after, .product .async-component__inner::after {
  height: 14px;
  width: 14px;
}

.product:has(.catalog-badge--aktion, .catalog-badge--sparset, .catalog-badge--sale) {
  border: 2px solid var(--color-red);
  border-radius: var(--border-radius-product-card);
}

.product__product-top {
  position: relative;
}

.badge {
  border-radius: var(--border-radius-default);
  padding: 0 0.75rem;
  font-size: 12px;
}
.badge::after {
  clip-path: unset;
  background: none;
}

.price-colored-gratis {
  color: var(--bg-badge-highlight);
}

.price-colored-xplusy {
  color: var(--color-badge-xplusy-background);
}

.price-colored-savings,
.price-colored-savings-pdp {
  color: var(--color-red) !important;
}

.price-colored-ratensavings {
  color: var(--color-badge-savings-text);
}

.product-button__cta-btn {
  margin-top: 8px;
}

.product-button__cta-btn-details {
  border-radius: var(--border-radius-default);
  background: var(--background-product-buy-button);
  color: var(--color-product-buy-button);
  text-transform: unset;
  font-size: var(--fs-base);
  font-weight: var(--fw-normal);
  letter-spacing: normal;
  padding: 10px 4px;
  min-width: unset;
}
@media (max-width: 47.99em) {
  .product-button__cta-btn-details {
    min-width: unset;
    font-size: var(--fs-s);
  }
}

.product-button__cta-btn-cart {
  background: var(--background-product-buy-button);
  border-radius: var(--border-radius-default);
  /* stylelint-disable-next-line selector-class-pattern */
}
@media (min-width: 48em) {
  .product-button__cta-btn-cart {
    display: flex;
  }
}
.product-button__cta-btn-cart .select-button__button {
  color: var(--color-product-buy-button);
  text-transform: unset;
  font-size: var(--fs-s);
  /* stylelint-disable-next-line selector-class-pattern */
}
@media (min-width: 48em) {
  .product-button__cta-btn-cart .select-button__button {
    font-size: var(--fs-base);
  }
}
.product-button__cta-btn-cart .select-button__button .icon--AddToCart {
  font-size: 24px;
}
.product-button__cta-btn-cart .icon--ArrowRightLight {
  display: none;
}
.product-button__cta-btn-cart .select-button__select {
  display: none;
}

.product-data__details-container {
  display: flex;
  flex-direction: column;
  min-height: 270px;
  height: 100%;
  padding: 8px 12px;
}
@media (min-width: 48em) {
  .product-data__details-container {
    min-height: 285px;
    padding: 16px;
  }
}
.product-data__details-container .product-data__old-price + .product-data__price {
  margin-top: -8px;
}

.product-data__reviews-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
}

.product-data__review-count {
  color: var(--color-star-text);
  margin-left: 4px;
  font-size: 12px;
  margin-top: 2px;
}

.product-data__delivery {
  font-size: 12px;
  font-weight: var(--fw-normal);
  display: flex;
  align-items: center;
  margin: 4px 0;
  word-wrap: break-word;
}
@media (max-width: 25em) {
  .product-data__delivery .product-data__delivery-days::before {
    content: "\a";
    white-space: pre-wrap;
  }
}
.product-data__delivery .product-data__delivery-days {
  color: var(--theme-success);
}
@media (max-width: 47.99em) {
  .product-data__delivery {
    align-items: flex-end;
  }
}
.product-data__delivery .product-data__icon {
  min-width: 13px;
  height: 13px;
  color: var(--color-white-true);
  background: var(--color-green-500);
  border-radius: 50%;
  padding: 3px;
  font-size: 13px;
  margin-left: 4px;
}

.product-data__details {
  margin-top: auto;
}

.product-data__infos {
  margin-top: auto;
}

.product-data__price {
  font-family: var(--font-product-price);
  font-weight: 500;
  font-size: 20px;
  color: var(--theme-dark);
}
.product-data__price--sale {
  color: var(--color-red);
}
@media (min-width: 48em) {
  .product-data__price {
    font-size: 23px;
  }
}

.product-data__marketing {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 23px;
}
@media (max-width: 23.125em) {
  .product-data__marketing {
    white-space: pre-wrap;
    text-align: left;
  }
}
.product-data__marketing--variants .catalog-badge {
  margin-bottom: 8px;
}
@media (max-width: 25em) {
  .product-data__marketing--variants .catalog-badge {
    white-space: pre-wrap;
  }
}
@media (min-width: 75em) and (max-width: 81.24em) {
  .product-data__marketing--variants .catalog-badge {
    white-space: pre-wrap;
  }
}
.product-data__marketing .catalog-badge {
  margin-bottom: 8px;
  text-align: left;
}

@media (min-width: 48em) {
  .product-data__details-container--has-delivery {
    padding: 8px 16px 16px;
  }
}

.product-description__title {
  margin-bottom: 4px;
}
@media (max-width: 47.99em) {
  .product-description__title {
    word-break: break-word;
  }
}
.product-description__title.product-description__title--default {
  color: var(--color-product-title);
}
.product-description__title a {
  font-family: var(--font-base);
  font-weight: var(--fw-semibold);
  color: var(--color-product-title);
  font-size: var(--fs-product-title-desktop);
  line-height: var(--lh-small);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
.product-description__title--lines-3 a {
  -webkit-line-clamp: 3;
}
.product-description__title--lines-4 a {
  -webkit-line-clamp: 4;
}

.product-description__description {
  height: auto;
  font-size: var(--fs-product-description-mobile);
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin-bottom: 8px;
  font-family: var(--ff-base);
}
.product-description__description--lines-3 {
  -webkit-line-clamp: 3;
}
.product-description__description--lines-4 {
  -webkit-line-clamp: 4;
}
.product-description__description--lines-5 {
  -webkit-line-clamp: 5;
}
@media (min-width: 48em) {
  .product-description__description {
    word-break: unset;
    font-size: var(--fs-product-description-desktop);
  }
}
.product-description__description p {
  margin: 0;
}

/* stylelint-disable max-nesting-depth */
@media (min-width: 75em) {
  .product-image--hover-effect-zoom :hover .product-image__image-container__image {
    transform: scale(1.05) translateY(-2.4%);
  }
}
@media (min-width: 75em) {
  .product-image--hover-effect-flip :hover .product-image__image-container__image--initial-opacity-1 {
    opacity: 0;
  }
  .product-image--hover-effect-flip :hover .product-image__image-container__image--initial-opacity-0 {
    opacity: 1;
  }
}

.product-image__image-container {
  position: relative;
  height: 0;
  padding-bottom: 100%;
}
.product-image__image-container--hover-effect-flip img {
  transition: opacity 400ms ease;
}

.product-image__image-container__image {
  position: absolute;
  object-fit: cover;
  height: 100%;
  border-radius: var(--border-radius-product-image-container);
  transform: scale(1);
  transition: transform 0.3s ease;
}
.product-image__image-container__image--initial-opacity-1 {
  opacity: 1;
}
.product-image__image-container__image--initial-opacity-0 {
  opacity: 0;
}

.product-image__image-container__image-rounded .product-image__image {
  border-radius: var(--border-radius-default);
}

.product-image__badge-wrapper {
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.product-image__badge-wrapper .catalog-badge--corner-bottom-right {
  line-height: 15px;
  padding: 1px 10px;
  word-break: break-word;
}

.product-image__badge-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-image__badge-container--bottom {
  margin-top: auto;
  align-items: flex-end;
}
.product-image__badge-container--bottom .catalog-badge {
  max-width: 75%;
}

.product-image__badge-container--top {
  align-items: flex-start;
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.product-image__badge-container--top :first-child {
  border-radius: var(--border-radius-default-inner) 0 0 0;
}
.product-image__badge-container--top :nth-child(2) {
  border-radius: 0 var(--border-radius-default-inner) 0 0;
}

.component-details-description__text {
  font-size: var(--fs-base);
  color: var(--color-black-true);
  margin-block-start: 5px;
}

.component-details-description__headlines {
  color: var(--color-headline);
}

.component-details-description__container-number {
  background: var(--bg-component-description-box);
  color: var(--color-component-description-box);
  font-size: var(--fs-l);
  font-weight: var(--fw-medium);
  margin: 0 10px 42px 0;
  height: 30px;
  text-align: center;
  min-width: 30px;
}

.component-details-description__article--disabled {
  opacity: 0.5;
}

.component-details-description__article--active {
  background-color: var(--theme-tertiary);
}

.component-details-description__article {
  transition: 0.3s;
  display: flex;
  padding: 8px;
  margin: 8px 0;
  cursor: pointer;
}

.product-component-details {
  margin: 32px 0;
}

.product-component-details__container {
  margin: 30px 15px;
}
@media (min-width: 48em) {
  .product-component-details__container {
    margin: 30px 0;
    display: grid;
    grid-template-columns: 30% 40% 30%;
    gap: 30px;
    justify-content: center;
    align-items: center;
  }
}

.product-component-details__image-container {
  padding: 0 15px;
}

.product-component-details__image {
  margin: auto;
  width: auto;
}

@media (min-width: 75em) {
  .product-component-details-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.product-component-details__image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  flex: 2 1 0;
}

.product-component-details__image-wrapper .marker {
  background-color: var(--theme-primary);
  position: absolute;
  cursor: pointer;
  text-align: center;
  color: #fff;
  z-index: 2;
  user-select: none;
}
.product-component-details__image-wrapper .marker--selected {
  background: var(--theme-tertiary);
  color: var(--theme-cta-font-color);
}

.product-component-details__image-wrapper img {
  width: 100%;
  height: auto;
  z-index: 1;
}

.product-component-details__image-wrapper .tooltip-hide {
  opacity: 0;
  position: absolute;
}

.product-component-details__image-wrapper .tooltip {
  display: flex;
  opacity: 100%;
  transition: opacity 0.3s ease-in-out;
  background-color: #fff;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 162px;
  gap: 10px;
  position: absolute;
  border-radius: var(--border-radius-default);
  text-align: center;
  padding: 15px 0;
  z-index: 3;
}
.product-component-details__image-wrapper .tooltip a {
  text-decoration: underline;
  text-underline-offset: 5px;
  color: #008bd2;
}
.product-component-details__image-wrapper .tooltip h4 {
  color: #008bd2;
}

.product-details-content__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
@media (max-width: 47.99em) {
  .product-details-content__wrapper {
    padding: 8px;
  }
}

.product-details-content__product-number {
  font-size: 16px;
}

.product-details-content__description {
  overflow: hidden;
  margin-bottom: 8px;
}

.product-details-content__description-item {
  margin-left: 0;
  margin-bottom: 4px;
}
.product-details-content__description-item::before {
  border-radius: 100%;
  width: 6px;
  height: 6px;
  top: 10px;
}
.product-details-content__description-item h3 {
  font-size: 16px;
  font-weight: 300;
  /* stylelint-disable-next-line declaration-no-important */
  font-family: var(--font-base) !important;
}
.product-details-content__description-item a {
  border-bottom: 1px solid;
  text-decoration: none;
  color: inherit;
}

.product-details-content__show-more-btn {
  color: var(--color-gray-800);
  border: none;
  text-decoration: none;
  border-bottom: 1px solid var(--color-black);
  margin-right: auto;
  background-color: unset;
  cursor: pointer;
  padding: 0;
  text-align: left;
}

.product-details-content__price-container {
  display: flex;
  flex-flow: column wrap;
  font-family: var(--font-product-price);
}

.product-details-content__price-container-price {
  display: grid;
  grid-template-areas: "uvp uvp" "price badge" "shipment shipment";
  column-gap: 10px;
  width: fit-content;
}

.product-details-content__price-wrapper {
  grid-area: price;
}

.product-details-content__price-container-badge {
  grid-area: badge;
  flex-direction: column;
  display: flex;
  font-family: var(--font-base);
  width: fit-content;
  justify-content: center;
  margin-top: 4px;
}

.product-details-content__pay-once {
  width: fit-content;
  font-family: var(--font-base);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  white-space: pre;
  text-decoration: none;
  border-bottom: 1px solid;
  border-bottom-color: inherit;
  color: var(--color-badge-raten-background);
}
.product-details-content__pay-once .icon {
  margin-left: 4px;
}

.product-details-content__pay-once--clickable {
  cursor: pointer;
}

.product-details-content__price-container-original {
  grid-area: uvp;
  font-family: var(--font-base);
  margin-bottom: 0;
}

.product-details-content__price {
  font-size: 30px;
  font-weight: var(--fw-bold);
}
.product-details-content__price--sale {
  color: var(--color-red);
}

.product-details-content__price-default {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-details-content__shipment {
  grid-area: shipment;
  font-size: 16px;
  font-weight: var(--fw-medium);
  font-family: var(--font-base);
  color: var(--color-gray-500);
}

.product-details-content__buy {
  position: relative;
}
@media (min-width: 48em) {
  .product-details-content__buy {
    padding-top: 0;
  }
}

@media (min-width: 48em) {
  .product-details-content__buy--with-select {
    display: grid;
    grid-template-areas: "select buy";
    grid-template-columns: 100%;
  }
}
@media (min-width: 75em) {
  .product-details-content__buy--with-select {
    grid-template-columns: 120px calc(100% - 120px);
  }
}
.product-details-content__buy--with-select .product-details-content__buy-button {
  margin-left: 100px;
}
@media (min-width: 75em) {
  .product-details-content__buy--with-select .product-details-content__buy-button {
    margin-left: 0;
  }
}

.product-details-content__buy-count-select {
  grid-area: select;
  position: absolute;
  top: 0;
  background: var(--color-white-true);
  border: 1px solid var(--color-black-true);
  border-radius: 20px;
  padding: 10px 20px;
  width: 90px;
  margin-right: 25px;
  height: fit-content;
  align-self: end;
}

.product-details-content__buy-button {
  display: flex;
  background: var(--pdp-background-buy-button);
  border-radius: var(--border-radius-default);
  color: var(--pdp-color-buy-button);
  height: 54px;
}
@media (min-width: 75em) {
  .product-details-content__buy-button {
    grid-area: buy;
    height: 54px;
  }
}
.product-details-content__buy-button .select__select {
  border: 1px solid var(--color-black-true);
  border-radius: var(--border-radius-default);
  padding: 10px 20px;
}
@media (min-width: 75em) {
  .product-details-content__buy-button .select__select {
    padding: 0 60px 0 30px;
  }
}
.product-details-content__buy-button .select__arrow {
  font-size: 18px;
}
.product-details-content__buy-button .select,
.product-details-content__buy-button .select-button__select {
  position: absolute;
  left: 0;
}
.product-details-content__buy-button .select-button__button {
  color: var(--pdp-color-buy-button);
  text-transform: inherit;
  width: 200px;
}
@media (min-width: 75em) {
  .product-details-content__buy-button .select-button__button {
    width: auto;
  }
}

.product-details-content__hints {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.product-details-content__price-hint {
  font-size: 13px;
  color: var(--color-gray-500);
  font-weight: var(--fw-light);
}

.product-details-content__price-hint-link {
  text-decoration: none;
  border-bottom: 1px solid var(--color-gray-500);
  cursor: pointer;
}

.product-details-content__shipping-icon {
  margin-right: 20px;
  color: var(--color-black-true);
  font-size: 35px;
}

.product-details-content__delivery {
  display: flex;
  align-items: center;
  word-wrap: break-word;
}
@media (max-width: 25em) {
  .product-details-content__delivery .product-details-content__delivery-days::before {
    content: "\a";
    white-space: pre-wrap;
  }
}
.product-details-content__delivery .product-details-content__delivery-days {
  color: var(--bg-button-success);
}
@media (max-width: 47.99em) {
  .product-details-content__delivery {
    align-items: flex-end;
  }
}
.product-details-content__delivery .product-details-content__icon {
  min-width: 13px;
  height: 13px;
  color: var(--color-white-true);
  background: var(--color-green-500);
  border-radius: 50%;
  padding: 3px;
  font-size: 13px;
  margin-left: 4px;
}
.product-details-content__delivery .product-details-content__shipping-checkmark {
  min-width: 13px;
  height: 13px;
  color: var(--color-white-true);
  background: var(--color-green-500);
  border-radius: 50%;
  padding: 3px;
  font-size: 13px;
  margin-left: 4px;
  position: relative;
  top: 2px;
}

.product-details-content__variant-switcher {
  margin-top: 20px;
}
@media (min-width: 48em) {
  .product-details-content__variant-switcher {
    margin-top: 0;
  }
}

.product-details-content__delivery-text {
  font-size: 16px;
  font-weight: var(--fw-medium);
}
.product-details-content__delivery-text.is--green {
  color: var(--bg-button-success);
  display: inline-block;
}

.product-details-content__cheaper-set-box {
  background: #fff;
  position: relative;
  border: 1px solid var(--color-gray-80);
  margin-top: 20px;
  padding: 15px;
}
@media (min-width: 48em) {
  .product-details-content__cheaper-set-box {
    width: fit-content;
  }
}

.product-details-content__cheaper-set-triangle {
  background: #fff;
  border: solid var(--color-gray-80);
  border-width: 1px 1px 0 0;
  transform: rotate(-45deg);
  position: absolute;
  left: 47px;
  top: -9px;
  width: 15px;
  height: 15px;
}

.product-details-content__cheaper-set-price {
  font-weight: 600;
}

.product-details-content__cheaper-set-text {
  font-size: 22px;
  display: flex;
}
@media (max-width: 74.99em) {
  .product-details-content__cheaper-set-text {
    flex-direction: column;
  }
}

.product-details-content__cheaper-set-quantity-advantage-text {
  font-weight: 700;
}

.product-details-content__product-unavailable {
  margin: 4px 0 12px;
}
.product-details-content__product-unavailable .product-details-content__product-unavailable-headline {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: var(--fw-semibold);
}
.product-details-content__product-unavailable .product-details-content__product-unavailable-indicator {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  background-color: var(--color-red);
}

.product-details-header__title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-details-header__title {
  color: var(--pdp-color-product-title);
  margin-bottom: 12px;
}

.product-details-header__subtitle {
  margin-bottom: 10px;
}
.product-details-header__subtitle h2 {
  font-size: 18px;
}

.product-details-header__ratings-holder {
  display: flex;
  align-items: center;
  margin: 8px 0 16px;
  cursor: pointer;
}

.product-details-header__rating {
  margin-right: 10px;
}
.product-details-header__rating .star-rating__star {
  font-size: 1.5em;
}

.product-details-header__review-count {
  margin-left: 8px;
  border-bottom: 1px solid var(--color-black);
}

.product-subscription-options__radio-group {
  position: relative;
  display: flex;
  flex-direction: column;
}
@media (min-width: 75em) {
  .product-subscription-options__radio-group {
    flex-direction: row;
  }
}
.product-subscription-options__radio-group .radio-button {
  margin-bottom: 20px;
}
.product-subscription-options__radio-group .radio-button__text {
  font-size: 18px;
}

.product-subscription-options__abo-info-text {
  position: absolute;
  color: var(--pdp-abo-info-color);
  font-size: var(--fs-s);
}

.product-subscription-options__select {
  border: 1px solid rgba(0, 0, 0, 0.3);
  margin: 20px 0;
}

.product-subscription-options__interval-error {
  color: var(--theme-error);
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.product-subscription-options__attention-icon {
  margin-right: 5px;
}

.product-details-gallery {
  display: block;
}
@media (min-width: 48em) {
  .product-details-gallery {
    position: relative;
  }
}
.product-details-gallery .swiper-wrapper {
  width: inherit;
  padding: 0;
  aspect-ratio: 1/1;
}
.product-details-gallery .arrow-button {
  display: none;
  color: var(--pdp-slider-arrow-color);
  background: var(--pdp-slider-arrow-background);
  z-index: 1;
  bottom: 45%;
}
@media (min-width: 48em) {
  .product-details-gallery .arrow-button {
    display: flex;
  }
}
.product-details-gallery .arrow-button--left {
  left: 8px;
}
.product-details-gallery .arrow-button--right {
  right: 8px;
}

.product-details-gallery__image {
  height: 100%;
}
.product-details-gallery__image:focus-visible {
  outline: none;
}

.product-details-gallery__pagination {
  display: flex;
  width: 100%;
  position: absolute;
  top: 100%;
  flex-wrap: wrap;
  text-align: center;
  margin: 4px 0;
}
@media (min-width: 48em) {
  .product-details-gallery__pagination {
    display: none;
  }
}
.product-details-gallery__pagination .slider__pagination-bullet {
  margin: 4px;
}

.product-details-gallery__slider {
  margin: 0;
}
.product-details-gallery__slider .slider__inner-wrapper {
  margin: 0;
}
.product-details-gallery__slider .swiper-slide {
  text-align: center;
}

/* stylelint-disable declaration-no-important */
.product-details-thumbnails-gallery--wrapper {
  grid-area: thumbnails;
  display: none;
  height: 350px;
  position: sticky;
  top: 30px;
}
@media (min-width: 81.25em) {
  .product-details-thumbnails-gallery--wrapper {
    height: 514px;
  }
}
@media (min-width: 75em) {
  .product-details-thumbnails-gallery--wrapper {
    display: block;
  }
}
.product-details-thumbnails-gallery--wrapper .product-details__image:focus-visible {
  outline: none;
}
.product-details-thumbnails-gallery--wrapper .product-details__image:hover {
  cursor: pointer;
}
.product-details-thumbnails-gallery--wrapper .arrow-button {
  padding: 5px 0;
  line-height: 15px;
  color: var(--theme-primary);
  background: none;
  border: none;
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 30px;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
}
.product-details-thumbnails-gallery--wrapper .arrow-button--navigation-disabled {
  display: block !important;
  cursor: auto;
}
.product-details-thumbnails-gallery--wrapper .arrow-button--navigation-disabled svg {
  color: transparent;
}
.product-details-thumbnails-gallery--wrapper .swiper {
  z-index: 0;
}
.product-details-thumbnails-gallery--wrapper .swiper-wrapper {
  width: inherit;
  padding: 0;
}
.product-details-thumbnails-gallery--wrapper .swiper-slide {
  height: 55px !important;
  width: 55px;
  display: flex;
  justify-content: center;
}
@media (min-width: 81.25em) {
  .product-details-thumbnails-gallery--wrapper .swiper-slide {
    height: 85px !important;
    width: 85px !important;
  }
}
.product-details-thumbnails-gallery--wrapper .swiper-slide-thumb-active {
  border: 2px solid var(--pdp-selected-image-border-color);
}

.product-details-thumbnails-gallery--video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 57px;
  width: 57px;
  background: var(--pdp-video-thumbnail-background);
  cursor: pointer;
}
@media (min-width: 81.25em) {
  .product-details-thumbnails-gallery--video-wrapper {
    height: 87px;
    width: 87px;
  }
}
.product-details-thumbnails-gallery--video-wrapper .product-details-thumbnails-gallery__playbutton {
  color: var(--pdp-video-thumbnail-icon);
  font-size: 18px;
  background: #fff;
  border-radius: 50%;
}
@media (min-width: 81.25em) {
  .product-details-thumbnails-gallery--video-wrapper .product-details-thumbnails-gallery__playbutton {
    font-size: 36px;
  }
}
.product-details-thumbnails-gallery--video-wrapper .product-details-thumbnails-gallery__videotext {
  position: relative;
  top: 6px;
  font-size: var(--fs-s);
  font-weight: 400;
}

.product-details-thumbnails-gallery__thumbnail-container {
  aspect-ratio: 1/1;
  position: relative;
}
.product-details-thumbnails-gallery__thumbnail-container img {
  object-fit: cover;
  height: 100%;
}

.product-details-thumbnails-gallery__image {
  cursor: pointer;
  height: 100%;
}

.product-details-thumbnails-gallery__slider {
  height: 300px;
  margin: 0;
}
@media (min-width: 81.25em) {
  .product-details-thumbnails-gallery__slider {
    height: 418px;
  }
}
.product-details-thumbnails-gallery__slider .slider__inner-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0;
}
.product-details-thumbnails-gallery__slider .arrow-button--top {
  order: 1;
}
.product-details-thumbnails-gallery__slider .product-details-thumbnails-gallery__swiper {
  order: 2;
}
.product-details-thumbnails-gallery__slider .arrow-button--bottom {
  order: 3;
}

body:has(.content-navigation) .product-details-thumbnails-gallery--wrapper {
  top: 72px;
}

.product-details--wrapper {
  display: block;
  margin: 30px 0;
}
@media (min-width: 48em) {
  .product-details--wrapper {
    display: grid;
    grid-template-columns: 350px auto;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    grid-template-areas: "slider content" ". content";
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    grid-gap: 20px;
  }
}
@media (min-width: 75em) {
  .product-details--wrapper {
    grid-template-columns: 57px 350px auto;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    grid-template-areas: "thumbnails slider content" ". . content";
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */
    padding: 0 16px;
  }
}
@media (min-width: 81.25em) {
  .product-details--wrapper {
    grid-template-columns: 87px 500px auto;
    /* stylelint-disable declaration-block-no-redundant-longhand-properties */
    grid-template-areas: "thumbnails slider content" ". . content";
    /* stylelint-enable declaration-block-no-redundant-longhand-properties */
  }
}

.product-details--content-wrapper {
  grid-area: content;
  position: relative;
  padding-left: 8px;
}
@media (max-width: 47.99em) {
  .product-details--content-wrapper {
    padding-top: 20px;
    padding-right: 8px;
  }
}

@media (min-width: 48em) {
  .product-details--slider-container {
    position: relative;
  }
}

.product-details--slider-container:has(.catalog-badge--aktion, .catalog-badge--sparset, .catalog-badge--sale) {
  border: 2px solid var(--color-red);
  line-height: 9px;
}
.product-details--slider-container:has(.catalog-badge--aktion, .catalog-badge--sparset, .catalog-badge--sale) .catalog-badge--gratis {
  border-bottom: none;
}

.product-details--blue-box {
  min-height: 58px;
  text-align: center;
  color: var(--pdp-color-caption);
  background: var(--pdp-background-caption);
  font-family: var(--ff-menu);
  font-size: var(--fs-17);
  font-weight: var(--fw-normal);
  line-height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
}
@media (max-width: 47.99em) {
  .product-details--blue-box {
    min-height: 84px;
  }
}

.product-details__videomodal .modal__body {
  position: relative;
  padding: 8px;
}
@media (min-width: 48em) {
  .product-details__videomodal .modal__body {
    padding: 46px;
  }
}
.product-details__videomodal .modal__close {
  z-index: 10;
}
@media (max-width: 74.99em) {
  .product-details__videomodal .modal__close {
    position: absolute;
    top: -33px;
    right: 0;
    background: var(--color-white-true);
    padding: 6px;
  }
}
@media (max-width: 74.99em) and (orientation: landscape) {
  .product-details__videomodal .modal__close {
    right: -26px;
    top: 0;
  }
}

.product-details--video-wrapper-mobile {
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85px;
  width: 85px;
  background: var(--pdp-video-thumbnail-background);
}
.product-details--video-wrapper-mobile--badges {
  top: 26px;
}
@media (min-width: 75em) {
  .product-details--video-wrapper-mobile {
    display: none;
  }
}
.product-details--video-wrapper-mobile .product-details__playbutton {
  color: var(--pdp-video-thumbnail-icon);
  font-size: 36px;
  background: #fff;
  border-radius: 50%;
}
.product-details--video-wrapper-mobile .product-details__videotext {
  position: relative;
  top: 6px;
  font-size: var(--fs-xs);
  font-weight: 400;
}

.product-details--video-wrapper-mobile-hidden {
  display: none;
}

/* stylelint-disable selector-max-id */
#product-details--image-portal {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background: var(--color-white-true);
}
#product-details--image-portal img {
  height: 100%;
}

.product-details__badge-wrapper {
  display: flex;
  flex-direction: column;
  top: 0;
  width: 100%;
  position: absolute;
  aspect-ratio: 1/1;
  pointer-events: none;
}

.product-details__badge-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.product-details__badge-container--bottom {
  margin-top: auto;
  align-items: flex-end;
}
.product-details__badge-container--bottom :first-child {
  z-index: 1;
  border-radius: 0;
}

.product-details__badge-container--top {
  align-items: flex-start;
  z-index: 1;
  gap: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.product-details__badge-container--top :first-child {
  border-radius: 0;
}
.product-details__badge-container--top :nth-child(2) {
  border-radius: 0;
}

.product-details__mobile-header {
  padding: 0 16px;
  margin-bottom: 20px;
}

.product-details--slider-wrapper {
  grid-area: slider;
  position: relative;
  margin-bottom: 30px;
}
@media (min-width: 48em) {
  .product-details--slider-wrapper {
    position: sticky;
    top: 30px;
  }
}
.product-details--slider-wrapper .product-details--blue-box {
  display: none;
}
.product-details--slider-wrapper--has-usp .product-details--blue-box {
  display: flex;
}
@media (min-width: 48em) {
  .product-details--slider-wrapper {
    margin-bottom: 0;
  }
}

.product-details__pagination {
  text-align: center;
}

@media (min-width: 48em) {
  body:has(.content-navigation) .product-details--slider-wrapper {
    top: 72px;
  }
}

/* stylelint-enable selector-max-id */
body:has(.sticky-add-to-cart-banner) {
  margin-bottom: 104px;
}
@media (min-width: 48em) {
  body:has(.sticky-add-to-cart-banner) {
    margin-bottom: 86px;
  }
}

.sticky-add-to-cart-banner {
  position: fixed;
  left: 0;
  right: 0;
  z-index: var(--zi-sticky-bar);
  background: var(--color-white-true);
  transition: all 0.3s ease-out;
  bottom: -105px;
}
@media (min-width: 48em) {
  .sticky-add-to-cart-banner {
    bottom: -87px;
  }
}
.sticky-add-to-cart-banner--active {
  bottom: 0;
  box-shadow: 0 -2px 20px var(--color-gray-900-20);
}

.sticky-add-to-cart-banner__wrapper {
  height: 104px;
  padding: 10px 20px;
}
@media (min-width: 48em) {
  .sticky-add-to-cart-banner__wrapper {
    display: grid;
    place-items: center;
    height: 86px;
    grid-template-areas: "image description button";
    grid-template-columns: 58px auto 330px;
  }
}

.sticky-add-to-cart-banner__image {
  display: none;
  grid-area: image;
}
@media (min-width: 48em) {
  .sticky-add-to-cart-banner__image {
    display: block;
  }
}
.sticky-add-to-cart-banner__image img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  padding: 1px;
  border: 1px solid var(--color-gray-80);
}

.sticky-add-to-cart-banner__content {
  grid-area: description;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 8px;
}
@media (min-width: 48em) {
  .sticky-add-to-cart-banner__content {
    flex-direction: column;
    padding: 0 40px 0 18px;
    justify-content: normal;
    gap: 0;
    margin-bottom: 0;
  }
}

@media (max-width: 47.99em) {
  .sticky-add-to-cart-banner__title-wrapper {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
  }
}

.sticky-add-to-cart-banner__title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 2;
  font-size: 12px;
  color: #777;
  font-weight: var(--fw-normal);
}
@media (min-width: 48em) {
  .sticky-add-to-cart-banner__title {
    -webkit-line-clamp: 1;
    font-size: 16px;
    font-weight: var(--fw-light);
  }
}

.sticky-add-to-cart-banner__price {
  font-family: var(--font-product-price);
  font-size: 20px;
  font-weight: var(--fw-semibold);
}
.sticky-add-to-cart-banner__price--sale {
  color: var(--color-red);
}

.sticky-add-to-cart-banner__button-wrapper {
  grid-area: button;
  width: 100%;
}

.sticky-add-to-cart-banner__buy-button {
  background: var(--pdp-background-buy-button);
  border-radius: var(--border-radius-default);
}
.sticky-add-to-cart-banner__buy-button .select-button__button {
  display: flex;
  font-size: 16px;
  height: 38px;
  color: var(--pdp-color-buy-button);
  text-transform: none;
}
@media (min-width: 48em) {
  .sticky-add-to-cart-banner__buy-button .select-button__button {
    height: 46px;
    font-size: 20px;
  }
}

.product-extra-info {
  padding: 40px 0;
}

.product-extra-info .headlines__headline {
  color: var(--theme-attention);
}

.product-extra-info__technical-data-wrapper {
  display: grid;
}

@media (min-width: 48em) {
  .product-extra-info__technical-data-wrapper--two-col {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
}

.product-extra-info-table {
  border-collapse: collapse;
}

.product-extra-info-table__row:nth-child(odd) {
  background-color: var(--color-gray-300);
}

.product-extra-info-table__data-label {
  text-align: left;
  padding: 8px;
  width: 50%;
  overflow: hidden;
}

.product-extra-info-table__data-value {
  text-align: left;
  padding: 8px;
  width: 50%;
  overflow: hidden;
}

.product-grid--container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 24px 12px;
  grid-auto-rows: minmax 1fr, 1fr;
  padding: 24px 12px 30px;
  grid-auto-flow: dense;
}
@media (min-width: 48em) {
  .product-grid--container {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 24px;
    grid-auto-rows: minmax 1fr, max-content;
  }
}
@media (min-width: 75em) {
  .product-grid--container {
    padding: 30px 0;
    grid-template-columns: repeat(5, 1fr);
  }
  .product-grid--container--col-3 {
    grid-template-columns: repeat(3, 1fr);
  }
  .product-grid--container--col-4 {
    grid-template-columns: repeat(4, 1fr);
  }
  .product-grid--container--col-5 {
    grid-template-columns: repeat(5, 1fr);
  }
}
.product-grid--container .product-grid__product-element {
  border-radius: var(--border-radius-default);
}
.product-grid--container .product-grid__content-container {
  height: 100%;
}
.product-grid--container .product-grid__special-deal {
  grid-column: span 2;
}

.product-grid__color-wrapper {
  background-color: var(--theme-beige);
}

.category-above-the-fold-text__anchor-link {
  cursor: pointer;
  text-decoration: underline;
}

.category-above-the-fold-text a {
  color: var(--color-black);
  text-decoration: underline;
}

.category-below-the-fold-text a {
  color: var(--color-black);
  text-decoration: underline;
}

.product-grid-category__wrapper {
  position: relative;
  padding-top: 20px;
}

.product-grid-category__headlines {
  padding: 0;
}
.product-grid-category__headlines > .headlines__headline {
  color: var(--plp-headline-color);
}

.product-grid-category__filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 12px;
  gap: 20px;
}
@media (max-width: 47.99em) {
  .product-grid-category__filter-header {
    flex-direction: column-reverse;
  }
}
@media (min-width: 75em) {
  .product-grid-category__filter-header {
    padding: 20px 0;
  }
}
.product-grid-category__filter-header > span {
  font-size: 18px;
}

.product-grid-category__product-amount {
  font-size: var(--fs-main-navigation-desktop);
  font-weight: inherit;
}

.product-grid-category__no-products {
  font-size: var(--fs-main-navigation-desktop);
  font-weight: inherit;
  box-shadow: var(--box-shadow-default);
  border-radius: var(--border-radius-default);
  padding: 20px 50px;
  margin: auto;
  color: var(--color-text);
  background: var(--color-white-true);
}

.product-grid-category__pagination-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 30px;
}

.product-grid-category__above-the-fold {
  margin: 0;
  padding: 0 12px 10px;
}
@media (min-width: 75em) {
  .product-grid-category__above-the-fold {
    padding: 0 0 10px;
  }
}

.product-grid-category__above-the-fold--with-title {
  padding: 20px 12px 10px;
}

.product-grid-category__below-the-fold {
  margin: 0;
  padding: 20px 12px 10px;
}
@media (min-width: 75em) {
  .product-grid-category__below-the-fold {
    padding: 20px 0 10px;
  }
}

.product-grid-category-sort__select-box > div:first-of-type {
  margin: 0;
  padding: 0;
  border: 1px solid var(--theme-primary);
  border-radius: var(--border-radius-default);
  background: var(--color-white-true);
  height: 46px;
}
.product-grid-category-sort__select-box:has(.select-box__options) > div:first-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.product-grid-category-sort__select-box > .select-box__options {
  border: 1px solid var(--theme-primary);
  border-top: none;
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
}
.product-grid-category-sort__select-box .select-box__selected-value-icon {
  border-color: var(--theme-primary);
  height: 30px;
  width: 40px;
}

.product-grid-exit-page {
  padding-bottom: 30px;
}

.product-grid-search__wrapper {
  padding-bottom: 50px;
  height: 100%;
}

.product-grid-search__headlines .headlines__headline {
  color: var(--plp-headline-color);
}

.product-grid-search__pagination-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 0 24px;
}

.product-grid-standalone__headlines + .product-grid--container {
  padding-top: 0;
}

.product-grid-standalone__pagination-container {
  padding-bottom: 30px;
}

.product-slider {
  padding-top: 30px;
  padding-bottom: 24px;
  font-family: var(--ff-menu);
}
.product-slider:has(.headlines) {
  padding-top: 10px;
}
@media (min-width: 75em) {
  .product-slider {
    padding: 30px 0 24px;
  }
}
@media (min-width: 75em) and (max-width: 68.75em) {
  .product-slider {
    padding: 30px 55px 24px;
  }
}
@media (min-width: 81.25em) and (max-width: 88.4375em) {
  .product-slider {
    padding: 30px 55px 24px;
  }
}
.product-slider .slider-navigation-item--dot {
  border-radius: 50px;
}
.product-slider .headlines {
  padding: 20px;
}
@media (min-width: 75em) {
  .product-slider .headlines {
    padding: 20px 0;
  }
}
.product-slider .headlines__headline {
  color: var(--color-headline);
  line-height: var(--lh-small);
}
@media (min-width: 48em) {
  .product-slider .headlines__headline {
    line-height: var(--lh-small);
  }
}
.product-slider .headlines__subheadline {
  line-height: var(--lh-small);
}
@media (min-width: 48em) {
  .product-slider .headlines__subheadline {
    line-height: var(--lh-small);
  }
}
.product-slider .arrow-button {
  display: none;
}
@media (min-width: 75em) {
  .product-slider .arrow-button {
    display: flex;
  }
}
@media (min-width: 75em) {
  .product-slider .arrow-button--left {
    left: -54px;
  }
}
@media (min-width: 75em) {
  .product-slider .arrow-button--right {
    right: -54px;
  }
}
.product-slider .product-slider__content-container {
  height: 100%;
  position: relative;
}
.product-slider .product__image-container__image {
  margin-top: auto;
}
@media (max-width: 47.99em) {
  .product-slider .swiper-wrapper .swiper-slide {
    height: auto;
  }
}
@media (max-width: 47.99em) {
  .product-slider .product-slider--adjust-slider {
    margin-left: -40px;
  }
}
@media (max-width: 47.99em) {
  .product-slider .swiper-slide-prev {
    margin-left: 20px;
  }
}
@media (max-width: 47.99em) {
  .product-slider .swiper-slide-next,
  .product-slider .swiper-slide-prev {
    opacity: 0.5;
  }
}
.product-slider .product__marketing .catalog-badge,
.product-slider .product__marketing--variants .catalog-badge {
  white-space: normal;
}

.product-slider__slider-wrapper {
  --swiper-theme-color: var(--theme-primary);
}
@media (min-width: 48em) {
  .product-slider__slider-wrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
}
@media (max-width: 47.99em) {
  .product-slider__slider-wrapper--items-1 .swiper-wrapper {
    margin-left: 0;
  }
}
.product-slider__slider-wrapper .swiper-container {
  width: 100%;
}
.product-slider__slider-wrapper .product {
  margin: 0 4px;
}

.product-slider__swiper-server .product {
  max-width: 250px;
}

.account-verification-modal__wrapper {
  text-align: center;
}

.account-verification-modal__attention-icon {
  font-size: 40px;
  color: var(--theme-tertiary);
}

.account-verification-modal__attention-wrapper {
  padding: 20px 25px;
  border-radius: var(--border-radius-default);
  display: inline-block;
  background: var(--theme-primary);
}

.account-verification-modal__header {
  margin-top: 15px;
}

.register__password-requirements {
  margin-bottom: 20px;
}

@media (max-width: 74.99em) {
  .register__content-wrapper {
    padding: 0 20px;
  }
}
.register__content-wrapper * {
  font-family: var(--font-base);
}

.register__headline {
  padding: 10px 0 0;
}
@media (min-width: 48em) {
  .register__headline {
    padding: 20px 0 0;
  }
}

.register__headline .headlines__headline {
  color: var(--theme-primary);
}

.register__password {
  margin-bottom: 20px;
}

.register__password-confirmation {
  margin-bottom: 20px;
}

.register__password-headline-wrapper {
  margin-top: 20px;
  margin-bottom: 20px;
}

.register__password-confirm {
  margin-bottom: 10px;
}

.register__wrapper {
  display: block;
  margin-top: 20px;
}
@media (min-width: 48em) {
  .register__wrapper {
    display: flex;
    gap: 50px;
  }
}

.register__gdpr-checkbox {
  margin-bottom: 20px;
  margin-top: 15px;
}

.register-addresses__hint {
  margin-bottom: 15px;
}

.register__information {
  flex: 1 1;
}

.register__form {
  flex: 2 1;
}

.register__submit-button {
  text-align: center;
}
.register__submit-button button {
  border-radius: var(--border-radius-default);
  margin-bottom: 30px;
}

.register__full-name {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.search-bar {
  width: 100%;
  grid-column: 2;
  grid-row: 1;
  grid-area: searchBar;
}
@media (min-width: 75em) {
  .search-bar {
    padding: 8px 0;
  }
}

.search-bar__input-container {
  position: relative;
  z-index: var(--zi-search-bar);
}

.search-bar__wrapper {
  position: relative;
  width: 100%;
  grid-column: 2;
  grid-row: 1;
  grid-area: searchBar;
  padding: 0 16px;
  display: flex;
  align-items: center;
}

@media (max-width: 47.99em) {
  body.scroll-lock--overflow {
    overflow: hidden;
    height: 100%;
  }
}
@media (min-width: 48em) {
  body.scroll-lock--overflow::after {
    opacity: 1;
    pointer-events: unset;
  }
}

body.scroll-lock--search-bar {
  position: relative;
}
@media (max-width: 47.99em) {
  body.scroll-lock--search-bar {
    overflow: hidden;
  }
}
@media (min-width: 48em) {
  body.scroll-lock--search-bar::after {
    opacity: 1;
    pointer-events: unset;
  }
}

body.scroll-lock--search-bar-custom {
  position: relative;
}
@media (min-width: 75em) {
  body.scroll-lock--search-bar-custom::after {
    opacity: 1;
    pointer-events: unset;
  }
}

body.scroll-lock--modal {
  position: fixed;
  inset: 0;
}

body::after {
  content: "\a";
  position: absolute;
  overflow: visible;
  inset: 0;
  pointer-events: none;
  top: var(--overlay-background-top);
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  z-index: var(--zi-search-backdrop);
}

.search-bar__close-icon {
  display: flex;
  cursor: pointer;
  background: none;
  border: none;
  width: 46px;
  height: 46px;
  justify-content: center;
  align-items: center;
}

.search-bar__search-overlay-portal {
  z-index: var(--zi-search-overlay-portal);
  background-color: var(--modal-overlay-bg-color);
  position: fixed;
  inset: 0;
  height: 100%;
}
.search-bar__search-overlay-portal .search-bar {
  background: var(--color-white-true);
  border-bottom: 1px solid var(--theme-primary);
}
.search-bar__search-overlay-portal .search-bar__input-container {
  display: flex;
  padding: 8px 0;
}
.search-bar__search-overlay-portal .search-bar__input {
  position: relative;
  width: 100%;
}
.search-bar__search-overlay-portal .search-input__input--autocomplete {
  position: absolute;
}
.search-bar__search-overlay-portal--custom .search-suggestion__autosuggest {
  top: 63px;
}

.search-input__form--open-portal {
  display: flex;
  padding-left: 12px;
  gap: 12px;
  align-items: center;
}

.search-input__input {
  position: relative;
  padding-right: 58px;
  width: 100%;
  line-height: 44px;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--theme-primary);
  color: var(--color-search-bar-input);
  font-size: var(--fs-search-bar-input);
  padding-left: 24px;
  z-index: 7;
  background-color: transparent;
}
.search-input__input:focus-visible, .search-input__input:focus {
  outline: none;
}

.search-input__input--open-portal {
  border: none;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}

.search-input__icon-button {
  position: absolute;
  right: 10px;
  color: var(--theme-primary);
  font-size: 44px;
  cursor: pointer;
  height: 44px;
  top: calc(50% - 22px);
  z-index: 7;
}

.search-input__icon-button--open-portal {
  position: relative;
  background: none;
  border-radius: 0;
  display: flex;
  align-items: center;
}
.search-input__icon-button--open-portal.search-input__icon-button-clear {
  right: 0;
  width: 44px;
  height: 44px;
  transform: scale(1);
  font-size: unset;
}

.search-suggestion__autosuggest {
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  z-index: var(--zi-search-bar-autosuggest);
  display: flex;
  flex-direction: column;
  padding-left: 16px;
  padding-right: 16px;
  max-height: calc(100% - 64px);
}
@media (min-width: 48em) {
  .search-suggestion__autosuggest {
    display: grid;
    position: absolute;
    top: calc(100% + 12px);
    grid-template-areas: "productSuggestion" "productSuggestionButton";
    grid-template-columns: 1fr;
    column-gap: 16px;
    max-height: none;
  }
  .search-suggestion__autosuggest.search-suggestion__autosuggest--has-search-suggestion {
    grid-template-areas: "searchSuggestion productSuggestion" "searchSuggestion productSuggestionButton";
    grid-template-columns: 1fr 2fr;
    overflow: auto;
  }
}
@media (min-width: 48em) and (max-width: 74.99em) {
  .search-suggestion__autosuggest.search-suggestion__autosuggest--has-search-suggestion {
    max-height: calc(100% - 63px);
  }
}
@media (min-width: 75em) {
  .search-suggestion__autosuggest {
    width: 100vw;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  .search-suggestion__autosuggest {
    top: calc(100% - 14px);
    height: auto;
    min-height: auto;
  }
}

.search-suggestion__autosuggest-keywords-wrapper {
  grid-area: searchSuggestion;
}

.search-suggestion__autosuggest-keywords {
  overflow-y: auto;
  margin: 0;
  list-style-type: none;
}
@media only screen and (max-device-width: 768px) and (orientation: landscape) {
  .search-suggestion__autosuggest-keywords {
    max-height: 80px;
  }
}

.search-suggestion__autosuggest-keyword {
  margin: 0 0 10px;
  padding: 0;
  cursor: pointer;
}
.search-suggestion__autosuggest-keyword:last-child {
  margin-bottom: 0;
}
@media only screen and (max-device-width: 768px) and (orientation: landscape) {
  .search-suggestion__autosuggest-keyword {
    margin-bottom: 4px;
    max-height: 80px;
  }
}

.search-suggestion__autosuggest-results-wrapper {
  grid-area: productSuggestion;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  .search-suggestion__autosuggest-results-wrapper {
    height: auto;
  }
}

.search-suggestion__autosuggest-keyword-bold {
  font-weight: var(--fw-semibold);
}

.search-suggestion__autosuggest-results {
  overflow-y: auto;
}

.search-suggestion__search-page-button {
  grid-area: productSuggestionButton;
  border-radius: 50px;
  border: 1px solid var(--theme-primary);
  color: var(--theme-primary);
  font-size: 1.2rem;
  text-align: center;
  padding: 10px;
  font-weight: var(--fw-bold);
  margin: 15px;
  cursor: pointer;
}

.search-suggestion__autosuggest-headline {
  font-size: var(--fs-base);
  font-weight: var(--fw-medium);
  margin: 16px 0 10px;
  color: var(--color-gray-500);
}
@media only screen and (max-device-width: 768px) and (orientation: landscape) {
  .search-suggestion__autosuggest-headline {
    margin-bottom: 4px;
    margin-top: 6px;
  }
}

.search-suggestion__result-mobile-wrapper {
  overflow: auto;
}

.product-suggestions__autosuggest-result {
  display: flex;
  margin-bottom: 10px;
}
.product-suggestions__autosuggest-result img {
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.product-suggestions__search-result-text {
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
  color: var(--theme-primary);
}

/* stylelint-disable max-nesting-depth */
.special-deal {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: var(--border-radius-product-card);
  border: 2px solid var(--color-red);
  display: flex;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
}
@media (min-width: 75em) {
  .special-deal--hover-effect-zoom :hover .special-deal__image {
    transform: scale(1.05) translateY(-2.4%);
  }
}
.special-deal .loading-indicator::after, .special-deal .async-component__inner::after {
  width: 18px;
  height: 18px;
}

/* stylelint-enable max-nesting-depth */
.special-deal__image-container {
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-radius: var(--border-radius-product-image-container);
}

.special-deal__badge-wrapper {
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}
.special-deal__badge-wrapper .catalog-badge--corner-bottom-right {
  line-height: 15px;
  padding: 1px 10px;
  word-break: break-word;
}

.special-deal__badge-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.special-deal__badge-container--bottom {
  margin-top: auto;
  align-items: flex-end;
}
.special-deal__badge-container--bottom .catalog-badge {
  max-width: 75%;
}

.special-deal__image {
  position: relative;
  object-fit: cover;
  border-radius: var(--border-radius-product-image-container);
  height: 100%;
  width: 100%;
  transform: scale(1);
  transition: transform 0.3s ease;
}
@media (max-width: 74.99em) {
  .special-deal__image {
    max-height: 450px;
  }
}

.special-deal__product-top {
  position: relative;
  max-height: 415px;
}
@media (min-width: 48em) {
  .special-deal__product-top {
    height: 75%;
    max-height: none;
  }
}

.special-deal__video {
  position: relative;
  aspect-ratio: 16/9;
}
@media (min-width: 48em) {
  .special-deal__video {
    position: absolute;
    inset: 0;
    aspect-ratio: auto;
  }
}

.special-deal__details-container {
  display: flex;
  flex-direction: column;
  padding: 8px 12px;
}
@media (min-width: 48em) {
  .special-deal__details-container {
    padding: 16px;
  }
}

.special-deal__title {
  margin-bottom: 4px;
}
.special-deal__title a {
  font-family: var(--font-base);
  font-weight: var(--fw-semibold);
  color: var(--color-product-title);
  font-size: var(--fs-product-title-special-deal-desktop);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
@media (max-width: 47.99em) {
  .special-deal__title a {
    font-size: var(--fs-product-title-mobile);
  }
}

.special-deal__description {
  font-size: var(--fs-product-description-mobile);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 8px;
}
@media (min-width: 48em) {
  .special-deal__description {
    word-break: unset;
    font-size: var(--fs-product-description-desktop);
  }
}
.special-deal__description p {
  margin: 0;
}

.special-deal__product-data {
  margin-top: auto;
}

.special-deal__cta-btn-details {
  border-radius: var(--border-radius-default);
  background: var(--background-product-buy-button);
  margin-top: auto;
  color: var(--color-product-buy-button);
  text-transform: unset;
  font-size: var(--fs-base);
  font-weight: var(--fw-normal);
  letter-spacing: normal;
  min-width: unset;
}
@media (max-width: 47.99em) {
  .special-deal__cta-btn-details {
    min-width: unset;
  }
}

@media (min-width: 48em) {
  .special-deal__cta-btn {
    margin-top: 16px;
    font-size: var(--fs-s);
  }
}

.special-deal__cta-btn-cart {
  background: var(--background-product-buy-button);
  border-radius: var(--border-radius-default);
  margin-top: auto;
  /* stylelint-disable-next-line selector-class-pattern */
}
@media (min-width: 48em) {
  .special-deal__cta-btn-cart {
    display: flex;
  }
}
.special-deal__cta-btn-cart .select-button__button {
  color: var(--color-product-buy-button);
  text-transform: unset;
  font-size: 16px;
  /* stylelint-disable-next-line selector-class-pattern */
}
.special-deal__cta-btn-cart .select-button__button .icon--AddToCart {
  font-size: 24px;
}
.special-deal__cta-btn-cart .icon--ArrowRightLight {
  display: none;
}
.special-deal__cta-btn-cart .select-button__select {
  display: none;
}

.special-deal__specials-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  top: 0;
}
.special-deal__specials-container .headlines {
  padding: 2px;
}
.special-deal__specials-container .headlines .headlines__headline {
  font-weight: var(--fw-black);
  font-size: var(--fs-l);
  padding-top: 3px;
}
.special-deal__specials-container .headlines .headlines__subheadline {
  font-size: 15px;
  color: var(--color-text);
  padding-bottom: 5px;
}
.special-deal__specials-container .special-deal__specials-bottom {
  background: rgba(34, 34, 34, 0.8);
  min-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--color-black-true);
  border-bottom: 1px solid var(--color-black-true);
}
.special-deal__specials-container .special-deal__specials-bottom--no-headlines {
  border-radius: 10px 10px 0 0;
}

.special-deal__specials-top {
  border-radius: 9px 9px 0 0;
  height: 70%;
  display: flex;
  flex-direction: column;
}

.special-deal__infos {
  display: grid;
  grid-template-areas: "badge price oldPrice";
  grid-template-columns: repeat(3, 1fr);
  margin: 8px 0;
}
@media (min-width: 48em) {
  .special-deal__infos {
    margin: 0;
    margin-top: 4px;
  }
}
.special-deal__infos .catalog-badge {
  grid-area: badge;
  width: fit-content;
  height: fit-content;
  align-self: center;
  font-size: var(--fs-xs);
}

.special-deal__price {
  color: var(--color-red);
  font-weight: var(--fw-medium);
  font-family: var(--font-product-price);
  font-size: var(--fs-ml);
  grid-area: price;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (min-width: 81.25em) {
  .special-deal__price {
    font-size: var(--fs-2xl);
  }
}

.special-deal__old-price {
  color: var(--menu-color-primary);
  font-weight: var(--fw-semibold);
  font-size: var(--fs-xs);
  grid-area: oldPrice;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 4px;
  white-space: pre-wrap;
  text-align: right;
}
.special-deal__old-price .catalog-badge {
  grid-area: badge;
  justify-content: left;
  width: fit-content;
  font-size: var(--fs-badge-small);
}

.special-deal__reviews-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
}

.special-deal__review-count {
  color: var(--color-star-text);
  margin-left: 4px;
  font-size: 12px;
  margin-top: 2px;
}

.special-deal__stars-normal {
  color: #bfbfbf;
}

.special-deal-countdown {
  width: 100%;
}

.special-deal-countdown__digits {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  color: var(--color-white-true);
  font-size: var(--fs-l);
  font-weight: var(--fw-semibold);
}
.special-deal-countdown__digits :last-child::after {
  display: none;
}
.special-deal-countdown__digits sup {
  font-size: 55%;
  position: relative;
  vertical-align: sub;
  margin-left: 4px;
}

.special-deal-countdown__time::after {
  content: " ";
  position: absolute;
  height: 22px;
  width: 1px;
  background: var(--color-gray-600);
  transform: translate(12px, 3px);
}

.special-deal-countdown__expired {
  font-style: italic;
  font-size: 15px;
  text-align: center;
  color: var(--color-white-true);
}

.subscription-management__headlines {
  padding: 20px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid var(--theme-primary);
  font-family: var(--ff-menu);
  font-weight: var(--fw-semibold);
}

.subscription-management__loading-indicator {
  margin-top: 20px;
}

.subscription-management-product__name {
  margin-bottom: 10px;
}

.subscription-management-product__image {
  max-width: 150px;
}

.subscription-management-product__wrapper {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  padding: 20px 0;
}
.subscription-management-product__wrapper:not(:last-child) {
  border-bottom: 1px solid var(--theme-primary);
}

.subscription-management-product__container {
  display: flex;
  flex-direction: column;
}

.subscription-management-product__details-container {
  display: flex;
  align-items: center;
  gap: 25px;
}
@media (max-width: 47.99em) {
  .subscription-management-product__details-container {
    gap: 15px;
  }
}

.subscription-management-product__details {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px 20px;
  font-weight: var(--fw-semibold);
}
.subscription-management-product__details div {
  display: contents;
}
.subscription-management-product__details span {
  display: block;
}

.subscription-management-product__cancel-button {
  margin-top: auto;
  margin-left: auto;
}

.teaser-grid {
  background: var(--color-white-true);
  position: relative;
}

.teaser-grid__grid {
  width: 100%;
  display: grid;
  gap: 20px;
  padding: 30px 20px;
}
@media (min-width: 48em) {
  .teaser-grid__grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.teaser-grid__grid--1-1-columns {
  grid-template-columns: repeat(1, 1fr);
}

.teaser-grid__grid--1-2-columns {
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 48em) {
  .teaser-grid__grid--1-2-columns {
    grid-template-columns: repeat(1, 1fr);
  }
}

.teaser-grid__grid--1x-1-columns {
  grid-template-columns: repeat(1, 1fr);
}

.teaser-grid__grid--1x-2-columns {
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 48em) {
  .teaser-grid__grid--1x-2-columns {
    grid-template-columns: repeat(1, 1fr);
  }
}

.teaser-grid__grid--2-2-columns {
  grid-template-columns: repeat(2, 1fr);
}

@media (min-width: 48em) {
  .teaser-grid__grid--3-1-columns {
    grid-template-columns: repeat(3, 1fr);
  }
}

.teaser-grid__grid--3-2-columns {
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 48em) {
  .teaser-grid__grid--3-2-columns {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 48em) {
  .teaser-grid__grid--4-1-columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

.teaser-grid__grid--4-2-columns {
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 48em) {
  .teaser-grid__grid--4-2-columns {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (min-width: 48em) {
  .teaser-grid__grid--5-1-columns {
    grid-template-columns: repeat(5, 1fr);
  }
}

.teaser-grid__grid--5-2-columns {
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 48em) {
  .teaser-grid__grid--5-2-columns {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 48em) {
  .teaser-grid__grid--6-1-columns {
    grid-template-columns: repeat(6, 1fr);
  }
}

.teaser-grid__grid--6-2-columns {
  grid-template-columns: repeat(2, 1fr);
}
@media (min-width: 48em) {
  .teaser-grid__grid--6-2-columns {
    grid-template-columns: repeat(6, 1fr);
  }
}

@media (max-width: 47.99em) {
  .teaser-grid__grid--big-first-row > div:first-child {
    grid-column: span 2;
  }
}

.teaser-grid__grid--no-gap-between-teasers {
  gap: 0;
}

.teaser-grid__grid--no-bottom-padding {
  padding-bottom: 0;
}

.teaser-grid__headlines {
  color: var(--color-black);
  padding: 20px 20px 0;
}

.teaser-grid-tile {
  background: var(--color-white-true);
  color: var(--color-black);
  border-radius: var(--border-radius-default);
  overflow: hidden;
}
.teaser-grid-tile--with-link {
  cursor: pointer;
}
.teaser-grid-tile--with-box-shadow {
  box-shadow: var(--box-shadow-default);
}
.teaser-grid-tile--with-square-corners {
  border-radius: 0;
}

.teaser-grid-tile__content-wrapper {
  position: relative;
  height: 100%;
  display: grid;
}
.teaser-grid-tile__content-wrapper--with-image, .teaser-grid-tile__content-wrapper--with-teaser {
  grid-template-rows: auto;
}

.teaser-grid-tile__text-container {
  width: 100%;
  padding: 20px;
  line-height: var(--lh-base);
  word-break: break-word;
  overflow: hidden;
}
.teaser-grid-tile__text-container p {
  margin: 0;
}
.teaser-grid-tile__text-container a {
  color: currentcolor;
  text-decoration: underline;
}
.teaser-grid-tile__text-container ul {
  margin: 0 0 0 10px;
}
.teaser-grid-tile__text-container h1 {
  line-height: 1.15;
}
.teaser-grid-tile__text-container h2 {
  line-height: 1.15;
}
.teaser-grid-tile__text-container h3 {
  line-height: 1.15;
}
.teaser-grid-tile__text-container h4 {
  line-height: 1.15;
}
.teaser-grid-tile__text-container sup {
  vertical-align: super;
  top: -0.25em;
}
.teaser-grid-tile__text-container sub {
  vertical-align: sub;
  bottom: -0.25em;
}

.teaser-grid-tile__link {
  color: inherit;
}

.teaser-grid-tile__image {
  width: 100%;
  height: auto;
}

.teaser-grid-tile__text-video {
  width: 100%;
  border-radius: var(--border-radius-default);
}

.teaser-grid-tile__text-video-with-padding {
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-bottom: 52%;
  border-radius: 0;
}

.teaser-grid__grid--1-1-columns .teaser-grid-tile__text-video,
.teaser-grid__grid--1-2-columns .teaser-grid-tile__text-video {
  border-radius: 0;
}
@media (min-width: 48em) {
  .teaser-grid__grid--1-1-columns .teaser-grid-tile__content-wrapper,
  .teaser-grid__grid--1-2-columns .teaser-grid-tile__content-wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 48em) {
  .teaser-grid-tile__text-container-wrapper {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }
  .teaser-grid-tile__text-container-wrapper--start {
    align-items: flex-start;
  }
  .teaser-grid-tile__text-container-wrapper--center {
    align-items: center;
  }
  .teaser-grid-tile__text-container-wrapper--end {
    align-items: flex-end;
  }
}
.teaser-grid-tile__content-wrapper--above picture,
.teaser-grid-tile__content-wrapper--above .bloomreach-video,
.teaser-grid-tile__content-wrapper--above .before-after-image {
  order: 2;
}
.teaser-grid-tile__content-wrapper--above .teaser-grid-tile__text-container-wrapper {
  order: 1;
}
.teaser-grid-tile__content-wrapper--above.teaser-grid-tile__content-wrapper--with-image.teaser-grid-tile__content-wrapper--with-teaser {
  grid-template-rows: 1fr auto;
}

.teaser-grid-tile__content-wrapper--below picture,
.teaser-grid-tile__content-wrapper--below .bloomreach-video,
.teaser-grid-tile__content-wrapper--below .before-after-image {
  order: 1;
}
.teaser-grid-tile__content-wrapper--below .teaser-grid-tile__text-container-wrapper {
  order: 2;
}
.teaser-grid-tile__content-wrapper--below.teaser-grid-tile__content-wrapper--with-image.teaser-grid-tile__content-wrapper--with-teaser {
  grid-template-rows: auto 1fr;
}

@media (max-width: 47.99em) {
  .teaser-grid-tile__content-wrapper--ignore-text-block-position picture,
  .teaser-grid-tile__content-wrapper--ignore-text-block-position .bloomreach-video,
  .teaser-grid-tile__content-wrapper--ignore-text-block-position .before-after-image {
    order: 1;
  }
  .teaser-grid-tile__content-wrapper--ignore-text-block-position .teaser-grid-tile__text-container-wrapper {
    order: 2;
  }
}

@media (min-width: 48em) {
  .teaser-grid-tile__content-wrapper:has(.bloomreach-video) {
    aspect-ratio: 1/1;
  }
  .teaser-grid-tile__content-wrapper:has(.bloomreach-video) .teaser-grid-tile__video {
    position: absolute;
    inset: 0;
  }
  .teaser-grid-tile__content-wrapper:has(.bloomreach-video).teaser-grid-tile__content-wrapper--with-teaser {
    aspect-ratio: auto;
  }
  .teaser-grid-tile__content-wrapper:has(.bloomreach-video).teaser-grid-tile__content-wrapper--with-teaser .teaser-grid-tile__video {
    position: relative;
    inset: auto;
  }
}
/* stylelint-disable declaration-no-important */
.text-with-image__image-container {
  clip-path: none !important;
}

.background__top-header {
  background: var(--top-header-background);
}

.top-header__top {
  display: flex;
  justify-content: center;
  padding: 4px 0;
  font-size: 14px;
  min-height: 25px;
}
@media (min-width: 75em) {
  .top-header__top {
    justify-content: space-between;
  }
}
.top-header__top img {
  min-height: 18px;
  aspect-ratio: auto 1/1;
}

.top-header__top-left {
  display: block;
  width: 100%;
}
@media (min-width: 75em) {
  .top-header__top-left {
    display: flex;
    justify-content: space-between;
    width: auto;
  }
}

.top-header__wrapper {
  color: var(--top-header-text-color);
  display: flex;
  align-items: center;
}
.top-header__wrapper .top-header__icon {
  display: inline-block;
  margin-bottom: -3px;
  color: var(--top-header-icon-color);
  min-height: 18px;
}

.top-header__phone-link {
  color: var(--top-header-phone-number-color);
  font-weight: var(--fw-bold);
  text-decoration: none;
}

.top-header__action-link {
  color: var(--top-header-cta-color);
}

.top-header__html-action-links {
  display: none;
}
@media (min-width: 75em) {
  .top-header__html-action-links {
    display: block;
  }
}
.top-header__html-action-links p {
  margin: 0;
  font-size: 14px;
}
.top-header__html-action-links a {
  color: var(--top-header-cta-color);
}

@media (min-width: 75em) {
  .top-header__phone-text,
  .top-header__usps-text {
    overflow-wrap: break-word;
  }
}
.top-header__phone-text p,
.top-header__usps-text p {
  margin: 0;
}

.top-header__phone-icon,
.top-header__usps-icon {
  display: inline-block;
  margin-right: 8px;
  width: 18px;
  padding: 2px;
}
@media (max-width: 47.99em) {
  .top-header__phone-icon,
  .top-header__usps-icon {
    display: none;
  }
}
@media (min-width: 75em) {
  .top-header__phone-icon,
  .top-header__usps-icon {
    padding: 0;
  }
}

.top-header__phone-wrapper {
  display: block;
  text-align: center;
}
@media (min-width: 75em) {
  .top-header__phone-wrapper {
    margin-right: 40px;
  }
}

.top-header__usps-wrapper {
  display: none;
}
.top-header__usps-wrapper a {
  color: currentcolor;
  display: flex;
}
@media (min-width: 48em) {
  .top-header__usps-wrapper {
    padding-right: 25px;
  }
}
@media (min-width: 75em) {
  .top-header__usps-wrapper {
    display: flex;
  }
}

.top-header__action-wrapper {
  display: none;
}
@media (min-width: 75em) {
  .top-header__action-wrapper {
    display: flex;
  }
}

.tracking-and-trace {
  padding: 20px 0;
}

.user-manuals {
  padding: 0 20px;
  margin-bottom: 20px;
}
@media (min-width: 48em) {
  .user-manuals {
    padding: 0;
  }
}

.user-manuals__headlines {
  padding: 20px 0;
}

.user-manuals__input-wrapper {
  position: relative;
}

.user-manuals__input {
  padding-right: 58px;
  width: 100%;
  line-height: 44px;
  border-radius: var(--border-radius-default);
  border: 1px solid var(--theme-primary);
  color: var(--color-search-bar-input);
  font-size: var(--fs-search-bar-input);
  padding-left: 24px;
  margin-bottom: 20px;
}

.user-manuals__table-wrapper {
  overflow: auto;
  width: calc(var(--max-width) - 40px);
  margin-bottom: 20px;
}
@media (min-width: 48em) {
  .user-manuals__table-wrapper {
    width: 100%;
  }
}

.user-manuals__table {
  width: 100%;
  border-collapse: collapse;
}
.user-manuals__table thead tr {
  border-bottom: 2px solid var(--theme-primary);
  white-space: nowrap;
}
.user-manuals__table tbody tr {
  border-bottom: 1px solid var(--theme-primary);
}
.user-manuals__table tbody tr:last-child {
  border-bottom: none;
}
.user-manuals__table th,
.user-manuals__table td {
  padding: 10px;
  text-align: center;
}
.user-manuals__table th:first-child, .user-manuals__table th:nth-child(2),
.user-manuals__table td:first-child,
.user-manuals__table td:nth-child(2) {
  text-align: left;
  min-width: 200px;
  word-break: break-word;
}
@media (min-width: 48em) {
  .user-manuals__table th,
  .user-manuals__table td {
    width: 150px;
  }
  .user-manuals__table th:first-child,
  .user-manuals__table td:first-child {
    width: calc(100% - 500px);
  }
  .user-manuals__table th:nth-child(2),
  .user-manuals__table td:nth-child(2) {
    width: 200px;
  }
}
@media (min-width: 75em) {
  .user-manuals__table th:first-child,
  .user-manuals__table td:first-child {
    width: calc(100% - 600px);
  }
  .user-manuals__table th:nth-child(2),
  .user-manuals__table td:nth-child(2) {
    width: 300px;
  }
}
.user-manuals__table th a,
.user-manuals__table td a {
  color: currentcolor;
  text-decoration: underline;
}

.user-manuals__icon-button {
  all: unset;
  position: absolute;
  right: 20px;
  top: 16px;
  cursor: pointer;
}

.voucher-check-modal {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.voucher-check-modal__headline {
  border-bottom: 1px solid var(--theme-dark);
  padding-bottom: 8px;
}

.voucher-check-modal__button {
  padding: 8px 12px;
}

.voucher-check-modal__overlay {
  position: fixed;
  background: var(--modal-overlay-bg-color);
  inset: 0;
  z-index: 2000;
}

.voucher-check-modal__voucher-details {
  display: flex;
  gap: 12px;
  font-size: var(--fs-l);
}
@media (max-width: 47.99em) {
  .voucher-check-modal__voucher-details {
    flex-direction: column;
    gap: 0;
  }
}

.voucher-check-modal__voucher-code {
  color: var(--theme-primary);
  font-weight: var(--fw-semibold);
}
@media (max-width: 47.99em) {
  .voucher-check-modal__voucher-code {
    margin-bottom: 0;
  }
}

@media (max-width: 47.99em) {
  .voucher-check-modal__voucher-text {
    margin-top: 0;
  }
}

.voucher-check-modal__legal-text {
  font-size: var(--fs-s);
}

.voucher-redemption__input {
  border-radius: var(--border-radius-default);
}

.checkout-guest {
  border: 1px solid var(--color-gray-400);
  border-top: none;
  padding-top: 10px;
}

.checkout-guest__read-only {
  padding: 20px;
}
@media (min-width: 75em) {
  .checkout-guest__read-only {
    padding: 40px 80px;
  }
}

.checkout-guest__required-hint {
  margin: 0 0 20px 4px;
  font-size: var(--fs-s);
  color: var(--color-gray-500);
}

.checkout-guest__info-text {
  padding: 5px 0 20px;
}

.checkout-addresses-read-only {
  margin-bottom: 20px;
}

.checkout-addresses-read-only__headline {
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
}

.checkout-addresses-read-only__edit-button {
  padding: 10px;
}

.customer-club-card-extension-modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.customer-club-card-extension-modal__attention-wrapper {
  background-color: var(--theme-primary);
  color: var(--theme-accent);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  margin-bottom: 8px;
}

.customer-club-card-extension-modal__attention-icon {
  font-size: 5rem;
  padding: 10px 15px 15px;
}

.customer-club-card-extension-modal__text {
  text-align: center;
}

.customer-club-card-extension-modal__actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
@media (min-width: 48em) {
  .customer-club-card-extension-modal__actions {
    flex-direction: row;
  }
}

.customer-club-card-extension-modal__cta {
  margin: 4px 0;
  padding: 11px;
}
@media (min-width: 75em) {
  .customer-club-card-extension-modal__cta {
    margin: 0;
  }
}

.checkout-login {
  border: 1px solid var(--color-gray-400);
  border-top: none;
  padding-top: 10px;
}
.checkout-login .login__hint {
  margin: 0 0 20px 4px;
  font-size: var(--fs-s);
  color: var(--color-gray-500);
}

.checkout-login__loading-indicator {
  padding: 50px;
}

.checkout-login__login-forgot {
  margin-top: 20px;
}
.checkout-login__login-forgot a,
.checkout-login__login-forgot a:visited {
  text-decoration: underline;
  color: inherit;
}

.checkout-login__logout {
  text-align: left;
  padding: 10px 18px;
}
@media (min-width: 48em) {
  .checkout-login__logout {
    text-align: center;
    padding: 10px 0;
  }
}

.checkout-login__login-success-label {
  font-weight: 600;
  color: var(--theme-success);
}

.checkout-login__logout-link {
  text-decoration: underline;
  cursor: pointer;
}

.checkout-login__form {
  padding: 20px;
}
@media (min-width: 75em) {
  .checkout-login__form {
    padding: 40px 80px;
  }
}

.checkout-login__read-only {
  padding: 20px;
}
@media (min-width: 75em) {
  .checkout-login__read-only {
    padding: 40px 80px;
  }
}

.checkout-login__shipping {
  margin-top: 50px;
}

.checkout-login__company-transactions {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.checkout-login-form__form {
  display: grid;
  gap: 15px;
}

.checkout-login-form__required-field-hint {
  margin: 0 0 20px 4px;
  font-size: var(--fs-s);
  color: var(--color-gray-500);
}

.checkout-login-form__full-name {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}

.checkout-login-form__forgot-password {
  margin-top: 20px;
}
.checkout-login-form__forgot-password a,
.checkout-login-form__forgot-password a:visited {
  text-decoration: underline;
  color: inherit;
}

.checkout-guest {
  border: 1px solid var(--color-gray-400);
  border-top: none;
  padding-top: 10px;
}

.checkout-guest__read-only {
  padding: 20px;
}
@media (min-width: 75em) {
  .checkout-guest__read-only {
    padding: 40px 80px;
  }
}

.checkout-guest__required-hint {
  margin: 0 0 20px 4px;
  font-size: var(--fs-s);
  color: var(--color-gray-500);
}

.checkout-guest__info-text {
  padding: 5px 0 20px;
}

.checkout-guest__error {
  color: var(--theme-error);
  margin-top: -12px;
  margin-bottom: 12px;
}

.checkout-summary-address {
  padding: 10px;
  border-bottom: 1px solid var(--color-gray-400);
}
@media (min-width: 48em) {
  .checkout-summary-address {
    display: flex;
    column-gap: 10px;
  }
}

.checkout-summary-address__header {
  flex-basis: 250px;
}
@media (max-width: 47.99em) {
  .checkout-summary-address__header {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
  }
}

.checkout-summary-address__title {
  font-weight: var(--fw-bold);
}

.checkout-summary-address__change {
  cursor: pointer;
  text-decoration: underline;
}

.express-checkout {
  padding: 0 0 50px;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin: 10px auto auto;
}
@media (min-width: 75em) {
  .express-checkout {
    width: 800px;
  }
}

.express-checkout__panel {
  border: 1px solid var(--color-gray-400);
  padding: 20px;
  margin-top: 20px;
}
@media (min-width: 75em) {
  .express-checkout__panel {
    padding: 40px 80px;
  }
}

.express-checkout__headline-banner {
  padding: 10px;
  background-color: var(--color-gray-300);
  font-size: var(--fs-l);
  font-weight: var(--fw-semibold);
  margin-bottom: 20px;
}

.express-checkout__shipping-address-form {
  padding: 10px;
  border-bottom: 1px solid var(--color-gray-400);
}

.express-checkout__shipping-address-title {
  font-weight: var(--fw-bold);
}

.express-checkout__payment-info,
.express-checkout__products {
  margin-bottom: 20px;
}

.express-checkout__footer {
  margin-top: 15px;
  color: var(--color-gray-500);
}

.express-checkout__footer-legal-text {
  padding-bottom: 15px;
  font-size: 0.9em;
}

.express-checkout__footer-legal-text a {
  text-decoration: underline;
  color: var(--color-gray-500);
  font-weight: 600;
}

.express-checkout__legal-opt-in {
  font-size: 0.9em;
}

.express-checkout__legal-opt-in .checkbox__label {
  color: var(--color-gray-500);
}

.express-checkout__legal-opt-in .checkbox__box {
  border-color: var(--color-gray-500);
}

.express-checkout__delivery-info {
  font-size: var(--fs-18);
  font-weight: var(--fw-semibold);
  margin-top: 25px;
}

.desktop-menu-item:hover .desktop-menu-item__menu-item {
  color: var(--menu-color-primary-hover);
  border-bottom-color: var(--menu-color-primary-hover);
}

.desktop-menu-item__menu-item {
  display: block;
  height: 68px;
  padding: 20px 0;
  margin: 0 10px;
  text-align: center;
  color: var(--menu-color-primary);
  font-family: var(--ff-menu);
  font-size: var(--fs-main-navigation-desktop);
  font-weight: var(--fw-semibold);
  font-feature-settings: "liga" off;
  border-bottom: 2px solid transparent;
}
.desktop-menu-item__menu-item--active {
  color: var(--menu-color-primary-hover);
  border-bottom-color: var(--menu-color-primary-hover);
}

.desktop-menu-item__submenu {
  display: none;
  width: 100%;
  position: absolute;
  top: calc(100% - 16px);
  left: 0;
  padding: 16px;
  margin-left: 0;
  background: var(--color-white-true);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 999;
  grid-template: "subCatOne subCatTwo subCatThree subImage" ". . . subImage" ". . . subImage" ". . . subImage" ". . . subImage";
  grid-gap: 10px;
  grid-template-rows: repeat(5, 1fr);
  grid-auto-flow: column;
}

.desktop-menu-item__submenu-item {
  list-style-type: none;
  margin: 0;
  padding: 10px;
}

.desktop-menu-item__submenu-item-link {
  color: var(--menu-color-primary-hover);
  font-family: var(--ff-menu);
  font-size: var(--fs-sub-navigation-desktop);
  font-weight: var(--fw-semibold);
}

@media (hover: hover) {
  .desktop-menu-item:hover .desktop-menu-item__submenu {
    display: grid;
  }
}
.desktop-menu-item-custom {
  grid-area: subImage;
  position: relative;
  width: 250px;
  justify-self: flex-end;
  list-style-type: none;
  padding: 10px;
  margin: 0;
}
.desktop-menu-item-custom::before {
  background: none;
}

.desktop-menu-item-custom__image {
  height: 90%;
  object-fit: cover;
}

.desktop-menu-item-custom__badge {
  position: absolute;
  top: 30px;
  left: -10px;
  background: var(--color-teaser-cta-box);
  text-transform: uppercase;
  padding: 5px 30px;
  border-radius: var(--border-radius-default);
  border-bottom-left-radius: 0;
}

.desktop-menu-item-custom__link {
  text-align: center;
}

.desktop-menu-item-custom__link-text {
  color: var(--theme-primary);
  text-decoration: underline;
  font-size: var(--fs-sub-navigation-desktop);
}

.desktop-menu {
  display: none;
}
@media (min-width: 75em) {
  .desktop-menu {
    display: block;
  }
  .desktop-menu > ul {
    display: flex;
    list-style-type: none;
    margin: 0;
    justify-content: space-between;
    align-items: center;
  }
  .desktop-menu > ul > li {
    margin: 0;
    padding: 0;
    position: initial;
  }
}

.desktop-menu-quicklink {
  border-radius: var(--border-radius-default);
  background: var(--quicklink-button-color);
  height: 42px;
  border-bottom: 2px solid transparent;
}
.desktop-menu-quicklink:hover {
  border-bottom-color: var(--menu-color-primary-hover);
}
.desktop-menu-quicklink > a {
  color: var(--quicklink-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 185px;
  padding: 0 10px;
  margin: 0;
  white-space: nowrap;
}
.desktop-menu-quicklink--custom-hover {
  border: none;
}
.desktop-menu-quicklink--custom-hover:hover {
  border-bottom: 2px solid;
}

@media (max-width: 74.99em) {
  .header-logo {
    align-self: center;
  }
}
.header-logo__desktop {
  display: none;
}
.header-logo__desktop img {
  min-height: var(--main-logo-min-height-desktop);
  aspect-ratio: auto 1/1;
}
@media (min-width: 75em) {
  .header-logo__desktop {
    display: block;
    width: 100%;
  }
  .header-logo__desktop > a {
    display: block;
    max-width: var(--main-logo-wrapper-max-width-desktop);
  }
}
.header-logo__mobile {
  display: block;
  min-height: var(--main-logo-wrapper-min-height-mobile);
  max-width: 100%;
}
.header-logo__mobile img {
  height: var(--main-logo-height-mobile);
  max-height: var(--main-logo-max-height-mobile);
  display: inline-block;
  vertical-align: bottom;
}
@media (min-width: 75em) {
  .header-logo__mobile {
    display: none;
  }
}

/* ::: Flip-Effect start */
.header-logo__flip-box {
  perspective: 1000px;
  width: 100%;
  height: 100%;
}

.header-logo__flip-box-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: rotateY(-180deg);
  animation: flipH 3s 0s infinite alternate ease-in-out;
}

.header-logo__flip-box-front,
.header-logo__flip-box-back {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

.header-logo__flip-box-back {
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
}

@keyframes flipH {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(0deg);
  }
  80% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
@-webkit-keyframes flipH {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(0deg);
  }
  80% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
/* ::: Flip-Effect end */
/* ::: Fade-Effect start */
.header-logo__fade {
  position: relative;
  width: 100%;
  height: 100%;
}

.header-logo__fade-first {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.header-logo__fade-second {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  animation: fade 3s infinite alternate ease-in-out;
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
/* ::: Fade-Effect end */
.main-navigation {
  position: relative;
  z-index: var(--zi-main-navigation);
  border-bottom: 1px solid var(--border-color-main-navigation);
}
.main-navigation .header-logo {
  grid-area: logo;
}
.main-navigation .user-menu {
  grid-area: userMenu;
}
.main-navigation .desktop-menu {
  grid-area: desktopMenu;
}
.main-navigation .search-bar__wrapper {
  padding: 0;
}

.main-navigation__wrapper,
.mobile-menu__header {
  position: relative;
  display: grid;
  grid-template-areas: "mobileMenuButton logo logo userMenu" "searchBar searchBar searchBar searchBar" "mobileQuickLinks mobileQuickLinks mobileQuickLinks mobileQuickLinks";
  grid-template-columns: var(--main-navigation-grid-column-width-mobile);
  gap: 10px;
  padding: 16px 12px;
}
@media (min-width: 48em) {
  .main-navigation__wrapper,
  .mobile-menu__header {
    grid-template-columns: var(--main-navigation-grid-column-width-tablet);
  }
}
@media (min-width: 75em) {
  .main-navigation__wrapper,
  .mobile-menu__header {
    grid-template-areas: "logo searchBar shippingText userMenu" "desktopMenu desktopMenu desktopMenu desktopMenu";
    grid-template-columns: var(--main-navigation-grid-column-width-desktop);
    padding: 16px 0;
    column-gap: 20px;
  }
}
@media (min-width: 81.25em) {
  .main-navigation__wrapper,
  .mobile-menu__header {
    grid-template-areas: "logo searchBar shippingText userMenu" "logo desktopMenu desktopMenu desktopMenu";
    grid-template-columns: var(--main-navigation-grid-column-width-wide);
  }
}

.main-navigation__mobile-button {
  grid-area: mobileMenuButton;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}
@media (min-width: 75em) {
  .main-navigation__mobile-button {
    display: none;
  }
}
.main-navigation__mobile-button svg {
  font-size: 50px;
  color: var(--theme-primary);
}

.main-navigation__shipping-text,
.mobile-menu__shipping-text {
  display: none;
  flex-direction: column;
  justify-content: center;
  grid-area: shippingText;
  color: var(--free-delivery-highlighted);
  font-family: var(--ff-menu);
}
@media (min-width: 75em) {
  .main-navigation__shipping-text,
  .mobile-menu__shipping-text {
    display: flex;
  }
}
.main-navigation__shipping-text p,
.mobile-menu__shipping-text p {
  margin: 0;
  font-size: 8px;
}
@media (min-width: 48em) {
  .main-navigation__shipping-text p,
  .mobile-menu__shipping-text p {
    font-size: 16px;
  }
}
.main-navigation__shipping-text p:first-of-type,
.mobile-menu__shipping-text p:first-of-type {
  font-size: 11px;
  font-weight: var(--fw-black);
}
@media (min-width: 48em) {
  .main-navigation__shipping-text p:first-of-type,
  .mobile-menu__shipping-text p:first-of-type {
    font-size: 25px;
  }
}

.main-navigation__mobile-quick-links {
  grid-area: mobileQuickLinks;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 75em) {
  .main-navigation__mobile-quick-links {
    display: none;
  }
}
.main-navigation__mobile-quick-links a {
  font-weight: var(--fw-semibold);
  color: var(--menu-color-primary);
}

.login-dropdown {
  display: none;
  padding: 0 15px;
  color: var(--color-black);
  background: var(--color-white-true);
  position: absolute;
  top: 100%;
  right: 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
  min-width: 219px;
}
.login-dropdown ul, .login-dropdown li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.login-dropdown li {
  margin: 1em 0;
}
.login-dropdown li span {
  cursor: pointer;
}
.login-dropdown a {
  width: 100%;
  word-break: keep-all;
  white-space: pre;
  text-decoration: none;
  color: var(--color-black);
}

.login-dropdown__welcome-message {
  border-bottom: 1px solid;
  border-color: var(--main-navigation-custom-separator-color);
}

.login-dropdown__welcome-message span {
  font-weight: 600;
}

.mobile-menu {
  position: fixed;
  inset: 0;
  z-index: var(--zi-mobile-menu);
  background: var(--color-white-true);
  overflow: scroll;
}
@media (min-width: 75em) {
  .mobile-menu {
    display: none;
  }
}

div.mobile-menu__header {
  grid-template-areas: "mobileMenuButton logo logo userMenu";
}
div.mobile-menu__header .header-logo {
  grid-area: logo;
}
div.mobile-menu__header .user-menu {
  grid-area: userMenu;
}

.mobile-menu__mobile-button {
  grid-area: mobileMenuButton;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 0;
}
.mobile-menu__mobile-button svg {
  font-size: 35px;
  color: var(--theme-primary);
}

.mobile-menu__navigation > ul {
  margin: 0;
}

.mobile-menu-item {
  position: relative;
  padding: 10px;
  margin: 0 0 4px;
  background: var(--color-teaser-right-active-box);
  list-style-type: none;
  font-size: var(--fs-main-navigation-mobile);
  font-weight: var(--fw-semibold);
  cursor: pointer;
}
.mobile-menu-item a {
  color: var(--menu-color-primary);
}
.mobile-menu-item:not(.mobile-menu-item__has-children) a {
  display: block;
}
.mobile-menu-item__has-children::before {
  position: absolute;
  right: 0;
  left: auto;
  content: "";
  display: inline-block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.1em solid var(--theme-primary);
  border-top: 0.1em solid var(--theme-primary);
  margin-right: 0.5em;
  font-size: 1.8em;
  color: var(--theme-primary);
}
.mobile-menu-item__arrow-up::before {
  transform: rotate(-45deg);
  top: 22px;
}
.mobile-menu-item__arrow-down::before {
  transform: rotate(135deg);
  top: 13px;
}

a.mobile-menu-item--active {
  color: var(--menu-color-primary-hover);
}

.mobile-menu-item__submenu {
  background: var(--color-white-true);
  margin: 10px -10px 0;
  padding: 10px;
}

.mobile-menu-item__submenu-item {
  margin: 0;
  padding: 10px;
  list-style-type: none;
  font-size: var(--fs-sub-navigation-desktop);
}
.mobile-menu-item__submenu-item a {
  display: block;
}

.mobile-menu-item__off-canvas {
  background: transparent;
}

.user-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 0 auto;
  gap: 8px;
}
.user-menu > * {
  padding: 4px;
}
.user-menu .country-switch {
  margin: 0;
  padding: 0;
  /* stylelint-disable-next-line selector-class-pattern */
}
@media (max-width: 47.99em) {
  .user-menu .country-switch {
    position: inherit;
  }
}
.user-menu .country-switch .dropdown {
  margin: 10px 0;
}
@media (min-width: 48em) {
  .user-menu .country-switch .dropdown {
    margin: 0;
  }
}
.user-menu .country-switch .dropdown__header .icon--ChevronDown {
  display: none;
}
@media (min-width: 48em) {
  .user-menu .country-switch .dropdown__header .icon--ChevronDown {
    display: inline-block;
  }
}
.user-menu .country-switch__select-container {
  left: -14px;
}
@media (max-width: 47.99em) {
  .user-menu .country-switch__select-container {
    top: unset;
    left: 0;
    width: 100vw;
    border-left: none;
    border-right: none;
  }
}
.user-menu .icon {
  fill: var(--color-icons);
  color: var(--color-icons);
  font-size: 25px;
  display: block;
}

.user-menu__account {
  position: relative;
  z-index: var(--zi-login-dropdown);
}
.user-menu__account:hover .login-dropdown {
  display: block;
}

.user-menu__cart-link {
  position: relative;
  padding: 4px;
}

.user-menu__cart-item-amount {
  position: absolute;
  top: 16px;
  left: 18px;
  width: 20px;
  height: 20px;
  font-size: 15px;
  text-align: center;
  border-radius: 50%;
  background-color: var(--theme-primary);
  color: var(--top-header-cta-color);
}

.burger-menu-button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  cursor: pointer;
  user-select: none;
  font-size: 32px;
  width: 45px;
}
@media (min-width: 75em) {
  .burger-menu-button {
    font-size: var(--fs-sm);
    height: 34px;
    width: auto;
    padding: 8px 12px;
    gap: 8px;
    border-radius: 16px;
    background: var(--color-gray-300);
  }
}
.burger-menu-button svg {
  font-size: 22px;
}
@media (min-width: 75em) {
  .burger-menu-button svg {
    font-size: 14px;
  }
}

.burger-menu-button__text {
  display: none;
  font-weight: var(--fw-medium);
}
@media (min-width: 75em) {
  .burger-menu-button__text {
    display: block;
  }
}

.burger-menu-country {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background: #f5f5f5;
  color: #666;
  user-select: none;
}

.burger-menu-country__wrapper--clickable {
  cursor: pointer;
}

.burger-menu-navigation-item {
  font-size: var(--burger-menu-font-size);
  user-select: none;
  list-style: none;
  padding: 4px 0;
  margin: 0;
}

.burger-menu-navigation-item__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: var(--theme-headlines);
  font-weight: var(--desktop-navigation-font-weight);
}
.burger-menu-navigation-item__title--is-open .burger-menu-navigation-item__arrow {
  transform: rotate(180deg);
}

.burger-menu-navigation-item__title--has-children {
  cursor: pointer;
}
@media (hover: hover) and (pointer: fine) {
  .burger-menu-navigation-item__title--has-children:hover {
    color: var(--main-navigation-custom-active-color);
  }
  .burger-menu-navigation-item__title--has-children:hover .burger-menu-navigation-item__link {
    color: var(--main-navigation-custom-active-color);
  }
}

.burger-menu-navigation-item__link {
  color: var(--main-navigation-custom-font-color);
}
.burger-menu-navigation-item__link:hover, .burger-menu-navigation-item__link--active {
  color: var(--main-navigation-custom-active-color);
}

.burger-menu-navigation-item__arrow {
  font-size: 18px;
  transition: transform 0.3s ease;
}

.burger-menu-sub-navigation {
  display: none;
  background: var(--main-navigation-custom-dropdown-background-color);
  list-style: none;
  margin: 4px -14px 0;
  padding: 10px 24px;
}
.burger-menu-sub-navigation--open {
  display: block;
}

.burger-menu-sub-navigation__item {
  list-style: none;
  margin: 0;
  padding: 4px 0;
  font-weight: var(--desktop-navigation-item-font-weight);
}
.burger-menu-sub-navigation__item--headline {
  font-weight: var(--desktop-navigation-headline-font-weight);
}
.burger-menu-sub-navigation__item--indented .burger-menu-sub-navigation__link::before {
  content: "›";
  padding-right: 4px;
  color: var(--main-navigation-custom-font-color);
}

.burger-menu-sub-navigation__link {
  color: var(--main-navigation-custom-font-color);
}
.burger-menu-sub-navigation__link:hover {
  color: var(--main-navigation-custom-active-color);
}
.burger-menu-sub-navigation__link:hover::before {
  color: var(--main-navigation-custom-active-color);
}

.burger-menu-navigation__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.burger-menu-quick-links-item {
  font-size: var(--burger-menu-font-size);
  font-weight: var(--desktop-navigation-item-font-weight);
  list-style: none;
  margin: 0;
  padding: 4px 0;
}

.burger-menu-quick-links-item__link {
  color: var(--burger-menu-quick-links-item-font-color);
}
.burger-menu-quick-links-item__link:hover {
  color: var(--main-navigation-custom-active-color);
}

.burger-menu-quick-links {
  margin-top: 14px;
  padding-top: 14px;
  border-top: 1px solid var(--main-navigation-custom-quick-links-separator-color);
}

.burger-menu-quick-links__list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.burger-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: var(--main-navigation-custom-background-color);
  border-top: 1px solid var(--main-navigation-custom-separator-color);
}
@media (min-width: 48em) and (max-width: 74.99em) {
  .burger-menu {
    max-width: 66.6666666667%;
  }
}
@media (min-width: 75em) {
  .burger-menu {
    max-width: 430px;
    right: 0;
    left: auto;
  }
}
.burger-menu--open {
  display: block;
}
.burger-menu .special-button {
  padding-bottom: 14px;
}

.burger-menu__inner-wrapper {
  padding: 14px;
}

body:has(.burger-menu--open)::after {
  opacity: 1;
  pointer-events: all;
}

.burger-menu--open:has(.country-select-container--open)::after {
  position: absolute;
  content: "";
  inset: 0 -1px -1px;
  background: rgba(0, 0, 0, 0.6);
  opacity: 1;
}

.desktop-navigation-item {
  font-size: var(--desktop-navigation-font-size);
  font-family: var(--desktop-navigation-font-family);
  position: initial;
  list-style: none;
  margin: 0;
  padding: 0;
  color: var(--main-navigation-custom-font-color);
  font-weight: var(--desktop-navigation-font-weight);
}
.desktop-navigation-item--hover,
.desktop-navigation-item .link-open-in-tab--active {
  color: var(--main-navigation-custom-active-color);
}
.desktop-navigation-item--hover .desktop-navigation-item__link-text,
.desktop-navigation-item .link-open-in-tab--active .desktop-navigation-item__link-text {
  border-color: var(--main-navigation-custom-active-color);
  color: var(--main-navigation-custom-active-color);
}
.desktop-navigation-item--hover svg,
.desktop-navigation-item .link-open-in-tab--active svg {
  fill: var(--main-navigation-custom-active-color);
}
.desktop-navigation-item--hover .desktop-navigation-item__arrow {
  transform: rotate(180deg);
}

.desktop-navigation-item__link {
  color: var(--main-navigation-custom-font-color);
}

.desktop-navigation-item__link-text {
  display: flex;
  gap: 7px;
  align-items: center;
  padding: 20px 0 18px;
  border-bottom: 3px solid transparent;
}

.desktop-navigation-item__arrow {
  font-size: 16px;
  transition: transform 0.3s ease;
}

.desktop-sub-navigation {
  display: none;
  position: absolute;
  top: 100%;
  border: 1px solid;
  padding: 25px;
  gap: 25px;
  background: var(--main-navigation-custom-dropdown-background-color);
}
.desktop-sub-navigation--right {
  right: 0;
}

.desktop-navigation-item--hover > .desktop-sub-navigation {
  display: flex;
}

.desktop-sub-navigation-column,
.desktop-sub-navigation-column__item {
  list-style: none;
  margin: 0;
  padding: 0;
}

.desktop-sub-navigation-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.desktop-sub-navigation-column__item {
  width: max-content;
  color: var(--main-navigation-custom-font-color);
  font-family: var(--ff-menu);
  font-weight: var(--desktop-navigation-item-font-weight);
}
.desktop-sub-navigation-column__item--headline {
  font-weight: var(--desktop-navigation-headline-font-weight);
  margin-bottom: 4px;
}
.desktop-sub-navigation-column__item--headline .desktop-sub-navigation-column__item-link {
  font-weight: var(--desktop-navigation-headline-font-weight);
}
.desktop-sub-navigation-column__item--indented .desktop-sub-navigation-column__item-link::before {
  content: "›";
  padding-right: 4px;
  color: var(--main-navigation-custom-font-color);
}

.desktop-sub-navigation-column__item-link {
  color: var(--main-navigation-custom-font-color);
  font-weight: var(--desktop-navigation-item-font-weight);
}
.desktop-sub-navigation-column__item-link:hover {
  color: var(--main-navigation-custom-active-color);
}
.desktop-sub-navigation-column__item-link:hover::before {
  color: var(--main-navigation-custom-active-color);
}

.desktop-navigation {
  height: 100%;
  position: relative;
}

.desktop-navigation__list {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: initial;
  list-style: none;
  margin: 0;
  padding: 0;
}
.desktop-navigation__list li:last-child {
  position: relative;
}
.desktop-navigation__list li:last-child .desktop-sub-navigation {
  min-width: 100%;
}

body:has(.desktop-navigation--hover)::after {
  opacity: 1;
  pointer-events: all;
}

.flip-logo__flip-box {
  perspective: 1000px;
  width: 100%;
  height: 100%;
}

.flip-logo__inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  transform: rotateY(-180deg);
  animation: flipH 3s 0s infinite alternate ease-in-out;
}

.flip-logo__front,
.flip-logo__back {
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  transform: rotateY(0deg);
  display: flex;
  align-items: center;
}

.flip-logo__back {
  position: absolute;
  top: 0;
  transform: rotateY(180deg);
}

@keyframes flipH {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(0deg);
  }
  80% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
@-webkit-keyframes flipH {
  0% {
    transform: rotateY(0deg);
  }
  20% {
    transform: rotateY(0deg);
  }
  80% {
    transform: rotateY(-180deg);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
.header-logo-custom,
.header-logo-custom img {
  max-width: var(--header-logo-custom-min-width-mobile-logo);
  width: 100%;
}
@media (min-width: 48em) {
  .header-logo-custom,
  .header-logo-custom img {
    max-width: var(--header-logo-custom-min-width-tablet-logo);
  }
}
@media (min-width: 75em) {
  .header-logo-custom,
  .header-logo-custom img {
    max-width: var(--header-logo-custom-min-width-desktop-logo);
  }
}

.header-logo-custom img {
  max-height: 87px;
}

.main-navigation-custom {
  position: relative;
  z-index: var(--zi-main-navigation);
  background: var(--main-navigation-custom-background-color);
  border-bottom: 1px solid var(--main-navigation-custom-separator-color);
}
@media (min-width: 75em) {
  .main-navigation-custom {
    height: var(--main-navigation-custom-height-desktop);
  }
}
.main-navigation-custom .link-open-in-tab--active {
  color: var(--main-navigation-custom-active-color);
}
.main-navigation-custom .link-open-in-tab--active::before {
  color: var(--main-navigation-custom-active-color);
}

.main-navigation-custom__inner {
  position: relative;
  display: grid;
  grid-template-areas: "logo meta" "mobileSearch mobileSearch";
  grid-template-columns: auto 1fr;
  column-gap: 45px;
}
@media (min-width: 48em) {
  .main-navigation-custom__inner {
    grid-template-areas: "logo meta";
  }
}
@media (min-width: 75em) {
  .main-navigation-custom__inner {
    grid-template-areas: "logo meta" "logo navigation";
  }
}

.main-navigation-custom__logo {
  display: flex;
  align-items: center;
  max-height: 87px;
  margin: 20px 0;
  grid-area: logo;
  gap: 5px;
  padding-left: 2px;
}
@media (min-width: 48em) {
  .main-navigation-custom__logo {
    padding-left: 0;
  }
}
.main-navigation-custom__logo .burger-menu-wrapper {
  display: block;
}
@media (min-width: 75em) {
  .main-navigation-custom__logo .burger-menu-wrapper {
    display: none;
  }
}

.main-navigation-custom__meta {
  grid-area: meta;
  display: grid;
  grid-template-areas: "metaRight";
  gap: 45px;
}
@media (min-width: 48em) {
  .main-navigation-custom__meta {
    grid-template-areas: "metaLeft metaRight";
    grid-template-columns: auto 131px;
  }
}
@media (min-width: 75em) {
  .main-navigation-custom__meta {
    grid-template-columns: auto 224px;
  }
}

.main-navigation-custom__meta-left {
  display: none;
  grid-area: metaLeft;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
  flex: 1;
}
@media (min-width: 48em) {
  .main-navigation-custom__meta-left {
    display: flex;
  }
}
@media (min-width: 75em) {
  .main-navigation-custom__meta-left {
    align-items: flex-end;
  }
}
.main-navigation-custom__meta-left .special-button {
  display: none;
}
@media (min-width: 75em) {
  .main-navigation-custom__meta-left .special-button {
    display: block;
  }
}
.main-navigation-custom__meta-left .search-bar {
  padding: 0;
}

.main-navigation-custom__search,
.main-navigation-custom__mobile-search {
  flex: 1;
}
.main-navigation-custom__search .search-bar__wrapper,
.main-navigation-custom__mobile-search .search-bar__wrapper {
  position: initial;
  padding: 0;
}
@media (min-width: 75em) {
  .main-navigation-custom__search .search-bar__wrapper,
  .main-navigation-custom__mobile-search .search-bar__wrapper {
    position: relative;
  }
}
.main-navigation-custom__search .search-input__input,
.main-navigation-custom__mobile-search .search-input__input {
  position: relative;
  height: 40px;
  line-height: 1;
  border: 1px solid var(--main-navigation-custom-search-border-color);
  color: var(--main-navigation-custom-search-font-color);
  font-size: var(--fs-sm);
  border-radius: 0;
  padding: 12.5px 64px 12.5px 14px;
  z-index: 2;
  top: initial;
  transform: none;
}
.main-navigation-custom__search .search-input__input::placeholder,
.main-navigation-custom__mobile-search .search-input__input::placeholder {
  color: var(--main-navigation-custom-search-font-color);
}
.main-navigation-custom__search .search-input__input--autocomplete,
.main-navigation-custom__mobile-search .search-input__input--autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: var(--main-navigation-custom-search-background-color);
}
.main-navigation-custom__search .search-input__icon-button,
.main-navigation-custom__mobile-search .search-input__icon-button {
  top: calc(50% - 13px);
  right: 0;
  font-size: 38px;
  height: 26px;
  width: 50px;
  z-index: 3;
  display: flex;
  border-left: 1px solid var(--main-navigation-custom-search-border-color);
  align-items: center;
  justify-content: center;
}
.main-navigation-custom__search .search-input__icon-button svg,
.main-navigation-custom__mobile-search .search-input__icon-button svg {
  fill: var(--main-navigation-custom-search-font-color);
  font-size: 22px;
}

.main-navigation-custom__meta-right {
  grid-area: metaRight;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding-right: 14px;
}
@media (min-width: 48em) {
  .main-navigation-custom__meta-right {
    padding-right: 12px;
  }
}
@media (min-width: 75em) {
  .main-navigation-custom__meta-right {
    padding-right: 4px;
    align-items: flex-end;
  }
}
.main-navigation-custom__meta-right .burger-menu-wrapper {
  display: none;
}
@media (min-width: 75em) {
  .main-navigation-custom__meta-right .burger-menu-wrapper {
    display: block;
    margin: 3px 0;
  }
}
.main-navigation-custom__meta-right .user-menu {
  gap: 12px;
  margin: 3px 0;
}
.main-navigation-custom__meta-right .user-menu .dropdown__header {
  /* stylelint-disable-next-line selector-class-pattern */
}
.main-navigation-custom__meta-right .user-menu .dropdown__header .icon--ChevronDown {
  display: none;
}
.main-navigation-custom__meta-right .user-menu .dropdown__header .dropdown__item-flag {
  margin: 0 4px 0 0;
}
.main-navigation-custom__meta-right .user-menu__account svg {
  font-size: 24px;
  margin-bottom: 1px;
}
.main-navigation-custom__meta-right .user-menu__cart-link {
  padding: 5px 4px 4px;
}
.main-navigation-custom__meta-right .user-menu__cart-item-amount {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--fw-normal);
  top: 0;
  left: 15px;
  font-size: var(--fs-xs);
  padding: 0;
}

.main-navigation-custom__nav {
  display: none;
  grid-area: navigation;
}
@media (min-width: 75em) {
  .main-navigation-custom__nav {
    display: block;
  }
}

.main-navigation-custom__mobile-search {
  grid-area: mobileSearch;
  padding: 0 14px 14px;
}
@media (min-width: 48em) {
  .main-navigation-custom__mobile-search {
    display: none;
  }
}

@media (min-width: 75em) {
  .main-navigation-custom__meta,
  .main-navigation-custom__nav {
    height: calc((var(--main-navigation-custom-height-desktop) - 1px) / 2);
  }
}

.main-navigation-custom:has(.desktop-navigation--hover) {
  box-shadow: 0 12px 12px 0 rgba(0, 0, 0, 0.25);
}

.live-tv-button {
  display: none;
}
@media (min-width: 75em) {
  .live-tv-button {
    display: block;
  }
}
.live-tv-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 117px;
  padding: 8px;
  font-size: var(--fs-18);
  font-weight: var(--fw-medium);
  background: var(--color-red);
  color: var(--live-tv-button-font-color);
  border-radius: var(--border-radius-button);
  border: 1px solid var(--color-red);
  gap: 8px;
}
.live-tv-button a:hover {
  color: var(--live-tv-button-font-color);
}

.live-tv-button__dot {
  width: 16px;
  height: 16px;
  border-radius: var(--border-radius-max);
  background: var(--color-white-true);
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(255, 255, 255, 0);
  }
}
.special-button {
  text-align: center;
}
.special-button a {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40px;
  height: 40px;
  font-size: var(--fs-18);
  font-weight: var(--fw-medium);
  background: #222;
  color: var(--special-button-font-color);
  padding: 7px 18px;
  border-radius: var(--border-radius-button);
  border: 1px solid var(--special-button-border-color);
}
.special-button a:hover {
  border-color: var(--special-button-border-color-hover);
  color: var(--special-button-font-color);
}

.mpcheckout {
  padding: 0 0 50px;
  align-items: center;
  flex-direction: column;
  display: flex;
  margin: 10px auto auto;
}
@media (min-width: 75em) {
  .mpcheckout {
    width: 800px;
  }
}

.mpcheckout__guest-order {
  border: 1px solid var(--color-gray-400);
  padding: 20px;
  margin-top: 10px;
}
@media (min-width: 75em) {
  .mpcheckout__guest-order {
    padding: 40px 80px;
  }
}

.mpcheckout__payment {
  border: 1px solid var(--color-gray-400);
  background: var(--color-white-true);
  padding: 20px;
  margin-top: 20px;
  width: 100%;
}
@media (min-width: 75em) {
  .mpcheckout__payment {
    padding: 40px 80px;
  }
}

.mpcheckout__summary {
  border: 1px solid var(--color-gray-400);
  padding: 20px;
  margin-top: 20px;
}
@media (min-width: 75em) {
  .mpcheckout__summary {
    padding: 40px 80px;
  }
}

.mpcheckout-cart__panel {
  width: 100%;
  margin-top: 30px;
}

.mpcheckout__cart {
  width: 100%;
  margin-bottom: 10px;
}

.mpcheckout__addresses {
  width: 100%;
  margin-top: 20px;
}

.mpcheckout__tabs {
  padding: 0 15px;
}
@media (min-width: 48em) {
  .mpcheckout__tabs {
    padding: 0;
  }
}

/* stylelint-disable no-duplicate-selectors */
.multi-page-checkout-steps__container {
  width: 100%;
  text-align: center;
}

.multi-page-checkout-steps {
  width: 100%;
  display: flex;
  justify-content: center;
  white-space: pre-wrap;
  align-items: baseline;
}
@media (max-width: 74.99em) {
  .multi-page-checkout-steps__step {
    flex-shrink: 2;
    flex-basis: min-content;
  }
}
.multi-page-checkout-steps__step--active, .multi-page-checkout-steps__step {
  font-size: var(--fs-xs);
  text-align: center;
  color: var(--color-gray-600);
  padding: 10px 0;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  flex: 1;
}
@media (min-width: 75em) {
  .multi-page-checkout-steps__step--active, .multi-page-checkout-steps__step {
    width: 100%;
    font-size: var(--fs-s);
  }
}
.multi-page-checkout-steps__step--active::after, .multi-page-checkout-steps__step::after {
  content: "";
  position: absolute;
  top: 30px;
  transform: translateX(calc(50% + 42px));
  width: calc(100% - 82px);
  height: 2px;
  z-index: 2;
  background: var(--color-gray-400);
}
@media (max-width: 47.99em) {
  .multi-page-checkout-steps__step--active::after, .multi-page-checkout-steps__step::after {
    top: 26px;
    width: calc(100% - 52px);
    transform: translateX(calc(50% + 26px));
  }
}
.multi-page-checkout-steps__step--active--clickable, .multi-page-checkout-steps__step--clickable {
  cursor: pointer;
}
.multi-page-checkout-steps__step--active__bubble, .multi-page-checkout-steps__step--active__bubble--active, .multi-page-checkout-steps__step--active__bubble--previous, .multi-page-checkout-steps__step__bubble, .multi-page-checkout-steps__step__bubble--active, .multi-page-checkout-steps__step__bubble--previous {
  background-color: var(--mp-checkout-steps-background-color);
  border-radius: 50%;
  color: var(--color-gray-600);
  font-size: var(--fs-base);
  font-weight: var(--fw-bold);
  height: 32px;
  text-align: center;
  width: 32px;
  line-height: 32px;
}
@media (min-width: 48em) {
  .multi-page-checkout-steps__step--active__bubble, .multi-page-checkout-steps__step--active__bubble--active, .multi-page-checkout-steps__step--active__bubble--previous, .multi-page-checkout-steps__step__bubble, .multi-page-checkout-steps__step__bubble--active, .multi-page-checkout-steps__step__bubble--previous {
    height: 40px;
    width: 40px;
    font-size: var(--fs-l);
    line-height: 40px;
  }
}
.multi-page-checkout-steps__step--active__bubble .icon, .multi-page-checkout-steps__step--active__bubble--active .icon, .multi-page-checkout-steps__step--active__bubble--previous .icon, .multi-page-checkout-steps__step__bubble .icon, .multi-page-checkout-steps__step__bubble--active .icon, .multi-page-checkout-steps__step__bubble--previous .icon {
  position: relative;
  top: 5px;
}
.multi-page-checkout-steps__step--active__bubble--active, .multi-page-checkout-steps__step__bubble--active {
  color: var(--mp-checkout-steps-text-color);
  background-color: var(--theme-primary);
}
.multi-page-checkout-steps__step--active__bubble--previous, .multi-page-checkout-steps__step__bubble--previous {
  background-color: var(--theme-success);
}
.multi-page-checkout-steps__step--active:last-child::after, .multi-page-checkout-steps__step:last-child::after {
  display: none;
}
.multi-page-checkout-steps__step--active {
  color: var(--theme-primary);
}
.multi-page-checkout-steps__step--previous {
  color: var(--theme-success);
  cursor: pointer;
}
.multi-page-checkout-steps__step--previous::after {
  background-color: var(--theme-success);
  cursor: default;
}
.multi-page-checkout-steps__step--previous .icon {
  color: var(--mp-checkout-steps-text-color);
}
.multi-page-checkout-steps__headline {
  margin: 10px;
  font-size: var(--fs-xl);
  font-weight: var(--fw-semibold);
}
@media (max-width: 47.99em) {
  .multi-page-checkout-steps__headline {
    font-size: var(--fs-l);
  }
}

.mp-checkout-summary {
  background: var(--bg-checkout-summary);
}
@media (min-width: 75em) {
  .mp-checkout-summary {
    overflow: auto;
    position: sticky;
    top: 20px;
  }
}

.mp-checkout-summary__header {
  font-size: var(--fs-h2);
  font-weight: var(--fw-semibold);
  color: var(--color-headline);
  padding-bottom: 15px;
}

.mp-checkout-summary__footer {
  margin-top: 15px;
  color: var(--color-gray-500);
}

.mp-checkout-summary__footer-legal-text {
  padding-bottom: 15px;
  font-size: 0.9em;
}

.mp-checkout-summary__footer-legal-text a {
  text-decoration: underline;
  color: var(--color-gray-500);
  font-weight: 600;
}

.mp-checkout-summary__legal-optin {
  font-size: 0.9em;
}

.mp-checkout-summary__legal-optin .checkbox__label {
  color: var(--color-gray-500);
}

.mp-checkout-summary__legal-optin .checkbox__box {
  border-color: var(--color-gray-500);
}

.mp-checkout-summary__buynow-button {
  padding: 20px;
  margin-top: 15px;
  font-size: var(--fs-xl);
  height: auto;
}

.mp-checkout-summary__delivery-info {
  font-size: var(--fs-18);
  font-weight: var(--fw-semibold);
  margin-top: 25px;
}

.mp-checkout-summary__usp::before {
  content: none;
}

.mp-checkout-summary__headline-banner {
  padding: 10px;
  background-color: var(--color-gray-300);
  font-size: var(--fs-l);
  font-weight: var(--fw-semibold);
  margin-bottom: 20px;
}

.mp-checkout-summary__payment-info,
.mp-checkout-summary__products {
  margin-bottom: 20px;
}

.mpc-order-overview {
  margin: 30px 10px 40px;
}

.mpc-order-overview__summary-line {
  display: grid;
  grid-template-columns: auto 140px;
  margin: 15px 0;
  width: 100%;
}
.mpc-order-overview__summary-line .mpc-order-overview__label {
  text-align: left;
  hyphens: auto;
}
.mpc-order-overview__summary-line .mpc-order-overview__value {
  font-family: var(--font-base);
  text-align: right;
}
.mpc-order-overview__summary-line .voucher-row__label {
  font-weight: var(--fw-normal);
}
.mpc-order-overview__summary-line .voucher-row__value {
  font-weight: var(--fw-normal);
}

.mpc-order-overview__subtotal {
  font-weight: var(--fw-bold);
}
.mpc-order-overview__subtotal .mpc-order-overview__label,
.mpc-order-overview__subtotal .mpc-order-overview__value {
  font-weight: var(--fw-bold);
}

.mpc-order-overview__total {
  padding-top: 15px;
  word-break: break-word;
  border-top: 2px solid var(--color-borders-checkout);
}
.mpc-order-overview__total .mpc-order-overview__total-label {
  font-weight: var(--fw-bold);
  font-size: var(--fs-l);
  display: inline-block;
  margin-right: 1ch;
}
.mpc-order-overview__total .mpc-order-overview__tax-label {
  font-size: var(--fs-s);
  color: #999;
  white-space: nowrap;
}
.mpc-order-overview__total .mpc-order-overview__total-price {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  color: var(--theme-primary);
}

.mpc-summary-payment {
  padding: 10px;
}
@media (min-width: 48em) {
  .mpc-summary-payment {
    display: flex;
    column-gap: 10px;
  }
}

.mpc-summary-payment__header {
  flex-basis: 250px;
}
@media (max-width: 47.99em) {
  .mpc-summary-payment__header {
    display: flex;
    padding-bottom: 10px;
    justify-content: space-between;
  }
}

.mpc-summary-payment__title {
  font-weight: var(--fw-bold);
}

.mpc-summary-payment__image {
  display: block;
  max-width: 50px;
  padding-bottom: 2px;
  font-size: 52px;
  max-height: 40px;
  color: var(--theme-primary);
}

.mpc-summary-payment__change {
  cursor: pointer;
  text-decoration: underline;
}

.mpc-summary-products__line-item {
  display: flex;
  align-items: center;
  padding: 10px;
  font-size: var(--fs-s);
}

.mpc-summary-products__line-item-change {
  cursor: pointer;
  text-decoration: underline;
}

.mpc-summary-products__divider {
  border-bottom: 1px solid var(--color-gray-400);
}

.mpc-summary-products__image {
  width: 80px;
  height: 80px;
  border-radius: var(--border-radius-default);
  margin-right: 15px;
}

.mpc-summary-products__product-name {
  color: var(--theme-primary);
  font-size: var(--fs-base);
  font-weight: var(--fw-semibold);
}

.mpc-summary-products__price-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 3px;
}

.mpc-summary-gotocart-modal__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mpc-summary-gotocart-modal__attention-wrapper {
  background-color: var(--theme-primary);
  color: var(--theme-accent);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  margin-bottom: 8px;
}

.mpc-summary-gotocart-modal__attention-icon {
  font-size: 5rem;
  padding: 10px 15px 15px;
}

.mpc-summary-gotocart-modal__text {
  text-align: center;
}

.mpc-summary-gotocart-modal__actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}

.mpc-summary-gotocart-modal__cta {
  margin: 4px 0;
}
@media (min-width: 75em) {
  .mpc-summary-gotocart-modal__cta {
    margin: 0 4px;
  }
}

.op-checkout-cart-line-item .price-box__price {
  color: var(--cart-product-price);
  font-size: var(--fs-3xl);
}

.op-checkout-cart-line-item__content {
  padding-bottom: 8px;
}

.op-checkout-cart-line-item__name {
  color: var(--theme-primary);
  font-weight: var(--fw-bold);
}

.op-checkout {
  display: grid;
  grid-template: "cart" "divider" "address" "payment" "summary";
  gap: 20px;
  padding: 0 0 50px;
  align-items: flex-start;
}
@media (min-width: 75em) {
  .op-checkout {
    grid-template: "cart cart" "address summary" "payment summary"/1fr 448px;
    padding: 50px 0;
  }
}

.op-checkout__header {
  font-size: var(--fs-h2);
  font-weight: var(--fw-bold);
  margin-bottom: 15px;
  padding-left: 20px;
}

.op-checkout__guest-order {
  border: 1px solid var(--color-gray-400);
  padding: 20px;
  margin-top: 10px;
}
@media (min-width: 75em) {
  .op-checkout__guest-order {
    padding: 40px 80px;
  }
}

.op-checkout__payment {
  border: 1px solid var(--color-gray-400);
  padding: 20px;
  margin-top: 10px;
}
@media (min-width: 75em) {
  .op-checkout__payment {
    padding: 40px 80px;
  }
}

.op-checkout-summary__installment-price {
  font-size: var(--fs-l);
  color: var(--color-badge-raten-background);
  font-weight: var(--fw-bold);
}

.op-checkout-summary__price-box {
  text-align: right;
}

.cart-addon-product {
  --color-text: var(--color-cart-addon-product);
  position: relative;
  display: grid;
  grid-template-areas: "productImage productDetail" "orderInfo orderInfo";
  grid-gap: 15px;
  padding: 10px;
  color: var(--color-cart-addon-product);
  background: var(--bg-cart-addon-product);
}
@media (min-width: 48em) {
  .cart-addon-product {
    grid-template-columns: 80px auto auto;
    grid-template-areas: "productImage productDetail orderInfo";
    padding: 20px;
    background: var(--bg-cart-addon-product);
  }
}

.cart-addon-product__image-container {
  grid-area: productImage;
}

.cart-addon-product__detail {
  grid-area: productDetail;
}

.cart-addon-product__order-info {
  grid-area: orderInfo;
  display: grid;
  grid-template-columns: auto auto;
  grid-template-areas: "addButton price";
  justify-content: space-between;
  align-items: flex-start;
}
@media (min-width: 48em) {
  .cart-addon-product__order-info {
    grid-template-columns: auto;
    grid-template-areas: "price" "addButton";
    justify-content: flex-end;
  }
}

.cart-addon-product__price {
  --color-price-box-price: var(--color-text);
  grid-area: price;
}

.cart-addon-product__add-button {
  grid-area: addButton;
  margin-top: 10px;
}

.cart-addon-product__description {
  grid-area: description;
  --bg-li-marker: var(--color-text);
}
.cart-addon-product__description li {
  margin-bottom: 0;
}

.cart-addon-product__price-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.cart-product {
  position: relative;
  display: grid;
  grid-template-areas: "productImage productDetail" "productImage orderInfo";
  grid-template-columns: 25% auto;
  grid-gap: 15px;
  margin: 0;
  background: var(--color-white-true);
  padding: 6px 6px 0;
}
@media (min-width: 48em) {
  .cart-product {
    background: var(--color-white-true);
    padding: 6px;
  }
}
.cart-product .price-box__unit-price {
  font-size: var(--fs-xs);
  margin-top: 5px;
}
@media (max-width: 47.99em) {
  .cart-product {
    grid-template-areas: "productImage productDetail" "orderInfo orderInfo";
    grid-template-columns: 25% auto;
  }
}

.cart-product__left {
  position: relative;
  grid-area: productImage;
  cursor: pointer;
}

.cart-product__image-container {
  overflow: hidden;
  aspect-ratio: 1/1;
  display: flex;
  align-items: stretch;
}

.cart-product__image {
  object-fit: cover;
  min-height: 100%;
}

.cart-product__detail {
  grid-area: productDetail;
}

.cart-product__order-info {
  grid-area: orderInfo;
  align-self: end;
}
@media (max-width: 47.99em) {
  .cart-product__order-info {
    padding: 0 6px;
  }
}
.cart-product__order-info .delivery-info {
  margin: -8px 0 8px;
}

.cart-product__detail-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.cart-product__title {
  cursor: pointer;
  font-family: var(--font-base);
  font-weight: var(--fw-semibold);
  color: var(--color-product-title);
  font-size: var(--fs-product-title-desktop);
}
@media (max-width: 26.25em) {
  .cart-product__title {
    word-break: break-word;
    hyphens: auto;
  }
}

.cart-product__remove-button {
  grid-area: removeButton;
  cursor: pointer;
  padding-left: 20px;
  border: none;
  background: none;
}
.cart-product__remove-button:hover:enabled {
  color: var(--theme-primary);
}

.cart-product__description {
  grid-area: description;
  font-size: var(--fs-product-description-mobile);
  margin-top: 4px;
}
@media (min-width: 48em) {
  .cart-product__description {
    font-size: var(--fs-product-description-desktop);
  }
}
.cart-product__description li {
  margin-bottom: 0;
}

.cart-product__price-info-container {
  display: grid;
  grid-template-areas: "priceBox quantityInfo";
  align-items: flex-end;
  margin-bottom: 0;
}

.cart-product__price-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 5px;
}

.cart-product__quantity-info {
  grid-area: quantityInfo;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cart-product__quantity-info select {
  border-radius: var(--border-radius-default);
}

.cart-product__price {
  grid-area: priceBox;
}
.cart-product__price .price-box__price {
  color: var(--cart-product-price);
  font-size: 22px;
  margin-bottom: 4px;
}
.cart-product__price .price-box__price--sale {
  color: var(--color-red);
}
.cart-product__price .price-box__old-price {
  font-size: var(--fs-xs);
  font-weight: var(--fw-semibold);
}

.cart-product__promotion {
  margin-bottom: 12px;
  padding: 3px 16px;
  border-radius: 5px 5px 5px 0;
  font-weight: 600;
  font-size: 13px;
  display: none;
}
@media (min-width: 48em) {
  .cart-product__promotion {
    display: block;
  }
}

.cart-product__quantity {
  margin-right: 0;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: var(--border-radius-default);
}
@media (max-width: 23.4375em) {
  .cart-product__quantity {
    width: auto;
  }
}
.cart-product__quantity select {
  border-color: var(--border-cart-product-select);
  font-size: var(--fs-base);
  padding: 12px 50px 12px 24px;
}
@media (max-width: 23.4375em) {
  .cart-product__quantity select {
    padding: 12px 36px 12px 18px;
  }
}
.cart-product__quantity select:focus-visible {
  outline: 0;
}
.cart-product__quantity .select__arrow {
  font-size: 16px;
}
.cart-product__quantity .select--medium {
  height: 40px;
}

.cart-product__badge-wrapper {
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 1px;
}
@media (min-width: 75em) {
  .cart-product__badge-wrapper {
    display: flex;
  }
}

.cart-product__badge-container--top {
  display: flex;
  flex-direction: row;
  width: inherit;
  position: absolute;
  align-items: flex-start;
  gap: 4px;
  justify-content: space-between;
  margin-top: -1px;
}
.cart-product__badge-container--top .catalog-badge {
  border-radius: 0;
}

.cart-product__badge-container--bottom {
  position: absolute;
  right: 0;
  bottom: 1px;
}

.cart-product__free-item {
  background: var(--color-badge-gratis-text);
  color: var(--color-white-true);
  font-size: var(--fs-badge-small);
  border-radius: var(--border-radius-badge);
  width: fit-content;
  line-height: 22px;
  font-family: var(--font-base);
  padding: 0 12px 0 8px;
}

.cart-product__delivery {
  font-size: var(--fs-s);
  font-weight: var(--fw-normal);
  display: flex;
  align-items: center;
  margin: 4px 0;
  gap: 4px;
}
@media (max-width: 25em) {
  .cart-product__delivery .cart-product__delivery-days::before {
    content: "\a";
    white-space: pre-wrap;
  }
}
.cart-product__delivery .cart-product__delivery-days {
  color: var(--color-green-500);
}
@media (max-width: 47.99em) {
  .cart-product__delivery {
    align-items: flex-end;
  }
}
.cart-product__delivery .cart-product__icon {
  min-width: 13px;
  height: 13px;
  color: var(--color-white-true);
  background: var(--color-green-500);
  border-radius: 50%;
  padding: 3px;
  font-size: 13px;
  margin-left: 4px;
}

.cart-summary-footer__payment-logos {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}
@media (min-width: 48em) {
  .cart-summary-footer__payment-logos {
    padding-top: 10px;
    margin-bottom: 10px;
  }
}

.cart-summary-footer__payment-logos-item {
  padding: 0 10px;
  max-height: 80px;
  max-width: 80px;
}

.cart-summary-footer__usps {
  background: var(--theme-beige);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.cart-summary-footer__usp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding: 5px;
  color: var(--theme-primary);
  white-space: nowrap;
}

.cart-summary-footer__usp-icon {
  margin-right: 5px;
}

.cart-summary__background-wrapper {
  background: var(--bg-cart-mobile);
}
@media (min-width: 48em) {
  .cart-summary__background-wrapper {
    background: var(--bg-cart);
  }
}

.cart-summary {
  padding: 0;
  background: var(--bg-cart-summary);
}

.cart-summary__content {
  padding: 0;
}
@media (min-width: 48em) {
  .cart-summary__content {
    padding: 0;
  }
}

.cart-summary__voucher-input {
  padding: 10px;
  background: var(--theme-beige);
  margin-bottom: 20px;
}

.cart-summary__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  width: 100%;
  min-width: 0;
}

.cart-summary__label {
  font-size: 14px;
  font-weight: 400;
  word-break: break-word;
  hyphens: auto;
}

.cart-summary__row-label {
  position: relative;
  display: flex;
}

.cart-summary__subtotal-label {
  font-size: var(--fs-l);
  margin-right: 1ch;
}

.cart-summary__subtotal-price {
  font-size: var(--fs-l);
  font-weight: var(--fw-bold);
  color: var(--color-cart-summary-total);
}

.cart-summary__delivery-label {
  font-size: var(--fs-sm);
  font-weight: var(--fw-regular);
  color: var(--color-checkout-delivery-cost);
  margin-right: 0;
  display: flex;
  flex-direction: row;
  gap: 4px;
}
.cart-summary__delivery-label span {
  cursor: pointer;
}

.cart-summary__delivery-price {
  font-family: var(--font-product-price);
  font-size: var(--fs-sm);
  font-weight: var(--fw-regular);
  color: currentcolor;
}

.cart-summary__payment-fee-price {
  font-family: var(--font-product-price);
  font-size: 14px;
  font-weight: var(--fw-regular);
  color: currentcolor;
}

.cart-summary__savings,
.cart-summary__club-savings {
  display: flex;
  justify-content: center;
  text-align: right;
  color: var(--color-cart-summary-savings);
  font-size: var(--fs-sm);
  font-weight: 400;
  margin-top: 6px;
  margin-bottom: 6px;
  flex-direction: column;
}
@media (min-width: 48em) {
  .cart-summary__savings,
  .cart-summary__club-savings {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}
.cart-summary__savings--icon,
.cart-summary__club-savings--icon {
  position: relative;
  bottom: -2px;
  right: 4px;
  cursor: pointer;
}
.cart-summary__savings .cart-summary__club-savings--link-underline,
.cart-summary__club-savings .cart-summary__club-savings--link-underline {
  text-decoration: underline;
}

.cart-summary__club-savings {
  cursor: pointer;
}

.cart-summary__installment {
  display: block;
  text-align: right;
  color: var(--color-cart-summary-installment);
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 6px;
}
@media (min-width: 48em) {
  .cart-summary__installment {
    margin-bottom: 10px;
  }
}

.cart-summary__total-label {
  margin-right: 0;
  font-size: var(--fs-l);
}

.cart-summary__tax-info {
  display: inline-block;
  font-size: var(--fs-xs);
  font-weight: var(--fw-regular);
  white-space: nowrap;
  hyphens: auto;
  color: var(--color-cart-summary-tax);
}

.cart-summary__price {
  white-space: nowrap;
  font-size: 22px;
  font-weight: var(--fw-semibold);
  color: var(--color-cart-summary-total);
}

.cart-summary__checkout-button {
  width: 100%;
  letter-spacing: 1.25px;
  font-weight: var(--fw-semibold);
  text-transform: uppercase;
  border-radius: var(--border-radius-default);
  display: flex;
}
.cart-summary__checkout-button .cart-summary__checkout-button-text {
  display: flex;
  flex-wrap: wrap;
  overflow-y: hidden;
  max-height: 18px;
  gap: 5px;
  justify-content: center;
}
.cart-summary__checkout-button .cart-summary__checkout-button-text .cart-summary__checkout-button-text--capitalize {
  text-transform: capitalize;
}

.cart-summary__payment-logos-item {
  padding: 0 10px;
  max-height: 80px;
  max-width: 80px;
}

.cart-summary__usps {
  background: var(--theme-beige);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.cart-summary__usp {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 12px;
  padding: 5px;
  color: var(--theme-primary);
  white-space: nowrap;
}

.cart-summary__usp-icon {
  margin-right: 5px;
}

.cart-summary--sticky {
  padding: 0 0 8px;
}
.cart-summary--sticky .cart-summary__desktop-container {
  padding: 4px 15px;
}
@media (min-width: 48em) {
  .cart-summary--sticky .cart-summary__desktop-container {
    display: grid;
    grid-template-columns: auto 330px;
  }
}
.cart-summary--sticky .cart-summary__total .cart-summary__price, .cart-summary--sticky .cart-summary__subtotal .cart-summary__price, .cart-summary--sticky .cart-summary__delivery .cart-summary__price {
  font-size: var(--fs-l);
}
.cart-summary--sticky .cart-summary-footer__usp {
  display: none;
}
.cart-summary--sticky .paypal-express {
  display: none;
}
@media (min-width: 48em) {
  .cart-summary--sticky .cart-summary__content {
    max-width: 1300px;
    padding: 4px 15px;
    margin: auto;
    align-items: center;
  }
  .cart-summary--sticky .cart-summary__total, .cart-summary--sticky .cart-summary__subtotal, .cart-summary--sticky .cart-summary__delivery {
    margin-bottom: 0;
  }
  .cart-summary--sticky .cart-summary__installment,
  .cart-summary--sticky .cart-summary__club-savings {
    text-align: left;
  }
  .cart-summary--sticky .cart-summary__row {
    display: block;
  }
  .cart-summary--sticky .cart-summary__checkout-button {
    height: 46px;
    font-size: 20px;
  }
}

.cart-summary__voucher-head {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}
.cart-summary__voucher-head:hover {
  cursor: pointer;
}

.cart-summary__voucher-body {
  display: flex;
  flex-direction: column;
}

.cart-summary__voucher-body-code {
  padding: 15px 25px;
  margin: 10px 20px;
  border: 1px solid var(--color-gray-600);
  border-radius: 50px;
}

.cart-summary__voucher-body-btn {
  padding: 15px 25px;
  margin: 10px 20px;
  border: none;
  background: var(--bg-button-tertiary);
  border-radius: 50px;
}
.cart-summary__voucher-body-btn:hover {
  cursor: pointer;
}

.cart-summary__voucher-msg-success {
  color: var(--theme-success);
  padding: 0 20px;
  margin-top: 20px;
}

.cart-summary__voucher-msg-error {
  color: var(--theme-error);
  padding: 0 20px;
  margin-top: 20px;
}

.cart-summary__voucher-wrapper {
  display: grid;
  grid-template-columns: 90% 10%;
  padding: 0 20px;
}

.cart-summary__remove-button {
  text-align: right;
  font-size: 20px;
}
.cart-summary__remove-button:hover {
  cursor: pointer;
}

.cart-summary__voucher {
  max-width: 250px;
}

.cart-summary__voucher-value {
  float: right;
  font-weight: 900;
}

.cart-summary__separator {
  border-top: 2px solid #eee;
  padding-top: 5px;
}
@media (min-width: 48em) {
  .cart-summary__separator {
    padding-top: 10px;
  }
}
@media (min-width: 75em) {
  .cart-summary__separator {
    padding-top: 8px;
  }
}

.cart-summary__border-top {
  border-radius: none;
  border-top: 1px solid var(--color-gray-80);
  border-left: 1px solid var(--color-gray-80);
  border-right: 1px solid var(--color-gray-80);
  padding: 14px 14px 0;
  border-top-right-radius: var(--border-radius-default);
  border-top-left-radius: var(--border-radius-default);
  margin: 0 15px;
}
@media (min-width: 48em) {
  .cart-summary__border-top {
    margin: 0;
  }
}

.cart-summary__border-side {
  border-radius: none;
  border-left: 1px solid var(--color-gray-80);
  border-right: 1px solid var(--color-gray-80);
  padding: 0 14px;
  margin: 0 15px;
}
@media (min-width: 48em) {
  .cart-summary__border-side {
    margin: 0;
  }
}

.cart-summary__border-bottom {
  border-radius: none;
  border-bottom: 1px solid var(--color-gray-80);
  border-left: 1px solid var(--color-gray-80);
  border-right: 1px solid var(--color-gray-80);
  padding: 0 14px 14px;
  border-bottom-right-radius: var(--border-radius-default);
  border-bottom-left-radius: var(--border-radius-default);
  margin: 0 15px;
}
@media (min-width: 48em) {
  .cart-summary__border-bottom {
    margin: 0;
  }
}

.cart-summary__box-shadow {
  box-shadow: 0 -2px 20px var(--color-gray-900-20);
  margin-left: 0;
  margin-right: 0;
  border: none;
  padding: 10px;
}

.cart-summary__payment-fee-label {
  font-size: var(--fs-sm);
}

.cart-empty-state__headline {
  text-align: center;
  color: var(--theme-primary);
  margin-bottom: 8px;
}
@media (min-width: 48em) {
  .cart-empty-state__headline {
    margin-bottom: 16px;
  }
}

.cart-empty-state__subline {
  text-align: center;
  margin-bottom: 12px;
}
@media (min-width: 48em) {
  .cart-empty-state__subline {
    font-size: var(--fs-l);
  }
}

.cart-empty-state__icon {
  text-align: center;
  margin-top: 2%;
  font-size: 66px;
}

.cart-empty-state__text {
  text-align: center;
  margin-top: 2%;
  color: var(--theme-primary);
}

.cart-empty-state__button {
  text-align: center;
  margin-top: 0;
  margin-bottom: 20px;
}

.cart-empty-state__empty-link {
  border-radius: var(--border-radius-default);
  padding: 10px 28px;
  background: var(--quicklink-button-color);
  min-width: 185px;
  border-color: transparent;
  color: var(--empty-cart-link);
}

.splp-cart__container {
  display: grid;
  grid-row-gap: 0;
  grid-template-areas: "products" "addon" "summary";
  background-color: var(--bg-cart);
  align-items: flex-start;
  padding: 0;
}
@media (min-width: 48em) {
  .splp-cart__container {
    grid-template-columns: auto 280px;
    grid-template-areas: "products summary" "addon summary";
    background-color: var(--bg-cart-mobile);
  }
}
@media (min-width: 75em) {
  .splp-cart__container {
    grid-template-columns: auto 350px;
  }
}
@media (min-width: 81.25em) {
  .splp-cart__container {
    grid-template-columns: auto 450px;
  }
}

.splp-cart__background {
  background-color: var(--bg-cart);
  padding: 20px 0;
}

.splp-cart__products {
  grid-area: products;
}
@media (min-width: 48em) {
  .splp-cart__products {
    margin-right: 6px;
  }
}

.splp-cart__headline-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: var(--fs-s);
  padding-bottom: 8px;
}
@media (min-width: 48em) {
  .splp-cart__headline-wrapper {
    padding: 20px 0 3px;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }
}
.splp-cart__headline-wrapper .splp-cart__headline-container {
  display: flex;
  align-items: baseline;
  gap: 15px;
}
.splp-cart__headline-wrapper .splp-cart__headline-container .splp-cart__headline {
  color: var(--theme-primary);
  font-size: var(--fs-l);
}
@media (min-width: 48em) {
  .splp-cart__headline-wrapper .splp-cart__headline-container .splp-cart__headline {
    font-size: var(--fs-xl);
  }
}
.splp-cart__headline-wrapper .splp-cart__headline-container .splp-cart__amount {
  display: none;
  gap: 6px;
  color: var(--color-gray-600);
  margin: 0;
}
.splp-cart__headline-wrapper .splp-cart__headline-container .splp-cart__amount span:last-child {
  font-weight: var(--fw-semibold);
}
@media (min-width: 48em) {
  .splp-cart__headline-wrapper .splp-cart__headline-container .splp-cart__amount {
    display: flex;
  }
}
.splp-cart__headline-wrapper .splp-cart__no-reservation {
  color: var(--color-gray-500);
  font-size: var(--fs-xs);
}

.splp-cart__addon {
  grid-area: addon;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  padding: 0 15px;
}
@media (min-width: 48em) {
  .splp-cart__addon {
    padding: 15px;
    background: var(--bg-cart-addon);
  }
}

.splp-cart__addon-headline {
  font-size: var(--fs-h2);
}

.splp-cart__summary {
  grid-area: summary;
  position: sticky;
  top: 20px;
  overflow: auto;
}
@media (min-width: 48em) {
  .splp-cart__summary {
    margin-left: 6px;
  }
}

.splp-cart--has-sticky-summary .splp-cart__summary {
  display: none;
}
.splp-cart--has-sticky-summary .splp-cart__sticky-summary {
  display: block;
  position: fixed;
  z-index: 10;
  right: 0;
  left: 0;
  bottom: 0;
  background: var(--bg-cart-sticky-footer);
}

.splp-cart__customerclub-details--expire {
  text-align: center;
  border: 1px solid var(--color-gray-80);
  border-radius: var(--border-radius-default);
  background: var(--color-white-true);
  padding: 16px;
  margin: 0 15px 26px;
  align-items: center;
}
@media (min-width: 48em) {
  .splp-cart__customerclub-details--expire {
    margin: 0 0 26px;
  }
}
@media (min-width: 75em) {
  .splp-cart__customerclub-details--expire {
    display: flex;
    justify-content: space-between;
    text-align: start;
  }
}
.splp-cart__customerclub-details--expire button {
  line-height: normal;
  width: 100%;
  padding: 6px 4px;
  margin-top: 5px;
}
@media (min-width: 75em) {
  .splp-cart__customerclub-details--expire button {
    width: auto;
    margin: 0;
  }
}
.splp-cart__customerclub-details--expire .splp-cart__icon {
  color: var(--color-red);
  position: relative;
  top: 2px;
  margin-right: 8px;
}

.splp-cart__product-card {
  padding: 8px;
  border: 1px solid var(--color-gray-80);
  border-radius: var(--border-radius-default);
  background: var(--color-white-true);
  margin: 0 15px 16px;
}
@media (min-width: 48em) {
  .splp-cart__product-card {
    margin: 0 0 26px;
  }
}

.splp-cart__customer-card {
  margin: 0 15px;
}
@media (min-width: 48em) {
  .splp-cart__customer-card {
    margin: 0;
  }
}

.splp-cart__separator {
  border-top: 1px solid rgba(233, 233, 233, 0.9137254902);
  width: calc(100% - 30px);
  margin: 10px auto;
}

.paypal-express {
  margin-top: 15px;
}

.splp-cart__progress-bar-container-margin {
  margin-bottom: 16px;
}
@media (min-width: 48em) {
  .splp-cart__progress-bar-container-margin {
    margin-bottom: 26px;
  }
}

.splp-cart__progress-bar-container {
  padding: 17px 15px;
  border: 1px solid var(--color-gray-80);
  border-radius: var(--border-radius-default);
  background: var(--color-white-true);
  min-width: 230px;
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
}
@media (min-width: 48em) {
  .splp-cart__progress-bar-container {
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 47.99em) {
  .splp-cart__progress-bar-container {
    font-size: var(--fs-s);
  }
}
.splp-cart__progress-bar-container .splp-cart__progress-bar-header {
  text-align: center;
  margin-top: 0 !important; /* stylelint-disable-line declaration-no-important */
  margin-bottom: 10px;
}
.splp-cart__progress-bar-container .splp-cart__progress-bar-header .splp-cart__progress-bar-price-difference {
  color: var(--theme-primary);
  font-weight: 700;
}
.splp-cart__progress-bar-container .splp-cart__progress-bar {
  text-align: center;
  width: 100%;
  height: 28px;
  overflow: hidden;
  background-color: var(--bg-shipping-cost-bar);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__text {
  color: var(--color-white-true);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding-left: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  min-width: fit-content;
  gap: 5px;
  cursor: pointer;
}
.splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__text a {
  color: var(--color-white-true);
}
.splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__text p {
  margin: 0;
}
.splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__text .splp-cart__price {
  font-weight: 800;
  font-size: var(--fs-18);
  margin-left: auto;
}
.splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__progress {
  width: 0%;
  height: 100%;
  background-color: var(--theme-primary);
  transition: width 0.3s ease;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: flex-end;
  position: relative;
}
@media (min-width: 48em) {
  .splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__progress {
    cursor: pointer;
  }
}
.splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__arrow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 20px;
  transition: left 0.3s ease;
  transform: scale(1.4);
  right: -7px;
}
@media (min-width: 48em) {
  .splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__arrow {
    cursor: pointer;
  }
}
.splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__arrow .splp-cart__polygon {
  fill: var(--theme-primary);
  z-index: 0;
}
.splp-cart__progress-bar-container .splp-cart__progress-bar .splp-cart__progress-bar-goal {
  color: var(--theme-primary);
  font-weight: 800;
  font-size: var(--fs-18);
  padding-left: 15px;
  padding-right: 8px;
}

.splp-cart__bold {
  font-weight: 700;
}

.splp-cart__text-green {
  color: var(--theme-success);
}

.splp-cart__underline {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

@media (max-width: 74.99em) {
  .wko-modal__modal-body {
    padding: 24px;
  }
}
@media (max-width: 47.99em) {
  .wko-modal__modal-body {
    padding: 14px;
  }
}

.wko-modal__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wko-modal__content-container {
  background: var(--theme-beige);
  border-radius: var(--border-radius-product-image-container);
  transform: translateY(8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.wko-modal__headline {
  color: var(--theme-primary);
  font-size: var(--fs-l);
  font-weight: var(--fw-semibold);
  white-space: pre-wrap;
  text-align: center;
  margin: 12px 0;
}

.wko-modal__content {
  display: flex;
  justify-content: center;
  padding: 24px;
  gap: 16px;
  color: var(--theme-primary);
  align-items: center;
}

.wko-modal__image {
  height: 75px;
  width: 75px;
  border-radius: var(--border-radius-default);
}

.wko-modal__description {
  display: flex;
  flex-direction: column;
}
.wko-modal__description--title {
  font-weight: var(--fw-semibold);
}

.wko-modal__decline-button {
  border-radius: var(--border-radius-default);
  padding: 11px;
  font-size: 18px;
  text-transform: unset;
  border: 1px solid var(--theme-primary);
  background: var(--color-white-true);
  color: var(--theme-primary);
  width: 100%;
}
@media (min-width: 75em) {
  .wko-modal__decline-button {
    width: 50%;
  }
}

.wko-modal__success-overlay {
  height: 100%;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 535px;
}
.wko-modal__success-overlay .icon {
  border-radius: 50%;
  background: rgba(56, 170, 3, 0.2);
  color: var(--theme-success);
  width: 120px;
  height: 120px;
  padding: 24px;
}
.wko-modal__success-overlay .wko-modal__success-message {
  white-space: pre-wrap;
  text-align: center;
  margin-top: 12px;
  color: var(--theme-success);
  font-size: var(--fs-l);
}

.wko-upsell-product {
  position: relative;
  display: grid;
  grid-template-areas: "productImage productDetail" "productImage orderInfo";
  grid-template-columns: 25% auto;
  grid-gap: 15px;
  padding: 10px 10px 16px;
  margin: 0 20px 26px;
  border: 1px solid var(--color-gray-80);
  border-radius: var(--border-radius-default);
  background: var(--color-white-true);
  width: 100%;
}
@media (min-width: 48em) {
  .wko-upsell-product {
    background: var(--color-white-true);
    padding: 16px;
    margin: 0 0 26px;
  }
}
.wko-upsell-product .wko-upsell-product__badge {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
}

.wko-upsell-product__image-container {
  border-radius: var(--border-radius-default);
  grid-area: productImage;
  overflow: hidden;
  aspect-ratio: 1/1;
  display: flex;
  align-items: stretch;
}

.wko-upsell-product__image {
  object-fit: cover;
}

.wko-upsell-product__details {
  grid-area: productDetail;
  margin-right: 12px;
  font-size: var(--fs-s);
}

.wko-upsell-product__order-info {
  grid-area: orderInfo;
  align-self: end;
}

.wko-upsell-product__detail-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 5px 10px 5px 0;
}

.wko-upsell-product__title {
  font-size: 16px;
  color: var(--theme-primary);
  font-weight: 700;
}

.wko-upsell-product-description__preview--oversized {
  overflow-y: hidden;
}

.wko-upsell-product__price-info-container {
  display: flex;
  justify-content: space-between;
}
.wko-upsell-product__price-info-container--sale {
  color: var(--color-red);
}
@media (max-width: 47.99em) {
  .wko-upsell-product__price-info-container {
    display: block;
  }
}

.wko-upsell-product__price {
  align-self: flex-end;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 27.5em) {
  .wko-upsell-product__price {
    flex-direction: column;
    align-items: baseline;
    gap: 8px;
  }
}
.wko-upsell-product__price .price-box__old-price {
  color: var(--color-black-true);
  margin-bottom: 10px;
}
.wko-upsell-product__price .price-box__price {
  color: var(--pre-checkout-product-price);
  font-size: 25px;
}

.wko-wpsell-product__promotion {
  margin-bottom: 12px;
  padding: 3px 16px;
  border-radius: var(--border-radius-precheckout);
  font-weight: 600;
  font-size: 13px;
  display: none;
}
@media (min-width: 48em) {
  .wko-wpsell-product__promotion {
    display: block;
  }
}

.wko-upsell-product__badge-wrapper {
  display: none;
  width: 100%;
  position: absolute;
}
@media (min-width: 75em) {
  .wko-upsell-product__badge-wrapper {
    display: flex;
  }
}

.wko-upsell-product__badge-container--top {
  display: flex;
  flex-direction: row;
  width: inherit;
  position: absolute;
  align-items: flex-start;
  gap: 4px;
  justify-content: space-between;
}
.wko-upsell-product__badge-container--top :first-child {
  border-radius: var(--border-radius-default) 0 0 0;
}
.wko-upsell-product__badge-container--top :nth-child(2) {
  border-radius: 0 var(--border-radius-default) 0 0;
}

.wko-upsell-product__cta-btn-cart {
  border-radius: var(--border-radius-default);
  padding: 11px;
  font-size: 18px;
  text-transform: unset;
  border: 1px solid var(--theme-primary);
  background: var(--color-white-true);
  color: var(--theme-primary);
  max-height: 45px;
  align-self: end;
  min-width: 140px;
}
@media (max-width: 27.5em) {
  .wko-upsell-product__cta-btn-cart {
    padding: 8px 4px;
    font-size: var(--fs-s);
    align-self: baseline;
  }
}

.wko-upsell-product__product-details__usps {
  margin-bottom: 8px;
}
.wko-upsell-product__product-details__usps ::before {
  border-radius: 50%;
  height: 6px;
  width: 6px;
  margin-top: 2px;
}

.wko-upsell-product__product-details__wrapper {
  display: flex;
  flex-direction: column;
}

.wko-upsell-product__product-details__claim,
.wko-upsell-product__product-details__deliverables {
  margin-bottom: 8px;
}

.wko-upsell-product__product-details__deliverables-text {
  font-weight: var(--fw-semibold);
}

.wko-upsell-product__product-details__cta-btn {
  border-radius: var(--border-radius-default);
  margin: 8px 0 12px;
  padding: 11px;
  width: 100%;
  font-size: 18px;
  align-self: center;
  text-transform: unset;
  border: 1px solid var(--theme-primary);
  background: var(--color-white-true);
  color: var(--theme-primary);
}
@media (max-width: 74.99em) {
  .wko-upsell-product__product-details__cta-btn {
    width: 50%;
    align-self: baseline;
    font-size: var(--fs-s);
    padding: 8px 4px;
    min-width: 140px;
  }
}

.wko-upsell-product__product-details__rating {
  align-self: baseline;
  margin-bottom: 8px;
  font-size: 16px;
}
.wko-upsell-product__product-details__rating .wko-upsell-product__product-details__review-count {
  font-size: 12px;
}

.container-account__content-wrapper .content-width-wrapper--holder {
  display: block;
}
@media (min-width: 48em) {
  .container-account__content-wrapper .content-width-wrapper--holder {
    display: grid;
    grid-template-areas: ". header" "navigation content";
    grid-template-columns: 21% 1fr;
    grid-gap: 30px;
  }
}

.container-account__account-header {
  grid-area: header;
}

.container-inline {
  display: grid;
  align-items: center;
  gap: 50px;
  grid-auto-columns: 1fr;
}
@media (min-width: 48em) {
  .container-inline {
    grid-auto-flow: column;
    padding: 30px 0;
  }
}

.container-inline__item .container-wrapper {
  width: auto;
}

.container-account-wrapper .content-width-wrapper--desktop .content-width-wrapper--holder {
  display: block;
}
@media (min-width: 48em) {
  .container-account-wrapper .content-width-wrapper--desktop .content-width-wrapper--holder {
    display: grid;
    grid-template-columns: 21% 1fr;
    grid-gap: 30px;
  }
}

body {
  scroll-behavior: smooth;
  position: relative;
}

input,
div.select {
  border-radius: var(--border-radius-default);
}

@media (min-width: 48em) {
  .toaster-wrapper {
    min-width: 250px;
  }
}
.toaster-wrapper button {
  font-size: 20px;
}

.pointer-events--disabled {
  pointer-events: none;
}

.main-navigation-vertical-brand__wrapper {
  border-bottom: 1px solid var(--border-color-main-navigation);
  position: relative;
  z-index: 5;
}

.main-navigation-vertical-brand__content {
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-navigation-vertical-brand__logo-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

@media (min-width: 75em) {
  .main-navigation-vertical-brand__burger-menu {
    display: none;
  }
}
.main-navigation-vertical-brand__burger-menu svg {
  font-size: 45px;
  transform: scale(0.7);
  cursor: pointer;
}

.main-navigation-vertical-brand__icon-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  grid-column: 3;
  grid-area: actions;
  margin-right: 8px;
  position: relative;
  place-self: center flex-end;
}
.main-navigation-vertical-brand__icon-wrapper > div,
.main-navigation-vertical-brand__icon-wrapper > a {
  margin: 0 4px;
  padding: 4px;
}
@media (min-width: 75em) {
  .main-navigation-vertical-brand__icon-wrapper {
    margin-right: 0;
    align-items: center;
  }
}
.main-navigation-vertical-brand__icon-wrapper .icon {
  color: var(--color-icons);
  fill: var(--color-icons);
  transform: scale(1);
  font-size: 25px;
  display: block;
}
.main-navigation-vertical-brand__icon-wrapper .country-switch__title {
  display: none;
}
.main-navigation-vertical-brand__icon-wrapper .country-switch {
  position: unset;
  padding: 0;
}
@media (min-width: 48em) {
  .main-navigation-vertical-brand__icon-wrapper .country-switch {
    position: relative;
  }
}
@media (max-width: 47.99em) {
  .main-navigation-vertical-brand__icon-wrapper .country-switch__select-container {
    position: absolute;
    background-color: #fff;
    z-index: var(--zi-index-dropdown);
    padding: 30px;
    left: calc(-99vw + 100%);
    width: 101vw;
    border-right: none;
    border-left: none;
  }
}
.main-navigation-vertical-brand__icon-wrapper .dropdown__header {
  font-size: 16px;
  /* stylelint-disable-next-line selector-class-pattern */
}
.main-navigation-vertical-brand__icon-wrapper .dropdown__header .icon--ChevronDown {
  display: none;
}
@media (min-width: 48em) {
  .main-navigation-vertical-brand__icon-wrapper .dropdown__header .icon--ChevronDown {
    display: inline-block;
  }
}

.main-navigation-vertical-brand__cart-item-amount {
  position: absolute;
  top: 0;
  left: 15px;
  color: #fff;
  background-color: var(--theme-primary);
  border-radius: 50%;
  font-size: 0.8em;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
  font-weight: var(--fw-normal);
}

.main-navigation-vertical-brand__login-wrapper {
  position: relative;
  cursor: pointer;
}

.main-navigation-vertical-brand__login-wrapper:hover .login-dropdown {
  display: block;
}

.main-navigation-vertical-brand__icon {
  transform: scale(1.2);
}

.main-navigation-vertical-brand__icon:hover {
  cursor: pointer;
}

.main-navigation-vertical-brand__cart-link {
  position: relative;
}

.main-navigation-vertical-brand__shipping-wrapper {
  display: none;
  grid-area: shipping;
  color: var(--free-delivery-highlighted);
  font-family: var(--ff-menu);
  flex-direction: column;
  justify-content: center;
}
@media (min-width: 75em) {
  .main-navigation-vertical-brand__shipping-wrapper {
    display: flex;
  }
}
.main-navigation-vertical-brand__shipping-wrapper p:first-of-type {
  font-family: var(--ff-menu);
  font-size: 0.7rem;
  font-weight: 800;
}
@media (min-width: 48em) {
  .main-navigation-vertical-brand__shipping-wrapper p:first-of-type {
    font-size: 1.6rem;
  }
}
.main-navigation-vertical-brand__shipping-wrapper p {
  margin: 0;
  line-height: 1.1;
  font-size: 0.5rem;
}
@media (min-width: 48em) {
  .main-navigation-vertical-brand__shipping-wrapper p {
    font-size: 1rem;
  }
}

/**
* Desktop styles
*/
.main-navigation-vertical-menu__desktop-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.main-navigation-vertical-menu__desktop-wrapper a {
  color: var(--menu-color-primary);
}

@media (min-width: 75em) {
  .main-navigation-vertical-menu__desktop-wrapper--link-text {
    padding: 14px 1px;
    position: relative;
  }
}

@media (min-width: 75em) {
  .main-navigation-vertical-menu__desktop-wrapper:hover .main-navigation-vertical-menu__main-navigation-link-hovered svg {
    transform: scale(0.9) rotate(180deg);
    margin-top: 17px;
  }
}

@media (min-width: 75em) {
  .main-navigation-vertical-menu__arrow-down {
    margin: 14px 0;
  }
}

.main-navigation-vertical-menu__desktop-wrapper--child-menu-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.main-navigation-vertical-menu__desktop-wrapper--child-menu-container--with-child-heading {
  display: flex;
  gap: 25px;
}

/**
* Mobile styles
*/
@media (max-width: 74.99em) and (max-width: 74.99em) {
  .main-navigation-vertical-menu__arrow-icon-rotation-state svg {
    transform: scale(0.9) rotate(180deg);
  }
}
@media (max-width: 74.99em) and (min-width: 75em) {
  .main-navigation-vertical-menu__arrow-icon-rotation-state svg {
    transform: scale(0.9) rotate(0deg);
  }
}

.main-navigation-vertical-menu__mobile-wrapper {
  position: absolute;
  top: 81px;
  right: 0;
  left: 0;
  height: 100vh;
  z-index: 10;
  background-color: var(--modal-overlay-bg-color);
}

.main-navigation-vertical-menu__mobile-content-wrapper {
  background-color: var(--color-white-true);
  max-height: calc(100% - var(--mobile-menu-top));
  overflow-y: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}

@supports (height: 100dvh) {
  .main-navigation-vertical-menu__mobile-wrapper {
    height: 100dvh;
  }
  .main-navigation-vertical-menu__mobile-content-wrapper {
    max-height: calc(100dvh - var(--mobile-menu-top));
  }
}
/**
* Common styles
*/
.main-navigation-vertical-menu__main-navigation-link {
  cursor: pointer;
  color: var(--menu-color-primary);
  border-bottom: 3px solid transparent;
  height: 42px;
  font-size: var(--fs-main-navigation-desktop);
  width: fit-content;
  display: flex;
  align-items: center;
}
.main-navigation-vertical-menu__main-navigation-link svg {
  margin-left: 5px;
  padding-top: 4px;
  transition: transform 0.3s ease;
  fill: var(--menu-color-primary);
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__main-navigation-link:hover svg {
    margin-top: 0;
  }
}
@media (min-width: 75em) {
  .main-navigation-vertical-menu__main-navigation-link:hover svg {
    margin-top: 17px;
    transform: scale(0.9) rotate(180deg);
  }
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__main-navigation-link {
    font-size: var(--fs-main-navigation-mobile);
    height: auto;
    padding: 3px;
    width: calc(100% - 23px);
    justify-content: space-between;
    margin-left: 10px;
  }
}
.main-navigation-vertical-menu__main-navigation-link a {
  display: inline-flex;
  color: var(--menu-color-primary);
  font-size: var(--fs-main-navigation-desktop);
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__main-navigation-link a {
    font-size: var(--fs-main-navigation-mobile);
  }
}

.main-navigation-vertical-menu__main-navigation-link-active,
.main-navigation-vertical-menu__main-navigation-link-hovered {
  color: var(--color-main-navigation-vb-hover);
  border-bottom: solid 3px var(--color-main-navigation-vb-hover);
}
.main-navigation-vertical-menu__main-navigation-link-active a,
.main-navigation-vertical-menu__main-navigation-link-active span,
.main-navigation-vertical-menu__main-navigation-link-hovered a,
.main-navigation-vertical-menu__main-navigation-link-hovered span {
  color: var(--color-main-navigation-vb-hover);
}
.main-navigation-vertical-menu__main-navigation-link-active svg,
.main-navigation-vertical-menu__main-navigation-link-hovered svg {
  fill: var(--color-main-navigation-vb-hover);
}

.main-navigation-vertical-menu__child-navigation-link-active {
  color: var(--color-main-navigation-vb-hover);
}
.main-navigation-vertical-menu__child-navigation-link-active a,
.main-navigation-vertical-menu__child-navigation-link-active span {
  color: var(--color-main-navigation-vb-hover) !important; /* stylelint-disable-line declaration-no-important */
}
.main-navigation-vertical-menu__child-navigation-link-active svg {
  fill: var(--color-main-navigation-vb-hover);
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__child-navigation-link-active svg {
    transform: scale(0.9) rotate(0deg);
  }
}
@media (min-width: 75em) {
  .main-navigation-vertical-menu__child-navigation-link-active svg {
    transform: scale(0.9) rotate(180deg);
  }
}

@media (min-width: 75em) {
  .main-navigation-vertical-menu__child-navigation-wrapper {
    position: absolute;
    z-index: 10;
    inset: 61px 0 0;
    pointer-events: none;
  }
}

@media (min-width: 75em) {
  .main-navigation-vertical-menu__child-navigation {
    pointer-events: all;
    position: absolute;
    background-color: var(--theme-beige);
    z-index: 15;
    left: 0;
    right: 0;
    top: 0;
  }
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__child-navigation {
    background-color: var(--theme-beige);
    padding: 10px 0;
    margin-bottom: 10px;
  }
}

.main-navigation-vertical-menu__navigation-list {
  display: flex;
  flex-flow: column wrap;
  gap: 10px 15px;
  list-style-type: none;
}
.main-navigation-vertical-menu__navigation-list--with-child-heading {
  gap: 5px 0;
}
@media (min-width: 75em) {
  .main-navigation-vertical-menu__navigation-list {
    margin: 20px 0 20px 5px;
  }
  .main-navigation-vertical-menu__navigation-list--with-child-heading {
    max-height: fit-content;
  }
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__navigation-list {
    margin: 10px 0 20px 25px;
  }
  .main-navigation-vertical-menu__navigation-list--with-child-heading {
    margin: 10px 0 10px 20px;
  }
}

.main-navigation-vertical-menu__navigation-list-item {
  margin: 0;
  font-size: var(--fs-main-navigation-desktop);
  width: fit-content;
}
.main-navigation-vertical-menu__navigation-list-item--headline {
  margin-bottom: 3px;
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__navigation-list-item {
    font-size: var(--fs-main-navigation-mobile);
    width: auto;
  }
}
.main-navigation-vertical-menu__navigation-list-item a,
.main-navigation-vertical-menu__navigation-list-item span {
  color: var(--menu-color-primary);
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__navigation-list-item a,
  .main-navigation-vertical-menu__navigation-list-item span {
    font-size: var(--fs-main-navigation-mobile);
  }
}
.main-navigation-vertical-menu__navigation-list-item a:hover {
  color: var(--color-main-navigation-vb-hover);
}
.main-navigation-vertical-menu__navigation-list-item span {
  padding: 6px 0;
}

.cursor-pointer {
  cursor: pointer;
}

.main-navigation-vertical-menu__backdrop {
  position: relative;
}
@media (min-width: 75em) {
  .main-navigation-vertical-menu__backdrop::after {
    opacity: 1;
    pointer-events: unset;
  }
}

/* stylelint-disable declaration-no-important */
:root {
  --zi-index-dropdown: 10;
  --color-main-navigation-vb-hover: var(--theme-primary);
}

:root {
  --theme-primary: #0047bb;
  --theme-secondary: #8cc7e9;
  --theme-tertiary: #d0d0ce;
  --theme-cta-font-color: #fff;
  --theme-attention: var(--theme-primary);
  --theme-gray: #75787b;
  --ff-base: "Roboto Condensed", sans-serif;
  --ff-title: "Roboto Condensed", sans-serif;
  --ff-headline: "Sofia Sans Condensed", sans-serif;
  --font-base: "Roboto Condensed", sans-serif;
  --font-title: "Roboto Condensed", sans-serif;
  --font-headlines: "Sofia Sans Condensed", sans-serif;
  --fs-main-navigation-mobile: 20px;
  --ff-menu: "Roboto Condensed", sans-serif;
  --font-product-price: "Roboto Condensed", sans-serif;
  --fw-normal: 400;
  --color-teaser-cta-box: var(--theme-tertiary);
  --color-teaser-text-cta-box: var(--theme-cta-font-color);
  --bg-button-primary: var(--theme-primary);
  --color-button-tertiary: var(--color-white-true);
  --background-product-buy-button: var(--theme-primary);
  --pdp-color-buy-button: var(--theme-cta-font-color);
  --pdp-background-buy-button: var(--theme-primary);
  --pdp-background-caption: var(--theme-tertiary);
  --footer-background-color: var(--theme-primary);
  --footer-font-color: var(--theme-cta-font-color);
  --plp-headline-color: var(--theme-cta-font-color);
  --empty-cart-link: var(--theme-cta-font-color);
  --theme-on-tertiary-border: var(--theme-cta-font-color);
  --theme-on-tertiary: var(--theme-cta-font-color);
  --quicklink-button-color: var(--theme-tertiary);
  --headline-font-size-desktop: 40px;
  --headline-line-height-desktop: 40px;
  --headline-font-size-mobile: 28px;
  --headline-line-height-mobile: 24px;
  --headline-margin-bottom: 6px;
  --subheadline-font-size-mobile: 17px;
  --subheadline-line-height-mobile: 20px;
  --color-headline: var(--theme-primary);
  --color-highlight: var(--theme-cta-font-color);
  --free-delivery-highlighted: var(--color-red);
  --menu-color-primary: #444;
  --zi-index-dropdown: 10;
  --mobile-menu-top: 110px;
  --desktop-navigation-font-weight: var(--fw-normal);
}

@font-face {
  font-family: "Sofia Sans Condensed";
  src: url("../assets/fonts/SofiaSansCondensed/SofiaSansCondensed-VariableFont_wght.woff2") format("woff2");
  font-style: normal;
  font-weight: 200 1000;
  font-display: swap;
}
@font-face {
  font-family: "Sofia Sans Condensed";
  src: url("../assets/fonts/SofiaSansCondensed/SofiaSansCondensed-Italic-VariableFont_wght.woff2") format("woff2");
  font-display: swap;
  font-style: italic;
  font-weight: 200 1000;
}
/* stylelint-disable declaration-no-important */
@media (max-width: 47.99em) {
  .main-navigation__content {
    gap: 10px !important;
  }
}
@media (max-width: 47.99em) {
  .main-navigation__content {
    grid-template-columns: 60px minmax(40px, 70px) auto 135px !important;
  }
}
@media (min-width: 48em) and (max-width: 74.99em) {
  .main-navigation__content {
    grid-template-columns: 120px 80px auto 155px !important;
  }
}
@media (max-width: 74.99em) {
  .main-navigation__content {
    grid-template-areas: "menuIcon logo shipping actions" "searchBar searchBar searchBar searchBar" "quickLinks quickLinks quickLinks quickLinks" !important;
  }
}

.main-navigation__logo-mobile {
  padding: 4px;
}
.main-navigation__logo-mobile img {
  max-height: 60px;
}

.main-navigation__shipping-wrapper {
  display: flex !important;
}

.burger-menu-button svg {
  font-size: 45px;
}
@media (min-width: 75em) {
  .burger-menu-button svg {
    font-size: var(--fs-sm);
  }
}

.main-navigation-custom__meta-right .user-menu__account,
.main-navigation-custom__meta-right .user-menu__cart-link {
  transform: scale(1.2);
}
.main-navigation-custom__meta-right .user-menu__cart-item-amount {
  top: -5px;
}

.main-navigation-vertical-brand__logo-container img {
  min-width: 150px;
  height: auto;
}
.main-navigation-vertical-brand__logo-container svg {
  font-size: 45px;
  cursor: pointer;
}

.main-navigation-vertical-brand__icon-wrapper {
  width: 150px;
}
.main-navigation-vertical-brand__icon-wrapper .main-navigation-vertical-brand__cart-link svg,
.main-navigation-vertical-brand__icon-wrapper .main-navigation-vertical-brand__user-link svg {
  transform: scale(1.2);
}
.main-navigation-vertical-brand__icon-wrapper .country-switch__select-country svg {
  transform: scale(1.6);
}

.main-navigation-vertical-brand__cart-item-amount {
  top: -5px;
  right: 0;
}

.main-navigation-vertical-brand__icon {
  fill: var(--theme-primary);
}

.main-navigation-vertical-brand__burger-menu svg {
  transform: scale(1) !important; /* stylelint-disable-line declaration-no-important */
}

/**
* Desktop styles
*/
.main-navigation-vertical-menu__desktop-wrapper {
  max-width: 600px;
  margin-top: 4px;
}

@media (min-width: 75em) {
  .main-navigation-vertical-menu__desktop-wrapper:hover .main-navigation-vertical-menu__main-navigation-link-hovered svg {
    transform: scale(2.3) rotate(180deg) !important; /* stylelint-disable-line declaration-no-important */
  }
}

/**
* Mobile styles
*/
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__child-navigation-link-active svg {
    transform: scale(2.3) rotate(0deg) !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (min-width: 75em) {
  .main-navigation-vertical-menu__child-navigation-link-active svg {
    transform: scale(2.3) rotate(180deg) !important; /* stylelint-disable-line declaration-no-important */
  }
}

@media (max-width: 74.99em) and (max-width: 74.99em) {
  .main-navigation-vertical-menu__arrow-icon-rotation-state svg {
    transform: scale(2.3) rotate(180deg) !important; /* stylelint-disable-line declaration-no-important */
  }
}
@media (max-width: 74.99em) and (min-width: 75em) {
  .main-navigation-vertical-menu__arrow-icon-rotation-state svg {
    transform: scale(2.3) rotate(0deg) !important; /* stylelint-disable-line declaration-no-important */
  }
}

/**
* Common styles
*/
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__main-navigation-link {
    overflow: hidden;
  }
  .main-navigation-vertical-menu__main-navigation-link svg {
    font-size: 25px;
  }
  .main-navigation-vertical-menu__main-navigation-link a,
  .main-navigation-vertical-menu__main-navigation-link span {
    font-weight: var(--fw-bold);
  }
}
@media (min-width: 75em) {
  .main-navigation-vertical-menu__main-navigation-link a,
  .main-navigation-vertical-menu__main-navigation-link span {
    font-weight: var(--fw-normal);
  }
}
.main-navigation-vertical-menu__main-navigation-link svg {
  padding-top: 1px !important; /* stylelint-disable-line declaration-no-important */
  transform: scale(2.3) rotate(0deg);
}
@media (min-width: 75em) {
  .main-navigation-vertical-menu__main-navigation-link:hover svg {
    transform: scale(2.3) rotate(180deg) !important; /* stylelint-disable-line declaration-no-important */
  }
}

@media (min-width: 75em) {
  .main-navigation-vertical-menu__child-navigation-wrapper {
    inset: 65px 0 0 !important; /* stylelint-disable-line declaration-no-important */
  }
}

.main-navigation-vertical-menu__main-navigation-link-active {
  height: 35px;
}

.main-navigation-vertical-menu__navigation-list-item {
  padding-left: 0;
}
.main-navigation-vertical-menu__navigation-list-item--headline {
  font-weight: var(--fw-semibold);
}
@media (max-width: 74.99em) {
  .main-navigation-vertical-menu__navigation-list-item--headline {
    font-weight: var(--fw-semibold);
    margin-bottom: 0;
  }
}

/* stylelint-disable declaration-no-important */
body,
ul,
li,
span,
p,
button,
a {
  font-family: var(--ff-base) !important;
}

p {
  margin: 12px 0 !important;
}

.product__title > a.link-open-in-tab {
  font-family: var(--ff-headline) !important;
}

/* Headlines */
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: var(--ff-headline) !important;
  margin: 0;
  color: var(--color-gray-800) !important;
}

.title-h1 {
  color: var(--theme-primary) !important;
}

.title-h2 {
  font-family: var(--ff-headline) !important;
}

.title-h3 {
  font-family: var(--ff-headline) !important;
}

.title-h4 {
  font-family: var(--ff-headline) !important;
}

/* Sub-Headlines */
.h1-sub {
  font-family: var(--ff-headline) !important;
  color: var(--theme-gray) !important;
  font-weight: var(--fw-normal) !important;
}

.title-h1-sub {
  font-family: var(--ff-base) !important;
  color: var(--theme-gray) !important;
  font-weight: var(--fw-normal) !important;
}

.title-h2-sub {
  font-family: var(--ff-base) !important;
  color: var(--theme-gray) !important;
  font-weight: var(--fw-normal) !important;
}

.title-h3-sub {
  font-family: var(--ff-headline) !important;
  color: var(--theme-gray) !important;
  font-weight: var(--fw-normal) !important;
}

.title-h4-sub {
  font-family: var(--ff-headline) !important;
  color: var(--theme-gray) !important;
  font-weight: var(--fw-normal) !important;
}
// Custom Properties in mediashop
:root {
    // Colors
    /////////////////////////////////////////////
    --theme-primary: #0047bb;
    --theme-secondary: #8cc7e9;
    --theme-tertiary: #d0d0ce;

    --theme-cta-font-color: #fff;
    --theme-attention: var(--theme-primary);

    --theme-gray: #75787b;

    // Fonts
    --ff-base: 'Roboto Condensed', sans-serif;
    --ff-title: 'Roboto Condensed', sans-serif;
    --ff-headline: 'Sofia Sans Condensed', sans-serif;
    --font-base: 'Roboto Condensed', sans-serif;
    --font-title: 'Roboto Condensed', sans-serif;
    --font-headlines: 'Sofia Sans Condensed', sans-serif;
    --fs-main-navigation-mobile: 20px;
    --ff-menu: 'Roboto Condensed', sans-serif;
    --font-product-price: 'Roboto Condensed', sans-serif;

    --fw-normal: 400;

    // CTA Colors
    --color-teaser-cta-box: var(--theme-tertiary);
    --color-teaser-text-cta-box: var(--theme-cta-font-color);

    // Button
    --bg-button-primary: var(--theme-primary);
    --color-button-tertiary: var(--color-white-true);
    --background-product-buy-button: var(--theme-primary);

    //PDP
    --pdp-color-buy-button: var(--theme-cta-font-color);
    --pdp-background-buy-button: var(--theme-primary);
    --pdp-background-caption: var(--theme-tertiary);

    // Footer
    --footer-background-color: var(--theme-primary);
    --footer-font-color: var(--theme-cta-font-color);

    // PLP-Search Results
    --plp-headline-color: var(--theme-cta-font-color);

    // Empty Cart Button
    --empty-cart-link: var(--theme-cta-font-color);

    // Newsletter Headline on tertiary theme
    --theme-on-tertiary-border: var(--theme-cta-font-color);
    --theme-on-tertiary: var(--theme-cta-font-color);

    //Live TV Link Button
    --quicklink-button-color: var(--theme-tertiary);

    // Headlines
    --headline-font-size-desktop: 40px;
    --headline-line-height-desktop: 40px;
    --headline-font-size-mobile: 28px;
    --headline-line-height-mobile: 24px;
    --headline-margin-bottom: 6px;
    --subheadline-font-size-mobile: 17px;
    --subheadline-line-height-mobile: 20px;
    --color-headline: var(--theme-primary);

    // Phone & Email in Contact
    --color-highlight: var(--theme-cta-font-color);

    // Navigation
    --free-delivery-highlighted: var(--color-red);
    --menu-color-primary: #444;

    --zi-index-dropdown: 10;

    // Mobile Menu
    --mobile-menu-top: 110px;

    // Desktop Navigation
    --desktop-navigation-font-weight: var(--fw-normal);
}

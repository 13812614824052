.main-navigation-custom__meta-right {
    .user-menu__account,
    .user-menu__cart-link {
        transform: scale(1.2)
    }

    .user-menu__cart-item-amount {
        top: -5px
    }
}

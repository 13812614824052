/* stylelint-disable declaration-no-important */
.main-navigation__content {
    @include mq($until: tablet) {
        gap: 10px !important;
    }

    @include mq($until: tablet) {
        grid-template-columns: 60px minmax(40px, 70px) auto 135px !important;
    }

    @include mq($from: tablet, $until: desktop) {
        grid-template-columns: 120px 80px auto 155px !important;
    }

    @include mq($until: desktop) {
        grid-template-areas:
            'menuIcon logo shipping actions'
            'searchBar searchBar searchBar searchBar'
            'quickLinks quickLinks quickLinks quickLinks' !important;
    }
}

.main-navigation__logo-mobile {
    padding: 4px;

    img {
        max-height: 60px;
    }
}

.main-navigation__shipping-wrapper {
    display: flex !important;
}
